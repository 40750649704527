/* eslint-disable no-unused-vars */

// React
import React, { useRef } from "react";

// Local
import { LabelTextField } from "components/LabelTextField";
import { LabelSelectField } from "components/LabelSelectField";
import { LabelNumberField } from "components/LabelNumberField";
import { isEmpty } from "util/common";
import {
  FULFILLMENT_PROCESS,
  FULFILLMENT_PROCESS_OPTIONS,
} from "constants/fulfillmentProcess";
import { getOptionText } from "util/common";
import { SUSTAINABLE_OPTIONS } from "constants/options";
import { EditThumbnailWrapper } from "components/EditThumbnailWrapper";

export const TopLevelSubcodeInfo = ({
  editSubcode,
  editSubcodeField,
  editMode,
  createMode,
  toggleEditMode,
  onSave,
  image,
  setImage,
  reloadImage
}) => {
  const saveButtonRef = useRef();

  const onOrderQuantityBlur = (value) => {
    if (value < 0) {
      editSubcodeField("minOrderQuantity", 0);
    } else {
      let remainder = value % editSubcode.packSize;
      editSubcodeField("minOrderQuantity", value - remainder);
    }
  };

  const onPackSizeBlur = (value) => {
    if (value < 1) {
      editSubcodeField("packSize", 1);
    } else {
      let remainder = editSubcode.minOrderQuantity % value;
      editSubcodeField(
        "minOrderQuantity",
        editSubcode.minOrderQuantity - remainder
      );
    }
  };

  const onInputKeyDown = (e) => {
    if (e.keyCode === 13) {
      e.preventDefault();
      saveButtonRef.current.click();
    }
  };

  return (
    <form
      onKeyDown={onInputKeyDown}
      onSubmit={(e) => {
        e.preventDefault();
        onSave();
      }}
    >
      <div className="card shadow" id="top-level-info">
        <div className="card-body">
          <div className="row" style={{ justifyContent: "flex-end" }}>
            <div className="col">
              <h2 style={{ marginBottom: "0px", marginLeft: "8px" }}>
                {editSubcode.productSubcode}
              </h2>
            </div>
            <button
              className="btn btn-primary btn-sm m-1 mr-4"
              type="button"
              onClick={toggleEditMode}
              hidden={editMode}
            >
              Edit
            </button>
            <button
              ref={saveButtonRef}
              className="btn btn-success btn-sm m-1"
              id="green"
              type="submit"
              hidden={!editMode}
            >
              Save
            </button>
            <button
              className="btn btn-primary btn-sm m-1 mr-4"
              type="button"
              onClick={toggleEditMode}
              hidden={!editMode}
            >
              Cancel
            </button>
          </div>
          <div className="row subcode-info">
            <div className="col-2">
              <EditThumbnailWrapper
                className="thumbnail-image"
                btnClassName={createMode ? "upload-btn-mt" : "upload-btn"}
                editMode={editMode}
                imagePath={editSubcode.productImage ? editSubcode.productImage.path : null}
                imageType={editSubcode.productImage ? editSubcode.productImage.type : null}
                onChange={(file) => setImage(file)}
                forceReload={reloadImage}
                image={image}
              />
            </div>
            <div className="col-10">
              <div className="row">
                <div className="col-3 p-0">
                  <ul>
                    <LabelTextField
                      label={"Product Subcode"}
                      value={editSubcode.productSubcode}
                      editMode={editMode}
                      onChange={(value) =>
                        editSubcodeField("productSubcode", value)
                      }
                      required
                    />
                    <LabelTextField
                      label={"Description"}
                      value={editSubcode.description}
                      editMode={editMode}
                      onChange={(value) =>
                        editSubcodeField("description", value)
                      }
                      required
                    />
                  </ul>
                </div>
                <div className="col-3 p-0">
                  <ul>
                    <LabelNumberField
                      label={"Minimum Order Quantity"}
                      value={editSubcode.minOrderQuantity}
                      editMode={editMode}
                      onChange={(value) =>
                        editSubcodeField("minOrderQuantity", value)
                      }
                      onBlur={onOrderQuantityBlur}
                      multiple={editSubcode.packSize}
                      required
                    />
                    <LabelNumberField
                      label={"Pack Size"}
                      value={editSubcode.packSize}
                      editMode={editMode}
                      onChange={(value) => editSubcodeField("packSize", value)}
                      onBlur={onPackSizeBlur}
                      required
                    />
                  </ul>
                </div>
                <div className="col-3 p-0">
                  <ul>
                    <LabelSelectField
                      label={"Fulfillment Process"}
                      value={
                        isEmpty(editSubcode.fulfillmentProcess)
                          ? "---"
                          : editMode
                          ? editSubcode.fulfillmentProcess
                          : getOptionText(
                              FULFILLMENT_PROCESS_OPTIONS,
                              editSubcode.fulfillmentProcess
                            )
                      }
                      options={FULFILLMENT_PROCESS_OPTIONS.filter(
                        (option) => option.value !== FULFILLMENT_PROCESS.NONE
                      )}
                      editMode={editMode}
                      onChange={(value) =>
                        editSubcodeField("fulfillmentProcess", value)
                      }
                    />
                    <LabelSelectField
                      label={"Sustainable"}
                      value={
                        isEmpty(editSubcode.sustainable)
                          ? "---"
                          : editMode
                          ? editSubcode.sustainable
                          : getOptionText(
                              SUSTAINABLE_OPTIONS.filter(
                                (option) => option.value !== ""
                              ),
                              editSubcode.sustainable
                            )
                      }
                      options={SUSTAINABLE_OPTIONS.filter(
                        (option) => option.value !== ""
                      )}
                      editMode={editMode}
                      onChange={(value) =>
                        editSubcodeField("sustainable", value)
                      }
                      booleanValue={true}
                    />
                  </ul>
                </div>
                <div className="col-3 p-0">
                  <ul>
                    <LabelTextField
                      label={"Sample Reference Number"}
                      value={editSubcode.sampleReferenceNumber}
                      editMode={editMode}
                      onChange={(value) =>
                        editSubcodeField("sampleReferenceNumber", value)
                      }
                    />
                    <LabelTextField
                      label={"Approved Date"}
                      value={editSubcode.approvedDate}
                      editMode={editMode}
                      onChange={(value) =>
                        editSubcodeField("approvedDate", value)
                      }
                    />
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};
