import * as types from "./productSubcodeActionTypes";

const initialState = {};

export const productSubcodeReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_PRODUCT_SUBCODE_SEARCH_VALUES: {
      return { ...state, searchValues: action.data };
    }
    default:
      return state;
  }
};
