export const LOGIN_USER = "LOGIN_USER";
export const LOGIN_USER_SUCCESS = "LOGIN_USER_SUCCESS";
export const LOGIN_USER_ERROR = "LOGIN_USER_ERROR";
export const LOGOUT_USER= "LOGOUT_USER";
export const LOGOUT_USER_SUCCESS = "LOGOUT_USER_SUCCESS";
export const LOGOUT_USER_ERROR = "LOGOUT_USER_ERROR";

export const IS_AUTHENTICATED = "IS_AUTHENTICATED";
export const NOT_AUTHENTICATED = "NOT_AUTHENTICATED"

export const LOGIN_REDIRECT_PATH = "LOGIN_REDIRECT_PATH";
