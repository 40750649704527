/* eslint-disable no-multiple-empty-lines */
/* eslint-disable no-trailing-spaces */
/* eslint-disable no-unused-vars */
import React, { useRef } from "react";
import { optionFromNameAndId, getNameAndIdFromList } from "constants/options";
import { getOptionText } from "util/common";
import { LabelTextField } from "components/LabelTextField";
import { LabelSelectField } from "components/LabelSelectField";
import { isEmpty } from "util/common";
import { IS_ACTIVE_OPTIONS } from "constants/activeStatus";

export const ProductItemRelationships = ({
  editProductItem,
  updateProductItemEntity,
  editProductItemField,
  editMode,
  productGroup,
  productSubgroup,
  attributeSchema,
  toggleEditMode,
  onSave,
}) => {
  const saveButtonRef = useRef();

  const onInputKeyDown = (e) => {
    if (e.keyCode === 13) {
      e.preventDefault();
      saveButtonRef.current.click();
    }
  };

  return (
    <div className="productItemEntity">
      <form
      onKeyDown={onInputKeyDown}
      onSubmit={(e) => {
        e.preventDefault();
        onSave();
      }}
    >
      <div className="row" style={{ justifyContent: "flex-end" }}>
        <div className="col">
          <h2 style={{ marginBottom: "0px", marginLeft: "8px" }}>
            {editProductItem.productSubcode.name}
          </h2>
        </div>
        <button
          className="btn btn-primary btn-sm m-1 mr-4"
          type="button"
          onClick={toggleEditMode}
          hidden={editMode}
        >
          Edit
            </button>
        <button
          ref={saveButtonRef}
          className="btn btn-success btn-sm m-1"
          id="green"
          type="submit"
          hidden={!editMode}
        >
          Save
            </button>
        <button
          className="btn btn-primary btn-sm m-1 mr-4"
          type="button"
          onClick={toggleEditMode}
          hidden={!editMode}
        >
          Cancel
            </button>
      </div>
      </form>
      <div className="row entity-list">
        <div className="col">
          <div className="productItem-info">
            <ul>
              <LabelTextField
                label={"Product Size"}
                value={editProductItem.productSize}
                editMode={editMode}
                onChange={(value) => editProductItemField("productSize", value)}
                required
              />
            </ul>
          </div>
        </div>
        <div className="col">
          <div className="productItem-info">
            <ul>
              <LabelTextField
                label={"Sort Key"}
                value={editProductItem.sortKey}
                editMode={editMode}
                onChange={(value) => editProductItemField("sortKey", value)}
                required
              />
            </ul>
          </div>
        </div>
        <div className="col">
          <div className="productItem-info">
            <ul>
              <LabelSelectField
                label={"Active"}
                value={
                  isEmpty(editProductItem.active)
                    ? "None"
                    : editMode
                      ? editProductItem.active
                      : getOptionText(IS_ACTIVE_OPTIONS, editProductItem.active)
                }
                options={IS_ACTIVE_OPTIONS}
                editMode={editMode}
                onChange={(value) => editProductItemField("active", value === "None" ? null : value)}
                booleanValue = {true}
              />
            </ul>
          </div>
        </div>
      </div>
      <div className="row entity-list">
        <div className="col">
          <div className="productItem-info">
            <ul>
              <LabelSelectField
                label={"Product Group"}
                value={
                  editMode
                    ? isEmpty(editProductItem.productGroup)
                      ? "None"
                      : editProductItem.productGroup.id
                    : isEmpty(editProductItem.productGroup)
                      ? "None"
                      : editProductItem.productGroup.name
                }
                options={productGroup.map((c) => optionFromNameAndId(c))}
                editMode={editMode}
                onChange={(id) =>
                  editProductItemField(
                    "productGroup",
                    id === "NONE" ? null : getNameAndIdFromList(productGroup, id)
                  )
                }
              />
            </ul>
          </div>
        </div>
        <div className="col">
          <div className="productItem-info">
            <ul>
              <LabelSelectField
                label={"Product Subgroup"}
                value={
                  editMode
                    ? isEmpty(editProductItem.productSubgroup)
                      ? "None"
                      : editProductItem.productSubgroup.id
                    : isEmpty(editProductItem.productSubgroup)
                      ? "None"
                      : editProductItem.productSubgroup.name
                }
                options={productSubgroup.map((c) => optionFromNameAndId(c))}
                editMode={editMode}
                onChange={(id) =>
                  editProductItemField(
                    "productSubgroup",
                    id === "NONE" ? null : getNameAndIdFromList(productSubgroup, id)
                  )
                }
              />
            </ul>
          </div>
        </div>
        <div className="col">
        </div>
      </div>
      <div className="row entity-list">
        <div className="col">
          <div className="productItem-info">
            <ul>
              <LabelTextField
                label={"Default Order Quantity"}
                value={editProductItem.defaultOrderQuantity}
                editMode={editMode}
                onChange={(value) => editProductItemField("defaultOrderQuantity", value)}
                required
              />
            </ul>
          </div>
        </div>
        <div className="col">
          <div className="productItem-info">
            <ul>
              <LabelSelectField
                label={"Required Attributes"}
                value={
                  editMode
                    ? isEmpty(editProductItem.attributeSchema)
                      ? "None"
                      : editProductItem.attributeSchema.id
                    : isEmpty(editProductItem.attributeSchema)
                      ? "None"
                      : editProductItem.attributeSchema.name
                }
                options={attributeSchema.map((c) => optionFromNameAndId(c))}
                editMode={editMode}
                onChange={(id) =>
                  editProductItemField(
                    "attributeSchema",
                    id === "NONE" ? null : getNameAndIdFromList(attributeSchema, id)
                  )
                }
              />
            </ul>
          </div>
        </div>
        <div className="col">
        </div>
      </div>
      <div className="row entity-list">
        <div className="col">
          <div className="productItem-info">
            <ul>
              <LabelTextField
                label={"Current Inventory"}
                value={editProductItem.currentInventory}
                editMode={editMode}
                onChange={(value) => editProductItemField("currentInventory", value)}
                required
              />
            </ul>
          </div>
        </div>
        <div className="col">
          <div className="productItem-info">
            <ul>
              <LabelTextField
                label={"Minimum Inventory"}
                value={editProductItem.minimumInventory}
                editMode={editMode}
                onChange={(value) => editProductItemField("minimumInventory", value)}
                required
              />
            </ul>
          </div>
        </div>
        <div className="col">
          <div className="productItem-info">
            <ul>
              <LabelTextField
                label={"Maximum Inventory"}
                value={editProductItem.maximumInventory}
                editMode={editMode}
                onChange={(value) => editProductItemField("maximumInventory", value)}
                required
              />
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};
