import * as types from "./productActionTypes";

const initialState = {
    searchValues: {
      productionRegion: "",
      productGroup: "",
      productSubcodeDescription: "",
      styleNumber: "",
      poNumber: "",
      orderStatus: "UPLOADED"
    }
};

export const productReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.SET_SEARCH_VALUES: {
          return { ...state, searchValues: action.data};
        }
        default:
            return state;
    }
};
