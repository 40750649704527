// React
import React, { useState } from "react";
import { useSelector } from "react-redux";

// Local
import api from "services/apiService";
import { PageLayout } from "layouts/PageLayout";
import { useURLNavigate } from "hooks/useURLNavigate";
import { VendorListSearch } from "./VendorListSearch";
import { VendorListRow } from "./VendorListRow";
import { joinParams } from "components/joinParams";
import { isNullOrUndefined, pluralize } from "util/common";
import { useOverlay } from "hooks/useOverlay";
import { useError } from "hooks/errors";

export const VendorListPage = () => {
  // hooks
  const navigate = useURLNavigate();
  const [showOverlay, hideOverlay] = useOverlay();
  const [showError] = useError();
  const message = useSelector((state) => state.message.message);

  // state
  const [vendors, setVendors] = useState([]);

  const searchVendors = async (searchValues) => {
    try {
      const params = getParams(searchValues);

      if (isNullOrUndefined(message)) {
        await showOverlay("Searching...");
      }

      const response = await api.getVendors(params);
      hideOverlay();
      setVendors(response.data.vendors);
    } catch (error) {
      hideOverlay();
      showError(error);
    }
  };

  const getParams = (searchValues) => {
    return joinParams({
      companyName: searchValues.companyName,
      address: searchValues.address,
      customerId: searchValues.customerId,
    });
  };

  return (
    <PageLayout className="vendor-list">
      <section className="site-content bodyWrap" id="content">
        <section className="content-body">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="page-title-row">
                  <h4 className="PageTitle">
                    <span className="page-parent">VENDORS</span>
                  </h4>
                  <button
                    className="btn btn-primary add-entity-btn"
                    onClick={() =>
                      navigate("/vendor-details", "createMode=true")
                    }
                  >
                    Add
                  </button>
                </div>
                <VendorListSearch searchVendors={searchVendors} />
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <div className="list-group mt-5" id="vendorList">
                  {vendors &&
                    vendors.map((vendor, index) => (
                      <VendorListRow key={index} vendor={vendor} />
                    ))}
                </div>
              </div>
            </div>
            <div className="vendorNumber-pagination">
              <span>
                {"Showing " +
                  vendors.length +
                  pluralize(" Vendor", vendors.length)}
              </span>
            </div>
          </div>
        </section>
      </section>
    </PageLayout>
  );
};
