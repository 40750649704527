export const PARSER_TYPE = Object.freeze({
    FLAT_FILE : "FLAT_FILE",
    DELIMITED_ROOTS: "DELIMITED_ROOTS",
    NONE: null
  });

export const PARSER_TYPE_OPTIONS = [
    {value: PARSER_TYPE.NONE, text : "None"},
    {value: PARSER_TYPE.FLAT_FILE, text: "Dunnes"},
    {value: PARSER_TYPE.DELIMITED_ROOTS, text: "Roots"},
  ];