// React
import React from "react";
import { useURLNavigate } from "hooks/useURLNavigate";
import { joinParams } from "components/joinParams";
import { PAGE_URL } from "constants/url";

export const ProductCodeRow = ({ productCode }) => {
  const navigate = useURLNavigate();

  const onClick = () => {
    const params = joinParams({
      id: productCode.id,
    });
    navigate(PAGE_URL.PRODUCT_CODE_DETAILS, params);
  };

  return (
    <tr className="pc-row" onClick={onClick}>
      <td className="data">{productCode.productCode}</td>
    </tr>
  );
};
