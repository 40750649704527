import React from "react";
import { isEmpty } from "util/common";

export const LabelValue = ({ label, value, labelClassName, listItemStyle, showLabelAlways=false }) => {
  // api determined user does not have permission.
  if (isEmpty(value) && !showLabelAlways) {
    return <></>;
  }

  return (
    <li style={listItemStyle}>
      <div className={"label " + (labelClassName ? labelClassName : "")}>
        {label}:
      </div>
      <div className="value">{value}</div>
    </li>
  );
};
