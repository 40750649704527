// React
import React from "react";
import { useURLNavigate } from "hooks/useURLNavigate";
import { joinParams } from "components/joinParams";
import { PAGE_URL } from "constants/url";



export const ProductionRegionListRow = ({ productionRegion }) => {
  const navigate = useURLNavigate();

  const onClick = () => {
    const params = joinParams({
      id: productionRegion.id,
    });
    navigate(PAGE_URL.PRODUCTION_REGION_DETAILS, params);
  };

  return (
    <tr className="pf-row" onClick={onClick}>
      <td className="data">{productionRegion.productionRegionName}</td>
    </tr>
  );
};
