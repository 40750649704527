import * as types from "./alertActionTypes";

export const showAlert = ({ title, description, cancelMessage, confirmMessage, cancelAction, confirmAction}) => {
    return {
        type: types.SHOW_ALERT,
        title: title,
        description: description,
        cancelMessage: cancelMessage,
        confirmMessage: confirmMessage,
        cancelAction: cancelAction,
        confirmAction: confirmAction
    };
};

export const hideAlert = () => {
    return {
        type: types.HIDE_ALERT
    };
};
