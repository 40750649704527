/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import api from "services/apiService";
import { StandardProductDetails } from "./StandardProductDetails";
import { StandardProductDetailsVendor } from "./StandardProductDetailsVendor";
import { PAGE_URL } from "constants/url";
import { useCurrentUser } from "hooks/useCurrentUser";
import { useTierCalculator } from "hooks/useTierCalculator";
import { ROLES } from "constants/roles";
import { formatCurrency } from "util/formatNumber";
import { isEmpty, stringToInt, sum } from "util/common";
import { useURLNavigate } from "../../../hooks/useURLNavigate";
import { CARE_LABEL_TYPE } from "constants/careLabelType";
import { pushSuccessMessage, showErrorMessage, } from "store/messages/messageActions";
import { StandardProductDetailsInventory } from "./StandardProductDetailsInventory";
import { useError } from "hooks/errors";
import { Link } from "react-router-dom";
import { PRODUCT_TYPE } from "constants/productType";
import { INVALID_PERCENTAGES_ERROR } from "constants/messages";

export const ProductStandardDetailsPage = ({ products, editMode, orderItemId }) => {

  const user = useCurrentUser();
  const navigate = useURLNavigate();
  const dispatch = useDispatch();
  const [showError] = useError();
  const getPriceCalculator = useTierCalculator();

  const [productConfigs, setProductConfigs] = useState({});
  const [total, setTotal] = useState(0);
  const [addToOrderEnabled, setAddToOrderEnabled] = useState(true);
  const [showValidation, setShowValidation] = useState(false);
  const [inventoryMode, setInventoryMode] = useState(false);
  const [productConfigsExist, setProductConfigsExist] = useState(false);

  const addToOrderRef = useRef(null);

  useEffect(() => {
    onProductsResponse(products);
  }, []);

  const onProductsResponse = (products) => {
    if (
      (user.role === ROLES.VENDOR || user.role === ROLES.CUSTOMER) ||
      (products[0].hasInventory &&
        (user.role === ROLES.ACCOUNT ||
          user.role === ROLES.FACTORY_ACCOUNT ||
          user.role === ROLES.ADMIN))
    ) {
      let configs = {};
      products.forEach(product => {
        configs[product.productSubcode] = createProductConfig(product);
      });
      setProductConfigs(configs);
      updateTotal(configs);
      setProductConfigsExist(true);
    }

    setInventoryMode(products[0].hasInventory);
  };

  const createProductConfig = (product) => {
    let config = {
      price: getPriceCalculator((user.role === ROLES.VENDOR || user.role === ROLES.CUSTOMER) ? product.prices : product.costs),
      quantities: {},
      totalCustomerQuantity: 0,
    };

    if (!product.hasSizes) {
      config.quantities[product.productItemId] = product.defaultOrderQuantity;
    }

    config.attributesItems = {};
    product.items &&
    product.items.forEach((item) => {
      if (!isEmpty(item.attributes)) {
        config.attributesItems[item.productItemId] = {};
        item.attributes.forEach(
          (a) =>
            (config.attributesItems[item.productItemId][a.name] = a.value
              ? a.value
              : "")
        );
      }
    });

    config.attributes = {};

    if (!isEmpty(product.attributes)) {
      product.attributes.forEach(
        (a) => (config.attributes[a.name] = a.value ? a.value : "")
      );
    }

    if (product.careLabel) {
      config.careLabel = {
        countryOfOrigin: null,
        specialInstructions: product.careLabel.specialInstructions,
        cautionMaintenance: null,
        garmentComponents: [],
      };

      if (product.careLabel.type === CARE_LABEL_TYPE.CARELABEL_BY_CODE) {
        config.careLabel.instructionsCode = null;
      } else {
        config.careLabel.wash = null;
        config.careLabel.bleach = null;
        config.careLabel.dry = null;
        config.careLabel.iron = null;
        config.careLabel.dryClean = null;
      }
    }

    if (product.hasSizes) {
      product.items.forEach(item => {
        config.quantities[item.productItemId] = item.defaultOrderQuantity;
        config.totalCustomerQuantity += item.defaultCustomerQuantity ? item.defaultCustomerQuantity : 0;
      });
      config.totalQuantity = sum(Object.values(config.quantities));
    }

    return config;
  };

  const onAttributeChange = (productSubcode, name, value) => {
    let configs = { ...productConfigs };
    configs[productSubcode].attributes[name] = value;
    setProductConfigs(configs);
  };

  const onAttributeItemChange = (
    productSubcode,
    productItemId,
    name,
    value
  ) => {
    let configs = { ...productConfigs };
    configs[productSubcode].attributesItems[productItemId][name] = value;
    setProductConfigs(configs);
  };

  const onQuantityChange = (productSubcode, productItemId, quantity) => {
    let product = products.find(
      (product) => product.productSubcode == productSubcode
    );
    let item = product?.items?.find(
      (item) => item.productItemId == productItemId
    );
    if (item) {
      item.quantity = quantity;
    }

    let configs = { ...productConfigs };
    configs[productSubcode].quantities[productItemId] = stringToInt(quantity);

    setProductConfigs(configs);
    updateTotal(configs);
  };

  const updateTotal = (configs) => {
    let totalQuantity = 0;
    let totalPrice = 0;

    Object.values(configs).forEach((config) => {
      var quantities = Object.values(config.quantities);
      totalQuantity += sum(quantities);

      quantities.forEach(q => {
        totalPrice += q * config.price(q);
      });
    });

    setAddToOrderEnabled(totalQuantity > 0);
    setTotal(totalPrice);
  };

  const onCareLabelChange = (careLabelConfig) => {
    const configs = { ...productConfigs };
    configs[products[0].productSubcode].careLabel = careLabelConfig;
    setProductConfigs(configs);
  };

  const onAddToOrder = async (e) => {
    e.preventDefault();

    if (products[0].careLabel) {
      const careLabel = productConfigs[products[0].productSubcode].careLabel;

      if (hasInvalidPercentages(careLabel)) {
        return false;
      }
    }

    try {
      const data = {
        productType: PRODUCT_TYPE.STANDARD,
        quantities: {}
      };

      const config = productConfigs[products[0].productSubcode];

      data.quantities = config.quantities;
      data.careLabel = config.careLabel;
      data.attributes = {};
      data.attributesItems = !config.attributesItems
        ? {}
        : config.attributesItems;

      Object.keys(config.attributes).forEach((key) => {
        data.attributes[key] = config.attributes[key];
      });

      if (editMode) {
        data.orderItemId = orderItemId;
        await api.updateOrder(data);
        dispatch(pushSuccessMessage("Product successfully updated"));
      } else {
        await api.addToOrder(data);
        dispatch(pushSuccessMessage("Product added to your cart"));
      }

      navigate(PAGE_URL.CART);
    } catch (error) {
      showError(error);
    }

    return false;
  };

  const hasInvalidPercentages = (careLabel) => {
    if (!careLabel.garmentComponentsInvalidPercentages) {
      return false;
    }

    setShowValidation(true);
    dispatch(showErrorMessage(INVALID_PERCENTAGES_ERROR, false));

    return true;
  };

  const cancel = () => {
    navigate(PAGE_URL.CART);
  };

  return (
    productConfigsExist && <div className="container">
      <form onSubmit={onAddToOrder}>
        <div className="row">
          <div className="col-12">
            <h4 className="PageTitle">
              <Link className="page-parent" to={PAGE_URL.PRODUCT}>
                PRODUCTS
              </Link>{" "}
              &ndash;{" "}
              <span className="page-current">PRODUCT DETAILS</span>
            </h4>
            {!isEmpty(products) && (
              <>
                {(user.role === ROLES.VENDOR) ? (
                  <StandardProductDetailsVendor
                    product={products[0]}
                    onQuantityChange={onQuantityChange}
                    onAttributeChange={onAttributeChange}
                    onAttributeItemChange={onAttributeItemChange}
                    onCareLabelChange={onCareLabelChange}
                    editMode={editMode}
                    showValidation={showValidation}
                    setShowValidation={setShowValidation}
                  />
                ) : inventoryMode ? (
                  <StandardProductDetailsInventory
                    product={products[0]}
                    onQuantityChange={onQuantityChange}
                  />
                ) : (
                  <StandardProductDetails product={products[0]} />
                )}
              </>
            )}
            {!isEmpty(products) &&
            (user.role === ROLES.VENDOR ||
              (inventoryMode &&
                (user.role === ROLES.ACCOUNT ||
                  user.role === ROLES.ADMIN))) && (
              <div className="d-flex justify-content-end align-items-center mt-5">
                <h5 className="total pr-5">
                  <span>Total:</span>
                  <span className="currency ml-2 mr-1">
                      {user.role === ROLES.VENDOR
                        ? products[0].prices[0].currency
                        : products[0].costs[0].currency}
                    </span>
                  <span className="value">{formatCurrency(total)}</span>
                </h5>
                {editMode && (
                  <button
                    className="btn btn-primary shadow cancel-button"
                    type="button"
                    onClick={(e) => cancel()}
                  >
                    Cancel
                  </button>
                )}
                <button
                  ref={addToOrderRef}
                  disabled={!addToOrderEnabled}
                  className="btn btn-primary shadow"
                  type="submit"
                >
                  {editMode ? "Save Changes" : "Add to Order"}
                </button>
              </div>
            )}
          </div>
        </div>
      </form>
    </div>
  );
};
