/* eslint-disable no-unused-vars */

// React
import React, { useEffect, useRef } from "react";

// Local
import { LabelTextField } from "components/LabelTextField";
import { LabelSelectField } from "components/LabelSelectField";
import { isEmpty } from "util/common";
import { optionFromNameAndId, getNameAndIdFromList } from "constants/options";
import { getOptionText } from "util/common";
import { PARSER_TYPE, PARSER_TYPE_OPTIONS } from "constants/parserType";

export const TopLevelCustomerInfo = ({
  warehouse,
  editCustomer,
  editCustomerField,
  editMode,
  createMode,
  toggleEditMode,
  onSave,
}) => {
  const saveButtonRef = useRef();

  const onInputKeyDown = (e) => {
    if (e.keyCode === 13) {
      e.preventDefault();
      saveButtonRef.current.click();
    }
  };

  return (
    <form
      onKeyDown={onInputKeyDown}
      onSubmit={(e) => {
        e.preventDefault();
        onSave();
      }}
    >
      <div className="card shadow" id="top-level-info">
        <div className="card-body">
          <div className="row" style={{ justifyContent: "flex-end" }}>
            <div className="col">
              <h2 style={{ marginBottom: "0px", marginLeft: "8px" }}>
                {editCustomer.companyName}
              </h2>
            </div>
            <button
              className="btn btn-primary btn-sm m-1 mr-4"
              type="button"
              onClick={toggleEditMode}
              hidden={editMode}
            >
              Edit
            </button>
            <button
              ref={saveButtonRef}
              className="btn btn-success btn-sm m-1"
              id="green"
              type="submit"
              hidden={!editMode}
            >
              Save
            </button>
            <button
              className="btn btn-primary btn-sm m-1 mr-4"
              type="button"
              onClick={toggleEditMode}
              hidden={!editMode}
            >
              Cancel
            </button>
          </div>
          <div className="row user-info">
            <div className="col-4">
              <div className="customer-info">
                <ul>
                  <LabelTextField
                    label={"Name"}
                    value={editCustomer.companyName}
                    editMode={editMode}
                    onChange={(value) => editCustomerField("companyName", value)}
                    required
                  />
                </ul>
              </div>
            </div>
            <div className="col-4">
            <div className="customer-info">
              <ul>
              <LabelSelectField
                    label={"Warehouse"}
                    value={
                      editMode
                        ? isEmpty(editCustomer.warehouse)
                          ? "None"
                          : editCustomer.warehouse.id
                        : isEmpty(editCustomer.warehouse)
                        ? "None"
                        : editCustomer.warehouse.name
                    }
                    options={warehouse.map((c) => optionFromNameAndId(c))}
                    editMode={editMode}
                    onChange={(id) =>
                      editCustomerField(
                        "warehouse", getNameAndIdFromList(warehouse, id)
                      )
                    }
                  />
              </ul>
              </div>
            </div>
            <div className="col-4">
            <div className="customer-info">
                <ul>
                  <LabelSelectField
                    label={"Variable Data Type"}
                    value={
                      isEmpty(editCustomer.parserType)
                        ? "None"
                        : editMode
                        ? editCustomer.parserType
                        : getOptionText(PARSER_TYPE_OPTIONS, editCustomer.parserType)
                    }
                    options={PARSER_TYPE_OPTIONS}
                    editMode={editMode}
                    onChange={(value) => editCustomerField("parserType", value === "None" ? null : value)}
                  />
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};
