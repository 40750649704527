import React from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import P from "prop-types";
import { Link, useLocation } from "react-router-dom";
import { useLogout, useCurrentUser } from "../hooks/useCurrentUser";

import { RolePermission } from "components/RolePermission";
import { CONFIGURATION_ACCESS_USERS, PAGE_ACCESSIBLE_USERS, ROLES } from "constants/roles";
import { PAGE_URL, PAGE_TITLE } from "constants/url";
import { CURRENT_YEAR } from "constants/date";
import api from "services/apiService";
import { loadCartStatusSuccess } from "store/cart/cartActions";
import { userHasAVendor } from "../store/user/userActions";
import { MessagePanel } from "./components/MessagePanel";
import { MessageOverlay } from "./components/MessageOverlay";
import { useError } from "hooks/errors";
import { AlertWindow } from "./components/AlertWindow";

export const PageLayout = ({ className, children }) => {
  // hooks
  const dispatch = useDispatch();
  const [logout, , unregisterSignedInUser] = useLogout();
  const user = useCurrentUser();
  const cartStatus = useSelector(state => state.cart.cartStatus);
  const userHasCompany = useSelector(state => state.user.userHasVendor);
  const [showError, ] = useError();
  const pageUrl = useLocation();

  // top level links
  const isNavOrderList = (className.startsWith("order-")) ? "active" : "";
  const isNavProductList = (className === "product-list" || className === "product-details") ? "active" : "";
  const isNavRestockLink = (className.startsWith("restock-")) ? "active" : "";
  const isNavCartLink = (className === "cart") ? "active" : "";

  // dropdown links
  const isVariableDataLink = (className === "variable-data-upload") ? "active" : "";
  const isUsersLink = (className === "user-list" || className === "user-details") ? "active" : "";
  const isVendorsLink = (className === "vendor-list" || className === "vendor-details") ? "active" : "";
  const isNavProductCodeLink = (className === "product-code-list" || className === "product-code-details") ? "active" : "";
  const isNavProductSubcodeLink = (className === "product-subcode-list" || className === "product-subcode-details") ? "active" : "";
  const isNavFactoryLink = (className === "factory-list" || className === "factory-details") ? "active" : "";
  const isCustomersLink = (className === "customer-list" || className === "customer-details") ? "active" : "";
  const isWarehousesLink = (className === "warehouse-list" || className === "warehouse-details") ? "active" : "";
  const isProductionRegionsLink = (className === "production-region-list" || className === "production-region-details") ? "active" : "";
  const isCareLabelOptionsLink = (className === "care-label-configuration") ? "active" : "";
  const isAttributeSchemaLink = (className === "attribute-schema-list" || className === "attributeSchema-details") ? "active" : "";
  const isConfigurationLink = isVariableDataLink === "active" ||
                              isUsersLink === "active" ||
                              isVendorsLink === "active" ||
                              isNavProductCodeLink === "active" ? "active" : "" ||
                              isNavProductSubcodeLink === "active" ? "active" : "" ||
                              isNavFactoryLink === "active" ? "active" : "" ||
                              isCustomersLink === "active" ? "active" : "" ||
                              isWarehousesLink === "active" ? "active" : "" ||
                              isProductionRegionsLink === "active" ? "active" : "" ||
                              isCareLabelOptionsLink === "active" ? "active" : "" ||
                              isAttributeSchemaLink === "active" ? "active" : "";

  const checkCartStatus = async () => {
    try {
      const response = await api.getCartStatus();
      dispatch(loadCartStatusSuccess(response.data));
    } catch (error) {
      showError(error);
    }
  };

  const loadCompanies = async () => {
    try {
      // Make sure user has a company they can order for.
      const response = await api.userHasCompany();
      dispatch(userHasAVendor(response.data.hasVendor));
    } catch (error) {
      showError(error);
    }
  };

  useEffect(() => {
    var pageName;

    Object.keys(PAGE_URL).forEach(key => {
      if (PAGE_URL[key] === pageUrl.pathname) {
        pageName = key;
      }
    });

    document.title = PAGE_TITLE[pageName] + " - The Unique Group";
  }, []);

  useEffect(() => {
    checkCartStatus();
  },[cartStatus]);

  useEffect(() => {
    loadCompanies();
  },[userHasCompany]);

  return (
    <div className={className} id="page">
      <a className="skip-link sr-only" href="#content">
        Content
      </a>

      <header className="site-header">
        <nav className="main-navigation navbar fixed-top navbar-expand custom-carets justify-content-between" id="site-navigation">
          <div className="container-fluid">
            <Link className="navbar-brand constant-color" rel="home" to={PAGE_URL.HOME} title="" itemProp="url">
              <span className="sr-only">The Unique Group</span>
              <div className="d-flex align-items-center">
                <span><img className="mr-4" src="./UNIQUE-LOGO150px.png" width="auto" alt="The Unique Group"></img></span>
                <span id="no-change">PRODUCT &amp; ORDER<br />MANAGEMENT SYSTEM</span>
              </div>
            </Link>

            <div className="navbar">
              <ul className="nav navbar-nav">
                <RolePermission roles={PAGE_ACCESSIBLE_USERS[PAGE_URL.ORDER]}>
                  <li className={"nav-item " + isNavOrderList}>
                    <Link className="nav-link" to={PAGE_URL.ORDER}>Orders</Link>
                  </li>
                </RolePermission>

                <RolePermission roles={PAGE_ACCESSIBLE_USERS[PAGE_URL.PRODUCT]}>
                  <li className={"nav-item " + isNavProductList}>
                    <Link className="nav-link" to={PAGE_URL.PRODUCT}>Products</Link>
                  </li>
                </RolePermission>

                <RolePermission roles={PAGE_ACCESSIBLE_USERS[PAGE_URL.RESTOCK]}>
                  <li className={"nav-item " + isNavRestockLink}>
                    <Link className="nav-link" to={PAGE_URL.RESTOCK}>Restock</Link>
                  </li>
                </RolePermission>


                <RolePermission roles={CONFIGURATION_ACCESS_USERS}>
                  <div className={"nav-item dropdown " + isConfigurationLink} >
                    <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                      Configuration
                    </a>
                    <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                      {/* product */}
                      <RolePermission roles={PAGE_ACCESSIBLE_USERS[PAGE_URL.VARIABLE_DATA_UPLOAD]}>
                        <li className={"dropdown-item " + isVariableDataLink}>
                          <Link className="nav-link in-dropdown" to={PAGE_URL.VARIABLE_DATA_UPLOAD}>Variable Data Upload</Link>
                        </li>
                      </RolePermission>

                      <RolePermission roles={PAGE_ACCESSIBLE_USERS[PAGE_URL.PRODUCT_CODES]}>
                        <li className={"dropdown-item " + isNavProductCodeLink}>
                          <Link className="nav-link in-dropdown" to={PAGE_URL.PRODUCT_CODES}>Product Codes</Link>
                        </li>
                      </RolePermission>

                      <RolePermission roles={PAGE_ACCESSIBLE_USERS[PAGE_URL.PRODUCT_SUBCODE]}>
                        <li className={"dropdown-item " + isNavProductSubcodeLink}>
                          <Link className="nav-link in-dropdown" to={PAGE_URL.PRODUCT_SUBCODE}>Product Subcodes</Link>
                        </li>
                      </RolePermission>

                      <RolePermission roles={PAGE_ACCESSIBLE_USERS[PAGE_URL.CARE_LABEL]}>
                        <li className={"dropdown-item " + isCareLabelOptionsLink}>
                          <Link className="nav-link in-dropdown" to={PAGE_URL.CARE_LABEL}>Care Labels</Link>
                        </li>
                      </RolePermission>
                      <RolePermission roles={PAGE_ACCESSIBLE_USERS[PAGE_URL.ATTRIBUTE_SCHEMA]}>
                        <li className={"dropdown-item " + isAttributeSchemaLink}>
                          <Link className="nav-link in-dropdown" to={PAGE_URL.ATTRIBUTE_SCHEMA}>Attribute Schemas</Link>
                        </li>
                      </RolePermission>

                      {/* user */}
                      <RolePermission roles={ROLES.ADMIN}>
                        <div className="dropdown-divider"></div>
                      </RolePermission>
                      <RolePermission roles={PAGE_ACCESSIBLE_USERS[PAGE_URL.USERS]}>
                        <li className={"dropdown-item " + isUsersLink}>
                          <Link className="nav-link in-dropdown" to={PAGE_URL.USERS}>Users</Link>
                        </li>
                      </RolePermission>
                      <RolePermission roles={PAGE_ACCESSIBLE_USERS[PAGE_URL.CUSTOMER]}>
                        <li className={"dropdown-item " + isCustomersLink}>
                          <Link className="nav-link in-dropdown" to={PAGE_URL.CUSTOMER}>Customers</Link>
                        </li>
                      </RolePermission>
                      <RolePermission roles={PAGE_ACCESSIBLE_USERS[PAGE_URL.VENDORS]}>
                        <li className={"dropdown-item " + isVendorsLink}>
                          <Link className="nav-link in-dropdown" to={PAGE_URL.VENDORS}>Vendors</Link>
                        </li>
                      </RolePermission>

                      {/* entity */}
                      <RolePermission roles={ROLES.ADMIN}>
                        <div className="dropdown-divider"></div>
                      </RolePermission>
                      <RolePermission roles={PAGE_ACCESSIBLE_USERS[PAGE_URL.WAREHOUSES]}>
                        <li className={"dropdown-item " + isWarehousesLink}>
                          <Link className="nav-link in-dropdown" to={PAGE_URL.WAREHOUSES}>Warehouses</Link>
                        </li>
                      </RolePermission>
                      <RolePermission roles={PAGE_ACCESSIBLE_USERS[PAGE_URL.PRODUCTION_REGION]}>
                        <li className={"dropdown-item " + isProductionRegionsLink}>
                          <Link className="nav-link in-dropdown" to={PAGE_URL.PRODUCTION_REGION}>Production Regions</Link>
                        </li>
                      </RolePermission>

                      <RolePermission roles={PAGE_ACCESSIBLE_USERS[PAGE_URL.FACTORY]}>
                        <li className={"dropdown-item " + isNavFactoryLink}>
                          <Link className="nav-link in-dropdown" to={PAGE_URL.FACTORY}>Factories</Link>
                        </li>
                      </RolePermission>
                    </div>
                  </div>

                </RolePermission>

                {(userHasCompany || (user && user.role !== ROLES.ADMIN)) && <RolePermission roles={PAGE_ACCESSIBLE_USERS[PAGE_URL.CART]}>
                  <li className={"nav-item " + isNavCartLink}>
                    <Link className={"nav-link " + (cartStatus ? "yesPendingOrder": "noPendingOrder")} 
                          id="cartLink" 
                          to={cartStatus ? PAGE_URL.CART : ""}>
                        {cartStatus && <span className="item-count"></span>}
                        <span> <i className="fal fa-shopping-cart"></i></span>
                    </Link>
                  </li>
                </RolePermission>}

                <li className="nav-item"> <Link className="nav-link" to="#" onClick={() => {
                  logout();
                  unregisterSignedInUser();
                }}>Log Out</Link></li>
              </ul>
            </div>
          </div>
        </nav>
      </header>
      
      <MessagePanel />
      <MessageOverlay />
      <AlertWindow />
      
      {children}

      <footer className="site-footer mt-5">
        <div className="site-info container text-right">
          <div className="row">
            <div className="col">
              <p>&copy;{CURRENT_YEAR}&nbsp;
                <a href="https://theuniquegroup.com/" rel="noopener noreferrer" target="_blank">The Unique Group</a> {"\u00a0\u00a0"} | {"\u00a0\u00a0"}
                <Link to={PAGE_URL.TERMS_AND_CONDITIONS} target="_blank "title="">Terms &amp; Conditions</Link>
              </p>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};

PageLayout.propTypes = {
  className: P.string,
  children: P.node,
  showCart: P.bool,
};
