import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { PageLayout } from "layouts/PageLayout";
import api from "services/apiService";
import { showSuccessMessage } from "store/messages/messageActions";
import { useOverlay } from "hooks/useOverlay";
import { useError } from "hooks/errors";
import { useURLNavigate } from "../../hooks/useURLNavigate";
import { isEmpty } from "util/common";
import { PAGE_URL } from "constants/url";

export const RestockPage = () => {

    const dispatch = useDispatch();
    const navigate = useURLNavigate();
    const [showOverlay, hideOverlay] = useOverlay();
    const [showError, ] = useError();
    const [customers, setCustomers] = useState(null);
    const [customerId, setCustomerId] = useState(null);

    const buildRestockingCart = async() => {
        if (!isEmpty(customerId)){
            try {
                await showOverlay("Adding Products to Order...");
                await api.restock(customerId);
                hideOverlay();
                dispatch(showSuccessMessage("Restocking Items Successfully Added to Order"));
                navigate(PAGE_URL.CART);
            } catch (error) {
                hideOverlay();
                showError(error);
            }
        } else {
            showError("Please select a customer to restock for")
        }
        
    };

    const loadCustomers = async () => {
        try {
            const response = await api.getRestockingCustomers();
            setCustomers(response.data.customers);
        } catch (error) {
            showError(error);
        }
    };

    const onKeyDown = (e) => {
        if (e.keyCode === 13) {
            e.preventDefault();
            e.target.blur();
        }
    };

    useEffect(() => {
        loadCustomers();
    }, []);

    useEffect(() => {
        if (customers){
            if (customers.length === 1){
                setCustomerId(customers[0].customerId)
            }
        }
    }, [customers]);

    return (
        <PageLayout id="page" className="restock-page">
            <section className="site-content bodyWrap" id="content">
                <section className="content-body">
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <h4 className="PageTitle"><span className="page-parent">RESTOCK</span></h4>
                            </div>
                        </div>
                        {customers && <div className="row">
                            <div className="col-12">
                                <div className="card card-body">
                                    <h2>Order Products for Restocking</h2>
                                    <div className="row first">
                                        <div className="col-4">
                                            <label>Here you can automatically build a cart with the items for your customer that need restocking.</label>
                                        </div>
                                        <div className="col-4">
                                            <label> <strong>Note:&nbsp;&nbsp;</strong>
                                                <i>Items which require restocking will be added to your existing cart if you already have one.</i>
                                            </label>
                                        </div>
                                    </div>
                                    <hr />
                                    <div className="row">
                                        <div className="col-3">
                                            <label><strong>Step 1: </strong>Select a Customer</label>
                                            <select className="form-control" required onChange={e => setCustomerId(e.target.value)} onKeyDown={onKeyDown} value={customerId}>
                                                {(customers && customers.length !== 1) &&
                                                    <option value="" hidden>---</option>
                                                }
                                                {customers && customers.map(customer =>
                                                    <option value={customer.customerId}>{customer.customerName}</option>
                                                )}
                                            </select>
                                        </div>
                                    </div>
                                    <hr />
                                    <div className="row">
                                        <div className="col-3">
                                            <label><strong>Step 2: </strong>Build Your Cart</label>
                                            <div>
                                                <input className="btn btn-primary" type="button" value="Restock" onClick={buildRestockingCart} disabled={customerId === null}/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>}
                    </div>
                </section>
            </section>
        </PageLayout>
    );
}