// React
import React, { useState, useEffect } from "react";

// Local
import { isEmpty } from "util/common";
import { ATTRIBUTE_FIELD_TYPE_OPTIONS } from "constants/options";
import { ATTRIBUTE_FIELD_TYPE } from "constants/attributeFieldType";
import { EditableListPanel } from "modules/care-label/shared/EditableListPanel";

export const AttributeFields = ({
  editAttributeSchema,
  editMode,
  addField,
  updateField,
  removeField,
  addFieldOption,
  updateFieldOption,
  removeFieldOption,
}) => {
  // state
  const [selectedField, setSelectedField] = useState("");
  const [editField, setEditField] = useState("");
  const [createField, setCreateField] = useState("");

  const [selectedOption, setSelectedOption] = useState("");
  const [editOption, setEditOption] = useState("");
  const [createOption, setCreateOption] = useState("");

  const [selectedFieldType, setSelectedFieldType] = useState(
    ATTRIBUTE_FIELD_TYPE.STRING
  );

  useEffect(() => {
    if (!editMode) {
      setSelectedField("");
      setEditField("");
      setCreateField("");
      setSelectedOption("");
      setEditOption("");
      setSelectedOption("");
    }
  }, [editMode]);

  // methods
  const getFieldsForType = (type) => {
    return editAttributeSchema.attributeFields.filter((f) => f.type === type);
  };

  // fields
  const addOrUpdateField = () => {
    if (isEmpty(editField)) {
      let _createField = { ...createField };
      _createField.type = selectedFieldType;
      addField(_createField);
    } else {
      updateField(selectedField, editField);
    }
  };

  const onClickRemoveField = () => {
    setEditField("");
    setSelectedField("");
    setCreateField("");
    removeField(selectedField);
  };

  const isFieldSelected = (entity, selectedEntity) => {
    return (
      entity &&
      selectedEntity &&
      entity.name === selectedEntity.name &&
      entity.type === selectedEntity.type
    );
  };

  // options
  const addOrUpdateOption = () => {
    if (isEmpty(editOption)) {
      addFieldOption(editField, createOption, setEditField);
      setCreateOption("");
    } else {
      updateFieldOption(editField, editOption, selectedOption, setEditField);
      setEditOption("");
    }
  };

  const onClickRemoveOption = () => {
    setEditOption("");
    setSelectedOption("");
    setCreateOption("");
    removeFieldOption(editField, selectedOption, setEditField);
  };

  const isOptionSelected = (option, selectedOption) => {
    return option && selectedOption && option.name === selectedOption.name;
  };

  const getOptionsAsObjects = (editField) => {
    let objects = [];

    if (editField && editField.options) {
      editField.options.forEach((o) => {
        let option = { name: o };
        objects.push(option);
      });
    }

    return objects;
  };

  return (
    <div className="card shadow">
      <div className="card-body">
        <div className="row entity-list">
          {/* Attribute Fields */}
          <div className="col-7">
            <label className="mt-1">Attribute Fields</label>
            <div className="row field-type-row">
              <label className="mr-2">Field Type:</label>
              <select
                style={{ display: "inline", width: "14.3rem" }}
                className={"form-control"}
                onChange={(e) => {
                  setSelectedField("");
                  setEditField("");
                  setSelectedFieldType(e.target.value);
                }}
                value={selectedFieldType}
              >
                {ATTRIBUTE_FIELD_TYPE_OPTIONS.map((option, index) => (
                  <option key={index} value={option.value}>
                    {option.text}
                  </option>
                ))}
              </select>
            </div>
            <EditableListPanel
              placeholder={"Enter a field name"}
              options={getFieldsForType(selectedFieldType)}
              selectedOption={selectedField}
              editOption={editField}
              createOption={createField}
              setSelectedOption={setSelectedField}
              setEditOption={setEditField}
              setCreateOption={setCreateField}
              addOrUpdateOption={addOrUpdateField}
              removeOption={onClickRemoveField}
              isEntitySelectedCustom={isFieldSelected}
              disabled={!editMode}
            />
          </div>
          <div className="col-5" style={{ paddingTop: "4rem" }}>
            <EditableListPanel
              title={"Field Options"}
              placeholder={"Enter an option name"}
              options={getOptionsAsObjects(editField)}
              selectedOption={selectedOption}
              editOption={editOption}
              createOption={createOption}
              setSelectedOption={setSelectedOption}
              setEditOption={setEditOption}
              setCreateOption={setCreateOption}
              addOrUpdateOption={addOrUpdateOption}
              removeOption={onClickRemoveOption}
              isEntitySelectedCustom={isOptionSelected}
              disabled={
                !editMode ||
                selectedFieldType !== ATTRIBUTE_FIELD_TYPE.DROPDOWN ||
                isEmpty(editField)
              }
            />
          </div>
        </div>
      </div>
    </div>
  );
};
