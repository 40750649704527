import * as types from "./customerActionTypes";

const initialState = {
  customerSearchValues: {
    name: "",
  }
};

export const customerReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_CUSTOMER_SEARCH_VALUES: {
      const data = action.data;
      return { ...state, customerSearchValues: data };
    }
    default:
      return state;
  }
};
