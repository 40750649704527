/* eslint-disable no-trailing-spaces */
/* eslint-disable no-unused-vars */
/* eslint-disable no-multiple-empty-lines */

// React
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

// Local
import { api } from "../../services/apiService";
import { PageLayout } from "layouts/PageLayout";
import { useURLNavigate } from "hooks/useURLNavigate";
import { useURLParams } from "hooks/useURLParams";
import { useOverlay } from "hooks/useOverlay";
import { useError } from "hooks/errors";
import { PAGE_URL } from "constants/url";
import { isEmpty, isNullOrUndefined , addNoneOption} from "util/common";
import { ProductItemRelationships } from "./components/ProductItemRelationships";
import { showSuccessMessage, pushSuccessMessage } from "store/messages/messageActions";
import { cloneDeep } from "lodash";
import { joinParams } from "components/joinParams";

export const ProductItemDetailPage = () => {
  // hooks
  const navigate = useURLNavigate();
  const [showError, pushError] = useError();
  const [getParam] = useURLParams();
  const [showOverlay, hideOverlay] = useOverlay();
  const dispatch = useDispatch();
  const message = useSelector(state => state.message.message);
  
  // state
  const [editMode, setEditMode] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [productItem, setProductItem] = useState();
  const [editProductItem, setEditProductItem] = useState();
  const [productGroup, setProductGroup] = useState();
  const [productSubgroup, setProductSubgroup] = useState();
  const [attributeSchema, setAttributeSchema] = useState();
  // url variables
  const productItemId = getParam("id");
  const subcode = getParam("subcode")
  const createMode = getParam("createMode");
  
  useEffect(() => {
    loadData();
  }, []);

  const loadData = async () => {

    setEditProductItem({
      defaultOrderQuantity: 0,
      sortKey: 1,
      productSize: null,
      productSubgroup: null,
      productSubcode: {id: subcode, name:""},
      productGroup: null,
      attributeSchema: null,
      active: true,
      currentInventory: null,
      minmumInventory: null,
      maximumInventory: null
    });

    // handle url hack
    if (createMode && !isEmpty(productItemId)) {
      pushError("Invalid URL params");
      navigate(PAGE_URL.PRODUCT_SUBCODE_DETAILS, joinParams({id: subcode}));
    }

    if (!isEmpty(productItemId)) {
      // load productItem
      try {
        const productItemResponse = await api.getProductItem(productItemId);
        setProductItem(cloneDeep(productItemResponse.data));
        setEditProductItem(cloneDeep(productItemResponse.data));
      } catch (error) {
        pushError(error);
        navigate(PAGE_URL.HOME);
      }
    }

    if (createMode) {
      try {
        // setup new productItem
        setEditMode(true);
        const productSubcodeNameIdResponse = await api.getProductSubcodeNameId(subcode);
        console.log("Subcode", productSubcodeNameIdResponse.data);
        setEditProductItem({
          defaultOrderQuantity: 0,
          sortKey: 1,
          productSize: null,
          productSubgroup: null,
          productSubcode: productSubcodeNameIdResponse.data,
          productGroup: null,
          attributeSchema: null,
          active: true,
          currentInventory: null,
          minmumInventory: null,
          maximumInventory: null
        });
      } catch (error) {
        pushError(error);
        navigate(PAGE_URL.HOME);
      }
    }

    if (createMode || !isEmpty(productItemId)) {
      try {
        const productGroupResponse = await api.getAllProductGroup();
        const productSubgroupResponse = await api.getAllProductSubgroup();
        const attributeSchemaResponse = await api.getAllAttributeSchema();
        setProductGroup(addNoneOption(productGroupResponse.data.listContent));
        setProductSubgroup(addNoneOption(productSubgroupResponse.data.listContent));
        setAttributeSchema(addNoneOption(attributeSchemaResponse.data.listContent));
        setLoaded(true);
      } catch (error) {
        pushError(error);
        navigate(PAGE_URL.HOME);
      }
    }
    

  };
  
  const toggleEditMode = () => {
    if (createMode) {
      navigate(PAGE_URL.PRODUCT_SUBCODE_DETAILS, joinParams({id: subcode}));
    } else if (editMode) {
      setEditProductItem(cloneDeep(productItem)); // revert changes
      setEditMode(false);
    } else {
      setEditMode(true);
    }
  };

  const onSave = async () => {

    if (isNullOrUndefined(message)) {
      await showOverlay("Saving...");
    }
    console.log("Product item", editProductItem)
    if (createMode) {
      try {
        await api.createProductItem(editProductItem);
        dispatch(pushSuccessMessage("Product Item Successfully Created"));
        navigate(PAGE_URL.PRODUCT_SUBCODE_DETAILS, joinParams({id: subcode}));
      } catch (error) {
        showError(error);
      }

    } else {
      try {
        await api.updateProductItem(productItem.id, editProductItem);
        setProductItem(cloneDeep(editProductItem));
        dispatch(showSuccessMessage("Product Item Successfully Updated"));
      } catch (error) {
        showError(error);
        setEditProductItem(productItem);
      } finally {
        setEditMode(false);
      }
    }

    hideOverlay();
  };

  const editProductItemField = (field, value) => {
    let _editProductItem = { ...editProductItem };
    _editProductItem[field] = value;
    setEditProductItem(_editProductItem);
  };

  const updateProductItemEntity = (field, entity) => {
    let _editProductItem = { ...editProductItem };
    _editProductItem[field] = entity;
    setEditProductItem(_editProductItem);
  };

  return (
    <PageLayout className="productItem-details">
      <section className="site-content bodyWrap" id="content">
        <section className="content-body">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <h4 className="PageTitle">
                  <Link className="page-parent" to={PAGE_URL.PRODUCT_SUBCODE_DETAILS + "?" + joinParams({id: subcode})}>
                    PRODUCT SUBCODE DETAILS
                  </Link>{" "}
                  &ndash; <span className="page-current">PRODUCT ITEM DETAILS</span>
                </h4>
              </div>
            </div>
            {loaded && (
              <div className="row">
                <div className="col-12">
                  <div className="card shadow" id="top-level-info">
                    <div className="card-body">
                      <ProductItemRelationships 
                        editProductItem={editProductItem}
                        updateProductItemEntity={updateProductItemEntity}
                        editProductItemField={editProductItemField}
                        editMode={editMode}
                        createMode={createMode}
                        productGroup = {productGroup}
                        productSubgroup = {productSubgroup}
                        attributeSchema = {attributeSchema}
                        toggleEditMode={toggleEditMode}
                        onSave={onSave}
                    />
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </section>
      </section>
    </PageLayout>
  );
};
