import React from "react";

export const GarmentComponents = ({ garmentComponents }) => {

    return (
        <div id="garmentComponents">
            <div className="form-row">
                <div className="col-4 order-label-sub-heading">
                    Component
                </div>
                <div className="col-8">
                    <div className="row">
                        <div className="col-6 order-label-sub-heading">
                            <label>Material </label>
                        </div>
                        <div className="col-6 order-label-sub-heading">
                            <label>%</label>
                        </div>
                    </div>
                </div>
            </div>
            <ul>
                {garmentComponents && garmentComponents.map((g, index) =>
                    <li className="material" key={index}>
                        <div className="form-row">
                            <div className="form-group col-4">
                                {g.componentType}
                            </div>
                            <div className="col-8">
                                {g.fabricCompositions.map((fabric, index) =>
                                    <div key={index} className="row">
                                        <div className="col-6">
                                            {fabric.material}
                                        </div>
                                        <div className="col-6">
                                            {fabric.percentage}
                                        </div>
                                    </div>)
                                }
                            </div>
                        </div>
                    </li>
                )}
            </ul>
        </div>
    )
}