export const ARTWORK_STATUS = Object.freeze({
    NEEDS_UPLOAD: "NEEDS_UPLOAD",
    UPLOADED: "UPLOADED",
    REJECTED: "REJECTED",
    APPROVED: "APPROVED",
});

export const ARTWORK_STATUS_MESSAGE = Object.freeze({
    [ARTWORK_STATUS.NEEDS_UPLOAD]: "Needs Artwork Upload",
    [ARTWORK_STATUS.UPLOADED]: "Needs Artwork Approval",
    [ARTWORK_STATUS.REJECTED]: "Needs Artwork Upload",
});