// React
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

// Local
import { api } from "services/apiService";
import { PageLayout } from "layouts/PageLayout";
import { CareLabelOptions } from "./options/CareLabelOptions";
import { useError } from "hooks/errors";
import { useURLNavigate } from "hooks/useURLNavigate";
import { PAGE_URL } from "constants/url";
import { useDispatch, useSelector } from "react-redux";
import {
  showSuccessMessage,
  showErrorMessage,
} from "store/messages/messageActions";
import { isEmpty, isNullOrUndefined, stringToInt } from "util/common";
import { useOverlay } from "hooks/useOverlay";
import { useURLParams } from "hooks/useURLParams";
import { CareLabelLists } from "./lists/CareLabelLists";

export const CareLabelPage = () => {
  // hooks
  const navigate = useURLNavigate();
  const [showError, pushError] = useError();
  const dispatch = useDispatch();
  const [showOverlay, hideOverlay] = useOverlay();
  const [getParam] = useURLParams();
  const tab_idx = stringToInt(getParam("tab"));
  const customer_id = stringToInt(getParam("customerId"));
  const message = useSelector((state) => state.message.message);

  // state
  const [loaded, setLoaded] = useState(false);

  const [countryOfOriginOptions, setCountryOfOriginOptions] = useState();
  const [componentTypeOptions, setComponentTypeOptions] = useState();
  const [materialOptions, setMaterialsOptions] = useState();
  const [specialInstructionOptions, setSpecialInstructionOptions] = useState();
  const [cautionMaintenanceOptions, setCautionMaintenanceOptions] = useState();
  const [careCodeOptions, setCareCodeOptions] = useState();
  const [careInstructionOptions, setCareInstructionOptions] = useState();

  const [countryOfOriginLists, setCountryOfOriginLists] = useState();
  const [cautionMaintenanceLists, setCautionMaintenanceLists] = useState();
  const [specialInstructionLists, setSpecialInstructionLists] = useState();
  const [fabricCompositionLists, setFabricCompositionLists] = useState();
  const [careCodeLists, setCareCodeLists] = useState();
  const [careInstructionLists, setCareInstructionLists] = useState();

  const [activeTab, setActiveTab] = useState(1);

  useEffect(() => {
    loadData();
  }, []);

  const loadData = async () => {
    if (tab_idx === 1 || tab_idx === 2) {
      setActiveTab(tab_idx);
    }

    await showOverlay("Loading...");

    try {
      await loadOptions();
      await loadLists();

      setLoaded(true);
    } catch (error) {
      pushError(error);
      navigate(PAGE_URL.HOME);
    } finally {
      hideOverlay();
    }
  };

  const loadOptions = async () => {
    const countryOfOriginOptionsResponse = await api.getCountryOfOriginOptions();
    const componentTypesResponse = await api.getComponentTypes();
    const materialsResponse = await api.getMaterials();
    const specialInstructionsResponse = await api.getSpecialInstructions();
    const cautionMaintenanceOptionsResponse = await api.getCautionMaintenanceOptions();
    const careCodesResponse = await api.getCareCodes();
    const careInstructionsResponse = await api.getCareInstructions();

    setCountryOfOriginOptions(countryOfOriginOptionsResponse.data.refs);
    setComponentTypeOptions(componentTypesResponse.data.refs);
    setMaterialsOptions(materialsResponse.data.refs);
    setSpecialInstructionOptions(specialInstructionsResponse.data.refs);
    setCautionMaintenanceOptions(cautionMaintenanceOptionsResponse.data.refs);
    setCareCodeOptions(careCodesResponse.data.codes);
    setCareInstructionOptions(careInstructionsResponse.data);
  };

  const loadLists = async () => {
    const cautionMaintenanceListsResponse = await api.getAllCautionMaintenanceLists();
    const fabricCompositionListsResponse = await api.getAllFabricCompositionLists();
    const careCodeListsResponse = await api.getAllCareCodeLists();
    const careInstructionListsResponse = await api.getAllCareInstructionLists();
    const countryOfOriginListsResponse = await api.getAllCountryOfOriginLists();
    const specialInstructionsListsResponse = await api.getAllSpecialInstructionLists();

    setCautionMaintenanceLists(
      cautionMaintenanceListsResponse.data.listContent
    );
    setFabricCompositionLists(fabricCompositionListsResponse.data.listContent);
    setCareCodeLists(careCodeListsResponse.data.listContent);
    setCareInstructionLists(careInstructionListsResponse.data.listContent);
    setCountryOfOriginLists(countryOfOriginListsResponse.data.listContent);
    setSpecialInstructionLists(
      specialInstructionsListsResponse.data.listContent
    );
  };

  const addRefOption = async (
    option,
    optionList,
    setOptionList,
    apiCall,
    sortField = "name"
  ) => {
    if (isNullOrUndefined(message)) {
      await showOverlay("Adding...");
    }

    try {
      const response = await apiCall(option);

      const _option = { ...option };
      _option.id = response.data;

      const _optionList = [...optionList];
      _optionList.push(_option);

      // sort
      if (typeof _optionList[0][sortField] === "string") {
        _optionList.sort((o1, o2) =>
          o1[sortField].localeCompare(o2[sortField])
        );
      } else {
        _optionList.sort((t1, t2) =>
          stringToInt(t1[sortField]) < stringToInt(t2[sortField])
            ? -1
            : stringToInt(t1[sortField]) > stringToInt(t2[sortField])
              ? 1
              : 0
        );
      }
      setOptionList(_optionList);
      dispatch(showSuccessMessage("Option Successfully Added"));
    } catch (error) {
      showError(error);
    } finally {
      hideOverlay();
    }
  };

  const updateRefOption = async (
    option,
    optionList,
    setOptionList,
    apiCall,
    sortField = "name",
    setFields = ["name"]
  ) => {
    if (isNullOrUndefined(message)) {
      await showOverlay("Updating...");
    }

    try {
      await apiCall(option);

      const _optionList = [...optionList];
      const _option = _optionList.find((o) => o.id === option.id);

      if (isEmpty(_option)) {
        dispatch(showErrorMessage("Could not find the option specified"));
      } else {
        for (let f of setFields) {
          _option[f] = option[f];
        }

        // sort
        if (typeof _optionList[0][sortField] === "string") {
          _optionList.sort((o1, o2) =>
            o1[sortField].localeCompare(o2[sortField])
          );
        } else {
          _optionList.sort((t1, t2) =>
            stringToInt(t1[sortField]) < stringToInt(t2[sortField])
              ? -1
              : stringToInt(t1[sortField]) > stringToInt(t2[sortField])
                ? 1
                : 0
          );
        }

        setOptionList(_optionList);
        dispatch(showSuccessMessage("Option Successfully Updated"));
      }
    } catch (error) {
      showError(error);
    } finally {
      hideOverlay();
    }
  };

  return (
    <PageLayout className="care-label-configuration">
      <section className="site-content bodyWrap" id="content">
        <section className="content-body">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="page-title-row">
                  <h4 className="PageTitle">
                    <span className="page-parent">CARE LABELS</span>
                  </h4>
                </div>
              </div>
            </div>
            <div className="card shadow">
              <div className="card-body">
                <ul className="nav nav-tabs mb-4">
                  <li className="nav-item">
                    <button
                      style={{ backgroundColor: "transparent", outline: "none"}}
                      className={
                        activeTab === 1 ? "nav-link active" : "nav-link"
                      }
                      onClick={() => setActiveTab(1)}
                    >
                      Options
                    </button>
                  </li>
                  <li className="nav-item">
                    <button
                      style={{ backgroundColor: "transparent", outline: "none"}}
                      className={
                        activeTab === 2 ? "nav-link active" : "nav-link"
                      }
                      onClick={() => setActiveTab(2)}
                    >
                      Lists
                    </button>
                  </li>
                  {customer_id !== 0 && (
                    <li style={{ marginTop: "6px", marginLeft: "auto" }}>
                      <Link to={"/customer-details?id=" + customer_id}>
                        Back to Customer
                      </Link>
                    </li>
                  )}
                </ul>
                {loaded && activeTab === 1 && (
                  <CareLabelOptions
                    countryOfOriginOptions={countryOfOriginOptions}
                    componentTypes={componentTypeOptions}
                    materials={materialOptions}
                    specialInstructions={specialInstructionOptions}
                    cautionMaintenanceOptions={cautionMaintenanceOptions}
                    careCodes={careCodeOptions}
                    careInstructions={careInstructionOptions}
                    setCountryOfOriginOptions={setCountryOfOriginOptions}
                    setComponentTypes={setComponentTypeOptions}
                    setMaterials={setMaterialsOptions}
                    setSpecialInstructions={setSpecialInstructionOptions}
                    setCautionMaintenanceOptions={setCautionMaintenanceOptions}
                    setCareCodes={setCareCodeOptions}
                    setCareInstructions={setCareInstructionOptions}
                    addRefOption={addRefOption}
                    updateRefOption={updateRefOption}
                  />
                )}
                {loaded && activeTab === 2 && (
                  <CareLabelLists
                    countryOfOriginLists={countryOfOriginLists}
                    cautionMaintenanceLists={cautionMaintenanceLists}
                    specialInstructionLists={specialInstructionLists}
                    fabricCompositionLists={fabricCompositionLists}
                    careCodeLists={careCodeLists}
                    careInstructionLists={careInstructionLists}
                    setCountryOfOriginLists={setCountryOfOriginLists}
                    setCautionMaintenanceLists={setCautionMaintenanceLists}
                    setSpecialInstructionLists={setSpecialInstructionLists}
                    setFabricCompositionLists={setFabricCompositionLists}
                    setCareInstructionLists={setCareInstructionLists}
                    setCareCodeLists={setCareCodeLists}
                    countryOfOriginOptions={countryOfOriginOptions}
                    cautionMaintenanceOptions={cautionMaintenanceOptions}
                    specialInstructionOptions={specialInstructionOptions}
                    componentTypeOptions={componentTypeOptions}
                    materialOptions={materialOptions}
                    careCodeOptions={careCodeOptions}
                    careInstructionOptions={careInstructionOptions}
                  />
                )}
              </div>
            </div>
          </div>
        </section>
      </section>
    </PageLayout>
  );
};
