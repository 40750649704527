import { useDispatch } from "react-redux";
import {
  showErrorMessage,
  pushErrorMessage,
} from "store/messages/messageActions";
import { UNEXPECTED_ERROR_MESSAGE, SERVER_CONNECTION_ERROR_MESSAGE } from "constants/messages";

export const useError = () => {

  const dispatch = useDispatch();

  const showError = (error) => {
    console.error(error);
    if (error && error.response && error.response.data) {
      if (error.response.data.message) {
        dispatch(showErrorMessage(error.response.data.message));
      } else {
        dispatch(showErrorMessage(SERVER_CONNECTION_ERROR_MESSAGE));
      }
    } else {
      dispatch(showErrorMessage(UNEXPECTED_ERROR_MESSAGE));
    }
  };

  const pushError = (error) => {
    if (error && error.response && error.response.data) {
      if (error.response.data.message) {
        dispatch(pushErrorMessage(error.response.data.message));
      } else {
        dispatch(pushErrorMessage(SERVER_CONNECTION_ERROR_MESSAGE));
      }
    } else {
      dispatch(pushErrorMessage(UNEXPECTED_ERROR_MESSAGE));
    }
  };

  return [showError, pushError];
};
