import React, { useEffect, useState } from "react";
import { PageLayout } from "layouts/PageLayout";
import { useURLParams } from "hooks/useURLParams";
import api from "services/apiService";
import { isEmpty } from "util/common";
import { useError } from "hooks/errors";
import { ProductStandardDetailsPage } from "./standard/ProductStandardDetailsPage";
import { ProductVariableDataDetailsPage } from "./variable/ProductVariableDataDetailsPage";
import { ProductDynamicDetailsPage } from "./dynamic/ProductDynamicDetailsPage";

export const ProductDetailsPage = () => {

  const [getParam] = useURLParams();
  const [showError] = useError();

  const [productType, setProductType] = useState(null);
  const [products, setProducts] = useState([]);
  const [orderItemId, setOrderItemId] = useState(null);
  const [editMode, setEditMode] = useState(false);

  const loadProducts = async () => {
    const productItemIds = getParam("productItemId");

    if (!isEmpty(productItemIds)) {
      try {
        const response = await api.getProductDetails(productItemIds);
        setProducts(response.data.products);
        setProductType(response.data.productType);
      } catch (error) {
        console.log(error);
        showError(error);
      }
    } else {
      setEditMode(true);

      const orderItemId = getParam("orderItemId");
      setOrderItemId(orderItemId);

      try {
        const response = await api.getEditProductDetails(orderItemId);
        setProducts(response.data.products);
        setProductType(response.data.productType);
      } catch (error) {
        showError(error);
      }
    }
  };

  useEffect(() => {
    loadProducts();
  }, []);

  useEffect(() => {
    window.setCustomInvalidHandlers();
  }, [products]);

  return (
    <PageLayout className="product-details">
      <section className="site-content bodyWrap" id="content">
        <section className="content-body">
          {productType === "STANDARD" &&
            <ProductStandardDetailsPage products={products} editMode={editMode} orderItemId={orderItemId}/>}
          {productType === "VARIABLE_DATA" &&
            <ProductVariableDataDetailsPage products={products} editMode={editMode} orderItemId={orderItemId}/>}
          {productType === "DYNAMIC" &&
            <ProductDynamicDetailsPage product={products[0]} editMode={editMode} orderItemId={orderItemId}/>}
        </section>
      </section>
    </PageLayout>
  );
};
