// React
import React from "react";
import { useURLNavigate } from "hooks/useURLNavigate";
import { joinParams } from "components/joinParams";
import { PAGE_URL } from "constants/url";



export const WarehouseListRow = ({ warehouse }) => {
  const navigate = useURLNavigate();

  const onClick = () => {
    const params = joinParams({
      id: warehouse.id,
    });
    navigate(PAGE_URL.WAREHOUSE_DETAILS, params);
  };

  return (
    <tr className="pf-row" onClick={onClick}>
      <td className="data">{warehouse.warehouseName}</td>
      <td className="data">{warehouse.address}</td>
      <td className="data">{warehouse.preferredShippingMethod}</td>
    </tr>
  );
};
