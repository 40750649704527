import React, { useState } from "react";
import { useSelector } from "react-redux";
import { PageLayout } from "../../layouts/PageLayout";
import api from "services/apiService";
import { CustomerListSearch } from "./CustomerListSearch";
import { CustomerListRow } from "./CustomerListRow";
import { joinParams } from "components/joinParams";
import { pluralize, isNullOrUndefined, isEmpty } from "util/common";
import { useOverlay } from "hooks/useOverlay";
import { useError } from "hooks/errors";
import { useURLNavigate } from "hooks/useURLNavigate";

export const CustomerListPage = () => {
  const [showOverlay, hideOverlay] = useOverlay();
  const message = useSelector(state => state.message.message);
  const [customers, setCustomers] = useState([]);
  const [showError, ] = useError();
  const navigate = useURLNavigate();

  const searchCustomers = async (searchValues) => {
    try {
      const params = getParams(searchValues);

      if (isNullOrUndefined(message)) {
        await showOverlay("Searching...");
      }

      const response = await api.getFilteredCustomers(params);
      hideOverlay();
      setCustomers(response.data.customers);
    } catch (error) {
      hideOverlay();
      showError(error);
    }
  };

  const getParams = (searchValues) => {
    return joinParams({
      name: searchValues.name,
    });
  };

  return (
        <PageLayout className="customer-list">
            <section className="site-content bodyWrap" id="content">
                <section className="content-body">
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                              <div className="page-title-row">
                                <h4 className="PageTitle"><span className="page-parent">CUSTOMERS</span></h4>
                                <button className="btn btn-primary add-entity-btn" onClick={() => navigate("/customer-details", "createMode=true")}>Add</button>
                              </div>
                              <CustomerListSearch searchCustomers={searchCustomers} />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <div className="list-group mt-5" id="customerList">
                                    {customers && customers.map((customer, index) =>
                                        <CustomerListRow key={index} customer={customer} />
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="productNumber-pagination">
                            <span>{"Showing " + customers.length + pluralize(" Customer", customers.length)}</span>
                        </div>
                    </div>
                </section>
            </section>
        </PageLayout>
  );
};
