import React from "react";
import { Link } from "react-router-dom";
import { OrderStatusCircles } from "./components/OrderStatusCircles";
import { LabelValue } from "components/LabelValue";
import { LabelDate } from "components/LabelDate";
import { LabelCurrency } from "components/LabelCurrency";

export const OrderListRow = ({ order }) => {
    return (
        <Link className="order-item list-group-item list-group-item-action" to={"/order-details?orderId=" + order.orderId}>
            <div className="d-flex w-100">
                <div className="order-details">
                    <div className="row">
                        <div className="col-5 header-item">{order.company}</div>
                        {order.cancelled &&
                            <div className="cancelled-item">CANCELLED</div>
                        }
                    </div>
                    <div className="order-meta">
                        <ul>
                            <LabelDate label="Order Date" date={order.orderDate} />
                            <LabelValue label="Customer" value={order.customer} />
                            <LabelValue label="Production Region" value={order.productionRegion} />
                            <LabelValue label="Vendor PO" value={order.vendorPO} labelClassName="short-label" />
                            {order.productionPONumbers && <LabelValue label="Production PO" value={order.productionPONumbers.join(", ")} />}
                        </ul>
                        <ul className="order-status">
                            <li>
                                <div className="label">Order Status:</div>
                                <OrderStatusCircles orderStatus={order.orderStatus} financeApproval={order.financeApproval} hasArtwork={order.hasArtwork} />
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="order-price">
                    {order.totalPrice &&
                        <div>
                            <span className="descriptor">Price:</span>
                            <LabelCurrency currency={order.totalPrice.currency} value={order.totalPrice.price} />
                        </div>
                    }
                    {order.totalCost &&
                        <div>
                            <span className="descriptor">Cost:</span>
                            <LabelCurrency currency={order.totalCost.currency} value={order.totalCost.price} />
                        </div>
                    }
                </div>
            </div>
        </Link>
    );
};
