import React from "react";
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import { useCurrentUser } from "hooks/useCurrentUser";
import { Thumbnail } from "components/Thumbnail";
import { LabelCurrency } from "components/LabelCurrency";
import { LabelValue } from "components/LabelValue";
import { Artwork } from "./components/Artwork";
import { ARTWORK_STATUS } from "constants/artworkStatus";
import { OrderItemNeedsActionWrapper } from "./components/OrderItemNeedsActionWrapper";
import { CareLabelDetails } from "./components/care-label/CareLabelDetails";
import { PAGE_URL } from "constants/url";
import { isNullOrFalse } from "util/common";
import { COST_ACCESSIBLE_USERS, PRICE_ACCESSIBLE_USERS, userCanPerformOperation, ROLE_OPERATIONS } from "constants/roles";
import { formatCurrency, formatLargeNumber } from "util/formatNumber";
import { ORDER_STATUS_API } from "constants/orderStatus";
import { RolePermission } from "components/RolePermission";
import { VariableDataUtils } from "util/variableData";

export const OrderDetailItem = ({ orderId, orderItem, orderStatus, isPendingOrder, isAllArtPrepared, isCsrsArtPrepared, tabSelected, removeItem, reloadOrder }) => {

    const [editMode, setEditMode] = useState(false);
    const [artChanged, setArtChanged] = useState(false);

    const [thumbnailPath, setThumbnailPath] = useState(orderItem.productThumbnail.path);
    const [reloadThumbnail, setReloadThumbnail] = useState(false);
    const user = useCurrentUser();

    useEffect(() => {
        if (!isPendingOrder && artChanged) {
            reloadOrder();
        }
    }, [artChanged]);

    useEffect(() => {
        if (orderItem.productThumbnail.path !== thumbnailPath) {
            setThumbnailPath(orderItem.productThumbnail.path);
            setReloadThumbnail(!reloadThumbnail);
        }
				if (orderItem.variants) {
					const _variants = VariableDataUtils.getSortedDynamicDataVariants(orderItem.variants);
					orderItem.variants = _variants;
				}
    }, [orderItem]);


    const getPriceCurrency = () => (orderItem.unitPrice ? " (" + orderItem.unitPrice.currency + ")" : "");

    const getCostCurrency = () => (orderItem.unitCost ? " (" + orderItem.unitCost.currency + ")" : "");

    const Sizes = ({ orderItem }) => {
        let sizes = [];

        if (orderItem.sizes) {
            sizes.push(
                <div className="row product-magic-attributes">
                    <div className="col-2 label">Size</div>
                    <div className="col-2 label">Quantity</div>
                    {orderItem.sizes && orderItem.sizes[0].attributesItem && orderItem.sizes[0].attributesItem.map((attribute, i) =>
                        <div key={"size-heading-" + i} className="col-2 label last-magic-attribute">{attribute.name}</div>
                    )}
                    {orderItem.totalPrice !== null && <RolePermission roles={PRICE_ACCESSIBLE_USERS}>
                        <div className="col-2 label">{"Unit Price" + getPriceCurrency()}</div>
                    </RolePermission>}
                    {orderItem.totalCost !== null && <RolePermission roles={COST_ACCESSIBLE_USERS}>
                        <div className="col-2 label">{"Unit Cost" + getCostCurrency()}</div>
                    </RolePermission>}
                </div>
            );

            orderItem.sizes.map(sizeItem => {
                sizes.push(
                    <div className="row product-magic-attribute">
                        <div className="col-2">
                            <div>{sizeItem.size}</div>
                        </div>
                        <div className="col-2" >{formatLargeNumber(sizeItem.quantity)}</div>
                        {sizeItem.attributesItem && sizeItem.attributesItem.map((attribute, i) =>
                            <div key={"size-value-" + i} className="col-2 last-magic-attribute" >{attribute.value}</div>
                        )}
                        <RolePermission roles={PRICE_ACCESSIBLE_USERS}>
                            {sizeItem.unitPrice && <div className="col-2">{parseFloat(sizeItem.unitPrice.price).toFixed(4)}</div>}
                        </RolePermission>
                        <RolePermission roles={COST_ACCESSIBLE_USERS}>
                            {sizeItem.unitCost && <div className="col-2">{parseFloat(sizeItem.unitCost.price).toFixed(4)}</div>}
                        </RolePermission>
                    </div>
                );
            });
        }

        return sizes;
    };

    const Attributes = () => {
        let labels = [];
        let i = 0;
        for (const [key, value] of Object.entries(orderItem.attributes)) {
            labels.push(<LabelValue label={key} value={value} key={"attribute-" + i} />);
            i++;
        }

        return labels;
    };

    const HeaderItemsForType = ({ type }) => {
        if (type === "variable") {
            return (
                <>
                    <LabelValue label={"PO Number"} value={orderItem.poNumber} />
                    <LabelValue label={"Ticket Type"} value={orderItem.productSubcode} />
                    <li className="description">
                        <div className="label">Description:</div>
                        <div className="value">{orderItem.description}</div>
                    </li>
                    {(orderItem.items.length > 1) && <>
                        <LabelValue label={"Total Quantity"} value={formatLargeNumber(orderItem.quantity)} />
                        <LabelValue label={"Customer Quantity"} value={formatLargeNumber(orderItem.customerQuantity)} />
                    </>}
                </>
            );
        } else {
            return (
                <>
                    {type === "dynamic" && <LabelValue label={"PO Number"} value={orderItem.poNumber} />}
                    <li>
                        <div className="label">Product Code:</div>
                        <div className="value">{orderItem.productSubcode}</div>
                    </li>
                    <li className="description">
                        <div className="label">Description:</div>
                        <div className="value">{orderItem.description}</div>
                    </li>
                    <li>
                        <div className="label">Pack Size:</div>
                        <div className="value">{orderItem.packSize}</div>
                    </li>
                    {isNullOrFalse(orderItem.sizes) && type === "standard" ?
                        <LabelValue label={"Quantity"} value={formatLargeNumber(orderItem.quantity)} /> :
                        <LabelValue label={"Total Quantity"} value={formatLargeNumber(orderItem.quantity)} />
                    }
                </>
            );
        }
    };

    const VariableDataTableHeader = ({ item }) => {
        const headers = [];
        let i = 0; // Each child in a list needs a unique key

        Object.keys(item).map(key => headers.push(<th key={"order-item-vd-header-" + i++} scope="col" >{key}</th>));

        // always add quantity column
        headers.push(<th key={"quantity-header-" + i++} scope="col">Quantity</th>);



        return (
            <tr>
                {headers}
                <RolePermission roles={PRICE_ACCESSIBLE_USERS}>
                    <th key={"unit-price-header-" + i++} scope="col">{"Unit Price" + getPriceCurrency()}</th>
                </RolePermission>
                <RolePermission roles={COST_ACCESSIBLE_USERS}>
                    <th key={"unit-cost-header-" + i++} scope="col">{"Unit Cost" + getCostCurrency()}</th>
                </RolePermission>
            </tr>
        );
    };

    const VariableDataRow = ({ itemData, quantity, unitPrice, unitCost, index }) => {
        const rowItems = [];

        itemData.map((data, idx) => {
            const key = "oi-variable-data-value-row-" + index + "-col-" + idx;
            rowItems.push(<td key={key}>{data}</td>);
        });

        // always add quantity
        rowItems.push(<td key={"oi-variable-data-quant-value-" + itemData.length}>{formatLargeNumber(quantity)}</td>);

        return (
            <tr>
                {rowItems}
                <RolePermission roles={PRICE_ACCESSIBLE_USERS}>
                    {unitPrice && <td>{unitPrice.price}</td>}
                </RolePermission>
                <RolePermission roles={COST_ACCESSIBLE_USERS}>
                    {unitCost && <td>{unitCost.price}</td>}
                </RolePermission>
            </tr>
        );
    };

    const VariableDataTable = ({ items }) => {
        return (
            <div className="required-attributes">
                <table className="table table-striped">
                    <thead>
                        <VariableDataTableHeader item={items[0].variableData} />
                    </thead>
                    <tbody>
                        {items.map(item => <VariableDataRow key={"variable-data-row-" + items.indexOf(item)} unitPrice={item.unitPrice} unitCost={item.unitCost} itemData={Object.values(item.variableData)} quantity={item.quantity} index={items.indexOf(item)} />)}
                    </tbody>
                </table>
            </div>
        );
    };

    const DynamicDataTableHeader = ({ data }) => {
        const headers = [];
        let i = 0; // Each child in a list needs a unique key

        Object.keys(data).map(key => headers.push(<th key={"order-item-dd-header-" + i++} scope="col" >{key === "Quantity" ? "Customer Quantity" : key}</th>));

        // always add quantity column
        headers.push(<th key={"quantity-header-" + i++} scope="col">Quantity</th>);

        return (
            <tr>
                {headers}
            </tr>
        );
    };

    const DynamicDataRow = ({ itemData, quantity, index }) => {
        const rowItems = [];

        // Will not show variants with 0 quantity in the cart.
        // If quantity remains 0 when the order is placed, they will be removed from the order.
        if (!quantity) {
            return <></>;
        }

        itemData.map((data, idx) => {
            const key = "oi-dynamic-data-value-row-" + index + "-col-" + idx;
            rowItems.push(<td key={key}>{data}</td>);
        });

        // always add quantity
        rowItems.push(<td key={"oi-dynamic-data-quant-value-" + itemData.length}>{formatLargeNumber(quantity)}</td>);

        return (
            <tr>
                {rowItems}
            </tr>
        );
    };

    const DynamicDataTable = ({ variants }) => {
        return (
            <div className="required-attributes">
                <table className="table table-striped">
                    <thead>
                        <DynamicDataTableHeader data={variants[0].dynamicData} />
                    </thead>
                    <tbody>
                        {variants.map(v => <DynamicDataRow key={"dynamic-data-row-" + variants.indexOf(v)} itemData={Object.values(v.dynamicData)} quantity={v.quantity} index={variants.indexOf(v)} />)}
                    </tbody>
                </table>
            </div>
        );
    };

    return (
        <OrderItemNeedsActionWrapper isPendingOrder={isPendingOrder} isBuildItem={orderItem.buildItem} orderStatus={orderStatus} artwork={orderItem.artwork} isCsrsArtPrepared={isCsrsArtPrepared} tabSelected={tabSelected} user={user}>
            <div className="product-item variable-data card shadow">
                <div className="general-details">
                    <div className="product-img">
                        <ul>
                            {orderItem.buildItem && (orderItem.artwork || userCanPerformOperation(user, ROLE_OPERATIONS.UPLOAD_ARTWORK)) && !isPendingOrder ?
                                <Artwork
                                    orderId={orderId}
                                    orderStatus={orderStatus}
                                    orderItemId={orderItem.orderItemId}
                                    artwork={orderItem.artwork}
                                    isAllArtPrepared={isAllArtPrepared}
                                    isCsrsArtPrepared={isCsrsArtPrepared}
                                    artChanged={artChanged}
                                    setArtChanged={setArtChanged}
                                    editMode={editMode}
                                    setEditMode={setEditMode}
                                />
                                :
                                <Thumbnail className="img-fluid" imagePath={orderItem.productThumbnail.path} imageType={orderItem.productThumbnail.type} forceReload={reloadThumbnail} />
                            }
                        </ul>
                        {orderItem.buildItem && !editMode && userCanPerformOperation(user, ROLE_OPERATIONS.REVIEW_ARTWORK) && orderItem.artwork &&
                            (orderItem.artwork.status === ARTWORK_STATUS.REJECTED || (orderItem.artwork.status === ARTWORK_STATUS.APPROVED && !orderItem.artwork.artworkFinalized)) &&
                            orderStatus === ORDER_STATUS_API.NEEDS_ART_APPROVAL &&
                            <button className="btn-edit-artwork btn-outline-primary btn btn-sm" onClick={() => setEditMode(true)}>Edit</button>}
                    </div>
                    <div className="product-info">
                        <div className="fixed-info">
                            <ul>
                                <HeaderItemsForType type={orderItem.variableDataProduct ? "variable" : (orderItem.variants ? "dynamic" : "standard")} />
                            </ul>

                            <ul className="unit-price-cost">
                                {orderItem.unitPrice && !orderItem.sizes && !orderItem.variableDataProduct &&
                                    <li className="stack">
                                        <span className="label">Unit Price: </span>
                                        <span className="value">
                                            <span className="currency">{orderItem.unitPrice.currency} </span><span className="price">{orderItem.unitPrice.price}</span>
                                        </span>
                                    </li>
                                }
                                {orderItem.unitCost &&
                                    <li className="stack">
                                        <span className="label">Unit Cost: </span>
                                        <span className="value">
                                            <span className="currency">{orderItem.unitCost.currency} </span><span className="price">{orderItem.unitCost.price}</span>
                                        </span>
                                    </li>
                                }
                                {orderItem.minValue &&
                                    <li className="stack">
                                        <span className="label">Min. Order Value: </span>
                                        <span className="value">
                                            <span className="currency">{orderItem.minValue.currency} </span><span className="price">{formatCurrency(orderItem.minValue.price)}</span>
                                        </span>
                                    </li>
                                }
                            </ul>
                        </div>
                        <div className="fixed-info">
                            <ul>
                                {orderItem.attributes && <Attributes attributes={orderItem.attributes} />}
                            </ul>
                            <ul></ul>
                        </div>

                        {
                            orderItem.sizes &&
                            <div className="product-magic-attributes">
                                <Sizes orderItem={orderItem} />
                            </div>
                        }

                        {orderItem.variableDataProduct && <VariableDataTable items={orderItem.items} />}
                        {!!orderItem.variants && <DynamicDataTable variants={orderItem.variants} />}

                        <h5 className="total">
                            {orderItem.totalPrice &&
                                <div>
                                    <span className="price-label">{orderItem.totalCost ? "Total Price: " : "Total: "}</span>
                                    <LabelCurrency currency={orderItem.totalPrice.currency} value={orderItem.totalPrice.price} />
                                </div>
                            }
                            {orderItem.totalCost &&
                                <div className="cost-and-price">
                                    <span className="price-label">{orderItem.totalPrice ? "Total Cost: " : "Total: "}</span>
                                    <LabelCurrency currency={orderItem.totalCost.currency} value={orderItem.totalCost.price} />
                                </div>
                            }
                            {isPendingOrder &&
                                <>
                                    <Link className="btn btn-primary btn-sm mx-3" to={PAGE_URL.PRODUCT_DETALS + "?orderItemId=" + orderItem.orderItemId}>Edit</Link>
                                    <button className="remove btn btn-danger btn-sm" type="button" onClick={() => removeItem(orderItem.orderItemId)}>Remove</button>
                                </>
                            }
                        </h5>
                    </div>
                </div>
            </div>
            {orderItem.careLabel && <CareLabelDetails careLabel={orderItem.careLabel} />}
        </OrderItemNeedsActionWrapper>
    );
};
