import React from "react";


export const LabelButton = ({ label, value, onClickHandler, labelClassName }) => {

    // api determined user does not have permission.
    if (!value) {
        return <></>;
    }

    return (
        <li>
            <div className={"label " + (labelClassName ? labelClassName : "")}>{label ? label : ""}:</div>
            <button onClick={onClickHandler} className="file-btn-link">{value}</button>
        </li>
    );
};
