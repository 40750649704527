export const SORTED_VARIABLE_DATA_SIZES = [
  "0/1",
  "0-3",
  "0-6",
  "0-12",
  "2-3",
  "2-4",
  "3-5",
  "3-6",
  "4-5",
  "4-7",
  "4-8",
  "6-7",
  "6-12",
  "7-9",
  "8-9",
  "9-10",
  "9-11",
  "9-12",
  "12-18",
  "12-24",
  "18-24",
  "31-32",
  "33-34",
  "25\"",
  "26\"",
  "2-3YRS",
  "3-4YRS",
  "3-6YRS",
  "5-6YRS",
  "7-9YRS",
  "8-9YRS",
  "2T/2ans",
  "3T/3ans",
  "4T/4ans",
  "5T/5ans",
  "1-3Y",
  "2-3Y",
  "9-10 Y",
  "9-11 Y",
  "10-11Y",
  "4-5 YR",
  "6-7 YR",
  "7-8 YR",
  "8-9 YR",
  "4 INCH",
  "6 INCH",
  "8 INCH",
  "4INF",
  "5INF",
  "6INF",
  "5065CM",
  "6580CM",
  "8095CM",
  ".5L",
  "1LITRE",
  "2LITRE",
  "0-3MTH",
  "3-6MTH",
  "6-9MTH",
  "30X50",
  "50X50",
  "58X58",
  "0",
  "1",
  "2",
  "3",
  "4",
  "5",
  "6",
  "7",
  "7.5",
  "8",
  "8",
  "8.5",
  "9",
  "9.5",
  "10",
  "10.5",
  "11",
  "11.5",
  "12",
  "13",
  "14",
  "16",
  "18",
  "20",
  "22",
  "24",
  "25",
  "26",
  "27",
  "28",
  "29",
  "30",
  "31",
  "32",
  "33",
  "34",
  "36",
  "37",
  "38",
  "39",
  "40",
  "40",
  "41",
  "41",
  "42",
  "4-6½ B",
  "4-6½ G",
  "6-8½",
  "12-3½",
  "25½",
  "8X29\"",
  "10X29\"",
  "12X29\"",
  "14X29\"",
  "16X29\"",
  "18X29\"",
  "20X29\"",
  "9-12MT",
  "12-18M",
  "18-23M",
  "O/S/T/U",
  "XXS/XS/TTP/TP",
  "S/M/P/M",
  "L/XL/G/TG",
  "2X/3X/2TG/3TG",
  "XS-SMA",
  "XS-S",
  "S-M",
  "M-L",
  "L-XL",
  "XL-XXL",
  "S/M",
  "M/L",
  "3XS/3TP",
  "2XS/2TP",
  "XXS/TTP",
  "XS/TP",
  "S/P",
  "M/M",
  "L/G",
  "XL/TG",
  "XXL/TTG",
  "XXXL/TTTG",
  "2XL/2TG",
  "3XL/3TG",
  "XXSMAL",
  "XSMALL",
  "XSMALL - XXL",
  "SMALL",
  "MEDIUM",
  "LARGE",
  "XLARGE",
  "3X",
  "4X",
  "5X",
  "XXL",
  "XXXL",
  "XXXXL",
  "S/M/P-M",
  "L/XL/G-TG",
  "1 SIZE",
  "4PIECE",
  "3 PACK",
  "ASSTD"
];