import React from "react";
import { formatCurrency } from "util/formatNumber";

export const LabelCurrency = ({ currency, value, classOption, currencyClass }) => {

    // api determined user does not have permission.
    if (value === null || value === undefined) {
        return <></>;
    }

    return (
        <>
            <span className={currencyClass ? currencyClass : "currency"}>{currency}</span>
            <span className={classOption ? classOption : "value"}>{formatCurrency(value)}</span>
        </>
    );

};