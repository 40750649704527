import React, { useEffect, useState } from "react";
import api from "services/apiService";

export const Thumbnail = ({ className, imagePath, imageType, forceReload }) => {
  const [imageData, setImageData] = useState(null);

  useEffect(() => {
    getImageByteArray();
  }, []);

  useEffect(() => {
    getImageByteArray();
  }, [forceReload])

  const getImageByteArray = async () => {
    try {
      const data = await api
        .getProductImage(imagePath)
        .then((response) =>
          new Buffer(response.data, "binary").toString("base64")
        );

      setImageData(data);
    } catch (e) {}
  };

  const openThumbnail = (e) => {
    e.preventDefault();

    let newWindow = window.open("");
    newWindow.document.write(
      "<img width='100%' src='data:" +
        imageType +
        ";base64," +
        encodeURI(imageData) +
        "'/>"
    );
    newWindow.document.close();
    newWindow.document.title = imagePath;
  };

  return imageData ? (
    <a href="#" className="clickable" onClick={openThumbnail}>
      <img
        alt=""
        className={className}
        width="100%"
        src={"data:" + imageType + ";base64," + encodeURI(imageData)}
      />
    </a>
  ) : (
    <></>
  );
};
