import React from "react";
import { isEmpty } from "util/common";
import { Thumbnail } from "components/Thumbnail";
import { UnitPriceCostTable } from "components/UnitPriceCostTable";
import { MIN_ORDER_VALUE_DESCRIPTION } from "constants/messages";
import { formatCurrency } from "util/formatNumber";

export const StandardProductDetails = ({ product }) => {

  return (
    <div className="product-item standard card shadow">
      <div className="general-details">
        <div className="product-img">
          <Thumbnail
            className="img-fluid"
            imagePath={product.productImagePath}
            imageType={product.productImageType}
          />
        </div>
        <div className="product-info">
          <div className="fixed-info">
            <ul>
              <li>
                <div className="label">Product Code:</div>
                <div className="value">{product.productSubcode}</div>
              </li>
              <li className="description">
                <div className="label">Description:</div>
                <div className="value">{product.description}</div>
              </li>
              <li>
                <div className="label">Pack Size:</div>
                <div className="value">{product.packSize}</div>
              </li>
              {!isEmpty(product.minValue) && (
              <li>
                <div
                  className="label"
                  data-toggle="tooltip"
                  data-placement="right"
                  title={MIN_ORDER_VALUE_DESCRIPTION}
                >
                  Minimum Order Value *
                </div>
                <div className="value">
                  {product.minValue.currency +
                    " " +
                    formatCurrency(product.minValue.price)}
                </div>
              </li>
            )}
            </ul>
            <ul>
              {!isEmpty(product.costs) &&
                (product.costs.length === 1 ? (
                  <li>
                    <div className="label">Unit Cost:</div>
                    <div className="value">
                      <span className="currency">
                        {product.costs[0].currency}{" "}
                      </span>
                      <span className="price">{product.costs[0].price}</span>
                    </div>
                  </li>
                ) : (
                  <UnitPriceCostTable
                    isPrice={false}
                    pricesOrCosts={product.costs}
                  />
                ))}
              {!isEmpty(product.prices) &&
                (product.prices.length === 1 ? (
                  <li>
                    <div className="label">Unit Price:</div>
                    {product.prices.map((p, i) => (
                      <div className="value" key={i}>
                        <span className="currency">{p.currency} </span>
                        <span className="price">{p.price}</span>
                      </div>
                    ))}
                  </li>
                ) : (
                  <UnitPriceCostTable
                    isPrice={true}
                    pricesOrCosts={product.prices}
                  />
                ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};
