import React from "react";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { showSuccessMessage } from "store/messages/messageActions";
import { PageLayout } from "../../layouts/PageLayout";
import { useDispatch } from "react-redux";
import { useURLParams } from "hooks/useURLParams";
import { useCurrentUser } from "hooks/useCurrentUser";
import { useURLNavigate } from "hooks/useURLNavigate";
import { ROLES, VIEW_ORDER_LOGS } from "constants/roles";
import { api } from "../../services/apiService";
import { TABS } from "constants/tabs";
import { PRICE_ACCESSIBLE_USERS, userCanPerformOperation, ROLE_OPERATIONS } from "constants/roles";
import { PAGE_URL } from "constants/url";
import { OrderDetailHeader } from "./components/OrderDetailHeader";
import { OrderDetailsStatus } from "./components/OrderDetailsStatus";
import { NavTabs } from "./components/NavTabs";
import { OrderDetailBilling } from "./components/OrderDetailBilling";
import { RolePermission } from "components/RolePermission";
import { OrderDetailShipping } from "./components/OrderDetailShipping";
import { OrderDetailItem } from "./OrderDetailItem";
import { ORDER_STATUS_API } from "constants/orderStatus";
import { useOverlay } from "hooks/useOverlay";
import { useError } from "hooks/errors";
import { downloadFile } from "util/download";
import { isEmpty } from "util/common";
import { OrderLogs } from "./components/OrderLogs";
import { RelatedDocuments } from "modules/cart/components/RelatedDocuments";
import { showAlert } from "store/alerts/alertActions";
import { OrderCharge } from "./OrderCharge";
import { VariableDataUtils } from "util/variableData";
import { DUNNES } from "constants/customer";

export const OrderDetailPage = () => {
  const APPROVE_FINANCE_SUCCESS = "Order successfuly approved.";
  const PRODUCTION_CONFIRMED_SUCCESS = "Production has been confirmed.";
  const SHIP_AND_COMPLETE_SUCCESS =
    "Successfully marked as shipped and completed.";

  const [getParam] = useURLParams();
  const order_id = getParam("orderId");
  const user = useCurrentUser();
  const [tabsShown, setTabsShown] = useState(false);
  const [tabSelected, setTabSelected] = useState(TABS.ALL);
  const [order, setOrder] = useState(null);
  const dispatch = useDispatch();
  const navigate = useURLNavigate();
  const [showOverlay, hideOverlay] = useOverlay();
  const [showError, pushError] = useError();
  const [hasVariableData, setHasVariableData] = useState(false);

  const loadOrderDetails = async () => {
    try {
      const response = await api.getOrder(order_id);
      const variableDataItems = response.data.orderItems.filter(item => item.variableDataProduct === true);
      setHasVariableData(!!variableDataItems?.length);
      VariableDataUtils.customSortVariableDataItems(variableDataItems);

      setOrder(response.data);
    } catch (error) {
      pushError(error);
      navigate(PAGE_URL.HOME);
    }
  };

  const markArtworkPrepared = async () => {
    try {
      await showOverlay("Requesting Approval...");
      await api.markArtworkPrepared(order_id);
      hideOverlay();
      window.scrollTo(0, 0);
      loadOrderDetails();
      dispatch(showSuccessMessage("Artwork approval successfully requested."));
    } catch (error) {
      hideOverlay();
      showError(error);
    }
  };

  const submitArtworkReviews = async () => {
    try {
      await showOverlay("Submitting Reviews...");
      await api.submitArtworkReviews(order_id);
      hideOverlay();
      window.scrollTo(0, 0);
      loadOrderDetails();
      dispatch(showSuccessMessage("Reviews sucessfully submitted."));
    } catch (error) {
      hideOverlay();
      showError(error);
    }
  };

  const approveFinance = async () => {
    try {
      await showOverlay("Approving Finance...");
      await api.approveFinance(order_id);
      hideOverlay();
      setOrder(null);
      window.scrollTo(0, 0);
      loadOrderDetails();
      dispatch(showSuccessMessage(APPROVE_FINANCE_SUCCESS));
    } catch (error) {
      hideOverlay();
      showError(error);
    }
  };

  const completeProduction = async () => {
    try {
      await showOverlay("Updating Order...");
      await api.completeProduction(order_id);
      hideOverlay();
      setOrder(null);
      window.scrollTo(0, 0);
      loadOrderDetails();
      dispatch(showSuccessMessage(PRODUCTION_CONFIRMED_SUCCESS));
    } catch (error) {
      hideOverlay();
      showError(error);
    }
  };

  const shipAndComplete = async () => {
    try {
      await showOverlay("Updating Order...");
      await api.shipAndComplete(order_id);
      hideOverlay();
      setOrder(null);
      window.scrollTo(0, 0);
      loadOrderDetails();
      dispatch(showSuccessMessage(SHIP_AND_COMPLETE_SUCCESS));
    } catch (error) {
      hideOverlay();
      showError(error);
    }
  };

  useEffect(() => {
    loadOrderDetails();
  }, []);

  useEffect(() => {
    if (order) {
      // Tabs Shown
      if (order.orderStatus === ORDER_STATUS_API.NEEDS_ARTWORK) {
        setTabsShown(user.role === ROLES.CSR);
        setTabSelected(user.role === ROLES.CSR ? TABS.NEEDS_ACTION : TABS.ALL);
      } else if (order.orderStatus === ORDER_STATUS_API.NEEDS_ART_APPROVAL) {
        setTabsShown(user.role === ROLES.VENDOR);
        setTabSelected(
          user.role === ROLES.VENDOR ? TABS.NEEDS_ACTION : TABS.ALL
        );
      } else {
        setTabsShown(false);
        setTabSelected(TABS.ALL);
      }
    }
  }, [order]);

  const downloadCsv = async () => {
    try {
      showOverlay("Downloading...");
      const response = await api.downloadOrderCsv(order_id);
      downloadFile(response);
      hideOverlay();
    } catch (error) {
      hideOverlay();
      showError(error);
    }
  };

  const downloadData = async () => {
    try {
      showOverlay("Downloading...");
      const response = await api.downloadOrderData(order_id);
      downloadFile(response);
      hideOverlay();
    } catch (error) {
      hideOverlay();
      showError(error);
    }
  };

  const cancelOrder = () => {
    dispatch(
      showAlert({
        title: "Cancel order",
        description: "Are you sure you want to cancel this order?",
        cancelMessage: "No",
        confirmMessage: "Yes",
        cancelAction: null,
        confirmAction: onCancelOrder,
      })
    );
  };

  const onCancelOrder = async () => {
    try {
      showOverlay("Cancelling Order...");
      await api.cancelOrder(order_id);
      hideOverlay();
      window.scrollTo(0, 0);
      loadOrderDetails();
    } catch (error) {
      hideOverlay();
      showError(error);
    }
  };

  return (
    <PageLayout className="order-details">
      <section className="site-content bodyWrap" id="content">
        <section className="content-body">
          <div className="container">
            <div className="row">
              <div className="col-10">
                <h4 className="PageTitle">
                  <Link className="page-parent" to={PAGE_URL.ORDER}>
                    ORDERS
                  </Link>{" "}
                  &ndash; <span className="page-current">VIEW ORDER</span>
                </h4>
              </div>
            </div>

            {order && (
              <>
                <OrderDetailsStatus
                  orderStatus={order.orderStatus}
                  hasArtwork={order.hasArtwork}
                  financeApproved={order.financeApproval}
                />

                {order.cancelled && <div className="cancelled">CANCELLED</div>}

                <OrderDetailHeader details={order} orderId={order_id} />

                {/* Middle of Page */}
                <div className="row">
                  <div className="col-12">
                    <NavTabs
                      orderStatus={order.orderStatus}
                      role={user.role}
                      tabSelected={tabSelected}
                      setTabSelected={setTabSelected}
                      tabsShown={tabsShown}
                    />

                    <div className="tab-content">
                      <div
                        className="tab-pane fade show active"
                        role="tabpanel"
                        aria-labelledby=""
                        >
                        {/* Order Items */}
                        <ul className="products-list">
                          {order &&
                            order.orderItems &&
                            order.orderItems.map((item, index) => (
                              <OrderDetailItem
                                orderId={order_id}
                                orderStatus={order.orderStatus}
                                orderItem={item}
                                isPendingOrder={false}
                                isAllArtPrepared={order.allArtPrepared}
                                isCsrsArtPrepared={order.csrArtPrepared}
                                tabSelected={tabSelected}
                                key={index}
                                reloadOrder={loadOrderDetails}
                              />
                            ))}
                        </ul>
                        {/* Order Charges */}
                        <div className="row">
                          <div className="col">
                            <ul className="charge-list">
                              {order.orderCharges && order.orderCharges.map((c, idx) => (
                                <OrderCharge key={idx} charge={c} />
                              ))}
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <OrderDetailShipping
                  orderId={order_id}
                  orderDetails={order}
                  user={user}
                />

                <RolePermission roles={PRICE_ACCESSIBLE_USERS}>
                  <OrderDetailBilling orderDetails={order} />
                </RolePermission>

                {order && !isEmpty(order.relatedDocs) && (
                  <RelatedDocuments orderDetails={order} />
                )}

                <RolePermission roles={VIEW_ORDER_LOGS}>
                  <OrderLogs logs={order.logs} />
                </RolePermission>

                <div className="d-flex">
                  <div>
                    <button
                      className="btn btn-primary shadow"
                      type="button"
                      onClick={() => downloadCsv()}
                    >
                      Download CSV
                    </button>
                    <RolePermission roles={ROLE_OPERATIONS.DOWNLOAD_VARIABLE_DATA_CSV}>
                      {order && order.customer == DUNNES && hasVariableData &&
                      <button
                        className="btn btn-primary shadow ml-2"
                        type="button"
                        onClick={() => downloadData()}
                      >
                        Download Data
                      </button>}
                    </RolePermission>
                  </div>
                  <div className="d-space"></div>

                  {!order.cancelled && (
                    <>
                      {order.cancelAllowed && (
                        <div>
                          <button
                            className="btn btn-red shadow"
                            type="button"
                            onClick={(e) => cancelOrder()}
                          >
                            Cancel Order
                          </button>
                        </div>
                      )}

                      {order.orderStatus === ORDER_STATUS_API.NEEDS_ARTWORK &&
                        !order.csrArtPrepared && userCanPerformOperation(user, ROLE_OPERATIONS.UPLOAD_ARTWORK) && (
                          <div>
                            <button
                              className="btn btn-primary shadow ml-2"
                              type="button"
                              onClick={(e) => markArtworkPrepared()}
                            >
                              Request Artwork Approval
                            </button>
                          </div>
                        )}

                      {order.orderStatus ===
                        ORDER_STATUS_API.NEEDS_ART_APPROVAL && userCanPerformOperation(user, ROLE_OPERATIONS.REVIEW_ARTWORK) && (
                          <div>
                            <button
                              disabled={!order.allArtReviewed}
                              className="btn btn-primary shadow ml-2"
                              type="button"
                              onClick={(e) => submitArtworkReviews()}
                            >
                              Submit Reviews
                            </button>
                          </div>
                        )}

                      {!order.financeApproval && userCanPerformOperation(user, ROLE_OPERATIONS.APPROVE_FINANCE) && (
                          <div>
                            <button
                              className="btn btn-primary shadow ml-2"
                              type="button"
                              onClick={(e) => approveFinance()}
                            >
                              Approve
                            </button>
                          </div>
                        )}

                      {order.orderStatus ===
                        ORDER_STATUS_API.PRODUCTION_READY &&
                        ((user.role === ROLES.CSR && !order.csrProductionConfirmed) ||
                        userCanPerformOperation(user, ROLE_OPERATIONS.ALWAYS_COMPLETE_PRODUCTION)) && (
                          <div>
                            <button
                              className="btn btn-primary shadow ml-2"
                              type="button"
                              onClick={(e) => completeProduction()}
                            >
                              Complete Production
                            </button>
                          </div>
                        )}

                      {order.orderStatus === ORDER_STATUS_API.READY_TO_SHIP && userCanPerformOperation(user, ROLE_OPERATIONS.SHIP_AND_COMPLETE)
                        && (
                          <div>
                            <button
                              className="btn btn-primary shadow ml-2"
                              type="button"
                              onClick={(e) => shipAndComplete()}
                            >
                              Ship & Complete
                            </button>
                          </div>
                        )}
                    </>
                  )}
                </div>
              </>
            )}
          </div>
        </section>
      </section>
    </PageLayout>
  );
};
