import * as types from "./warehouseActionTypes";

const initialState = {
  warehouseSearchValues: {
    warehouseName: "",
    address: "",
    preferredShippingMethod: ""
  }
};

export const warehouseReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_WAREHOUSE_SEARCH_VALUES: {
      const data = action.data;
      return { ...state, warehouseSearchValues: data };
    }
    default:
      return state;
  }
};
