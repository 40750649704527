/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-trailing-spaces */
/* eslint-disable no-unused-vars */
/* eslint-disable no-multiple-empty-lines */

// React
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

// Local
import { api } from "../../services/apiService";
import { PageLayout } from "layouts/PageLayout";
import { useURLNavigate } from "hooks/useURLNavigate";
import { useURLParams } from "hooks/useURLParams";
import { useOverlay } from "hooks/useOverlay";
import { useError } from "hooks/errors";
import { PAGE_URL } from "constants/url";
import { joinParams } from "components/joinParams";
import { ProductItemListRow } from "./ProducItemListRow";
import { isEmpty, isNullOrUndefined, isTrue } from "util/common";
import { TopLevelSubcodeInfo } from "./components/TopLevelSubcodeInfo";
import { SubcodeRelationships } from "./components/SubcodeRelationships";
import {
  showSuccessMessage,
  pushSuccessMessage,
  showErrorMessage,
} from "store/messages/messageActions";
import { cloneDeep } from "lodash";
import { FULFILLMENT_PROCESS } from "constants/fulfillmentProcess";
import { PriceAndCostTiers } from "./components/PriceAndCostTiers";
import { PRODUCT_TYPE } from "constants/productType";

export const ProductSubcodeDetailPage = () => {
  // hooks
  const navigate = useURLNavigate();
  const [showError, pushError] = useError();
  const [getParam] = useURLParams();
  const [showOverlay, hideOverlay] = useOverlay();
  const dispatch = useDispatch();
  const message = useSelector((state) => state.message.message);

  // state
  const [editMode, setEditMode] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [subcode, setSubcode] = useState();
  const [editSubcode, setEditSubcode] = useState();
  const [customers, setCustomers] = useState();
  const [factories, setFactories] = useState();
  const [factoryCurrency, setFactoryCurrency] = useState();
  const [productCodes, setProductCodes] = useState();
  const [schemas, setSchemas] = useState();
  const [currencies, setCurrencies] = useState();
  const [productItems, setProductItems] = useState([]);
  const [image, setImage] = useState(null);
  const [reloadImage, setReloadImage] = useState(false);
  const [productType, setProductType] = useState(null);

  // url variables
  const subcodeId = getParam("id");
  const createMode = getParam("createMode");

  useEffect(() => {
    loadData();
  }, []);

  useEffect(() => {
    if (isTrue(loaded) && createMode) {
      // set default of required fields
      let _editSubcode = { ...editSubcode };
      _editSubcode["customer"] = customers[0];
      _editSubcode["factory"] = factories[0];
      setEditSubcode(_editSubcode);
    }
  }, [loaded]);

  useEffect(() => {
    if (
      !isEmpty(editSubcode) &&
      !isEmpty(editSubcode.customer) &&
      !isEmpty(editSubcode.schema) &&
      !isEmpty(schemas)
    ) {
      checkIfSelectedSchemaMatchesCustomer();
    }
  }, [editSubcode]);

  const checkIfSelectedSchemaMatchesCustomer = () => {
    const schemaWithCustomerId = getSchemaWithCustomerId(
      schemas,
      editSubcode.schema.customerId
    );

    if (
      editSubcode.schema.customerId != -1 &&
      (isEmpty(schemaWithCustomerId) ||
        schemaWithCustomerId.customerId !== editSubcode.customer.id)
    ) {
      editSubcodeField("schema", null);
    }
  };

  const getActiveProductItems = async () => {
    const params = joinParams({
      productSubcode: subcodeId,
      skipVariableDataProducts: true,
    });
    const productItemResponse = await api.getProductItems(params);
    const activeProductItems = productItemResponse.data.productItems.filter((productItem) => productItem.active);
    if (!isEmpty(activeProductItems)) {
      setProductType(activeProductItems[0].productType);
    }
    return activeProductItems;
  };

  const getSchemaWithCustomerId = (list, cid) => {
    for (let schema of list) {
      if (schema.customerId == cid) {
        return schema;
      }
    }
  };
  const loadData = async () => {
    // handle url hack
    if (createMode && !isEmpty(subcodeId)) {
      pushError("Invalid URL params");
      navigate(PAGE_URL.PRODUCT_SUBCODE);
    }

    if (!isEmpty(subcodeId)) {
      // load subcode
      try {
        const subcodeResponse = await api.getProductSubcode(subcodeId);

        setSubcode(cloneDeep(subcodeResponse.data));
        setEditSubcode(cloneDeep(subcodeResponse.data));
        setProductItems(await getActiveProductItems());
      } catch (error) {
        pushError(error);
        navigate(PAGE_URL.HOME);
      }
    }

    if (createMode) {
      // setup new subcode
      setEditMode(true);
      setEditSubcode({
        description: "",
        productSubcode: "",
        sampleReferenceNumber: "", // not required
        approvedDate: "", // not required
        minOrderQuantity: 0,
        packSize: 1,
        sustainable: false,
        productCode: null,
        customer: null,
        factory: null,
        schema: null,
        fulfillmentProcess: FULFILLMENT_PROCESS.PICK_AND_PACK,
        productImage: null,
        priceTiers: [],
        costTiers: [],
        minValues: [],
      });
    }

    if (createMode || !isEmpty(subcodeId)) {
      // load entities
      try {
        const customersResponse = await api.getAllCustomers();
        const factoriesResponse = await api.getAllFactories();
        const productCodesResponse = await api.getProductCodeList();
        const schemasResponse = await api.getAttributeSchemas();
        const currencyResponse = await api.getCurrencies();

        setCustomers(customersResponse.data.customers);
        setFactories(factoriesResponse.data.factories);
        setFactoryCurrency(factoriesResponse.data.factoryCurrency);
        setProductCodes(productCodesResponse.data.productCodes);
        setSchemas(schemasResponse.data.schemas);
        setCurrencies(currencyResponse.data.refs);
        setLoaded(true);
      } catch (error) {
        pushError(error);
        navigate(PAGE_URL.HOME);
      }
    }
  };

  const toggleEditMode = () => {
    if (createMode) {
      navigate(PAGE_URL.PRODUCT_SUBCODE);
    } else if (editMode) {
      setEditSubcode(cloneDeep(subcode)); // revert changes
      setImage(null);
      setEditMode(false);
    } else {
      setEditMode(true);
    }
  };

  const convertProductType = async () => {
    if (isNullOrUndefined(message)) {
      await showOverlay("Saving...");
    }

    try {
      const params = joinParams({
        productSubcode: subcodeId,
        productType
      });
      await api.convertProductType(params);
      setProductItems(await getActiveProductItems());
    } catch (error) {
      showError(error);
    }
    hideOverlay();
  };

  const onSave = async () => {
    if (isNullOrUndefined(message)) {
      await showOverlay("Saving...");
    }

    if (createMode) {
      try {
        const subcodeIdResponse = await api.createProductSubcode(editSubcode);
        if (image) {
          const formData = getFormDataForImage();
          await api.uploadProductImage(subcodeIdResponse.data, formData);
        }

        dispatch(pushSuccessMessage("Product Subcode Successfully Created"));
        navigate(PAGE_URL.PRODUCT_SUBCODE);
      } catch (error) {
        showError(error);
      }
    } else {
      try {
        const editSubcodeResponse = await api.updateProductSubcode(editSubcode);

        // update price/cost tiers with ids returned from adding tiers
        let _editSubcode = { ...editSubcode };
        _editSubcode["priceTiers"] = editSubcodeResponse.data.priceTiers;
        _editSubcode["costTiers"] = editSubcodeResponse.data.costTiers;
        _editSubcode["minValues"] = editSubcodeResponse.data.minValues;

        if (image) {
          // setup image form data
          const formData = getFormDataForImage();
          const thumbnailResponse = await api.uploadProductImage(
            subcodeId,
            formData
          );

          // update local subcodes new thumbnail
          let _thumbnail = thumbnailResponse.data;
          _editSubcode["productImage"] = _thumbnail;

          setEditSubcode(_editSubcode);
          setSubcode(cloneDeep(_editSubcode));
          setImage(null);
          setReloadImage(!reloadImage);
        } else {
          setEditSubcode(_editSubcode);
          setSubcode(cloneDeep(_editSubcode));
        }

        dispatch(showSuccessMessage("Product Subcode Successfully Updated"));
      } catch (error) {
        showError(error);
        setEditSubcode(subcode);
      } finally {
        setEditMode(false);
      }
    }

    hideOverlay();
  };

  const getFormDataForImage = () => {
    const formData = new FormData();
    formData.append("productImage", image);
    return formData;
  };

  const editSubcodeField = (field, value) => {
    let _editSubcode = { ...editSubcode };
    _editSubcode[field] = value;
    setEditSubcode(_editSubcode);
  };

  const addSubcodeTier = (field, tier) => {
    let _editSubcode = { ...editSubcode };
    _editSubcode[field].push(tier);
    _editSubcode[field].sort((t1, t2) =>
      t1.quantity < t2.quantity ? -1 : t1.quantity > t2.quantity ? 1 : 0
    );
    setEditSubcode(_editSubcode);
  };

  const updateSubcodeTier = (field, newTier, oldTier) => {
    let _editSubcode = { ...editSubcode };
    let _tier = _editSubcode[field].find(
      (t) =>
        t.quantity === oldTier.quantity &&
        t.currency === oldTier.currency &&
        t.price === oldTier.price
    );
    if (isEmpty(_tier)) {
      dispatch(showErrorMessage("Could not find the tier specified"));
      return false;
    }
    _tier.quantity = newTier.quantity;
    _tier.price = newTier.price;
    setEditSubcode(_editSubcode);
    return true;
  };

  const removeSubCodeTier = (field, tier) => {
    let _editSubcode = { ...editSubcode };
    _editSubcode[field] = _editSubcode[field].filter(
      (t) =>
        t.quantity !== tier.quantity ||
        t.price !== tier.price ||
        t.currency != tier.currency
    );
    setEditSubcode(_editSubcode);
  };

  const updateMinValue = (currency, price) => {
    let _editSubcode = { ...editSubcode };

    let _min = _editSubcode.minValues.find((m) => m.currency === currency);

    if (!_min) {
      _min = { currency, price };
      _editSubcode.minValues.push(_min);
    } else {
      _min.price = price;
    }

    setEditSubcode(_editSubcode);
  };

  return (
    <PageLayout className="product-subcode-details">
      <section className="site-content bodyWrap" id="content">
        <section className="content-body">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <h4 className="PageTitle">
                  <Link className="page-parent" to={PAGE_URL.PRODUCT_SUBCODE}>
                    PRODUCT SUBCODES
                  </Link>{" "}
                  &ndash;{" "}
                  <span className="page-current">PRODUCT SUBCODE DETAILS</span>
                </h4>
              </div>
            </div>
            {loaded && (
              <div className="row">
                <div className="col-12">
                  <TopLevelSubcodeInfo
                    editSubcode={editSubcode}
                    editSubcodeField={editSubcodeField}
                    editMode={editMode}
                    createMode={createMode}
                    toggleEditMode={toggleEditMode}
                    onSave={onSave}
                    customers={customers}
                    image={image}
                    setImage={setImage}
                    reloadImage={reloadImage}
                  />
                </div>
              </div>
            )}
            {loaded && (
              <div className="row">
                <div className="col-12">
                  <SubcodeRelationships
                    editSubcode={editSubcode}
                    editSubcodeField={editSubcodeField}
                    editMode={editMode}
                    createMode={createMode}
                    customers={customers}
                    factories={factories}
                    productCodes={productCodes}
                    schemas={schemas}
                  />
                </div>
              </div>
            )}
            {loaded && editSubcode.factory && (
              <div className="row">
                <div className="col-12">
                  <PriceAndCostTiers
                    editMode={editMode}
                    createMode={createMode}
                    priceTiers={editSubcode.priceTiers}
                    costTiers={editSubcode.costTiers}
                    minValues={editSubcode.minValues}
                    currencies={currencies}
                    costCurrency={factoryCurrency[editSubcode.factory.id]}
                    addSubcodeTier={addSubcodeTier}
                    updateSubcodeTier={updateSubcodeTier}
                    removeSubCodeTier={removeSubCodeTier}
                    updateMinValue={updateMinValue}
                  />
                </div>
              </div>
            )}
            {loaded && !createMode && <div className="row">
              <div className="col-12">
                <div className="card shadow">
                  <div className="card-body">
                  <div className="row" style={{ justifyContent: "flex-end" }}>
                    <div className="col">
                      <h2 style={{ marginBottom: "0px", marginLeft: "8px" }}>
                      {"Product Items"}
                      </h2>
                      <p style={{ marginLeft: "10px", marginTop: "10px" }}>
                        * Variable Data Products Temporarily Hidden on all Sub-codes for performance reasons.
                        </p>
                    </div>
                    {!isEmpty(productItems) && [PRODUCT_TYPE.STANDARD, PRODUCT_TYPE.DYNAMIC].includes(productType) && <button
                      className="btn btn-primary btn-sm m-1 mr-4 mb-4"
                      type="button"
                      onClick={() =>
                        convertProductType()
                      }>
                      Convert to {productType === PRODUCT_TYPE.STANDARD ? "Dynamic" : "Standard"}
                    </button>}
                    {(isEmpty(productItems) || productType === "STANDARD") && <button
                      className="btn btn-primary btn-sm m-1 mr-4 mb-4"
                      type="button"
                      onClick={() =>
                        navigate(PAGE_URL.PRODUCT_ITEM_DETAILS, joinParams({ createMode: true, subcode: subcodeId }))}
                      hidden={editMode}>
                      Add
                    </button>}
                    </div>
                  <table
                      className="table table-striped"
                      id="ProductItemList">
                        <thead>
                          <tr>
                            <th scope="col" width="20%">
                              Product Group
                            </th>
                            <th scope="col" width="20%">
                              Product Subcode
                            </th>
                            <th scope="col" width="20%">
                              Product Size
                            </th>
                            <th scope="col" width="20%">
                              Product Type
                            </th>
                            <th scope="col" width="20%">
                              Product Subgroup
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {!isEmpty(productItems) &&
                            productItems.map((productItem, index) => (
                              <ProductItemListRow
                                key={index}
                                productItem={productItem}
                                subcode={subcodeId}
                              />
                            ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            }
          </div>
        </section>
      </section>
    </PageLayout>
  );
};
