import React, { useState } from "react";
import { isEmpty, isNullOrUndefined } from "util/common";
import { useEffect } from "react";
import { ProductUtils } from "util/product";

export const QuantityField = ({
  defaultQuantity,
  minQuantity,
  maxQuantity,
  packSize,
  onChange,
  onBlur,
  onFocus,
  className,
  ...rest
}) => {

  const [quantity, setQuantity] = useState(ProductUtils.getQuantityForPackSize(defaultQuantity, packSize));

  useEffect(() => {
    var intQuantity = parseInt(quantity);

    if (!isNullOrUndefined(maxQuantity) && intQuantity > maxQuantity) {
      setQuantityOnChange(maxQuantity);
    }
  }, [maxQuantity]);

  useEffect(() => {
    if (typeof defaultQuantity === "number") {
      setQuantity(defaultQuantity);
    }

  }, [defaultQuantity]);

  const onQuantityChange = (quantity) => {
    const q = quantity.replace(/\D/, "");
    setQuantityOnChange(q);
  };

  const onQuantityBlur = () => {
    var intQuantity = parseInt(quantity);

    if (!isEmpty(quantity) && intQuantity === 0) {
      setQuantity(intQuantity);

      if (onBlur) {
        onBlur(intQuantity);
      }
    } else if (isEmpty(quantity) || intQuantity < minQuantity) {
      setQuantityOnBlur(minQuantity);
    } else if (!isNullOrUndefined(maxQuantity) && intQuantity > maxQuantity) {
      setQuantityOnBlur(maxQuantity);
    } else if (intQuantity % packSize !== 0) {
      setQuantityOnBlur(ProductUtils.getQuantityForPackSize(intQuantity, packSize));
    } else {
      setQuantity(intQuantity);

      if (onBlur) {
        onBlur(intQuantity);
      }
    }
  };

  const onQuantityFocus = () => {
    if (onFocus) {
      onFocus(quantity);
    }
  };

  const setQuantityOnChange = (quantity) => {
    setQuantity(quantity);

    if (onChange) {
      onChange(quantity);
    }
  };

  const setQuantityOnBlur = (quantity) => {
    setQuantity(quantity);

    if (onChange) {
      onChange(quantity);
    } else if (onBlur) {
      onBlur(quantity);
    }
  };

  const onKeyDown = (e) => {
    if (e.keyCode === 13) {
      e.preventDefault();
      e.target.blur();
    }
  };

  return (
    <div className={className}>
      <input
        {...rest}
        className="form-control"
        type="text"
        value={quantity}
        onChange={(e) => onQuantityChange(e.target.value)}
        onBlur={onQuantityBlur}
        onFocus={onQuantityFocus}
        onKeyDown={onKeyDown}
      />
    </div>
  );
};
