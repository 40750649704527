// React
import React from "react";

// Local
import { isEmpty } from "util/common";
import { EntityTable } from "./EntityTable";

export const EditableListPanel = ({
  title,
  placeholder,
  options,
  selectedOption,
  editOption,
  createOption,
  setOptions,
  setSelectedOption,
  setEditOption,
  setCreateOption,
  apiAddCall,
  apiUpdateCall,
  apiRemoveCall,
  addOrUpdateOption,
  removeOption,
  isEntitySelectedCustom,
  disabled,
}) => {
  const updateOptionField = (entity, setEntity, field, value) => {
    const _entity = { ...entity };
    _entity[field] = value;
    setEntity(_entity);
  };

  const isEntitySelected = (entity, selectedEntity) => {

    if (isEntitySelectedCustom) {
      return isEntitySelectedCustom(entity, selectedEntity);
    }

    return entity && selectedEntity && entity.id === selectedEntity.id;
  };

  const getFieldIfPresent = (entity, field) => {
    return isEmpty(entity) || isEmpty(entity[field]) ? "" : entity[field];
  };

  const toggleSelectedOption = (
    entity,
    selectedOption,
    setSelectedOption,
    setEditOption
  ) => {
    if (isEntitySelected(entity, selectedOption)) {
      setSelectedOption();
      setEditOption();
    } else {
      setSelectedOption(entity);
      setEditOption({ ...entity });
    }
  };

  return (
    <div>
      {title && <label>{title}</label>}
      <div className="row" id="entity-buttons">
        <input
          style={removeOption ? { width: "47%" } : { width: "66%" }}
          className="form-control price-field"
          type="text"
          placeholder={placeholder}
          value={getFieldIfPresent(
            isEmpty(selectedOption) ? createOption : editOption,
            "name"
          )}
          onChange={(e) =>
            isEmpty(selectedOption)
              ? updateOptionField(
                  createOption,
                  setCreateOption,
                  "name",
                  e.target.value
                )
              : updateOptionField(
                  editOption,
                  setEditOption,
                  "name",
                  e.target.value
                )
          }
          disabled={disabled}
        ></input>
        <button
          style={removeOption ? { width: "25%" } : { width: "32%" }}
          className={"btn btn-primary add-tier-btn"}
          onClick={() =>
            addOrUpdateOption(
              editOption,
              createOption,
              options,
              setOptions,
              apiAddCall,
              apiUpdateCall
            )
          }
          disabled={
            disabled || (isEmpty(selectedOption)
              ? isEmpty(createOption?.name)
              : isEmpty(editOption?.name) ||
                editOption?.name === selectedOption?.name)
          }
        >
          {isEmpty(selectedOption) ? "Add" : "Update"}
        </button>
        {removeOption && (
          <button
            style={{ width: "25%" }}
            className={"btn btn-danger add-tier-btn"}
            onClick={() =>
              removeOption(
                selectedOption,
                setSelectedOption,
                setEditOption,
                options,
                setOptions,
                apiRemoveCall
              )
            }
            disabled={disabled || isEmpty(selectedOption)}
          >
            Remove
          </button>
        )}
      </div>
      <EntityTable
        entities={options}
        columns={["Option"]}
        fields={["name"]}
        selectedEntity={selectedOption}
        onClick={(entity) =>
          toggleSelectedOption(
            entity,
            selectedOption,
            setSelectedOption,
            setEditOption
          )
        }
        isEntitySelectedCustom={isEntitySelectedCustom}
        disableRows={disabled}
      />
    </div>
  );
};
