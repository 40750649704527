import React from "react";
import { GarmentComponent } from "./GarmentComponent";

export const GarmentComponents = ({ components, getAvailableComponentTypes, onPercentageChange, onMaterialChange, removeFabricComposition, addFabricComposition, onComponentTypeChange, addComponent, getAvailableMaterials }) => {

    return (
        <div id="garmentComponents">
            <div className="form-row">
                <div className="col-4">
                    <label>Component</label>
                </div>
                <div className="col-8">
                    <div className="form-row">
                        <div className="col-2">
                            <label>% </label>
                        </div>
                        <div className="col-5">
                            <label>Material </label>
                        </div>
                    </div>
                </div>
            </div>
            <ul>
                {components.map((c, index) =>
                    <GarmentComponent
                        key={index}
                        component={c}
                        isOnlyComponent={components.length === 1}
                        getAvailableComponentTypes={getAvailableComponentTypes}
                        onPercentageChange={onPercentageChange}
                        onMaterialChange={onMaterialChange}
                        removeFabricComposition={removeFabricComposition}
                        addFabricComposition={addFabricComposition}
                        onComponentTypeChange={onComponentTypeChange}
                        getAvailableMaterials={getAvailableMaterials}
                    />
                )}
            </ul>
            <button className="btn btn-primary mt-2" type="button" onClick={addComponent}>Add Component</button>
        </div>
    );
};
