import React from "react";
import { ARTWORK_STATUS } from "constants/artworkStatus";
import { TABS } from "constants/tabs";
import { ROLES, ROLE_OPERATIONS, userCanPerformOperation } from "constants/roles";
import { ORDER_STATUS_API } from "constants/orderStatus";

export const OrderItemNeedsActionWrapper = ({ children, isPendingOrder, isBuildItem, orderStatus, isCsrsArtPrepared, artwork, tabSelected, user }) => {

    const WrapperWithMessage = ({ className, message, children }) => {
        return (
            <li className={className}>
                <div className="message">{message}</div>
                {children}
            </li>
        );
    };

    if (isPendingOrder) {
        return <li>{children}</li>;
    }

    const itemAppearsInNeedsActionForCsr = isBuildItem && !isCsrsArtPrepared && (!artwork || artwork.status !== ARTWORK_STATUS.APPROVED);
    const itemAppearsInNeedsActionForVendor = isBuildItem && (artwork && !artwork.artworkFinalized);

    if (user.role != ROLES.VENDOR && itemAppearsInNeedsActionForCsr) {
        // wrap with correct message and colour

        if (!artwork || artwork.status === ARTWORK_STATUS.NEEDS_UPLOAD) {
            return <WrapperWithMessage message={"Needs Artwork Upload"} className={"neutral-needs-action"}>{children}</WrapperWithMessage>;
        }

        if (artwork.status === ARTWORK_STATUS.REJECTED) {
            return <WrapperWithMessage message={"Rejected with comments"} className={"needs-action"}>{children}</WrapperWithMessage>;
        }

        if (artwork.status === ARTWORK_STATUS.UPLOADED) {
            return <WrapperWithMessage message={"Artwork Uploaded"} className={"action-taken"}>{children}</WrapperWithMessage>;
        }

    } else if (userCanPerformOperation(user, ROLE_OPERATIONS.REVIEW_ARTWORK) && itemAppearsInNeedsActionForVendor && orderStatus === ORDER_STATUS_API.NEEDS_ART_APPROVAL) {
        // wrap with correct message and colour
        if (artwork.status === ARTWORK_STATUS.UPLOADED){
            return <WrapperWithMessage message={"Requires Artwork Review"} className={"neutral-needs-action"}>{children}</WrapperWithMessage>;
        }
        
        if (artwork.status === ARTWORK_STATUS.REJECTED){
            return <WrapperWithMessage message={"Rejected with comments"} className={"needs-action"}>{children}</WrapperWithMessage>;
        }

        if (artwork.status === ARTWORK_STATUS.APPROVED){
            return <WrapperWithMessage message={"Artwork Approved"} className={"positive-action"}>{children}</WrapperWithMessage>;
        }

    }

    if (tabSelected === TABS.ALL) {
        return <li>{children}</li>;

    } else {
        return <></>;
    }
}
