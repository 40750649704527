import React from "react";
import { useRef } from "react";
import { useEffect } from "react";
import { TextInput } from "components/TextInput";
import { TextArea } from "components/TextArea";
import DatePickerWithIcon from "components/DatePickerWithIcon";
import moment from "moment";
import { Link } from "react-router-dom";
import { PAGE_URL } from "../../../constants/url";
import { Button } from "reactstrap";
import { getRole } from "../../../services/authService";
import { ROLES } from "../../../constants/roles";
import { VALIDATE_EMAIL_REGEX } from "../../../constants/email";
import { INVALID_EMAIL_MESSAGE } from "../../../constants/messages";



export const CartShippingForm = ({ cartConfig, shippingAddresses, onChange, onBlur, validEmail, setValidEmail }) => {
    const dateRequestedRef = useRef(null);
    const emailRef = useRef(null);

    useEffect(() => {
        window.clearDatePickerHistories();
        setValidEmail(false);
        isValidEmail(cartConfig.email);
    }, []);

    const onDateRequestedChange = (date) => {
        if (date === null) {
            dateRequestedRef.current.setOpen(false);
        }

        onChange("dateRequested", date, true);
    };

    const isValidEmail = (email) => {
        let validEmailRegex = new RegExp(VALIDATE_EMAIL_REGEX);
        setValidEmail(validEmailRegex.test(email));
    };

    return (
        <div className="row">
            <div className="col-12">
                <div className="card" id="shipping">
                    <div className="card-body">
                        <h2>Shipping</h2>
                        <div className="row">
                            {/* Contact */}
                            <div className="col-4">
                                <h3>Contact Details:</h3>
                                <div className="form-group">
                                    <label>Contact *</label>
                                    <TextInput
                                        className="form-control"
                                        value={cartConfig.contact}
                                        onChange={value => onChange("contact", value)}
                                        onBlur={onBlur}
                                        required
                                    />
                                </div>
                                <div className={validEmail ? "form-group" : "form-group mb-0"}>
                                    <label>Email *</label>
                                    <TextInput
                                        className="form-control"
                                        value={cartConfig.email}
                                        onChange={value => {
                                            onChange("email", value);
                                            isValidEmail(value);
                                        }}
                                        onBlur={onBlur}
                                        required
                                        pattern={VALIDATE_EMAIL_REGEX}
                                    />
                                    <label style={{color: "red"}} className={validEmail ? "d-none" : ""} ref={emailRef}>
                                        {INVALID_EMAIL_MESSAGE}
                                    </label>
                                </div>
                                <div className="form-group">
                                    <label>Phone *</label>
                                    <TextInput
                                        className="form-control"
                                        value={cartConfig.phone}
                                        onChange={value => onChange("phone", value)}
                                        onBlur={onBlur}
                                        required
                                    />
                                </div>
                            </div>

                            {/* Deliver To */}
                            <div className="col-4">
                                <h3>Deliver To:</h3>
                                <div className="form-group">
                                    <label>Address *</label>
                                    <select
                                      className={"form-control"}
                                      onChange={(e) => {
                                        onChange("shippingAddress", e.target.value, true);
                                      }}
                                      value={cartConfig.shippingAddress}
                                    >
                                      {shippingAddresses && shippingAddresses.map((ship, index) => (
                                        <option key={index} value={ship.address}>
                                          {ship.optionName}
                                        </option>
                                      ))}
                                    </select>
                                    {(getRole() === ROLES.VENDOR || getRole() === ROLES.ADMIN) ?
                                        <Link to={PAGE_URL.VIEW_VENDOR + "?id=" + cartConfig.vendorId + "&fromCart=true"}>
                                            <Button className="file-btn-link btn btn-secondary mt-1" role="button">
                                                + Add New Shipping Address
                                            </Button>
                                        </Link>
                                         : <></>}
                                    <TextArea
                                        className="form-control address-field"
                                        style={(getRole() === ROLES.VENDOR || getRole() === ROLES.ADMIN) ? {height:"7.3em", marginTop: ".40em"} : {}}
                                        value={cartConfig.shippingAddress}
                                        maxLength="255"
                                        allowEnter={true}
                                        onChange={value => onChange("shippingAddress", value)}
                                        onBlur={onBlur}
                                        required
                                    />
                                </div>
                            </div>
                        </div>

                        <hr />

                        <div className="row">
                            <div className="col-4">
                                <div className="form-group">
                                    <label>Date Requested *</label>
                                    <DatePickerWithIcon
                                        ref={dateRequestedRef}
                                        selected={cartConfig.dateRequested}
                                        dateFormat="dd-MMM-yyyy"
                                        className="form-control"
                                        minDate={moment().add(14, 'days').toDate()}
                                        placeholderText="Enter Date Requested"
                                        onChange={onDateRequestedChange}
                                        required 
                                    />

                                </div>
                            </div>

                            <div className="col-4">
                                <div className="form-group">
                                    <label>Delivery Method</label>
                                    <TextInput
                                        className="form-control"
                                        value={cartConfig.deliveryMethod}
                                        onChange={value => onChange("deliveryMethod", value)}
                                        onBlur={onBlur}
                                    />
                                </div>
                            </div>

                            <div className="col-4">
                                <div className="form-group">
                                    <label>Delivery Account</label>
                                    <TextInput
                                        className="form-control"
                                        value={cartConfig.deliveryAccount}
                                        onChange={value => onChange("deliveryAccount", value)}
                                        onBlur={onBlur}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-12">
                                <div className="form-group">
                                    <label>Comments, Special Requirements, and Delivery Instructions</label>
                                    <TextArea
                                        className="form-control comments-field"
                                        value={cartConfig.comments}
                                        allowEnter={true}
                                        onChange={value => onChange("comments", value)}
                                        onBlur={onBlur}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
