/* eslint-disable no-multiple-empty-lines */

// React
import React, { useState } from "react";

// Local
import api from "services/apiService";
import { PageLayout } from "layouts/PageLayout";
import { useURLNavigate } from "hooks/useURLNavigate";
import { pluralies, isNullOrUndefined } from "util/common";
import { FactorySearch } from "./FactoryListSearch";
import { FactoryRow } from "./FactoryListRow";
import { joinParams } from "components/joinParams";
import { useOverlay } from "hooks/useOverlay";
import { useError } from "hooks/errors";
import { useSelector } from "react-redux";

export const FactoryListPage = () => {
  // hooks
  const [showOverlay, hideOverlay] = useOverlay();
  const [showError, ] = useError();
  const navigate = useURLNavigate();
  const message = useSelector(state => state.message.message);

  // state
  const [factories, setFactories] = useState([]);

  // methods
  const onSearch = async (searchValues) => {
    try {
      const params = getParams(searchValues);

      if (isNullOrUndefined(message)) {
        await showOverlay("Searching...");
      }

      const response = await api.getFactories(params);
      hideOverlay();
      setFactories(response.data.factories);
    } catch (error) {
      hideOverlay();
      showError(error);
    }

  };

  const getParams = (searchValues) => {
    return joinParams({
      factoryName: searchValues.factoryName,
      shortCode: searchValues.shortCode,
      customerId: searchValues.customerId,
      regionId: searchValues.regionId,
    });
  };

  return (
    <PageLayout className="factory-list">
      <section className="site-content bodyWrap" id="content">
        <section className="content-body">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="page-title-row">
                  <h4 className="PageTitle">
                    <span className="page-parent">FACTORIES</span>
                  </h4>
                  <button
                    className="btn btn-primary add-entity-btn"
                    onClick={() =>
                      navigate("/factory-details", "createMode=true")
                    }
                  >
                    Add
                  </button>
                </div>
                <FactorySearch onSearch={onSearch} />
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <section className="pt-5">
                  <table className="table table-striped" id="FactoryList">
                    <thead>
                      <tr>
                        <th scope="col" width="40%">Factory Name</th>
                        <th scope="col" width="12%">Short Code</th>
                        <th scope="col" width="20%">Production Region</th>
                        <th scope="col" width="28%">{"Customer(s)"}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {factories &&
                        factories.map((factory, index) => (
                          <FactoryRow key={index} factory={factory} />
                        ))}
                    </tbody>
                  </table>
                </section>
              </div>
            </div>
            <div className="factoryNumber-pagination">
              <span>
                {"Showing " +
                  factories.length +
                  pluralies(" Factor", factories.length)}
              </span>
            </div>
          </div>
        </section>
      </section>
    </PageLayout>
  );
};
