import React from "react";
import { LabelValue } from "components/LabelValue";
import { LabelCurrency } from "components/LabelCurrency";
import { useCurrentUser } from "hooks/useCurrentUser";
import { ROLES } from "constants/roles";
import { TextInput } from "components/TextInput";

export const CartHeader = ({ cartConfig, cartOrderDetails, onChange, onBlur }) => {
    const user = useCurrentUser();

    return (
        <div className="row">
            <div className="col-12">
                <div className="list-group" id="orderList">
                    <div className="order-item list-group-item list-group-item-action">
                        <div className="d-flex w-100">
                            <div className="order-details">
                                <h5 className="company">{cartOrderDetails.company}</h5>
                                <div className="order-meta">
                                    <ul>
                                        <LabelValue label="Customer" value={cartOrderDetails.customer} />
                                        <LabelValue label="Region" value={cartOrderDetails.productionRegion} />
                                        {user.role === ROLES.VENDOR &&
                                            <li className="ml-3">
                                                <div className="label order-detail-header">Vendor PO:</div>
                                                <TextInput
                                                    className="form-control"
                                                    placeholder="Enter Vendor PO"
                                                    value={cartConfig.vendorPO}
                                                    onChange={value => onChange("vendorPO", value)}
                                                    onBlur={onBlur}
                                                    required
                                                />
                                            </li>
                                        }
                                    </ul>
                                </div>
                            </div>
                            <div className="order-price">
                                {cartOrderDetails.totalPrice &&
                                    <div>
                                        {cartOrderDetails.totalCost &&
                                            <span className="descriptor">Price:</span>
                                        }
                                        <LabelCurrency currency={cartOrderDetails.totalPrice.currency} value={cartOrderDetails.totalPrice.price} />
                                    </div>
                                }
                                {cartOrderDetails.totalCost &&
                                    <div>
                                        {cartOrderDetails.totalPrice &&
                                            <span className="descriptor">Cost:</span>
                                        }
                                        <LabelCurrency currency={cartOrderDetails.totalCost.currency} value={cartOrderDetails.totalCost.price} />                                        
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
