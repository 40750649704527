import React from "react";
import api from "services/apiService";
import { useError } from "hooks/errors";
import { downloadFile } from "util/download";
import { useOverlay } from "hooks/useOverlay";

export const RelatedDoc = ({ relatedDoc, orderId }) => {
    const [showError, ] = useError();
    const [showOverlay, hideOverlay] = useOverlay();

    const downloadDocument = async () => {
        try {
            showOverlay("Downloading...");
            const response = await api.downloadRelatedDocument(orderId, relatedDoc.documentId);
            downloadFile(response);
            hideOverlay();
        } catch (error) {
            hideOverlay();
            showError(error);
        }
    };

    return (
            <ul className="readonly flex-list">
                <li className="flex-list-item">
                    <div className="value">
                        <button onClick={() => downloadDocument(relatedDoc)} className="file-btn-link">{relatedDoc.name}</button>
                    </div>
                </li>
            </ul>
    );
};
