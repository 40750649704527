import * as types from "./productItemActionTypes";

const initialState = {
  productItemSearchValues: {
    productSubcode: "",
    productSize: "",
    productType: "STANDARD",
    productGroup: "",
    productSubgroup:"",
  }
};

export const productItemReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_PRODUCT_ITEM_SEARCH_VALUES: {
      const data = action.data;
      return { ...state, productItemSearchValues: data };
    }
    default:
      return state;
  }
};
