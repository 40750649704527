import React from "react";
import ReactDatePicker from "react-datepicker";

const DatePickerWithIcon = ({ selected, dateFormat, className, minDate, placeHolderText, onChange, highlightDates, ...rest }, ref) => {

    const calendarClickHandler = () => {
        ref.current.setOpen(true)
    };

    return (
        <div className="input-group">
            <ReactDatePicker
                ref={ref}
                selected={selected}
                dateFormat={dateFormat}
                className={className}
                minDate={minDate}
                placeholderText={placeHolderText}
                onChange={onChange}
                highlightDates={highlightDates}
                {...rest}
            />
            <div className="input-group-append" onClick={calendarClickHandler}>
                <span className="input-group-text"><i className="fal fa-calendar-alt" /></span>
            </div>
        </div>
    );
};

const forwardedInput = React.forwardRef(DatePickerWithIcon);

export default forwardedInput;