import React, { useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { api } from "../services/apiService";
import { getRole } from "../services/authService";
import { loginSuccess, loginRedirectPathAction } from "../store/auth/authActions";
import { userHasAVendor } from "../store/user/userActions";
import { useLogout } from "../hooks/useCurrentUser";
import { useURLNavigate } from "hooks/useURLNavigate";
import { PAGE_URL, PAGE_TITLE } from "constants/url";
import { CURRENT_YEAR } from "constants/date";
import { ROLES } from "constants/roles";
import { useError } from "hooks/errors";
import { MessagePanel } from "layouts/components/MessagePanel";
import { clearMessage } from "store/messages/messageActions";
import { showErrorMessage } from "store/messages/messageActions";
import { UNEXPECTED_ERROR_MESSAGE } from "constants/messages";

export const LoginPage = () => {
  const dispatch = useDispatch();
  const [, registerSignedInUser,] = useLogout();
  const navigate = useURLNavigate();
  const [invalid, setInvalid] = useState(false);
  const [username, setUsername] = useState(null);
  const [password, setPassword] = useState(null);
  const loginRedirectPath = useSelector(state => state.auth.loginRedirectPath);
  const loginButtonRef = useRef(null);
  const [showError, ] = useError();

  const redirect = () => {
    if (loginRedirectPath) {
      const path = loginRedirectPath;
      dispatch(loginRedirectPathAction({ path: null }));
      navigate(path);
    } else {
      navigate(PAGE_URL.HOME);
    }
  };

  const loadCompanies = async () => {
    try {
      // Make sure user has a company they can order for.
      const response = await api.userHasCompany();
      dispatch(userHasAVendor(response.data.hasVendor));
    } catch (error) {
      showError(error);
    }
};

  useEffect(() => {
    document.title = PAGE_TITLE.LOGIN + " - The Unique Group";
  }, []);

  const login = async () => {
    try {
      const response = await api.login({ email: username, password });
      const data = response.data;

      if (!data?.access_token) {
        // response contains the token
        dispatch(showErrorMessage(data.message ? data.message : UNEXPECTED_ERROR_MESSAGE));
        setInvalid(true);
      } else {
        dispatch(loginSuccess(data));
        registerSignedInUser(username);

        const role = getRole();

        if (role === ROLES.VENDOR || role === ROLES.ACCOUNT || role === ROLES.FACTORY_ACCOUNT || role === ROLES.ADMIN) {
          loadCompanies();
        }
        redirect();
      }

    } catch (error) {
      showError(error);
      setInvalid(true);
    }
  };

  const onInputKeyDown = (e) => {
    if (e.keyCode === 13) {
      e.preventDefault();
      loginButtonRef.current.click();
    }
  };

  const onInputChange = () => {
    if (invalid) {
      dispatch(clearMessage());
      setInvalid(false);
    }
  };

  return (
    <div className="bodyWrapLogin">
      <div className="p-0 login" id="page">
        <a className="skip-link sr-only" href="#content"></a>
        <header className="site-header"></header>
        <MessagePanel />
        <section className="site-content" id="content">
          <section className="content-body">
            <div className="container">
              <div className="row">
                <div className="col">
                  <div className="hanger"></div>
                  <div className="loginWrap shadow">
                    <div className="loginlogo"><span className="circle mx-auto"></span><img className="mt-4 mb-3" src="./UNIQUE-LOGO-white.png" width="60%" alt="The Unique Group"></img>
                      <h6>Product &amp; Order<br />Management System</h6>
                    </div>
                    <div className="login-form">
                      <form>
                        <div className="form">
                          <div className="form-group">
                            <label>Username:</label>
                            <input className="form-control" type="next" onChange={e => { setUsername(e.target.value); onInputChange(); }} onKeyDown={onInputKeyDown} required />
                            <div className="invalid-feedback">Please enter your username</div>
                          </div>
                          <div className="form-group">
                            <label>Password:</label>
                            <input className="form-control" type="password" onChange={e => { setPassword(e.target.value); onInputChange(); }} onKeyDown={onInputKeyDown} required />
                            <div className="invalid-feedback">Please enter your password</div>
                          </div>
                          <button ref={loginButtonRef} className="btn shadow btn-primary btn-block mt-4" type="button" onClick={login}>Log In</button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="site-info login-info container text-center">
              <div className="row">
                <div className="col">
                  Please note that your password will expire after six months of inactivity.
                </div>
              </div>
              <div className="row">
                <div className="col">
                  If you are unable to log in, please contact <a href="mailto: anar@theuniquegroup.com">anar@theuniquegroup.com</a>
                </div>
              </div>
            </div>
          </section>
        </section>
        <footer className="site-footer">
          <div className="site-info container text-center">
            <div className="row">
              <div className="col">
                <p>
                  &copy; {CURRENT_YEAR} <a href="https://theuniquegroup.com/" target="_blank">The Unique Group</a> {"\u00a0\u00a0"} | {"\u00a0\u00a0"}
                  <Link to={PAGE_URL.TERMS_AND_CONDITIONS} target="_self" title="">Terms &amp; Conditions </Link>
                </p>
              </div>
            </div>
          </div>
        </footer>
      </div>
    </div>
  );
};
