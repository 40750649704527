import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { createStore, compose } from "redux";
import { toast } from "react-toastify";
import { App } from "./App";
import rootReducer from "./store/rootReducer";
import "react-toastify/dist/ReactToastify.min.css";
import "./assets/css/vendor/formValidation.css";
import "./assets/css/app.css";
import 'react-datepicker/dist/react-datepicker.css';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(rootReducer, composeEnhancers());
toast.configure();

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById("root")
);
