import React, { useEffect } from "react";
import { useState } from "react";

export const ProductCheckbox = ({ productIds, onChange, checkAll }) => {
  const [checked, setChecked] = useState(false);

  useEffect(() => {
    setChecked(checkAll);
  }, [checkAll]);

  return (
    <input
        className="custom-control-input"
        type="checkbox"
        onChange={() => { setChecked(!checked); onChange(productIds, !checked); }}
        checked={checked}
        >
    </input>
  );
};
