import * as types from "./userActionTypes";

const initialState = {
  userHasVendor: false,
};

export const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_USER_HAS_A_VENDOR: {
      const data = action.data;
      return { ...state, userHasVendor: data };
    }
    case types.SET_USER_SEARCH_VALUES: {
      const data = action.data;
      return { ...state, userSearchValues: data };
    }
    default:
      return state;
  }
};
