import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Route, Redirect } from "react-router";
import { useAuthenticatedUser } from "../hooks/useCurrentUser";
import { PAGE_URL } from "constants/url";
import { PAGE_ACCESSIBLE_USERS } from "constants/roles";
import { useDispatch } from "react-redux";
import { loginRedirectPathAction } from "store/auth/authActions";
import { useURLPath } from "hooks/useURLPath";

export const PrivateRoute = ({ path, children, ...rest }) => {
  const dispatch = useDispatch();
  const url = useURLPath();

  const [authenticated, user] = useAuthenticatedUser();

  const allowedRoles = PAGE_ACCESSIBLE_USERS[path];
  const allowed = authenticated && user && user.role && allowedRoles && allowedRoles.includes(user.role);

  useEffect(() => {
    if (!authenticated) {
      dispatch(loginRedirectPathAction({path: url}));
    }
  }, [path]);

  return allowed ? (
    <Route path={path} {...rest}>{children}</Route>
  ) : (
    <Redirect to={PAGE_URL.LOGIN} />
  );
};

PrivateRoute.propTypes = {
  children: PropTypes.node,
};
