import React from "react";
import { isEmpty } from "util/common";

export const EntityTable = ({
  entities,
  columns,
  fields,
  selectedEntity,
  onClick,
  showHead = false,
  disableRows = false,
  isEntitySelectedCustom,
}) => {

  const isEntitySelected = (entity, selectedEntity) => {

    if (isEntitySelectedCustom) {
      return isEntitySelectedCustom(entity, selectedEntity);
    }

    return !isEmpty(selectedEntity) && entity.id === selectedEntity.id;
  };

  return (
    <div className="table-responsive">
      <table className="table table-striped entity-table">
        {showHead && (
          <thead>
            <tr>
              {columns.map((col, idx) => (
                <th key={idx} scope="col">
                  {col}
                </th>
              ))}
            </tr>
          </thead>
        )}
        <tbody>
          {entities &&
            entities.map((entity, idx) => (
              <tr
                key={idx}
                className={disableRows ? "" : "highlight"}
                style={
                  isEntitySelected(entity, selectedEntity)
                    ? { backgroundColor: "rgb(0 174 239 / 70%)" }
                    : {}
                }
                onClick={disableRows ? () => null : () => onClick(entity)}
              >
                {fields.map((f, idx) => (
                  <td key={idx}>{entity[f]}</td>
                ))}
              </tr>
            ))}
          {isEmpty(entities) && (
            <tr>
              <td>{"No entries"}</td>
              <td></td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};
