import React, { useEffect, useState } from "react";
import { AttributeField } from "../components/AttributeField";
import { CareLabelDetails } from "../components/CareLabelDetails";
import { Thumbnail } from "components/Thumbnail";
import { UnitPriceCostTable } from "components/UnitPriceCostTable";
import { isEmpty } from "util/common";
import { MIN_ORDER_VALUE_DESCRIPTION } from "constants/messages";
import { formatCurrency } from "util/formatNumber";
import { RolePermission } from "../../../components/RolePermission";
import { ROLES } from "../../../constants/roles";
import { QuantityField } from "../components/QuantityField";

export const DynamicProductDetailsVendor = ({
  product,
  itemVariants,
  onAttributeChange,
  onQuantityChange,
  clearQuantities,
  onCareLabelChange,
  editMode,
  showValidation,
  setShowValidation,
  productConfig,
  loadDynamicVariableData,
  showNoResults,
  showDynamicData,
  numDataCols,
  customerQuantity
}) => {
  const [editQuantity, setEditQuantity] = useState(null);
  const [cartQuantity, setCartQuantity] = useState(0);
  const [attributes, setAttributes] = useState([]);
  const [totalQuantity, setTotalQuantity] = useState(0);
  const [styleNumber, setStyleNumber] = useState(0);
  const [poNumber, setPoNumber] = useState(0);

  const getColumnDisplayName = (colName) => {
    return colName === "Quantity" ? "Customer Quantity" : colName;
  };

  useEffect(() => {
    if (productConfig) {
      setTotalQuantity(productConfig?.totalQuantity);
    }
    if (product) {
      setAttributes(product.attributes);

    }
  }, [productConfig, product]);

  return (
    <>
      <div className="product-item standard card shadow">
        <div className="general-details">
          <div className="product-img">
            <Thumbnail
              className="img-fluid"
              imagePath={product.productImagePath}
              imageType={product.productImageType}
            />
          </div>
          <div className="product-info">
            <div className="fixed-info">
              <ul>
                <li key={"product-code"}>
                  <div className="label">Product Code:</div>
                  <div className="value">{product.productSubcode}</div>
                </li>
                <li key={"description"} className="description">
                  <div className="label">Description:</div>
                  <div className="value">{product.description}</div>
                </li>
                <li key={"pack-size"}>
                  <div className="label">Pack Size:</div>
                  <div className="value">{product.packSize}</div>
                </li>
                {!isEmpty(product.minValue) && (
                  <li key={"min-order-value-description"}>
                    <div
                      className="label"
                      data-toggle="tooltip"
                      data-placement="right"
                      title={MIN_ORDER_VALUE_DESCRIPTION}
                    >
                      Minimum Order Value *
                    </div>
                    <div className="value">
                      {product.minValue.currency +
                        " " +
                        formatCurrency(product.minValue.price)}
                    </div>
                  </li>
                )}

              </ul>
              {product.prices.length === 1 ? (
                <ul>
                  <li key={"unit-price"}>
                    <div className="label">Unit Price:</div>
                    <div className="value">
                      <span className="currency">
                        {product.prices[0].currency}{" "}
                      </span>
                      <span className="price">{product.prices[0].price}</span>
                    </div>
                  </li>
                </ul>
              ) : (
                <UnitPriceCostTable
                  isPrice={true}
                  pricesOrCosts={product.prices}
                  editQuantity={editQuantity} // this should be the total quantity
                  cartQuantity={cartQuantity}
                />
              )}
            </div>
            <div className="row product-attributes">
              {attributes &&
                attributes.map((a, i) => (
                  <AttributeField
                    key={i}
                    name={a.name}
                    type={a.type}
                    options={a.options}
                    initialValue={a && a.value ? a.value : ""}
                    onAttributeChange={(value) =>
                      onAttributeChange(a.name, value)
                    }
                  />
                ))}
            </div>
            {!editMode && <div className="row product-attributes">
              <AttributeField
                name="Style Number"
                initialValue=""
                onAttributeChange={setStyleNumber}
                />
              <AttributeField
                name="PO Number"
                initialValue=""
                showSearchButton={true}
                onSearch={() => loadDynamicVariableData(styleNumber, poNumber)}
                onAttributeChange={setPoNumber}
              />
              {/* No Results Box */}
              {showNoResults &&
              <div style={{display: "flex", alignItems: "center", justifyContent: "center" }}>
                <p style={{margin: "15px", padding: "10px", backgroundColor: "lightgrey", color: "black", fontWeight: "400", borderRadius: "5px" }}>No dynamic data found for search</p>
              </div>}
            </div>}
            {/* DYNAMIC DATA */}
            {showDynamicData &&
            <div className="required-attributes variable-data-table">
              {/* CLEAR QUANTITIES */}
              <div style={{ display: "flex", justifyContent: "right" }}>
                <button
                  className="btn btn-primary"
                  style={{marginRight: "5px", marginBottom: "15px"}}
                  onClick={(e) => {
                    e.preventDefault();
                    clearQuantities();
                  }}
                >
                    Clear Quantities
                </button>
              </div>
              <table className="table table-striped">
                <thead>
                <tr>
                  {Object.keys(itemVariants[0]?.dynamicData).map((colName, i) => (
                      <th key={"variable-data-key-" + i} scope="col">
                        {getColumnDisplayName(colName)}
                      </th>
                    ))}
                  <RolePermission roles={[ROLES.VENDOR]}>
                    <th scope="col" width="15%">
                      Quantity
                    </th>
                  </RolePermission>
                </tr>
                </thead>
                <tbody>
                {itemVariants.map((variant, i) => (
                  <tr key={"variant-" + i}>
                    {Object.entries(variant.dynamicData).map(([key, value]) => (
                      key !== "Quantity" ?
                      <td key={"variant-value-" + i + key}>{value}</td> :
                      <>
                        <td key={"variant-value-" + i + key}>{value}</td>
                        <RolePermission roles={[ROLES.VENDOR]} key={"role-" + i + key}>
                          <td key={"col-" + i + key}>
                            <QuantityField
                              key={"qnt-" + i + key}
                              defaultQuantity={productConfig.quantities[variant.id]}
                              minQuantity={0}
                              packSize={product.packSize}
                              onChange={(quantity) => {
                                onQuantityChange(
                                  variant.id,
                                  quantity
                                );
                                setEditQuantity(quantity);
                              }}
                              onFocus={(quantity) => {
                                setEditQuantity(quantity);
                                setCartQuantity(variant.cartQuantity);
                              }}
                              onBlur={() => {
                                setEditQuantity(null);
                                setCartQuantity(0);
                              }}
                            />
                          </td>
                        </RolePermission>
                      </>
                    ))}
                  </tr>
                ))}
                  <tr style={{ backgroundColor: "white" }}>
                    {/* Add Total Row  */}
                    {Object.values(itemVariants[0].dynamicData).map((_, i) => (
                      (i === (numDataCols - 2)) ?
                        <td key={"vd-total-" + i} style={{ fontSize: "16px" }}>
                          <b key={"b-total-" + i}>Total</b>
                        </td> :
                      (i === (numDataCols - 1)) ?
                        <></> :
                        <td key={"padding-cell-" + i}></td>
                      )
                    )}
                    <td key={"customer-quantity"} style={{ paddingLeft: "25px", fontSize: "16px" }}><b>{customerQuantity}</b></td>{/* Customer quantity */}
                    <td key={"total-quantity"} style={{ paddingLeft: "25px", fontSize: "16px" }}><b>{totalQuantity}</b></td>
                  </tr>
                </tbody>
              </table>
            </div>}
          </div>
        </div>
      </div>
      {product.careLabel &&
        <CareLabelDetails
          careLabel={product.careLabel}
          onCareLabelChange={onCareLabelChange}
          editMode={editMode}
          showValidation={showValidation}
          setShowValidation={setShowValidation}
        />
      }
    </>
  );
};
