import * as types from "./vendorActionTypes";

const initialState = {};

export const vendorReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_VENDOR_SEARCH_VALUES: {
      const data = action.data;
      return { ...state, searchValues: data };
    }
    default:
      return state;
  }
};
