import { SORTED_VARIABLE_DATA_SIZES } from "constants/sorted_variable_data_sizes.js";
import { isEmpty } from "./common";
export class VariableDataUtils {

  static SORTED_SIZES = SORTED_VARIABLE_DATA_SIZES;
  static COLOR_CODE_REGEX = /([a-zA-Z]*)(\d*)([a-zA-Z]*)/;

  /*
    Colour & Size column names are not always the same in variable data.
    Names will be listed here and checked in the order in which they are listed.
  */
    static COLOR_COLUMN_NAMES = ["Color Code", "Colour", "Pack Colours", "Color ENG", "Color FRE"];
    static SIZE_COLUMN_NAMES = ["Size", "Pack Content Size", "Euro Size", "Size Code"];

  /**
   * Variable data products are first grouped and sorted by "Color Code",
   * then are sorted by "Size".
   *
   * Colors Codes are always 3 characters and can have the following orders:
   * - all numbers
   * - letter then number
   * - number then letter
   * - all letters**
   *
   * **Note: although no data is currently in the system we will also handle
   * all letters, in case those codes appear in the future.
   *
   * Increasing Order Example:
   * 0, 1, 2, 3, 4, 898, A11, AC6, AF8, AM6, AM7, AM8, B03, B25, 35B, 39B, 02C, 10C, AAA, AAB
   *
   * Sizes are sorted according to the predifined order in
   * sorted_variable_data_sizes.js
   *
   * @param {*} products list of variable data product item responses to sort
   */
  static customSortVariableDataItems = (products, colorColumnName = "Color Code", sizeColumnName = "Size") => {
    for (const p of products) {
      if (isEmpty(p.items)) {
        continue; // not expected
      }

      const itemGroups = VariableDataUtils.getVariableDataItemsGroupedAndSortedByColourColumnName(p.items, colorColumnName, "variableData");
      let sortedItems = [];

      // sort each group of items by size
      for (const g of itemGroups) {
        let sortedItemGroup = g[1];

        let _sizeColumnName = VariableDataUtils.getDefaultColumnName(p.items[0].variableData, sizeColumnName, this.SIZE_COLUMN_NAMES);
        sortedItemGroup.sort(VariableDataUtils.sortDataItemsBySize(_sizeColumnName, "variableData"));
        sortedItems = sortedItems.concat(sortedItemGroup);
      }
      p.items = sortedItems;
    }
  };

  static getSortedDynamicDataVariants = (variants, colorColumnName = "Colour", sizeColumnName = "Size") => {
    const variantGroups = VariableDataUtils.getVariableDataItemsGroupedAndSortedByColourColumnName(variants, colorColumnName, "dynamicData");
    let sortedItems = [];

    // sort each group of items by size
    for (const g of variantGroups) {
      let sortedItemGroup = g[1];

      let _sizeColumnName = VariableDataUtils.getDefaultColumnName(variants[0].dynamicData, sizeColumnName, this.SIZE_COLUMN_NAMES);
      sortedItemGroup.sort(VariableDataUtils.sortDataItemsBySize(_sizeColumnName, "dynamicData"));
      sortedItems = sortedItems.concat(sortedItemGroup);
    }

    return sortedItems;
  };

  static sortDataItemsBySize = (sizeColumnName, dataAttributeName = "variableData") => {
    return (itemA, itemB) => {
      const sizeA = itemA[dataAttributeName][sizeColumnName];
      const sizeB = itemB[dataAttributeName][sizeColumnName];

      return VariableDataUtils.sortBySize(sizeA, sizeB);
    };
  };

  static sortBySize = (sizeA, sizeB) => {
    if (sizeA === sizeB) {
      return 0;
    }

    if (sizeA && !sizeB) {
      return 1;
    } else if (!sizeA && sizeB) {
      return -1;
    }

    const idxA = VariableDataUtils.SORTED_SIZES.indexOf(sizeA);
    const idxB = VariableDataUtils.SORTED_SIZES.indexOf(sizeB);

    if (idxA > idxB) {
      return 1;
    } else if (idxB > idxA) {
      return -1;
    }
  }

  /**
   * Will default to colour columns if none is provided.
   */
  static getVariableDataItemsGroupedAndSortedByColourColumnName = (itemsOrVariants, columnName, dataAttributeName = "variableData") => {
    const groups = new Map();

    let _columnName = VariableDataUtils.getDefaultColumnName(itemsOrVariants[0][dataAttributeName], columnName, this.COLOR_COLUMN_NAMES);

    for (const i of itemsOrVariants) {
      let groupName = i[dataAttributeName][_columnName];
      VariableDataUtils.createOrAddToGroup(groups, groupName ? groupName : "", i);
    }

    let sortingFn;
    if (_columnName === "Color Code") {
      sortingFn = VariableDataUtils.sortColorCodes;
    } else {
      sortingFn = VariableDataUtils.sortAlphabetically;
    }

    const sortedGroups = new Map([...groups].sort((groupA, groupB) => sortingFn(groupA[0], groupB[0])));
    return sortedGroups;
  };

  static createOrAddToGroup = (groups, groupName, item) => {
    if (groups.has(groupName)) {
      groups.get(groupName).push(item);
    } else {
      groups.set(groupName, [item]);
    }
  };

  static sortAlphabetically(colorA, colorB) {
    if (colorA === colorB) {
      return 0;
    } else if (colorA > colorB) {
      return 1;
    } else {
      return -1;
    }
  }

  static getDefaultColumnName(variableOrDynamicData, specifiedName, defaultColumnNames) {
    let _columnName;
    if (specifiedName && variableOrDynamicData[specifiedName]) {
      _columnName = specifiedName;
    } else {
      _columnName = defaultColumnNames.find(name => !!variableOrDynamicData[name]);
    }
    return _columnName;
  }

  /**
   * Sorts color codes.
   *
   * @param {*} codeA 3 digit color code
   * @param {*} codeB 3 digit color code
   * @returns -1 if codeA comes first, +1 if codeA comes after, 0 if they they are to stay.
   */
  static sortColorCodes(codeA, codeB) {

    if (codeA === codeB) {
      return 0;
    }

    const resultA = codeA.match(VariableDataUtils.COLOR_CODE_REGEX);
    const resultB = codeB.match(VariableDataUtils.COLOR_CODE_REGEX);

    // Both all numbers => direct comparison
    if (VariableDataUtils.isAllNumbers(resultA) && VariableDataUtils.isAllNumbers(resultB)) {
      if (resultA[2] > resultB[2]) {
        return 1;
      } else if (resultA[2] < resultB[2]) {
        return -1;
      }
    }

    // Both all letters => direct comparison
    if (VariableDataUtils.isAllLetters(resultA) && VariableDataUtils.isAllLetters(resultB)) {
      if (resultA[1] > resultB[1]) {
        return 1;
      } else if (resultA[1] < resultB[1]) {
        return -1;
      }
    }

    // One is all numbers one has letters
    if (VariableDataUtils.hasLetter(resultA) && VariableDataUtils.isAllNumbers(resultB)) {
      return 1;
    } else if (VariableDataUtils.isAllNumbers(resultA) && VariableDataUtils.hasLetter(resultB)) {
      return -1;
    }

    // One is all letters one has numbers
    if (VariableDataUtils.isAllLetters(resultA) && VariableDataUtils.hasNumber(resultB)) {
      return 1;
    } else if (VariableDataUtils.hasNumber(resultA) && VariableDataUtils.isAllLetters(resultB)) {
      return -1;
    }

    // One starts with a number the other with a letter
    if (VariableDataUtils.startsWithNumber(resultA) && VariableDataUtils.startsWithLetter(resultB)) {
      return 1;
    } else if (VariableDataUtils.startsWithLetter(resultA) && VariableDataUtils.startsWithNumber(resultB)) {
      return -1;
    }

    // Both start with letters
    if (VariableDataUtils.startsWithLetter(resultA) && VariableDataUtils.startsWithLetter(resultB)) {
      if (resultA[1] > resultB[1]) {
        return 1;
      } else if (resultA[1] < resultB[1]) {
        return -1;
      } else if (resultA[2] > resultB[2]) {
        return 1;
      } else if (resultA[2] < resultB[2]) {
        return -1;
      }
    }

    // Both start with numbers
    if (VariableDataUtils.startsWithNumber(resultA) && VariableDataUtils.startsWithNumber(resultB)) {
      if (resultA[3] > resultB[3]) {
        return 1;
      } else if (resultA[3] < resultB[3]) {
        return -1;
      } else if (resultA[2] > resultB[2]) {
        return 1;
      } else if (resultA[2] < resultB[2]) {
        return -1;
      }
    }
  }

  // "Color Code" regex helper methods
  static hasLetter(result) {
    return result[1] || result[3];
  }

  static hasNumber(result) {
    return result[2];
  }

  static isAllNumbers(result) {
    return !result[1] && result[2] && !result[3];
  }

  static isAllLetters(result) {
    return result[1] && !result[2];
  }

  static startsWithLetter(result) {
    return result[1];
  }

  static startsWithNumber(result) {
    return !result[1] && result[2];
  }

}