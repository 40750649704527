import React from "react";
import { isEmpty } from "util/common";

export const TierTable = ({
  tiers,
  selectedTier,
  name = "Unit Price",
  onClick,
  editMode,
}) => {
  const tierIsSelected = (tier) => {
    return (
      !isEmpty(selectedTier) &&
      tier.quantity === selectedTier.quantity &&
      tier.price === selectedTier.price &&
      tier.currency === selectedTier.currency
    );
  };

  return (
    <table className="table table-striped tierTable">
      <thead>
        <tr>
          <th scope="col" width="50%">Quantity</th>
          <th scope="col" width="50%">{name}</th>
        </tr>
      </thead>
      <tbody>
        {tiers &&
          tiers.map((tier, idx) => (
            <tr
              key={idx}
              className={editMode ? "tier-row" : ""}
              style={
                tierIsSelected(tier)
                  ? { backgroundColor: "rgb(0 174 239 / 70%)" }
                  : {}
              }
              onClick={
                editMode ? () => onClick(tier) : (e) => e.preventDefault()
              }
            >
              <td>{tier.quantity}</td>
              <td>{tier.price}</td>
            </tr>
          ))}
        {isEmpty(tiers) && (
          <tr>
            <td>{"No tiers for selected currency"}</td>
            <td></td>
          </tr>
        )}
      </tbody>
    </table>
  );
};
