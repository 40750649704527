import * as types from "./attributeSchemaActionTypes";

const initialState = {
  attributeSchemaSearchValues: {
    name: "",
    global: null,
    customer: "",
    productGroup: "",
  }
};

export const attributeSchemaReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_ATTRIBUTE_SCHEMA_SEARCH_VALUES: {
      const data = action.data;
      return { ...state, attributeSchemaSearchValues: data };
    }
    default:
      return state;
  }
};
