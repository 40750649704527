// React
import React from "react";
import { Link } from "react-router-dom";

// Local
import { isEmpty } from "util/common";
import { LabelValue } from "components/LabelValue";

export const VendorListRow = ({ vendor }) => {
  return (
    <Link
      className="vendor-item list-group-item list-group-item-action"
      to={"/vendor-details?id=" + vendor.id}
    >
      <div className="d-flex w-100">
        <div className="vendor-details">
          <div className="vendor-meta">
            <ul>
              <LabelValue
                label="Company Name"
                value={isEmpty(vendor.companyName) ? "---" : vendor.companyName}
                listItemStyle={{ width: "13rem", overflowWrap: "anywhere" }}
              />
              <LabelValue
                label="Shipping Address(es)"
                value={isEmpty(vendor.shippingAddresses) ? "---" : vendor.shippingAddresses.join(", ")}
                listItemStyle={{ width: "25rem", overflowWrap: "anywhere" }}
              />
              <LabelValue
                label="Billing Address"
                value={isEmpty(vendor.billingAddress) ? "---" : vendor.billingAddress}
                listItemStyle={{ width: "25rem", overflowWrap: "anywhere" }}
              />
              <LabelValue
                label="Customer(s)"
                value={
                  isEmpty(vendor.customers[0])
                    ? "---"
                    : vendor.customers.map((c) => c.name).join(", ")
                }
              />
            </ul>
          </div>
        </div>
      </div>
    </Link>
  );
};
