import React, { useState, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import api from "services/apiService";
import { showSuccessMessage, showErrorMessage } from "store/messages/messageActions";
import { RolePermission } from "components/RolePermission";
import { ROLES, ROLE_OPERATIONS } from "constants/roles";
import { ARTWORK_STATUS } from "constants/artworkStatus";
import { ORDER_STATUS, ORDER_STATUS_PARAM_VALUE, ORDER_STATUS_API } from "constants/orderStatus";
import { useOverlay } from "hooks/useOverlay";
import { useError } from "hooks/errors";
import { TextArea } from "components/TextArea";
import { downloadFile } from "util/download";
import { isEmpty } from "util/common";

export const Artwork = ({ orderId, orderStatus, orderItemId, artwork, isAllArtPrepared, isCsrsArtPrepared, artChanged, setArtChanged, editMode, setEditMode }) => {
    const dispatch = useDispatch();
    const [file, setFile] = useState(null)
    const [comment, setComment] = useState(artwork ? artwork.comments : null);
    const [showOverlay, hideOverlay] = useOverlay();
    const [showError, ] = useError();
    const fileRef = useRef(null);

    const uploadArtwork = async () => {
        try {
            showOverlay("Uploading Artwork...");
            const response = await api.getMaxArtworkUploadSize();
            
            if (file.size > response.data.maxSizeInBytes) {
                hideOverlay();
                dispatch(showErrorMessage("File size can not exceed " + response.data.maxSizeInMB + " MB."));
                setFile(null);
                fileRef.current.value = null;
                return;
            }

            const formData = new FormData();
            formData.append("artwork", file);
            await api.uploadArtwork(orderId, orderItemId, formData);

            hideOverlay();
            setArtChanged(!artChanged);
            dispatch(showSuccessMessage("Artwork has been uploaded successfully."))
        } catch (error) {
            hideOverlay();
            showError(error);
            setFile(null);
            fileRef.current.value = null;
        }
    };

    const downloadArtwork = async () => {
        try {
            showOverlay("Downloading...");
            const response = await api.getArtwork(orderId, orderItemId);
            downloadFile(response);
            hideOverlay();
        } catch (error) {
            hideOverlay();
            showError(error);
        }
    };

    const onArtworkChange = (e) => {
        setFile(e.target.files[0]);
    }

    const reviewArtwork = async (approved) => {
        try {
            const data = {
                comment: comment,
                approved: approved
            };

            await showOverlay("Updating Artwork Review...");
            await api.reviewArtwork(orderId, orderItemId, data);
            hideOverlay();
            setEditMode(false);
            setArtChanged(!artChanged);
        } catch (error) {
            hideOverlay();
            showError(error);
        }
    };

    useEffect(() => {
        if (file != null) {
            uploadArtwork();
        }
    }, [file])

    const artAffordances = [];

    if (artwork) {
        if (!isEmpty(artwork.name)) {
            artAffordances.push(<button onClick={() => downloadArtwork()} className="file-btn-link">{artwork.name}</button>);
        } else {
            artAffordances.push(<div>- No Artwork Uploaded -</div>);
        }

        if (!artwork.artworkFinalized && isAllArtPrepared && (artwork.status == ARTWORK_STATUS.UPLOADED || editMode) && orderStatus == ORDER_STATUS_PARAM_VALUE[ORDER_STATUS.ART_PREPARED]) {
            artAffordances.push(
                <RolePermission roles={ROLE_OPERATIONS.REVIEW_ARTWORK}>
                    <form onSubmit={e => {e.preventDefault();reviewArtwork(false)}}>
                        <TextArea
                            onChange={setComment}
                            className="form-control my-3 artwork-comment-field"
                            placeholder="Enter Comments"
                            row="5"
                            value={comment}
                            required
                        />
                        <button type="button" onClick={() => reviewArtwork(true)} className="btn btn-approve btn-block">Approve</button>
                        <button type="submit" className="btn btn-reject btn-block">Reject</button>
                    </form>
                </RolePermission>
            );
        }
        
        if (orderStatus == ORDER_STATUS_API.NEEDS_ARTWORK && artwork.status != ARTWORK_STATUS.APPROVED && !isCsrsArtPrepared){
            artAffordances.push(
                <RolePermission roles={ROLE_OPERATIONS.UPLOAD_ARTWORK} >
                    <form>
                        <label className="file-upload-btn">
                            Choose File
                            <input ref={fileRef} type="file" onChange={onArtworkChange} name="file" className="btn-block" />
                        </label>
                    </form>
                </RolePermission>
            );
        }

        if (artwork.comments){
            let roles = [ROLES.CSR, ROLES.ACCOUNT, ROLES.FACTORY_ACCOUNT, ROLES.FINANCE, ROLES.ADMIN];

            if (!editMode && (orderStatus != ORDER_STATUS_API.NEEDS_ART_APPROVAL || artwork.status == ARTWORK_STATUS.APPROVED || artwork.status == ARTWORK_STATUS.REJECTED)){
                roles.push(ROLES.VENDOR);
            }

            artAffordances.push(
                <RolePermission roles={roles}>
                    <div className="label comment-label">Comments</div><div className="view-comment">{artwork.comments}</div>
                </RolePermission>
            );
        }

        return artAffordances;
    } else {
        return (
            <RolePermission roles={ROLE_OPERATIONS.UPLOAD_ARTWORK} >
                <form>
                    <label className="file-upload-btn">
                        Choose File
                        <input ref={fileRef} type="file" onChange={onArtworkChange} name="file" className="btn-block" />
                    </label>
                </form>
            </RolePermission>
        );
    }
};
