import React from "react";
import { useSelector } from "react-redux";
import { useRef } from "react";
import { useEffect } from "react";

export const MessageOverlay = () => {
    const message = useSelector(state => state.message.overlayMessage);
    const overlayRef = useRef(null);

    useEffect(() => {
        if (message) {
            overlayRef.current.focus();
        } else if (overlayRef.current) {
            overlayRef.current.blur();
        }
    }, [message]);

    if (message) {
        return (
            <div className="overlay-message">
                <div className="overlay-message-text">{message}</div>
                <input className="input-not-visible" ref={overlayRef} type="text" onKeyDown={e => e.preventDefault()} />
            </div>
        );
    } else {
        return <></>;
    }
}
