import { useDispatch } from "react-redux";
import { showOverlayMessage, hideOverlayMessage, clearMessage } from "store/messages/messageActions";

export const useOverlay = () => {
    const dispatch = useDispatch();

    const showOverlay = (message) => {
        dispatch(clearMessage());
        dispatch(showOverlayMessage(message));
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                resolve();
            }, 300);
        });
    };

    const hideOverlay = () => {
        dispatch(hideOverlayMessage());
    };

    return [showOverlay, hideOverlay];
};
