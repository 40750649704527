// React
import React from "react";
import { useURLNavigate } from "hooks/useURLNavigate";
import { joinParams } from "components/joinParams";
import { isTrue } from "util/common";
import { getDisplayForProcessValue } from "constants/fulfillmentProcess";

export const ProductSubcodeRow = ({ subcode }) => {
  const navigate = useURLNavigate();

  const onClick = () => {
    const params = joinParams({
      id: subcode.id,
    });
    navigate("product-subcode-details", params);
  };

  return (
    <tr className="sc-row" onClick={onClick}>
      <td className="data">{subcode.productSubcode ? subcode.productSubcode : "---"}</td>
      <td className="data">{subcode.productCode ? subcode.productCode.name : "---" }</td>
      <td className="data">{subcode.description ? subcode.description : "---"}</td>
      <td className="data">{subcode.sampleReferenceNumber ? subcode.sampleReferenceNumber : "---"}</td>
      <td className="data">{subcode.customer ? subcode.customer.name : "---"}</td>
      <td className="data">{subcode.factory ? subcode.factory.name : "---"}</td>
      <td className="data">{subcode.fulfillmentProcess ? getDisplayForProcessValue(subcode.fulfillmentProcess) : "---"}</td>
      <td className="data">{isTrue(subcode.sustainable) ? "Yes" : "No"}</td>
    </tr>
  );
};
