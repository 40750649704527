import React, { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setProductSearchValues } from "store/products/productActions";
import { isEmpty, isTrue, isNullOrFalse, hasLength, isNullOrUndefined } from "util/common";
import { TextInput } from "components/TextInput";
import api from "services/apiService";
import { useError } from "hooks/errors";
import { joinParams } from "components/joinParams";
import { useCurrentUser } from "hooks/useCurrentUser";
import { ROLES } from "constants/roles";
import { ORDER_STATUS_PRODUCT } from "../../../constants/orderStatus";

export const ProductSearchForm = ({ onProductSearch }) => {

  const dispatch = useDispatch();
  const searchValues = useSelector(state => state.products.searchValues);
  const user = useCurrentUser();

  const [variableDataSearch, setVariableDataSearch] = useState(null);
  const [productionRegions, setProductionRegions] = useState(null);
  const [initialProductionRegion, setInitialProductionRegion] = useState(null);
  const [productGroups, setProductGroups] = useState(null);
  const [styleNumberRequired, setStyleNumberRequired] = useState(false);

  const searchButtonRef = useRef(null);
  const searchToggleRef = useRef(null);

  const [showError, ] = useError();

  const loadProductionRegions = async () => {
    try {
      const response = await api.getProductSearchProductionRegions();
      const productionRegions = response.data.productionRegions.map(p => {
        return {
          name: p,
          disabled: (!isNullOrUndefined(response.data.productionRegion) && p !== response.data.productionRegion)
        };
      });

      let productionRegion = response.data.productionRegion;

      if (isEmpty(productionRegion)) {
        productionRegion = hasLength(productionRegions, 1) ? productionRegions[0].name : "";
      }

      setProductionRegions(productionRegions);
      setInitialProductionRegion(productionRegion);
    } catch (error) {
      showError(error);
    }
  };

  const loadProductGroups = async () => {
    try {
      const response = await api.getProductGroups();
      setProductGroups(response.data.refs);
    } catch (error) {
      showError(error);
    }
  };

  const loadCustomerDefaults = async () => {
    try {
      const params = joinParams({
        defaultName: "VARIABLE_DATA_STYLE_NUMBER_REQUIRED",
        isEqualTo: true
      });
      const response = await api.getCustomerDefaultEqualTo(params);

      if (response.data.value) {
        setStyleNumberRequired(true);
      }
    } catch (error) {
      showError(error);
    }
  };

  useEffect(() => {
    loadCustomerDefaults();
    loadProductionRegions();
    loadProductGroups();
  }, []);

  useEffect(() => {
    if (productionRegions && productGroups && !isNullOrUndefined(initialProductionRegion)) {
      const _searchValues = { ...searchValues };

      if (isEmpty(_searchValues.productionRegion)) {
        _searchValues.productionRegion = initialProductionRegion;
      }

      onProductGroupChange(searchValues.productGroup, _searchValues);

      if (searchParametersValid(_searchValues)) {
        onProductSearch(_searchValues);
      }

      window.setCustomInvalidHandlers();
      searchToggleRef.current.click();
    }
  }, [productionRegions, productGroups, initialProductionRegion, styleNumberRequired]);

  const searchParametersValid = (searchValues) => {
    if (isEmpty(searchValues.productionRegion)) {
      return false;
    }

    if (searchValues.productGroup === "VARIABLE_DATA") {
      if (isEmpty(searchValues.poNumber) && user.role !== ROLES.VENDOR) {
        return false;
      }

      if (styleNumberRequired && isEmpty(searchValues.styleNumber)) {
        return false;
      }
    } else if (isEmpty(searchValues.productGroup) && isEmpty(searchValues.productSubcodeDescription)) {
      return false;
    }

    return true;
  };

  const onProductGroupChange = (productGroup, searchValues, shouldLoadIfVariableData = false) => {
    const variableDataSearch = isEmpty(productGroup) ? null : productGroup === "VARIABLE_DATA";
    setVariableDataSearch(variableDataSearch);

    const _values = { ...searchValues };
    _values.productGroup = productGroup;

    if (variableDataSearch) {
      _values.productSubcodeDescription = "";
    } else {
      _values.styleNumber = "";
      _values.poNumber = "";
    }

    dispatch(setProductSearchValues(_values));

    if (shouldLoadIfVariableData && searchParametersValid(_values)) {
      onProductSearch(_values);
    }
  };

  const updateSearchValue = (name, value) => {
    const _searchValues = { ...searchValues };
    _searchValues[name] = value;
    dispatch(setProductSearchValues(_searchValues));
  };

  const onInputKeyDown = (e) => {
    if (e.keyCode === 13) {
      e.preventDefault();
      searchButtonRef.current.click();
    }
  };

  return (
    <form onKeyDown={onInputKeyDown} onSubmit={e => { e.preventDefault(); onProductSearch(searchValues); }}>
      <a className="toggleBTN collapsed" data-toggle="collapse" href="#searchForm" role="button" aria-expanded="false" aria-controls="" ref={searchToggleRef}><span id="ToggleIcon"></span>Search</a>

      <div className="collapse rounded" id="searchForm">
              <div className="searchForm">
                  <div className="form-row">
                    <div className="form-group col-3 col-left-none">
                        <label><strong>Step 1:&nbsp;&nbsp;</strong>Select Production Region *</label>
                        <select className="form-control" onChange={e => updateSearchValue("productionRegion", e.target.value)} value={searchValues.productionRegion} required>
                            {(!productionRegions || productionRegions.length > 1) &&
                              <option value="" hidden>---</option>
                            }
                            {productionRegions && productionRegions.map((p, index) => (
                                <option key={index} value={p.name} disabled={p.disabled}>{p.name}</option>
                            ))}
                        </select>
                    </div>
                    <div className="form-group col-3">
                        <label><strong>Step 2:&nbsp;&nbsp;</strong>Select Product Group</label>
                        <select className="form-control" onChange={e => onProductGroupChange(e.target.value, searchValues, e.target.value === "VARIABLE_DATA")} value={searchValues.productGroup}>
                            <option value="">---</option>
                            {productGroups && productGroups.map((productGroup, index) => (
                            <option key={index} value={productGroup.value}>
                                {productGroup.displayName}
                            </option>
                            ))}
                        </select>
                    </div>
                </div>
                <div className="step3Filters">
                    <h5 className="filterTitle"><strong>Step 3:&nbsp;&nbsp;</strong>Filter by</h5>
                    <div className="row">
                        <div className="col-9">
                            <div className="form-row border">
                                {isNullOrFalse(variableDataSearch) && <div className="form-group col-4">
                                  <label>Product Code / Description</label>
                                  <TextInput
                                    onChange={(value) => updateSearchValue("productSubcodeDescription", value)}
                                    className="form-control"
                                    placeholder={(isTrue(variableDataSearch) || isEmpty(searchValues.productionRegion)) ? "" : "Enter Product Code"}
                                    disabled={isEmpty(searchValues.productionRegion)}
                                    value={searchValues.productSubcodeDescription}
                                  />
                                </div>}
                                {isTrue(variableDataSearch) && <>
                                  <div className="form-group col-4">
                                    <label>{"Style No." + (styleNumberRequired ? " *" : "")}</label>
                                    <TextInput
                                      onChange={(value) => updateSearchValue("styleNumber", value)}
                                      className="form-control"
                                      placeholder="Enter Style No."
                                      value={searchValues.styleNumber}
                                      required={styleNumberRequired}
                                    />
                                  </div>
                                  <div className="form-group col-4">
                                    <label>{"PO Number" + (user.role === ROLES.VENDOR ? "" : " *")}</label>
                                    <TextInput
                                    onChange={(value) => updateSearchValue("poNumber", value)}
                                    className="form-control"
                                    placeholder="Enter PO Number"
                                    value={searchValues.poNumber}
                                    required={user.role !== ROLES.VENDOR}
                                    />
                                  </div>
                                  <div className="form-group col-4">
                                    <label>Order Status</label>
                                    <select className="form-control" onChange={e => updateSearchValue("orderStatus", e.target.value)} value={searchValues.orderStatus} >
                                      {ORDER_STATUS_PRODUCT.map((option, index) =>
                                        <option key={index} value={option.value}>{option.text}</option>
                                      )}
                                    </select>
                                  </div>
                                </>}
                            </div>
                        </div>
                        <div className="col-3 search-btn-col">
                            <button ref={searchButtonRef} className="searchBTN btn btn-block btn-outline-primary" type="submit">Search</button>
                        </div>
                    </div>
                </div>
              </div>
          </div>

    </form>
  );
};
