/* eslint-disable no-multiple-empty-lines */
/* eslint-disable no-trailing-spaces */
/* eslint-disable no-unused-vars */
import React from "react";
import {
  optionFromNameAndId,
  getNameAndIdFromList,
  SHOW_OPTIONS,
} from "constants/options";
import { getOptionText } from "util/common";
import { LabelTextField } from "components/LabelTextField";
import { LabelSelectField } from "components/LabelSelectField";
import { isEmpty } from "util/common";
import { Link } from "react-router-dom";

export const CustomerRelationships = ({
  editCustomer,
  updateCustomerEntity,
  editCustomerField,
  editMode,
  cautionMaintenanceList,
  fabricCompositionList,
  careInstructionList,
  careCodeList,
  countryOfOriginList,
  specialInstructionsList,
}) => {
  return (
    <div className="row entity-list">
      <div className="col">
        <div className="customer-info">
          <ul>
            <LabelSelectField
              label={"Country Of Origin"}
              value={
                editMode
                  ? isEmpty(editCustomer.countryOfOriginList)
                    ? "None"
                    : editCustomer.countryOfOriginList.id
                  : isEmpty(editCustomer.countryOfOriginList)
                  ? "None"
                  : editCustomer.countryOfOriginList.name
              }
              options={countryOfOriginList.map((c) => optionFromNameAndId(c))}
              editMode={editMode}
              onChange={(id) =>
                editCustomerField(
                  "countryOfOriginList",
                  id === "NONE"
                    ? null
                    : getNameAndIdFromList(countryOfOriginList, id)
                )
              }
            />
          </ul>
        </div>
        <div className="customer-info">
          <ul>
            <LabelSelectField
              label={"Special Instructions"}
              value={
                editMode
                  ? isEmpty(editCustomer.specialInstructionsList)
                    ? "None"
                    : editCustomer.specialInstructionsList.id
                  : isEmpty(editCustomer.specialInstructionsList)
                  ? "None"
                  : editCustomer.specialInstructionsList.name
              }
              options={specialInstructionsList.map((c) =>
                optionFromNameAndId(c)
              )}
              editMode={editMode}
              onChange={(id) =>
                editCustomerField(
                  "specialInstructionsList",
                  id === "NONE"
                    ? null
                    : getNameAndIdFromList(specialInstructionsList, id)
                )
              }
            />
          </ul>
        </div>
        <div className="customer-info">
          <ul>
            <LabelSelectField
              label={"Show Copyright"}
              value={
                isEmpty(editCustomer.copyrightShow)
                  ? "---"
                  : editMode
                  ? editCustomer.copyrightShow
                  : getOptionText(
                      SHOW_OPTIONS,
                      editCustomer.copyrightShow
                    )
              }
              options={SHOW_OPTIONS}
              editMode={editMode}
              onChange={(value) => editCustomerField("copyrightShow", value)}
              booleanValue={true}
            />
          </ul>
        </div>
      </div>
      <div className="col">
        <div className="customer-info">
          <ul>
            <LabelSelectField
              label={"Care Instructions"}
              value={
                editMode
                  ? isEmpty(editCustomer.careInstructionList)
                    ? "None"
                    : editCustomer.careInstructionList.id
                  : isEmpty(editCustomer.careInstructionList)
                  ? "None"
                  : editCustomer.careInstructionList.name
              }
              options={careInstructionList.map((c) => optionFromNameAndId(c))}
              editMode={editMode}
              onChange={(id) =>
                editCustomerField(
                  "careInstructionList",
                  getNameAndIdFromList(careInstructionList, id)
                )
              }
            />
          </ul>
        </div>
        <div className="customer-info">
          <ul>
            <LabelTextField
              label={"Special Instructions Limit "}
              value={editCustomer.specialInstructionsLimit}
              editMode={editMode}
              onChange={(value) =>
                editCustomerField("specialInstructionsLimit", value)
              }
              required
            />
          </ul>
        </div>
        <div className="customer-info">
          <ul>
            <LabelSelectField
              label={"Caution Maintenance"}
              value={
                editMode
                  ? isEmpty(editCustomer.cautionMaintenanceList)
                    ? "None"
                    : editCustomer.cautionMaintenanceList.id
                  : isEmpty(editCustomer.cautionMaintenanceList)
                  ? "None"
                  : editCustomer.cautionMaintenanceList.name
              }
              options={cautionMaintenanceList.map((c) =>
                optionFromNameAndId(c)
              )}
              editMode={editMode}
              onChange={(id) =>
                editCustomerField(
                  "cautionMaintenanceList",
                  getNameAndIdFromList(cautionMaintenanceList, id)
                )
              }
            />
          </ul>
        </div>
      </div>
      <div className="col">
        <div className="customer-info">
          <ul>
            <LabelSelectField
              label={"Care Codes"}
              value={
                editMode
                  ? isEmpty(editCustomer.careCodeList)
                    ? "None"
                    : editCustomer.careCodeList.id
                  : isEmpty(editCustomer.careCodeList)
                  ? "None"
                  : editCustomer.careCodeList.name
              }
              options={careCodeList.map((c) => optionFromNameAndId(c))}
              editMode={editMode}
              onChange={(id) =>
                editCustomerField(
                  "careCodeList",
                  getNameAndIdFromList(careCodeList, id)
                )
              }
            />
          </ul>
        </div>
        <div className="customer-info">
          <ul>
            <LabelSelectField
              label={"Fabric Composition"}
              value={
                editMode
                  ? isEmpty(editCustomer.fabricCompositionList)
                    ? "None"
                    : editCustomer.fabricCompositionList.id
                  : isEmpty(editCustomer.fabricCompositionList)
                  ? "None"
                  : editCustomer.fabricCompositionList.name
              }
              options={fabricCompositionList.map((c) => optionFromNameAndId(c))}
              editMode={editMode}
              onChange={(id) =>
                editCustomerField(
                  "fabricCompositionList",
                  getNameAndIdFromList(fabricCompositionList, id)
                )
              }
            />
          </ul>
        </div>
        <div className="customer-info">
          <ul>
            <LabelSelectField
              label={"Show Fabric Composition"}
              value={
                isEmpty(editCustomer.fabricCompositionShow)
                  ? "---"
                  : editMode
                    ? editCustomer.fabricCompositionShow
                    : getOptionText(
                        SHOW_OPTIONS,
                        editCustomer.fabricCompositionShow
                      )
              }
              options={SHOW_OPTIONS}
              editMode={editMode}
              onChange={(value) => editCustomerField("fabricCompositionShow", value)}
              booleanValue={true}
            />
            <li>
              <Link to={"care-label?tab=2&customerId=" + editCustomer.id }>
                Edit Care Label Lists
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};
