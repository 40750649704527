import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { PageLayout } from "../../layouts/PageLayout";
import api from "services/apiService";
import { AttributeSchemaListSearch } from "./AttributeSchemaListSearch";
import { AttributeSchemaListRow } from "./AttributeSchemaListRow";
import { joinParams } from "components/joinParams";
import { pluralize, isNullOrUndefined, isEmpty, addNoneOption } from "util/common";
import { useOverlay } from "hooks/useOverlay";
import { useError } from "hooks/errors";
import { useURLNavigate } from "hooks/useURLNavigate";
import { PAGE_URL } from "constants/url";

export const AttributeSchemaListPage = () => {
  const [showOverlay, hideOverlay] = useOverlay();
  const message = useSelector(state => state.message.message);
  const [attributeSchemas, setAttributeSchemas] = useState([]);
  const [productGroup, setProductGroup] = useState();
  const [customers, setCustomer] = useState();
  const [loaded, setLoaded] = useState(false);
  const [showError,] = useError();
  const navigate = useURLNavigate();

  useEffect(() => {
    loadData();
  }, []);

  useEffect(() => {
    console.log("productGroup", productGroup);
    console.log("customer", customers);
  }, [productGroup, customers]);


  const loadData = async () => {
    try {
      const productGroupResponse = await api.getAllProductGroup();
      const customersResponse = await api.getAllCustomers();

      let _customers = customersResponse.data.customers;
      let _productGroup = productGroupResponse.data.listContent;

      let none = { id: "", name: "---" };
      _customers.unshift(none);
      _productGroup.unshift(none);

      setProductGroup(_productGroup);
      setCustomer(_customers);

      setLoaded(true);
    } catch (error) {
      showError(error);
    }
  };

  const searchAttributeSchemas = async (searchValues) => {
    try {
      const params = getParams(searchValues);
      if (isNullOrUndefined(message)) {
        await showOverlay("Searching...");
      }
      const response = await api.getFilteredAttributeSchemas(params);
      hideOverlay();
      setAttributeSchemas(response.data.listContent);
    } catch (error) {
      hideOverlay();
      showError(error);
    }
  };

  const getParams = (searchValues) => {
    return joinParams({
      name: searchValues.name,
      global: searchValues.global,
      customer: searchValues.customer,
      productGroup: searchValues.productGroup
    });
  };

  return (
    <PageLayout className="attribute-schema-list">
      <section className="site-content bodyWrap" id="content">
        <section className="content-body">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="page-title-row">
                  <h4 className="PageTitle">
                    <span className="page-parent">ATTRIBUTE SCHEMAS</span>
                  </h4>
                  <button
                    className="btn btn-primary add-entity-btn"
                    onClick={() =>
                      navigate(PAGE_URL.ATTRIBUTE_SCHEMA_DETAILS, "createMode=true")
                    }
                  >
                    Add
                  </button>
                </div>
                {loaded && <AttributeSchemaListSearch 
                searchAttributeSchemas={searchAttributeSchemas}
                customers={customers}
                productGroup={productGroup}
                 />}
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <section className="pt-5">
                  <table className="table table-striped" id="AttributeSchemaList">
                    <thead>
                      <tr>
                        <th scope="col" width="25%">Attribute Schema Name</th>
                        <th scope="col" width="25%">Global</th>
                        <th scope="col" width="25%">Customer</th>
                        <th scope="col" width="25%">Product Group </th>
                      </tr>
                    </thead>
                    <tbody>
                      {attributeSchemas &&
                        attributeSchemas.map((attributeSchema, index) => (
                          <AttributeSchemaListRow key={index} attributeSchema={attributeSchema} />
                        ))}
                    </tbody>
                  </table>
                </section>
              </div>
            </div>
            <div className="attributeSchema-pagination">
              <span>
                {"Showing " +
                  attributeSchemas.length +
                  pluralize(" Attribute Schema", attributeSchemas.length)}
              </span>
            </div>
          </div>
        </section>
      </section>
    </PageLayout>
  );
};
