// React
import React from "react";
import { useURLNavigate } from "hooks/useURLNavigate";
import { joinParams } from "components/joinParams";
import { PAGE_URL } from "constants/url";
import { isEmpty } from "util/common";




export const AttributeSchemaListRow = ({ attributeSchema }) => {
  const navigate = useURLNavigate();

  const onClick = () => {
    const params = joinParams({
      id: attributeSchema.id,
    });
    navigate(PAGE_URL.ATTRIBUTE_SCHEMA_DETAILS, params);
  };

  return (
    <tr className="pf-row" onClick={onClick}>
      <td className="data">{attributeSchema.name}</td>
      <td className="data">{attributeSchema.global ? "TRUE" : "FALSE"}</td>
      <td className="data">{isEmpty(attributeSchema.customer) ? null : attributeSchema.customer.name}</td>
      <td className="data">{isEmpty(attributeSchema.productGroup) ? null: attributeSchema.productGroup.name}</td>
    </tr>
  );
};
