import React, { useState, useEffect } from "react";
import { LabelValue } from "components/LabelValue";
import { LabelDate } from "components/LabelDate";
import { OrderDetailShippingDoc } from "./OrderDetailShippingDoc";
import { ROLES, userCanPerformOperation, ROLE_OPERATIONS } from "constants/roles";
import { ORDER_STATUS_API } from "constants/orderStatus";
import api from "services/apiService";
import { showSuccessMessage } from "store/messages/messageActions";
import { useDispatch } from "react-redux";
import { isEmpty } from "util/common";
import { useError } from "hooks/errors";

export const OrderDetailShipping = ({ orderId, orderDetails, user }) => {
    const dispatch = useDispatch();
    const [shippingDocs, setShippingDocs] = useState([]);
    const [file, setFile] = useState(null);
    const [showError, ] = useError();

    useEffect(() => {
        if (!isEmpty(file)){            
            uploadDocument();
        }

    }, [file]);

    useEffect(() => {
        setShippingDocs(orderDetails.shippingDocs);
    },[]);

    const uploadDocument = async () => {
        const formData = new FormData();
        formData.append("shippingDocument", file);

        try {
            let _shippingDocs = [...shippingDocs];
            const response = await api.uploadShippingDocument(orderId, formData);
            dispatch(showSuccessMessage("Document has been uploaded successfully."))
            setShippingDocs([..._shippingDocs, response.data]);
        } catch (error) {
            showError(error);
        }
    };

    return (
        <div className="row">
            <div className="col-12">
                <div className="card shadow" id="shipping">
                    <div className="card-body">
                        <h2>Shipping</h2>
                        <div className="row">
                            <div className="col-4">
                                <ul className="readonly">
                                    <LabelValue label="Contact" value={orderDetails.contact} />
                                    <LabelValue label="Email" value={orderDetails.email} />
                                    <LabelValue label="Phone" value={orderDetails.phone} />
                                </ul>
                            </div>
                            <div className="col-7">
                                <ul className="readonly">
                                    <LabelValue label="Deliver To" value={orderDetails.shippingAddress} />
                                </ul>
                            </div>
                        </div>
                        <hr />
                        <div className="row">
                            <div className="col-4">
                                <ul className="readonly">
                                    <LabelDate label="Date Requested" date={orderDetails.dateRequested} />
                                    <LabelValue label="Delivery Method" 
                                                value={isEmpty(orderDetails.deliveryMethod) ? '---' : orderDetails.deliveryMethod} />
                                    <LabelValue label="Delivery Account" 
                                                value={isEmpty(orderDetails.deliveryAccount) ? '---' : orderDetails.deliveryAccount} />
                                    <LabelValue label="Comments" value={isEmpty(orderDetails.comments) ? '---' : orderDetails.comments} />
                                </ul>
                            </div>
                        </div>

                        {(orderDetails.orderStatus == ORDER_STATUS_API.READY_TO_SHIP || !isEmpty(shippingDocs)) && 
                            <>
                                <hr />
                                <div className="row">
                                    <div className="col-6">
                                        <ul className="readonly">
                                            <li>
                                                <div className="label">Shipping Documents:</div>
                                                <div className="value">
                                                    {userCanPerformOperation(user, ROLE_OPERATIONS.CAN_UPLOAD_SHIPPING_DOCS) && orderDetails.orderStatus === ORDER_STATUS_API.READY_TO_SHIP && 
                                                        <form>
                                                            <label className="docs-upload file-upload-btn">
                                                                Choose File
                                                                <input type="file" onChange={(e) => setFile(e.target.files[0])} name="file" className="btn-block" />
                                                            </label>
                                                        </form>
                                                    }

                                                    {user.role !== ROLES.CSR && shippingDocs && shippingDocs.map((doc, index) => 
                                                        <OrderDetailShippingDoc shippingDoc={doc} orderId={orderId} index={index}/>)
                                                    }
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </>
                        }
                    </div>
                </div>
            </div>
        </div>
    );
};
