/* eslint-disable no-multiple-empty-lines */

// React
import React, { useState } from "react";

// Local
import api from "services/apiService";
import { PageLayout } from "layouts/PageLayout";
import { useURLNavigate } from "hooks/useURLNavigate";
import { pluralize, isNullOrUndefined, isEmpty } from "util/common";
import { ProductCodeRow } from "./ProductCodeRow";
import { ProductCodeSearch } from "./ProductCodeSearch";
import { joinParams } from "components/joinParams";
import { useOverlay } from "hooks/useOverlay";
import { useError } from "hooks/errors";
import { useSelector } from "react-redux";

export const ProductCodeListPage = () => {
  // hooks
  const [showOverlay, hideOverlay] = useOverlay();
  const [showError, ] = useError();
  const navigate = useURLNavigate();
  const message = useSelector(state => state.message.nextMessage);

  // state
  const [productCodes, setProductCodes] = useState([]);

  // methods
  const onSearch = async (searchValues) => {
    try {
      const params = getParams(searchValues);

      if (isNullOrUndefined(message)) {
        await showOverlay("Searching...");
      }

      const response = await api.getProductCodeList(params);
      hideOverlay();
      setProductCodes(response.data.productCodes);
    } catch (error) {
      hideOverlay();
      showError(error);
    }

  };

  const getParams = (searchValues) => {
    return joinParams({
      productCode: isEmpty(searchValues.productCode) ? null : searchValues.productCode,
    });
  };

  return (
    <PageLayout className="product-code-list">
      <section className="site-content bodyWrap" id="content">
        <section className="content-body">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="page-title-row">
                  <h4 className="PageTitle">
                    <span className="page-parent">PRODUCT CODES</span>
                  </h4>
                  <button
                    className="btn btn-primary add-entity-btn"
                    onClick={() =>
                      navigate("/product-code-details", "createMode=true")
                    }
                  >
                    Add
                  </button>
                </div>
                <ProductCodeSearch onSearch={onSearch} />
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <section className="pt-5">
                  <table className="table table-striped" id="productCodeList">
                    <thead>
                      <tr>
                        <th scope="col">Product Code</th>
                      </tr>
                    </thead>
                    <tbody>
                      {productCodes &&
                        productCodes.map((code, index) => (
                          <ProductCodeRow key={index} productCode={code} />
                        ))}
                    </tbody>
                  </table>
                </section>
              </div>
            </div>
            <div className="productCodeNumber-pagination">
              <span>
                {"Showing " +
                  productCodes.length +
                  pluralize(" Product Code", productCodes.length)}
              </span>
            </div>
          </div>
        </section>
      </section>
    </PageLayout>
  );
};
