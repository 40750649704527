import React from "react";
import { BrowserRouter as Router, Switch } from "react-router-dom";
import { LoginPage } from "./modules/LoginPage";
import { ResetPasswordPage } from "./modules/ResetPasswordPage";
import { ProductListPage } from "./modules/product/ProductListPage";
import { RestockPage } from "./modules/restock/RestockPage";
import { OrderDetailPage } from "./modules/order-details/OrderDetailPage";
import { PublicRoute } from "./layouts/PublicRoute";
import { PrivateRoute } from "./layouts/PrivateRoute";
import { OrderListPage } from "./modules/order-list/OrderListPage";
import { CustomerListPage } from "./modules/customer-list/CustomerListPage";
import { CustomerDetailPage} from "./modules/customer-details/CustomerDetailPage";
import { PAGE_URL } from "constants/url";
import { TermsAndConditions } from "modules/TermsAndConditions";
import { HomeRoute } from "layouts/HomeRoute";
import { ProductDetailsPage } from "modules/product-details/ProductDetailsPage";
import { CartPage } from "modules/cart/CartPage";
import { VariableDataUploadPage } from "modules/variable-data/VariableDataPage";
import { UserListPage } from "modules/user/UserListPage";
import { UserDetailPage } from "modules/user-details/UserDetailPage";
import { ProductCodeListPage } from "modules/product-code/ProductCodeListPage";
import { ProductCodeDetailPage } from "modules/product-code/ProductCodeDetailsPage";
import { ProductSubcodeListPage } from "modules/product-subcode/ProductSubcodeListPage";
import { ProductSubcodeDetailPage } from "modules/product-subcode-details/ProductSubcodeDetailPage";
import { VendorListPage } from "modules/vendor/VendorListPage";
import { VendorDetailPage } from "modules/vendor-details/VendorDetailPage";
import { FactoryListPage } from "modules/factory/FactoryListPage";
import { FactoryDetailPage } from "modules/factory-details/FactoryDetailPage";
import { WarehouseListPage } from "modules/warehouse-list/WarehouseListPage";
import { WarehouseDetailPage } from "modules/warehouse-details/WarehouseDetailPage";
import { ProductionRegionListPage } from "modules/production-region-list/ProductionRegionListPage";
import { ProductionRegionDetailPage } from "modules/production-region-details/ProductionRegionDetailPage";
import { CareLabelPage } from "modules/care-label/CareLabelPage";
import { ProductItemDetailPage } from "modules/product-item-details/ProductItemDetailPage";
import { AttributeSchemaListPage } from "modules/attribute-schema-list/AttributeSchemaListPage";
import { AttributeSchemaDetailPage } from "modules/attribute-schema-details/AttributeSchemaDetailPage";

export const App = () => {
  return (
    <Router>
      <Switch>
        <PublicRoute exact path={PAGE_URL.LOGIN}><LoginPage /></PublicRoute>
        <PublicRoute exact path={PAGE_URL.TERMS_AND_CONDITIONS}><TermsAndConditions /></PublicRoute>
        <PublicRoute exact path={PAGE_URL.RESET_PASSWORD}><ResetPasswordPage /></PublicRoute>
        <PrivateRoute exact path={PAGE_URL.HOME}><HomeRoute /></PrivateRoute>
        <PrivateRoute exact path={PAGE_URL.VIEW_ORDER}><OrderDetailPage /></PrivateRoute>
        <PrivateRoute exact path={PAGE_URL.CART}><CartPage /></PrivateRoute>
        <PrivateRoute exact path={PAGE_URL.PRODUCT}><ProductListPage /></PrivateRoute>
        <PrivateRoute exact path={PAGE_URL.RESTOCK}><RestockPage /></PrivateRoute>
        <PrivateRoute exact path={PAGE_URL.ORDER}><OrderListPage /></PrivateRoute>
        <PrivateRoute exact path={PAGE_URL.CUSTOMER}><CustomerListPage /></PrivateRoute>
        <PrivateRoute exact path={PAGE_URL.VIEW_CUSTOMER}><CustomerDetailPage /></PrivateRoute>
        <PrivateRoute exact path={PAGE_URL.PRODUCT_DETALS}><ProductDetailsPage /></PrivateRoute>
        <PrivateRoute exact path={PAGE_URL.VARIABLE_DATA_UPLOAD}><VariableDataUploadPage /></PrivateRoute>
        <PrivateRoute exact path={PAGE_URL.USERS}><UserListPage /></PrivateRoute>
        <PrivateRoute exact path={PAGE_URL.VIEW_USER}><UserDetailPage /></PrivateRoute>
        <PrivateRoute exact path={PAGE_URL.PRODUCT_CODES}><ProductCodeListPage /></PrivateRoute>
        <PrivateRoute exact path={PAGE_URL.PRODUCT_CODE_DETAILS}><ProductCodeDetailPage /></PrivateRoute>
        <PrivateRoute exact path={PAGE_URL.PRODUCT_SUBCODE}><ProductSubcodeListPage /></PrivateRoute>
        <PrivateRoute exact path={PAGE_URL.PRODUCT_SUBCODE_DETAILS}><ProductSubcodeDetailPage /></PrivateRoute>
        <PrivateRoute exact path={PAGE_URL.CARE_LABEL}><CareLabelPage /></PrivateRoute>
        <PrivateRoute exact path={PAGE_URL.VENDORS}><VendorListPage /></PrivateRoute>
        <PrivateRoute exact path={PAGE_URL.VIEW_VENDOR}><VendorDetailPage /></PrivateRoute>
        <PrivateRoute exact path={PAGE_URL.FACTORY}><FactoryListPage /></PrivateRoute>
        <PrivateRoute exact path={PAGE_URL.FACTORY_DETAILS}><FactoryDetailPage /></PrivateRoute>
        <PrivateRoute exact path={PAGE_URL.WAREHOUSES}><WarehouseListPage /></PrivateRoute>
        <PrivateRoute exact path={PAGE_URL.WAREHOUSE_DETAILS}><WarehouseDetailPage /></PrivateRoute>
        <PrivateRoute exact path={PAGE_URL.PRODUCTION_REGION}><ProductionRegionListPage /></PrivateRoute>
        <PrivateRoute exact path={PAGE_URL.PRODUCTION_REGION_DETAILS}><ProductionRegionDetailPage /></PrivateRoute>
        <PrivateRoute exact path={PAGE_URL.PRODUCT_ITEM_DETAILS}><ProductItemDetailPage /></PrivateRoute>
        <PrivateRoute exact path={PAGE_URL.ATTRIBUTE_SCHEMA}><AttributeSchemaListPage /></PrivateRoute>
        <PrivateRoute exact path={PAGE_URL.ATTRIBUTE_SCHEMA_DETAILS}><AttributeSchemaDetailPage /></PrivateRoute>
      </Switch>
    </Router>
  );
};
