import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { clearMessage, popMessage } from "store/messages/messageActions";
import { MESSAGE_TYPE } from "constants/messageType";

export const MessagePanel = () => {
    const dispatch = useDispatch();
    const message = useSelector(state => state.message.message);

    useEffect(() => {
        if (message && message.displayed) {
            dispatch(clearMessage());
        }

        dispatch(popMessage());
    }, []);

    useEffect(() => {
        if (message) {
            message.displayed = true;

            if (!message.retain) {
                setTimeout(() => {
                    dispatch(clearMessage());
                }, 4000);
            }
        }
    }, [message]);

    const onMessageClick = () => {
        if (message && message.retain) {
            dispatch(clearMessage());
        }
    };

    if (message) {
        return (
            <div className={"alert alert-message-" + (message.type === MESSAGE_TYPE.SUCCESS ? "success" : "error")} role="alert" onClick={e => onMessageClick()}>
                {message.message}
            </div>
        );
    } else {
        return <></>;
    }
}
