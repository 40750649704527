/* eslint-disable indent */
import React, { useState } from "react";
import { CARE_LABEL_TYPE } from "constants/careLabelType";
import { GarmentComponents } from "./GarmentComponents";
import { SpecialInstructions } from "./SpecialInstructions";
import { TextInput } from "components/TextInput";
import { isEmpty, isNotEmpty } from "util/common";
import { showErrorMessage } from "store/messages/messageActions";
import { useDispatch } from "react-redux";
import { useEffect } from "react";

export const CareLabelDetails = ({ careLabel, onCareLabelChange, editMode, showValidation, setShowValidation }) => {
    const dispatch = useDispatch();
    const [careLabelConfig, setCareLabelConfig] = useState(null);
    const [instructionCodeText, setInstructionCodeText] = useState(null);
    const [selectedComponentTypes, setSelectedComponentTypes] = useState(["BODY"]);
    const [selectedSpecialInstructionTypes, setSelectedSpecialInstructionTypes] = useState([]);
    const [countryOfOrigin, setCountryOfOrigin] = useState(careLabel.countryOfOrigin);
    const [instructionCode, setInstructionCode] = useState(careLabel.instructionCode);
    const [cautionMaintenance, setCautionMaintenance] = useState(careLabel.cautionMaintenance);
    const [copyright, setCopyright] = useState(isEmpty(careLabel.copyright) ? "" : careLabel.copyright);
    const [components, setComponents] = useState([{
        id: 0,
        selectedMaterials: [],
        fabricCompositions: [{
            id: 0,
            percentage: "",
            material: ""
        }],
        componentType: "BODY",
        invalidPercentages: false,
        validationCleared: true
    }]);
    const [specialInstructions, setSpecialInstructions] = useState([{
        id: 0,
        specialInstructionType: careLabel.specialInstruction ? careLabel.specialInstruction : ""
    }]);
    const specialInstructionsLimit = careLabel.specialInstructionsLimit;

    useEffect(() => {
        window.refreshSelectPicker();

        if (careLabel.cautionMaintenanceOptions.some(option => option.value === "KEEP_AWAY_FIRE")) {
            setCautionMaintenance("KEEP_AWAY_FIRE");
        }

        const config = {
            countryOfOrigin: countryOfOrigin,
            wash: careLabel.wash,
            bleach: careLabel.bleach,
            dry: careLabel.dry,
            iron: careLabel.iron,
            dryClean: careLabel.dryClean,
            instructionCode: instructionCode,
            cautionMaintenance: cautionMaintenance,
            garmentComponentsInvalidPercentages: false
        };

        if (editMode) {
            initializeEditValues(config);
        } else {
            setCareLabelConfig(config);
        }
    }, [careLabel]);

    useEffect(() => {
        if (showValidation) {
            let _components = [...components];

            _components.forEach(c => {
                c.validationCleared = !c.invalidPercentages;
            });

            setComponents(_components);
            setShowValidation(false);
        }
    }, [showValidation]);

    const initializeEditValues = (config) => {
        if (careLabel.type === CARE_LABEL_TYPE.CARELABEL_BY_CODE) {
            setInstructionCodeText(getInstructionCodeText(instructionCode));
        }

        let _components = [];
        let specialInstructions = [];

        // Garment components
        if (careLabel.fabricCompositionShow) {
            const existingGarmentComponents = careLabel.garmentComponents;

            if (isNotEmpty(existingGarmentComponents)) {
                existingGarmentComponents.forEach((g, index) => {
                    _components.push({
                        id: index,
                        componentType: g.componentType,
                        selectedMaterials: g.fabricCompositions.map(f => f.material),
                        fabricCompositions: g.fabricCompositions.map((f, index) => {
                            return {
                                id: index,
                                percentage: f.percentage,
                                material: f.material
                            };
                        }),
                        invalidPercentages: false,
                        validationCleared: true
                    });
                });

                setSelectedComponentTypes(existingGarmentComponents.map(g => g.componentType));
            } else {
                // The backend is expecting garment components but none are present
                // the customer configuration may have changed so we will add the default.
                _components = components;
                setSelectedComponentTypes(_components.map(g => g.componentType));
            }

        }

        setComponents(_components);

        // Special Instructions
        careLabel.specialInstructions.forEach((s, index) => {
            specialInstructions.push({
                id: index,
                specialInstructionType: s
            });
        });

        if (specialInstructions.length > 0) {
            setSelectedSpecialInstructionTypes(careLabel.specialInstructionOptions.map(s => s.specialInstructionType));
            setSpecialInstructions(specialInstructions);
        }

        window.setSelectPickerValue("care-instruction-wash", careLabel.wash);
        window.setSelectPickerValue("care-instruction-bleach", careLabel.bleach);
        window.setSelectPickerValue("care-instruction-dry", careLabel.dry);
        window.setSelectPickerValue("care-instruction-iron", careLabel.iron);
        window.setSelectPickerValue("care-instruction-dryclean", careLabel.dryClean);

        setCareLabelConfig(config);
        updateCareLabelConfig(config, _components, specialInstructions);
    };

    const onKeyDown = (e) => {
        if (e.keyCode === 13) {
            e.preventDefault();
            e.target.blur();
        }
    };

    const onCareLabelFieldChange = (field, value) => {
        const config = {...careLabelConfig};
        config[field] = isEmpty(value) ? null : value;
        setCareLabelConfig(config);
        updateCareLabelConfig(config, components, specialInstructions);
    };

    const onInstructionsCodeChange = (code) => {
        onCareLabelFieldChange("instructionCode", code);
        setInstructionCodeText(getInstructionCodeText(code));
    };

    const getInstructionCodeText = (code) => {
        for (let i = 0; i < careLabel.instructionCodeOptions.length; i++) {
            if (careLabel.instructionCodeOptions[i].code === code) {
                return careLabel.instructionCodeOptions[i].text;
            }
        }

        return null;
    };

    const addComponent = () => {
        let _components = [...components];
        _components.push({
            id: getNextAvailableId(_components),
            componentType: "",
            selectedMaterials: [],
            fabricCompositions: [{
                id: 0,
                percentage: "",
                material: ""
            }],
            invalidPercentages: false,
            validationCleared: true
        });
        setComponents(_components);
        updateCareLabelConfig(careLabelConfig, _components, specialInstructions);
    };

    const addSpecialInstruction = () => {
        let _specialInstructions = [...specialInstructions];
        if(_specialInstructions.length <= careLabel.specialInstructionsLimit) {
            _specialInstructions.push({
                id: getNextAvailableId(_specialInstructions),
                specialInstructionType: "",
            });
            setSpecialInstructions(_specialInstructions);
            updateCareLabelConfig(careLabelConfig, components, _specialInstructions);
        } else {
            dispatch(showErrorMessage("Special Care Instructions Limit: " + careLabel.specialInstructionsLimit));
        }
    };

    const getNextAvailableId = (objectsWithIds) => {
        let id = 0;
        let found = false;

        while (!found) {
            found = true;

            for (let i = 0; i < objectsWithIds.length; i++) {
                if (objectsWithIds[i].id === id) {
                    found = false;
                    break;
                }
            }

            if (!found) {
                id = id + 1;
            }
        }

        return id;
    };

    const getComponent = (components, id) => {
        for (let i = 0; i < components.length; i++) {
            if (components[i].id === id) {
                return components[i];
            }
        }

        return null;    // This should never happen
    };

    const getSpecialInstruction = (specialInstructions, id) => {
        for (let i = 0; i < specialInstructions.length; i++) {
            if (specialInstructions[i].id === id) {
                return specialInstructions[i];
            }
        }

        return null;    // This should never happen
    };

    const onComponentTypeChange = (componentId, componentType) => {
        const _components = [...components];
        getComponent(_components, componentId).componentType = componentType;
        setComponents(_components);
        updateSelectedComponentTypes(_components);
        updateCareLabelConfig(careLabelConfig, _components, specialInstructions);
    };

    const onSpecialInstructionTypeChange = (specialInstructionId, specialInstructionType) => {
        const _specialInstructions = [...specialInstructions];
        getSpecialInstruction(_specialInstructions, specialInstructionId).specialInstructionType = specialInstructionType;
        setSpecialInstructions(_specialInstructions);
        updateSelectedSpecialInstructionTypes(_specialInstructions);
        updateCareLabelConfig(careLabelConfig, components, _specialInstructions);
    };

    const updateSelectedComponentTypes = (components) => {
        let componentTypes = [];

        components.forEach(c => {
            if (!isEmpty(c.componentType)) {
                componentTypes.push(c.componentType);
            }
        });

        setSelectedComponentTypes(componentTypes);
    };

    const updateSelectedSpecialInstructionTypes = (specialInstructions) => {
            let specialInstructionTypes = [];

            specialInstructions.forEach(c => {
                if (!isEmpty(c.specialInstructionType)) {
                    specialInstructionTypes.push(c.specialInstructionType);
                }
            });

            setSelectedSpecialInstructionTypes(specialInstructionTypes);
        };

    const getAvailableComponentTypes = (componentType) => {
        return careLabel.componentTypes.filter(c => c.value === componentType || !selectedComponentTypes.includes(c.value));
    };

    const getAvailableSpecialInstructionTypes = (specialInstructionTypes) => {
        return careLabel.specialInstructionOptions.filter(c => c.value === specialInstructionTypes || !selectedSpecialInstructionTypes.includes(c.value));
    };

    const onPercentageChange = (componentId, id, percentage) => {
        const _components = [...components];

        let component = getComponent(_components, componentId);

        component.fabricCompositions.forEach(f => {
            if (f.id === id) {
                f.percentage = percentage;
            }
        });

        component.invalidPercentages = componentHasInvalidPercentages(component);

        if (!component.validationCleared) {
            component.validationCleared = true;
        }

        setComponents(_components);
        updateCareLabelConfig(careLabelConfig, _components, specialInstructions);
    };

    const componentHasInvalidPercentages = (component) => {
        let totalPercentage = 0;

        component.fabricCompositions.forEach(f => {
            if (!isEmpty(f.percentage)) {
                totalPercentage += f.percentage;
            }
        });

        return totalPercentage !== 100;
    };

    const onMaterialChange = (componentId, id, material) => {
        const _components = [...components];
        let component = getComponent(_components, componentId);

        component.fabricCompositions.forEach(c => {
            if (c.id === id) {
                c.material = material;
            }
        });

        updateSelectedMaterials(component);
        setComponents(_components);
        updateCareLabelConfig(careLabelConfig, _components, specialInstructions);
    };

    const addFabricComposition = (componentId) => {
        const _components = [...components];
        let component = getComponent(_components, componentId);

        component.fabricCompositions.push({
            id: getNextAvailableId(component.fabricCompositions),
            percentage: "",
            material: ""
        });

        if (!component.validationCleared) {
            component.validationCleared = true;
        }

        setComponents(_components);
        updateCareLabelConfig(careLabelConfig, _components, specialInstructions);
    };

    const removeFabricComposition = (componentId, id) => {
        const _components = [...components];
        let component = getComponent(_components, componentId);

        for (let i = 0; i < component.fabricCompositions.length; i++) {
            if (component.fabricCompositions[i].id === id) {
                if (component.fabricCompositions.length === 1) {
                    component.fabricCompositions.pop();
                } else {
                    component.fabricCompositions.splice(i, 1);
                }
                
                break;
            }
        }

        if (component.fabricCompositions.length === 0) {
            removeComponent(_components, componentId);
            updateSelectedComponentTypes(_components);
        } else {
            updateSelectedMaterials(component);
        }

        component.invalidPercentages = componentHasInvalidPercentages(component);

        if (!component.validationCleared) {
            component.validationCleared = true;
        }
        
        setComponents(_components);
        updateCareLabelConfig(careLabelConfig, _components, specialInstructions);
    };

    const removeComponent = (components, componentId) => {
        for (let i = 0; i < components.length; i++) {
            if (components[i].id === componentId) {
                if (components.length === 1) {
                    components.pop();
                } else {
                    components.splice(i, 1);
                }
                
                break;
            }
        }
    };

    const removeSpecialInstruction = (specialInstructionId) => {
        const _specialInstructions = [...specialInstructions];
        let specialInstruction = getSpecialInstruction(_specialInstructions, specialInstructionId);

        for (let i = 0; i < _specialInstructions.length; i++) {
            if (_specialInstructions[i].id === specialInstructionId) {
                if (_specialInstructions.length === 1) {
                    _specialInstructions.pop();
                } else {
                    _specialInstructions.splice(i, 1);
                }
                break;
            }
        }

        updateSelectedSpecialInstructionTypes(_specialInstructions);
        setSpecialInstructions(_specialInstructions);
        updateCareLabelConfig(careLabelConfig, components, _specialInstructions);
    };

    const updateSelectedMaterials = (component) => {
        component.selectedMaterials = [];

        component.fabricCompositions.forEach(f => {
            if (!isEmpty(f.material)) {
                component.selectedMaterials.push(f.material);
            }
        });
    };

    const getAvailableMaterials = (componentId, material) => {
        const component = getComponent(components, componentId);
        return careLabel.materials.filter(m => m.value === material || !component.selectedMaterials.includes(m.value));
    };

    const updateCareLabelConfig = (careLabelConfig, components, specialInstructions) => {
        let config = {...careLabelConfig};
        config.garmentComponents = [];

        // garment components (fabric composition)
        if (careLabel.fabricCompositionShow) {
            // Fabric compositions will not be sent to backend if customer has configured fabric compositions to be hidden.
            for (let i = 0; i < components.length; i++) {
                let fabricCompositions = [];

                components[i].fabricCompositions.forEach(f => {
                    fabricCompositions.push({
                        percentage: f.percentage,
                        material: f.material
                    });
                });

                config.garmentComponents.push({
                    componentType: components[i].componentType,
                    fabricCompositions: fabricCompositions
                });

                if (!config.garmentComponentsInvalidPercentages && components[i].invalidPercentages) {
                    config.garmentComponentsInvalidPercentages = true;
                }
            }
        }

        // special instructions
        config.specialInstructions = [];
        for (let i = 0; i < specialInstructions.length; i++) {
            config.specialInstructions.push(specialInstructions[i].specialInstructionType);
        }

        onCareLabelChange(config);
    };

    return (
        <div className="product-item care-label card shadow">
            <div className="country-of-origin">
                <h2>Country of Origin {careLabel.countryOfOriginsRequired ? "*" : ""}</h2>
                <div className="form-row">
                    <div className="form-group col-4">
                        <select className="form-control" data-size="10" onChange={e => { onCareLabelFieldChange("countryOfOrigin", e.target.value);
                            setCountryOfOrigin(e.target.value); }} onKeyDown={onKeyDown} value={countryOfOrigin ? countryOfOrigin : ""}
                            required={careLabel.countryOfOriginsRequired}>
                            <option value="" hidden={careLabel.countryOfOriginsRequired}>---</option>
                            {careLabel.countryOfOrigins.map((option, index) =>
                                <option key={index} value={option.value}>{option.displayName}</option>
                            )}
                        </select>
                    </div>
                </div>
            </div>
            <div className="care-instructions">
                <h2>Care Instructions</h2>
                {careLabel.type === CARE_LABEL_TYPE.CARELABEL_STANDARD &&
                    <div className="form-row">
                        <div className="form-group col-4">
                            <label>Wash</label>
                            <select id="care-instruction-wash" className={"form-control selectpicker"} data-size="10" title={"---"} required onChange={e => onCareLabelFieldChange("wash", e.target.value)} onKeyDown={onKeyDown}>
                                {careLabel.washOptions.map((option, index) =>
                                    <option key={index} data-icon={option.icon} value={option.value}>{option.displayName}</option>
                                )}
                            </select>
                        </div>
                        <div className="form-group col-4">
                            <label>Bleach</label>
                            <select id="care-instruction-bleach" className="form-control selectpicker" data-size="10" title={"---"} required onChange={e => onCareLabelFieldChange("bleach", e.target.value)} onKeyDown={onKeyDown}>
                                {careLabel.bleachOptions.map((option, index) =>
                                    <option key={index} data-icon={option.icon} value={option.value}>{option.displayName}</option>
                                )}
                            </select>
                        </div>
                        <div className="form-group col-4">
                            <label>Dry</label>
                            <select id="care-instruction-dry" className="form-control selectpicker" data-size="10" title={"---"} required onChange={e => onCareLabelFieldChange("dry", e.target.value)} onKeyDown={onKeyDown}>
                                {careLabel.dryOptions.map((option, index) =>
                                    <option key={index} data-icon={option.icon} value={option.value}>{option.displayName}</option>
                                )}
                            </select>
                        </div>
                        <div className="form-group col-4">
                            <label>Iron</label>
                            <select id="care-instruction-iron" className="form-control selectpicker" data-size="10" title={"---"} required onChange={e => onCareLabelFieldChange("iron", e.target.value)} onKeyDown={onKeyDown}>
                                {careLabel.ironOptions.map((option, index) =>
                                    <option key={index} data-icon={option.icon} value={option.value}>{option.displayName}</option>
                                )}
                            </select>
                        </div>
                        <div className="form-group col-4">
                            <label>Dry Clean</label>
                            <select id="care-instruction-dryclean" className="form-control selectpicker" data-size="10" title={"---"} required onChange={e => onCareLabelFieldChange("dryClean", e.target.value)} onKeyDown={onKeyDown}>
                                {careLabel.dryCleanOptions.map((option, index) =>
                                    <option key={index} data-icon={option.icon} value={option.value}>{option.displayName}</option>
                                )}
                            </select>
                        </div>
                    </div>
                }
                {careLabel.type === CARE_LABEL_TYPE.CARELABEL_BY_CODE &&
                    <div className="form-row">
                        <div className="form-group col-4">
                            <label>Code</label>
                            <select className="form-control" required onChange={e => { onInstructionsCodeChange(e.target.value); setInstructionCode(e.target.value); }} onKeyDown={onKeyDown} value={instructionCode ? instructionCode : ""}>
                                <option value="" hidden>---</option>
                                {careLabel.instructionCodeOptions.map((option, index) =>
                                    <option key={index} value={option.code}>{option.code}</option>
                                )}
                            </select>
                        </div>
                        <div className="form-group col-1"></div>
                        {instructionCodeText &&
                            <div className="form-group col-6 care-label-instruction-code-text">
                                <div>{instructionCodeText}</div>
                            </div>
                        }
                    </div>
                }
            </div>
            {/* Fabric Composition can be hidden and is configured on the Customer level. */}
            {careLabel.fabricCompositionShow &&
            <div className="fabric-composition">
                <h2>Fabric Composition</h2>
                <div className="tab-content pt-4">
                    <GarmentComponents
                        components={components}
                        getAvailableComponentTypes={getAvailableComponentTypes}
                        onPercentageChange={onPercentageChange}
                        onMaterialChange={onMaterialChange}
                        removeFabricComposition={removeFabricComposition}
                        addFabricComposition={addFabricComposition}
                        onComponentTypeChange={onComponentTypeChange}
                        addComponent={addComponent}
                        getAvailableMaterials={getAvailableMaterials}
                    />
                </div>
            </div>}
            <div className="special-instructions">
                <h2>Special Care Instructions {careLabel.specialInstructionsRequired ? "*" : ""}</h2>
                <SpecialInstructions
                    specialInstructions={specialInstructions}
                    specialInstructionsLimit={careLabel.specialInstructionsLimit}
                    specialInstructionsRequired={careLabel.specialInstructionsRequired}
                    getAvailableSpecialInstructionTypes={getAvailableSpecialInstructionTypes}
                    removeSpecialInstruction={removeSpecialInstruction}
                    addSpecialInstruction={addSpecialInstruction}
                    onSpecialInstructionTypeChange={onSpecialInstructionTypeChange}
                />
            </div>
            <div className="fabric-composition">
                <div className="row">
                    <div className="col-12">
                        <div className="form-row caution-maintenance">
                            {careLabel.copyrightShow &&
                                <div className="form-group col-4">
                                    <label>Copyright</label>
                                    <TextInput
                                        className="form-control"
                                        placeholder="Enter Copyright"
                                        value={copyright}
                                        onChange={value => { onCareLabelFieldChange("copyright", value); setCopyright(value); }}/>
                                </div>
                            }
                            <div className="form-group col-4">
                                <label>Caution Maintenance</label>
                                <select className="form-control" onChange={e => {onCareLabelFieldChange("cautionMaintenance", e.target.value); setCautionMaintenance(e.target.value); }} onKeyDown={onKeyDown} value={cautionMaintenance ? cautionMaintenance : ""}>
                                    <option value="">---</option>
                                    {careLabel.cautionMaintenanceOptions.map((option, index) =>
                                        <option key={index} value={option.value}>{option.displayName}</option>
                                    )}
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
