import React from "react";
import { useCurrentUser } from "hooks/useCurrentUser";

export const RolePermission = ({ roles, children }) => {
  const user = useCurrentUser();
  return user && user.role && roles && roles.includes(user.role) ? (
    children
  ) : (
    <></>
  );
};
