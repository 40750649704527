import * as types from "./messageActionTypes";

const initialState = {
    message: null,
    nextMessage: null,
    overlayMessage: null
};

export const messageReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.SHOW_MESSAGE: {
            const message = {
                message: action.message,
                type: action.messageType,
                retain: action.retain,
                displayed: false
            };
            return { ...state, message: message};
        }
        case types.CLEAR_MESSAGE: {
            return {...state, message: null};
        }
        case types.PUSH_MESSAGE: {
            const message = {
                message: action.message,
                type: action.messageType,
                retain: action.retain
            };
            return {...state, nextMessage: message};
        }
        case types.POP_MESSAGE: {
            if (state.nextMessage) {
                const message = {...state.nextMessage};
                message.displayed = false;
                return {...state, message: message, nextMessage: null};
            }
        }
        case types.SHOW_OVERLAY_MESSAGE: {
            return {...state, overlayMessage: action.message};
        }
        case types.HIDE_OVERLAY_MESSAGE: {
            return {...state, overlayMessage: null};
        }
        default:
            return state;
    }
}
