import * as types from "./orderActionTypes";
import { ORDER_STATUS } from "constants/orderStatus";
import { FINANCE_APPROVAL } from "constants/financeApproval";

const initialState = {
    initialOrderSearchValuesUpdated: false,
    orderSearchValues: {
        orderNumber: "",
        startDate: null,
        endDate: null,
        orderStatus: ORDER_STATUS.OPEN_ORDERS,
        customer: "",
        productionRegion: "",
        company: "",
        financeApproval: FINANCE_APPROVAL.NONE,
        startHighlightDates: [],
        page: 0,
        size: 50
    }
};

export const orderReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.SET_ORDER_SEARCH_VALUES: {
          const data = action.data;
          return { ...state, orderSearchValues: data, initialOrderSearchValuesUpdated: true};
        }
        default:
            return state;
    }
};
