import { combineReducers } from "redux";
import { authReducer } from "store/auth/authReducer";
import { cartReducer } from "./cart/cartReducer";
import { userReducer } from "./user/userReducer";
import { vendorReducer } from "./vendor/vendorReducer";
import { messageReducer } from "./messages/messageReducer";
import { orderReducer } from "./orders/orderReducer";
import * as types from "store/auth/authActionTypes";
import { productReducer } from "./products/productReducer";
import { productCodeReducer } from "./product-codes/productCodeReducer";
import { productSubcodeReducer } from "./product-subcodes/productSubcodeReducer";
import { factoryReducer } from "./factory/factoryReducer";
import { alertReducer } from "./alerts/alertReducer";
import { customerReducer } from "./customer/customerReducer";
import { warehouseReducer } from "./warehouse/warehouseReducer";
import { productionRegionReducer } from "./productionRegion/productionRegionReducer";
import { productItemReducer } from "./productItem/productItemReducer";
import { attributeSchemaReducer } from "./attribute-schema/attributeSchemaReducer";

const appReducer = combineReducers({
  auth: authReducer,
  cart: cartReducer,
  user: userReducer,
  vendor: vendorReducer,
  message: messageReducer,
  orders: orderReducer,
  products: productReducer,
  productCodes: productCodeReducer,
  productSubcodes: productSubcodeReducer,
  factory: factoryReducer,
  alerts: alertReducer,
  customer: customerReducer,
  warehouse: warehouseReducer,
  productionRegion: productionRegionReducer,
  productItem: productItemReducer,
  attributeSchema: attributeSchemaReducer,
});

const rootReducer = (state, action) => {
  if (action.type === types.LOGOUT_USER) {
    state = undefined;
  }

  return appReducer(state, action);
};

export default rootReducer;
