import React from "react";
import { ORDER_STATUS_API } from "constants/orderStatus";
import { NavTab } from "./NavTab";
import { TABS } from "constants/tabs";
import { ROLES } from "constants/roles";

export const NavTabs = ({ orderStatus, role, tabSelected, setTabSelected, tabsShown }) => {

    if (role == ROLES.CSR && orderStatus != ORDER_STATUS_API.NEEDS_ARTWORK){
        setTabSelected(TABS.ALL);
        return <div className="nav-tabs noBorder"></div>; // for proper spacing
    }

    if (role == ROLES.VENDOR && orderStatus != ORDER_STATUS_API.NEEDS_ART_APPROVAL){
        setTabSelected(TABS.ALL);
        return <div className="nav-tabs noBorder"></div>; // for proper spacing
    }

    if (tabsShown){
        return (
            <ul className="nav nav-tabs" role="tablist">
                <li className="nav-item"> <span className="nav-label">View:</span></li>
                <NavTab tabName="All" tabActive={tabSelected == TABS.ALL} setTabSelected={() => setTabSelected(TABS.ALL)} />
                <NavTab tabName="Needs Action" tabActive={tabSelected == TABS.NEEDS_ACTION} setTabSelected={() => setTabSelected(TABS.NEEDS_ACTION)} />
            </ul>
        );
    } else {
        return <div className="nav-tabs noBorder"></div>; // for proper spacing
    }
}
