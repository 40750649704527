import { isEmpty } from "util/common";

export const useTierCalculator = () => {

  const getPriceCalculator = (tiers) => {
    return (quantity) => {
      let _price = 0;
      let i = 0;
      while (!isEmpty(tiers[i]) && quantity >= tiers[i].quantity) {
        _price = tiers[i].price;
        i += 1;
      }

      return _price;
    };
  };

  return getPriceCalculator;
};