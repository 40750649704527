/* eslint-disable no-unused-vars */

// React
import React, { useRef } from "react";

// Local
import { LabelTextField } from "components/LabelTextField";


export const TopLevelProductionRegionInfo = ({
  productionRegion,
  editProductionRegion,
  editProductionRegionField,
  editMode,
  createMode,
  toggleEditMode,
  onSave,
}) => {
  const saveButtonRef = useRef();

  const onInputKeyDown = (e) => {
    if (e.keyCode === 13) {
      e.preventDefault();
      saveButtonRef.current.click();
    }
  };

  return (
    <form
      onKeyDown={onInputKeyDown}
      onSubmit={(e) => {
        e.preventDefault();
        onSave();
      }}
    >
      <div className="card shadow" id="top-level-info">
        <div className="card-body">
          <div className="row" style={{ justifyContent: "flex-end" }}>
            <div className="col">
              <h2 style={{ marginBottom: "0px", marginLeft: "8px" }}>
                {editProductionRegion.productionRegionName}
              </h2>
            </div>
            <button
              className="btn btn-primary btn-sm m-1 mr-4"
              type="button"
              onClick={toggleEditMode}
              hidden={editMode}
            >
              Edit
            </button>
            <button
              ref={saveButtonRef}
              className="btn btn-success btn-sm m-1"
              id="green"
              type="submit"
              hidden={!editMode}
            >
              Save
            </button>
            <button
              className="btn btn-primary btn-sm m-1 mr-4"
              type="button"
              onClick={toggleEditMode}
              hidden={!editMode}
            >
              Cancel
            </button>
          </div>
          <div className="row production-region-info">
            <div className="col-4">
              <div className="production-region-info">
                <ul>
                  <LabelTextField
                    label={"Production Region Name"}
                    value={editProductionRegion.productionRegionName}
                    editMode={editMode}
                    onChange={(value) => editProductionRegionField("productionRegionName", value)}
                    required
                  />
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};
