// React
import React, { useState } from "react";

// Local
import { api } from "services/apiService";
import { EntityTable } from "../shared/EntityTable";
import { isEmpty } from "util/common";
import { EditableListPanel } from "../shared/EditableListPanel";
import {
  CARE_INSTRUCTION_TYPE,
  INSTRUCTION_OPTIONS,
  INSTRUCTION_TYPE_GROUP,
} from "constants/careInstructionType";

export const CareLabelOptions = ({
  countryOfOriginOptions,
  componentTypes,
  materials,
  specialInstructions,
  cautionMaintenanceOptions,
  careCodes,
  careInstructions,
  setCountryOfOriginOptions,
  setComponentTypes,
  setMaterials,
  setSpecialInstructions,
  setCautionMaintenanceOptions,
  setCareCodes,
  setCareInstructions,
  addRefOption,
  updateRefOption,
}) => {
  // state
  const [selectedCountry, setSelectedCountry] = useState("");
  const [selectedComponent, setSelectedComponent] = useState("");
  const [selectedMaterial, setSelectedMaterial] = useState("");
  const [selectedSpecial, setSelectedSpecial] = useState("");
  const [selectedCaution, setSelectedCaution] = useState("");
  const [selectedCode, setSelectedCode] = useState("");
  const [selectedInstruction, setSelectedInstruction] = useState("");

  const [editCountry, setEditCountry] = useState("");
  const [editComponent, setEditComponent] = useState("");
  const [editMaterial, setEditMaterial] = useState("");
  const [editSpecial, setEditSpecial] = useState("");
  const [editCaution, setEditCaution] = useState("");
  const [editCode, setEditCode] = useState("");
  const [editInstruction, setEditInstruction] = useState("");

  const [createCountry, setCreateCountry] = useState("");
  const [createComponent, setCreateComponent] = useState("");
  const [createMaterial, setCreateMaterial] = useState("");
  const [createSpecial, setCreateSpecial] = useState("");
  const [createCaution, setCreateCaution] = useState("");
  const [createCode, setCreateCode] = useState("");
  const [createInstruction, setCreateInstruction] = useState("");

  const [instructionType, setInstructionType] = useState(
    CARE_INSTRUCTION_TYPE.WASH
  );

  const updateOptionField = (entity, setEntity, field, value) => {
    const _entity = { ...entity };
    _entity[field] = value;
    setEntity(_entity);
  };

  const isEntitySelected = (entity, selectedEntity) => {
    return entity && selectedEntity && entity.id === selectedEntity.id;
  };

  const getFieldIfPresent = (entity, field) => {
    return isEmpty(entity) || isEmpty(entity[field]) ? "" : entity[field];
  };

  const customSetCareInstructions = (instructions) => {
    const _careInstructions = { ...careInstructions };
    _careInstructions[INSTRUCTION_TYPE_GROUP[instructionType]] = instructions;
    setCareInstructions(_careInstructions);
  };

  const addOrUpdateOption = (
    editOption,
    createOption,
    entityOptions,
    setEntityOptions,
    addApiCall,
    updateApiCall,
    sortField,
    setFields
  ) => {
    if (isEmpty(editOption)) {
      addRefOption(
        createOption,
        entityOptions,
        setEntityOptions,
        addApiCall,
        sortField
      );
    } else {
      updateRefOption(
        editOption,
        entityOptions,
        setEntityOptions,
        updateApiCall,
        sortField,
        setFields
      );
    }
  };

  const toggleSelectedOption = (
    entity,
    selectedOption,
    setSelectedOption,
    setEditOption
  ) => {
    if (isEntitySelected(entity, selectedOption)) {
      setSelectedOption();
      setEditOption();
    } else {
      setSelectedOption(entity);
      setEditOption({ ...entity });
    }
  };

  return (
    <>
      <div className="row">
        <div className="col-4">
          <EditableListPanel
            title={"Country Of Origin Options"}
            placeholder={"Enter a Country of Origin"}
            options={countryOfOriginOptions}
            selectedOption={selectedCountry}
            editOption={editCountry}
            createOption={createCountry}
            setOptions={setCountryOfOriginOptions}
            setSelectedOption={setSelectedCountry}
            setEditOption={setEditCountry}
            setCreateOption={setCreateCountry}
            apiAddCall={api.addCountryOfOriginOption}
            apiUpdateCall={api.updateCountryOfOriginOption}
            addOrUpdateOption={addOrUpdateOption}
          />
        </div>
        <div className="col-4">
          <EditableListPanel
            title={"Caution Maintenance Options"}
            placeholder={"Enter a Caution Mainenance Option"}
            options={cautionMaintenanceOptions}
            selectedOption={selectedCaution}
            editOption={editCaution}
            createOption={createCaution}
            setOptions={setCautionMaintenanceOptions}
            setSelectedOption={setSelectedCaution}
            setEditOption={setEditCaution}
            setCreateOption={setCreateCaution}
            apiAddCall={api.addCautionMaintenanceOption}
            apiUpdateCall={api.updateCautionMaintenanceOption}
            addOrUpdateOption={addOrUpdateOption}
          />
        </div>
        <div className="col-4">
          <EditableListPanel
            title={"Special Instruction Options"}
            placeholder={"Enter a Special Instruction"}
            options={specialInstructions}
            selectedOption={selectedSpecial}
            editOption={editSpecial}
            createOption={createSpecial}
            setOptions={setSpecialInstructions}
            setSelectedOption={setSelectedSpecial}
            setEditOption={setEditSpecial}
            setCreateOption={setCreateSpecial}
            apiAddCall={api.addSpecialInstructionOption}
            apiUpdateCall={api.updateSpecialInstructionOption}
            addOrUpdateOption={addOrUpdateOption}
          />
        </div>
      </div>
      <div className="row mt-3">
        <div className="col-6">
          <EditableListPanel
            title={"Component Type Options"}
            placeholder={"Enter a Component Type"}
            options={componentTypes}
            selectedOption={selectedComponent}
            editOption={editComponent}
            createOption={createComponent}
            setOptions={setComponentTypes}
            setSelectedOption={setSelectedComponent}
            setEditOption={setEditComponent}
            setCreateOption={setCreateComponent}
            apiAddCall={api.addComponentTypeOption}
            apiUpdateCall={api.updateComponentTypeOption}
            addOrUpdateOption={addOrUpdateOption}
          />
        </div>
        <div className="col-6">
          <EditableListPanel
            title={"Material Type Options"}
            placeholder={"Enter a Material Type"}
            options={materials}
            selectedOption={selectedMaterial}
            editOption={editMaterial}
            createOption={createMaterial}
            setOptions={setMaterials}
            setSelectedOption={setSelectedMaterial}
            setEditOption={setEditMaterial}
            setCreateOption={setCreateMaterial}
            apiAddCall={api.addMaterialOption}
            apiUpdateCall={api.updateMaterialOption}
            addOrUpdateOption={addOrUpdateOption}
          />
        </div>
      </div>
      <div className="row mt-3">
        {/* Care Codes */}
        <div className="col-6">
          <label className="mt-1">Care Codes</label>
          <div className="row entity-buttons">
            <input
              style={{ width: "35%" }}
              className="form-control price-field"
              type="text"
              placeholder="Enter a Care Code"
              value={getFieldIfPresent(
                isEmpty(selectedCode) ? createCode : editCode,
                "code"
              )}
              onChange={(e) =>
                isEmpty(selectedCode)
                  ? updateOptionField(
                      createCode,
                      setCreateCode,
                      "code",
                      e.target.value
                    )
                  : updateOptionField(
                      editCode,
                      setEditCode,
                      "code",
                      e.target.value
                    )
              }
            ></input>
            <input
              style={{ width: "63%" }}
              className="form-control price-field"
              type="text"
              placeholder="Enter a Description"
              value={getFieldIfPresent(
                isEmpty(selectedCode) ? createCode : editCode,
                "text"
              )}
              onChange={(e) =>
                isEmpty(selectedCode)
                  ? updateOptionField(
                      createCode,
                      setCreateCode,
                      "text",
                      e.target.value
                    )
                  : updateOptionField(
                      editCode,
                      setEditCode,
                      "text",
                      e.target.value
                    )
              }
            ></input>
            <button
              style={{ width: "100%", marginTop: "0.5rem", height: "2.3rem"}}
              className={"btn btn-primary add-tier-btn"}
              onClick={() =>
                addOrUpdateOption(
                  editCode,
                  createCode,
                  careCodes,
                  setCareCodes,
                  api.addCareCodeOption,
                  api.updateCareCode,
                  "code",
                  ["code", "text"]
                )
              }
              disabled={
                isEmpty(selectedCode)
                  ? isEmpty(createCode?.code) || isEmpty(createCode?.text)
                  : isEmpty(editCode?.code) || isEmpty(editCode?.text)
              }
            >
              {isEmpty(selectedCode) ? "Add" : "Update"}
            </button>
          </div>
          <EntityTable
            entities={careCodes}
            columns={["Code", "Description"]}
            fields={["code", "text"]}
            selectedEntity={selectedCode}
            onClick={(entity) =>
              toggleSelectedOption(
                entity,
                selectedCode,
                setSelectedCode,
                setEditCode
              )
            }
            showHead={true}
          />
        </div>
        {/* Care Instructions */}
        <div className="col-6">
          <label className="mt-1">Care Instructions</label>
          <div className="row entity-buttons">
            <select
              style={{ width: "20%" }}
              className="form-control"
              onChange={(e) => {
                setSelectedInstruction("");
                setInstructionType(e.target.value);
              }}
              value={instructionType}
            >
              {INSTRUCTION_OPTIONS.map((option, index) => (
                <option key={index} value={option.value}>
                  {option.text}
                </option>
              ))}
            </select>
            <input
              style={{ width: "30%" }}
              className="form-control price-field"
              type="text"
              placeholder="Enter an Instruction"
              value={getFieldIfPresent(
                isEmpty(selectedInstruction)
                  ? createInstruction
                  : editInstruction,
                "displayName"
              )}
              onChange={(e) =>
                isEmpty(selectedInstruction)
                  ? updateOptionField(
                      createInstruction,
                      setCreateInstruction,
                      "displayName",
                      e.target.value
                    )
                  : updateOptionField(
                      editInstruction,
                      setEditInstruction,
                      "displayName",
                      e.target.value
                    )
              }
            ></input>
            <input
              style={{ width: "24%" }}
              className="form-control price-field"
              type="text"
              placeholder="Enter an Icon Name"
              value={getFieldIfPresent(
                isEmpty(selectedInstruction)
                  ? createInstruction
                  : editInstruction,
                "icon"
              )}
              onChange={(e) =>
                isEmpty(selectedCode)
                  ? updateOptionField(
                      createInstruction,
                      setCreateInstruction,
                      "icon",
                      e.target.value
                    )
                  : updateOptionField(
                      editInstruction,
                      setEditInstruction,
                      "icon",
                      e.target.value
                    )
              }
            ></input>
            <input
              style={{ width: "22%" }}
              className="form-control price-field"
              type="number"
              min={0}
              step={1}
              placeholder="Enter a Sort Key"
              value={getFieldIfPresent(
                isEmpty(selectedInstruction)
                  ? createInstruction
                  : editInstruction,
                "sortKey"
              )}
              onChange={(e) =>
                isEmpty(selectedInstruction)
                  ? updateOptionField(
                      createInstruction,
                      setCreateInstruction,
                      "sortKey",
                      e.target.value
                    )
                  : updateOptionField(
                      editInstruction,
                      setEditInstruction,
                      "sortKey",
                      e.target.value
                    )
              }
            ></input>
            <button
              style={{ width: "100%", marginTop: "0.5rem", height: "2.3rem" }}
              className={"btn btn-primary add-tier-btn"}
              onClick={() => {
                const _createInstruction = { ...createInstruction };
                _createInstruction.type = instructionType;

                addOrUpdateOption(
                  editInstruction,
                  _createInstruction,
                  careInstructions[INSTRUCTION_TYPE_GROUP[instructionType]],
                  customSetCareInstructions,
                  api.addCareInstructionOption,
                  api.updateCareInstructionOption,
                  "sortKey",
                  ["displayName", "icon", "sortKey"]
                );
              }}
              disabled={
                isEmpty(selectedInstruction)
                  ? isEmpty(createInstruction?.displayName) ||
                    isEmpty(createInstruction?.icon) ||
                    isEmpty(createInstruction?.sortKey)
                  : isEmpty(editInstruction?.displayName) ||
                    isEmpty(editInstruction?.icon) ||
                    isEmpty(editInstruction?.sortKey)
              }
            >
              {isEmpty(selectedInstruction) ? "Add" : "Update"}
            </button>
          </div>
          <EntityTable
            entities={careInstructions[INSTRUCTION_TYPE_GROUP[instructionType]]}
            columns={["Description", "Icon Name", "Sort Key"]}
            fields={["displayName", "icon", "sortKey"]}
            selectedEntity={selectedInstruction}
            onClick={(entity) =>
              toggleSelectedOption(
                entity,
                selectedInstruction,
                setSelectedInstruction,
                setEditInstruction
              )
            }
            showHead={true}
          />
        </div>
      </div>
    </>
  );
};
