import React from "react";
import { Link } from "react-router-dom";

export const SpecialInstruction = ({ specialInstruction, isLimitOne, isOnlyComponent, getAvailableSpecialInstructionTypes, removeSpecialInstruction, onSpecialInstructionTypeChange, specialInstructionsRequired }) => {
    const onKeyDown = (e) => {
        if (e.keyCode === 13) {
            e.preventDefault();
            e.target.blur();
        }
    };
    return (
        <li className="special-instruction" key={specialInstruction.id}>
            <div className="form-row">
                <div className="form-group col-4">
                    <select key={specialInstruction.id} className="garment-component form-control"
                        onChange={e => onSpecialInstructionTypeChange(specialInstruction.id, e.target.value)}
                        onKeyDown={onKeyDown} value={specialInstruction.specialInstructionType}
                        required={specialInstructionsRequired}>
                        <option value="" hidden={specialInstructionsRequired}>---</option>
                        {getAvailableSpecialInstructionTypes(specialInstruction.specialInstructionType).map((option, index) =>
                            <option key={index} value={option.value}>{option.displayName}</option>
                        )}
                    </select>
                </div>
                <div className={"col-1" + (isOnlyComponent ? " isHidden" : "" )}>
                    <Link to="#" className="remove" data-toggle="tab" onClick={() => removeSpecialInstruction(specialInstruction.id)}><i className="fal fa-times-square fa-2x"></i></Link>
                </div>
            </div>
        </li>
    );
};
