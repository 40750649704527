export const ORDER_STATUS_ENUM = Object.freeze({
    NEEDS_ARTWORK: 1,
    NEEDS_ART_APPROVAL: 2,
    PRODUCTION_READY: 3,
    PRODUCTION_CONFIRMED: 4,
    READY_TO_SHIP: 5,
    COMPLETE_AND_SHIPPED: 6
});

export const ORDER_STATUS = Object.freeze({
    NONE: "NONE",
    OPEN_ORDERS: "OPEN_ORDERS",
    PENDING: "PENDING",
    PLACED: "PLACED",
    ART_PREPARED: "ART_PREPARED",
    ART_APPROVED: "ART_APPROVED",
    PRODUCTION_CONFIRMED: "PRODUCTION_CONFIRMED",
    SHIPPED: "SHIPPED",
    CANCELLED: "CANCELLED"
});

export const ORDER_STATUS_OPTIONS = [
    {value: ORDER_STATUS.NONE, text: "---"},
    {value: ORDER_STATUS.OPEN_ORDERS, text: "Open Orders"},
    {value: ORDER_STATUS.PLACED, text: "Placed"},
    {value: ORDER_STATUS.ART_PREPARED, text: "Art Prepared"},
    {value: ORDER_STATUS.ART_APPROVED, text: "Art Approved"},
    {value: ORDER_STATUS.PRODUCTION_CONFIRMED, text: "Production Confirmed"},
    {value: ORDER_STATUS.SHIPPED, text: "Shipped"},
    {value: ORDER_STATUS.CANCELLED, text: "Cancelled"}
];

export const ORDER_STATUS_PARAM_VALUE = Object.freeze({
    NONE: null,
    OPEN_ORDERS: "READY_TO_SHIP",
    PLACED: "NEEDS_ARTWORK",
    ART_PREPARED: "NEEDS_ART_APPROVAL",
    ART_APPROVED: "PRODUCTION_READY",
    PRODUCTION_CONFIRMED: "PRODUCTION_CONFIRMED",
    SHIPPED: "COMPLETE_AND_SHIPPED"
});

export const ORDER_STATUS_API = Object.freeze({
    PENDING: "PENDING",
    NEEDS_ARTWORK: "NEEDS_ARTWORK",
    NEEDS_ART_APPROVAL: "NEEDS_ART_APPROVAL",
    PRODUCTION_READY: "PRODUCTION_READY", 
    PRODUCTION_CONFIRMED: "PRODUCTION_CONFIRMED",
    READY_TO_SHIP: "READY_TO_SHIP",
    COMPLETE_AND_SHIPPED: "COMPLETE_AND_SHIPPED"
});

export const ORDER_STATUS_PRODUCT = [
    {value: "PLACED", text: "PO Placed"},
    {value: "UPLOADED", text: "PO Uploaded"},
    {value: "SHIPPED", text: "PO Shipped"}
];
