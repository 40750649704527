import React from "react";
import { useURLNavigate } from "hooks/useURLNavigate";
import { joinParams } from "components/joinParams";
import { PAGE_URL } from "constants/url";

export const StandardProductRow = ({ product }) => {
  const navigate = useURLNavigate();

  const onClick = () => {
    const params = joinParams({
      productItemId: product.productItemId
    });
    navigate(PAGE_URL.PRODUCT_DETALS, params);
  };

  return (
    <tr className="standard-product-row" onClick={onClick}>
      <td className="standard-product-item">{product.productSubcode}</td>
      <td>{product.description}</td>
    </tr>
  );
};
