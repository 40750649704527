// React
import React, { useRef, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

// Local
import api from "services/apiService";
import { setFactorySearchValues } from "store/factory/factoryActions";
import { isEmpty, isNullOrUndefined } from "util/common";
import { useError } from "hooks/errors";

export const FactorySearch = ({ onSearch }) => {
  // hooks
  const dispatch = useDispatch();
  const searchValues = useSelector((state) => state.factory.searchValues);
  const searchButtonRef = useRef(null);
  const filterRef = useRef(null);
  const [showError] = useError();

  // state
  const [customers, setCustomers] = useState([]);
  const [regions, setRegions] = useState([]);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    loadData();
  }, []);

  useEffect(() => {
    if (loaded) {
      filterRef.current.click();
      searchButtonRef.current.click();
    }
  }, [loaded]);

  const loadData = async () => {
    try {
      const customersResponse = await api.getAllCustomers();
      const regionsResponse = await api.getAllRegions();

      let _customers = customersResponse.data.customers;
      let _regions = regionsResponse.data.regions;

      // add to beginning of array
      let none = { id: "", name: "---" };
      _customers.unshift(none);
      _regions.unshift(none);

      setCustomers(_customers);
      setRegions(_regions);
      setLoaded(true);
    } catch (error) {
      showError(error);
    }
  };

  const updateSearchValue = (name, value) => {
    const _searchValues = { ...searchValues };
    _searchValues[name] = value;

    dispatch(setFactorySearchValues(_searchValues));
  };

  const onInputKeyDown = (e) => {
    if (e.keyCode === 13) {
      e.preventDefault();
      searchButtonRef.current.click();
    }
  };

  return (
    <form onKeyDown={onInputKeyDown}>
      <a
        className="toggleBTN collapsed"
        data-toggle="collapse"
        href="#searchForm"
        role="button"
        aria-expanded="false"
        aria-controls="searchForm"
        ref={filterRef}
      >
        <span id="ToggleIcon"></span>Filter
      </a>
      <div className="collapse rounded" id="searchForm">
        <div className="searchForm">
          <div className="form-row form-group">
            <div className="col-3 col-left-none">
              <label>Production Factory</label>
              <input
                type="text"
                className="form-control"
                placeholder="Enter Factory Name"
                value={isNullOrUndefined(searchValues) ? "" : searchValues.factoryName}
                onChange={(e) => updateSearchValue("factoryName", e.target.value)}
              />
            </div>
            <div className="col-3">
            <label>Short Code</label>
              <input
                type="text"
                className="form-control"
                placeholder="Enter Factory Short Code"
                value={isNullOrUndefined(searchValues) ? "" : searchValues.shortCode}
                onChange={(e) => updateSearchValue("shortCode", e.target.value)}
              />
            </div>
            <div className="col-3">
              <label>Region</label>
              <select
                className="form-control"
                onChange={(e) =>
                  updateSearchValue("regionId", e.target.value)
                }
                value={
                  isNullOrUndefined(searchValues) ? "" : searchValues.regionId
                }
              >
                {regions.map((r, index) => (
                  <option key={index} value={r.id}>
                    {r.name}
                  </option>
                ))}
              </select>
            </div>
            <div className="col-3">
              <label>Customer</label>
              <select
                className="form-control"
                onChange={(e) =>
                  updateSearchValue("customerId", e.target.value)
                }
                value={
                  isNullOrUndefined(searchValues) ? "" : searchValues.customerId
                }
              >
                {customers.map((c, index) => (
                  <option key={index} value={c.id}>
                    {c.name}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="form-row">
            <div className="col-3 offset-9" id="searchButtonCol">
              <button
                ref={searchButtonRef}
                className="searchBTN btn btn-block btn-outline-primary"
                type="button"
                onClick={() =>
                  onSearch(isEmpty(searchValues) ? {} : searchValues)
                }
              >
                Search
              </button>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};
