import React from "react";
import { LabelValue } from "./LabelValue";
import { formatDateForUI } from "./formatDate";

export const LabelDate = ({ label, date, labelClassName, optionalDefaultValue }) => {
  return (
      <LabelValue
          label={label}
          value={date ? formatDateForUI(date) : (optionalDefaultValue ? optionalDefaultValue : null)}
          labelClassName={labelClassName ? labelClassName : ""}
      />
  );
};
