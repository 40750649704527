import React from "react";
import { SpecialInstruction } from "./SpecialInstruction";
import { Link } from "react-router-dom";

export const SpecialInstructions = ({ specialInstructions, specialInstructionsLimit, getAvailableSpecialInstructionTypes, removeSpecialInstruction, addSpecialInstruction, onSpecialInstructionTypeChange, specialInstructionsRequired }) => {
    return (
        <div className="tab-content pt-4">
            <ul>
                {specialInstructions.map((c, index) =>
                    <SpecialInstruction
                        key={index}
                        isLimitOne={specialInstructionsLimit === 1}
                        specialInstruction={c}
                        isOnlyComponent={specialInstructions.length === 1}
                        getAvailableSpecialInstructionTypes={getAvailableSpecialInstructionTypes}
                        removeSpecialInstruction={removeSpecialInstruction}
                        onSpecialInstructionTypeChange={onSpecialInstructionTypeChange}
                        specialInstructionsRequired={specialInstructionsRequired}
                    />
                )}
                <li className="special-instruction">
                    <div className="form-row">
                        <div className="col-4"></div>
                        <div className="col-3">
                            <Link to="#" className="add add-for-instruction" data-toggle="tab" onClick={() => addSpecialInstruction()}><i className="fal fa-plus-square fa-2x"></i>Add Special Instruction</Link>
                        </div>
                    </div>
                </li>
            </ul>
        </div>
    );
};
