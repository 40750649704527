// React
import React, { useState, useEffect } from "react";

// Local
import { isEmpty } from "util/common";
import { EntityTable } from "modules/care-label/shared/EntityTable";
import {getRole} from "../../services/authService";
import {ROLES} from "../../constants/roles";

export const VendorRelationships = ({
  editVendor,
  updateVendorEntity,
  editMode,
  customers,
  selectDeselectAll,
  addShippingAddress,
  updateShippingAddress,
  removeShippingAddress,
}) => {
  // state
  const [selectedAddress, setSelectedAddress] = useState("");
  const [editAddress, setEditAddress] = useState("");
  const [createAddress, setCreateAddress] = useState("");

  useEffect(() => {
    if (!editMode) {
      setSelectedAddress("");
      setEditAddress("");
      setCreateAddress("");
    }
  }, [editMode]);

  // methods
  const getSelectLabel = (items, itemsSelected) =>
    items.length == itemsSelected.length ? "Deselect All" : "Select All";

  const getFieldIfPresent = (entity, field) => {
    return isEmpty(entity) || isEmpty(entity[field]) ? "" : entity[field];
  };

  const updateOptionField = (entity, setEntity, field, value) => {
    const _entity = { ...entity };
    _entity[field] = value;
    setEntity(_entity);
  };

  const addOrUpdateOption = () => {
    if (isEmpty(editAddress)) {
      addShippingAddress(createAddress);
    } else {
      updateShippingAddress(selectedAddress, editAddress);
    }
  };

  const onClickRemove = () => {
    setEditAddress("");
    setSelectedAddress("");
    setCreateAddress("");
    removeShippingAddress(selectedAddress);
  };

  const toggleSelectedOption = (
    option,
    selectedOption,
    setSelectedOption,
    setEditOption
  ) => {
    if (isEntitySelected(option, selectedOption)) {
      setSelectedOption();
      setEditOption();
    } else {
      setSelectedOption(option);
      setEditOption({ ...option });
    }
  };

  const isEntitySelected = (entity, selectedEntity) => {

    return (
      entity &&
      selectedEntity &&
      entity.optionName === selectedEntity.optionName &&
      entity.address === selectedEntity.address
    );
  };

  return (
    <div className="row entity-list">
      {/* Shipping Addresses */}
      <div className={getRole() !== ROLES.VENDOR ? "col-6" : "card-body"}>
        <label className="mt-1">Shipping Addresses</label>
        <div className="row entity-buttons">
          <input
            style={{ width: "35%" }}
            className="form-control price-field"
            type="text"
            placeholder="Enter a short name"
            value={getFieldIfPresent(
              isEmpty(selectedAddress) ? createAddress : editAddress,
              "optionName"
            )}
            onChange={(e) =>
              isEmpty(selectedAddress)
                ? updateOptionField(
                    createAddress,
                    setCreateAddress,
                    "optionName",
                    e.target.value
                  )
                : updateOptionField(
                    editAddress,
                    setEditAddress,
                    "optionName",
                    e.target.value
                  )
            }
            disabled={!editMode}
          ></input>
          <input
            style={{ width: "63%" }}
            className="form-control price-field"
            type="text"
            placeholder="Enter the full address"
            value={getFieldIfPresent(
              isEmpty(selectedAddress) ? createAddress : editAddress,
              "address"
            )}
            onChange={(e) =>
              isEmpty(selectedAddress)
                ? updateOptionField(
                    createAddress,
                    setCreateAddress,
                    "address",
                    e.target.value
                  )
                : updateOptionField(
                    editAddress,
                    setEditAddress,
                    "address",
                    e.target.value
                  )
            }
            disabled={!editMode}
          ></input>
          <button
            style={{
              width: "49%",
              marginTop: "0.5rem",
              marginRight: "0.1rem",
              height: "2.3rem",
            }}
            className={"btn btn-primary add-tier-btn"}
            onClick={addOrUpdateOption}
            disabled={
              isEmpty(selectedAddress)
                ? isEmpty(createAddress?.optionName) ||
                  isEmpty(createAddress?.address)
                : isEmpty(editAddress?.optionName) ||
                  isEmpty(editAddress?.address)
            }
          >
            {isEmpty(selectedAddress) ? "Add" : "Update"}
          </button>
          <button
            style={{
              width: "49%",
              marginTop: "0.5rem",
              marginLeft: "0.1rem",
              height: "2.3rem",
            }}
            className={"btn btn-danger add-tier-btn"}
            onClick={onClickRemove}
            disabled={isEmpty(selectedAddress)}
          >
            Remove
          </button>
        </div>
        <EntityTable
          entities={editVendor.shippingAddresses}
          columns={["Short Name", "Address"]}
          fields={["optionName", "address"]}
          selectedEntity={selectedAddress}
          onClick={(entity) =>
            toggleSelectedOption(
              entity,
              selectedAddress,
              setSelectedAddress,
              setEditAddress
            )
          }
          showHead={true}
          disableRows={!editMode}
          isEntitySelectedCustom={isEntitySelected}
        />
      </div>
      {getRole() !== ROLES.VENDOR ? <div className="col-6">
        {/* Customers */}
        <label>Customers</label>
        <button
          style={{ marginTop: "0.6rem" }}
          className="btn btn-secondary select-all-btn"
          disabled={!editMode}
          onClick={() => selectDeselectAll("customers", customers)}
        >
          {getSelectLabel(customers, editVendor.customers)}
        </button>
        <div className="scroller">
          {editVendor &&
            editVendor.customers &&
            customers &&
            customers.map((c, index) => (
              <div className="item" key={index}>
                <input
                  key={index}
                  disabled={!editMode}
                  type="checkbox"
                  checked={editVendor.customers.map((c) => c.id).includes(c.id)}
                  onClick={() => updateVendorEntity("customers", c)}
                  className="checkbox"
                />
                {c.name}
              </div>
            ))}
        </div>
      </div> : <></>}
    </div>
  );
};
