// React
import React from "react";
import { useURLNavigate } from "hooks/useURLNavigate";
import { joinParams } from "components/joinParams";
import { PAGE_URL } from "constants/url";

export const FactoryRow = ({ factory }) => {
  const navigate = useURLNavigate();

  const onClick = () => {
    const params = joinParams({
      id: factory.id,
    });
    navigate(PAGE_URL.FACTORY_DETAILS, params);
  };

  return (
    <tr className="pf-row" onClick={onClick}>
      <td className="data">{factory.name}</td>
      <td className="data">{factory.shortCode}</td>
      <td className="data">{factory.productionRegion.name}</td>
      <td className="data">{factory.customers.map(c => c.name).join(", ")}</td>
    </tr>
  );
};
