import React from "react";
import { isTrue } from "util/common";

export const TextArea = ({ onChange, allowEnter, ...rest }) => {

    const onKeyDown = (e) => {
        if (!isTrue(allowEnter) && e.keyCode === 13) {
            e.preventDefault();
            e.target.blur();
        }
    };

    return <textarea
        {...rest}
        onChange={e => onChange(e.target.value)}
        onKeyDown={onKeyDown}
    />
};
