// User
export const EMAIL_ENABLED = [
  { value: true, text: "Enabled" },
  { value: false, text: "Disabled" },
];

export const USER_ACTIVE = [
  { value: true, text: "Active" },
  { value: false, text: "Inactive" },
];

// Product Subcode
export const SUSTAINABLE_OPTIONS = [
  { value: "", text: "---" },
  { value: true, text: "Yes" },
  { value: false, text: "No" },
];

export const optionFromNameAndId = (nameAndId) => {
  return {
    value: nameAndId.id,
    text: nameAndId.name,
  };
};

export const getNameAndIdFromList = (list, id) => {
  for (let nameAndId of list) {
    if (nameAndId.id == id) {
      return nameAndId;
    }
  }
};

export const SHOW_OPTIONS = [
  { value: true, text: "Show" },
  { value: false, text: "Don't show" },
];

// Attribute Field Type Options
export const ATTRIBUTE_FIELD_TYPE_OPTIONS = [
  { value: "STRING", text: "String" },
  { value: "DROPDOWN", text: "Dropdown" },
];
