/* eslint-disable no-trailing-spaces */
/* eslint-disable no-unused-vars */

// React
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

// Local
import { api } from "../../services/apiService";
import { PageLayout } from "layouts/PageLayout";
import { useURLNavigate } from "hooks/useURLNavigate";
import { useURLParams } from "hooks/useURLParams";
import { useOverlay } from "hooks/useOverlay";
import { useError } from "hooks/errors";
import { PAGE_URL } from "constants/url";
import { isEmpty, isNullOrUndefined } from "util/common";
import {
  showSuccessMessage,
  pushSuccessMessage,
} from "store/messages/messageActions";
import { cloneDeep } from "lodash";
import { TopLevelFactoryInfo } from "./TopLevelFactoryInfo";
import { FactoryRelationships } from "./FactoryRelationships";

export const FactoryDetailPage = () => {

  // hooks
  const navigate = useURLNavigate();
  const [showError, pushError] = useError();
  const [getParam] = useURLParams();
  const [showOverlay, hideOverlay] = useOverlay();
  const dispatch = useDispatch();
  const message = useSelector((state) => state.message.message);

  // state
  const [editMode, setEditMode] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [customers, setCustomers] = useState();
  const [regions, setRegions] = useState();
  const [currencies, setCurrencies] = useState();

  const [factory, setFactory] = useState();
  const [editFactory, setEditFactory] = useState();

  // url variables
  const factoryId = getParam("id");
  const createMode = getParam("createMode");

  useEffect(() => {
    loadData();
  }, []);

  const loadData = async () => {
    // handle url hack
    if (createMode && !isEmpty(factoryId)) {
      pushError("Invalid URL params");
      navigate(PAGE_URL.USERS);
    }

    if (!isEmpty(factoryId)) {
      try {
        const userResponse = await api.getFactory(factoryId);

        setFactory(cloneDeep(userResponse.data));
        setEditFactory(cloneDeep(userResponse.data));
      } catch (error) {
        pushError(error);
        navigate(PAGE_URL.HOME);
      }
    }

    if (createMode) {
      // setup new factory
      setEditMode(true);
      setEditFactory({
        customers: [],
        currencyType: { name: "CAD", id: 1 },
        productionRegion: { name: "Bangladesh", id: 1 },
        name: "",
        shortCode: "",
        address: "",
        telephone: "",
      });
    }

    if (createMode || !isEmpty(factoryId)) {
      try {
        const customersResponse = await api.getAllCustomers();
        const regionsResponse = await api.getAllRegions();
        const currencyResponse = await api.getCurrencies();

        setCustomers(customersResponse.data.customers);
        setRegions(regionsResponse.data.regions);
        setCurrencies(currencyResponse.data.refs);
        setLoaded(true);
      } catch (error) {
        pushError(error);
        navigate(PAGE_URL.HOME);
      }
    }
  };

  const toggleEditMode = () => {
    if (createMode) {
      navigate(PAGE_URL.FACTORY);
    } else if (editMode) {
      setEditFactory(cloneDeep(factory)); // revert changes
      setEditMode(false);
    } else {
      setEditMode(true);
    }
  };

  const onSave = async () => {
    if (isNullOrUndefined(message)) {
      await showOverlay("Saving...");
    }

    if (createMode) {
      try {
        await api.createFactory(editFactory);
        hideOverlay();
        dispatch(pushSuccessMessage("Factory Successfully Created"));
        navigate(PAGE_URL.FACTORY);
      } catch (error) {
        hideOverlay();
        showError(error);
      }
    } else {
      try {
        await api.updateFactory(editFactory);
        setFactory(cloneDeep(editFactory));
        dispatch(showSuccessMessage("Factory Successfully Updated"));
      } catch (error) {
        showError(error);
        setEditFactory(factory);
      } finally {
        setEditMode(false);
        hideOverlay();
      }
    }
  };

  const editFactoryField = (field, value) => {
    let _editFactory = { ...editFactory };
    _editFactory[field] = value;
    setEditFactory(_editFactory);
  };

  const updateFactoryEntity = (field, entity) => {
    let _editFactory = { ...editFactory };

    if (
      _editFactory[field] &&
      _editFactory[field].map((e) => e.id).includes(entity.id)
    ) {
      _editFactory[field] = _editFactory[field].filter((e) => e.id != entity.id);
    } else {
      _editFactory[field].push(entity);
    }

    setEditFactory(_editFactory);
  };

  const selectDeselectAll = (field, entityList) => {
    let _editUser = { ...editFactory };

    if (_editUser[field].length === entityList.length) {
      // remove all
      _editUser[field] = [];
    } else {
      // add all
      _editUser[field] = cloneDeep(entityList);
    }

    setEditFactory(_editUser);
  };

  return (
    <PageLayout className="factory-details">
    <section className="site-content bodyWrap" id="content">
      <section className="content-body">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h4 className="PageTitle">
                <Link className="page-parent" to={PAGE_URL.FACTORY}>
                  FACTORIES
                </Link>{" "}
                &ndash; <span className="page-current">FACTORY DETAILS</span>
              </h4>
            </div>
          </div>
          {loaded && (
            <div className="row">
              <div className="col-12">
                <TopLevelFactoryInfo
                  editFactory={editFactory}
                  editFactoryField={editFactoryField}
                  editMode={editMode}
                  toggleEditMode={toggleEditMode}
                  regions={regions}
                  currencies={currencies}
                  onSave={onSave}
                />
              </div>
            </div>
          )}
          {loaded && (
            <div className="row">
              <div className="col-12">
                <div className="card shadow" id="top-level-info">
                  <div className="card-body">
                    <FactoryRelationships
                      editFactory={editFactory}
                      updateFactoryEntity={updateFactoryEntity}
                      editMode={editMode}
                      customers={customers}
                      selectDeselectAll={selectDeselectAll}
                    />
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </section>
    </section>
  </PageLayout>
  );
};