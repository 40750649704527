import React from "react";
import { isEmpty } from "util/common";

export const SelectorCheckList = ({
  title,
  lists,
  onChangeList,
  selectedList,
  options,
  listOptions,
  onClickOption,
  optionNameField = "name",
  secondaryLists,
  secondaryOnChangeList,
  secondarySelectedList,
  secondaryValueField,
  secondaryTextField,
  style,
  checkListDisabled = false
}) => {

  return (
    <div style={style}>
      {title && <label>{title}</label>}
      {lists && <select
        className="form-control mb-3"
        onChange={(e) => onChangeList(e.target.value)}
        value={selectedList.id}
      >
        {lists.map((list, index) => (
          <option key={index} value={list.id}>
            {list.name}
          </option>
        ))}
      </select>}
      {secondaryLists && <select
        className="form-control mb-3"
        onChange={(e) => secondaryOnChangeList(e.target.value)}
        value={secondarySelectedList[secondaryValueField]}
      >
        {secondaryLists.map((list, index) => (
          <option key={index} value={list[secondaryValueField]}>
            {list[secondaryTextField]}
          </option>
        ))}
      </select>}
      <div className="scroller">
        {options.map((o, index) => (
          <div className={"item" + (checkListDisabled ? " disabled" : "")} key={index}>
            <input
              key={index}
              className="checkbox"
              type="checkbox"
              checked={
                !isEmpty(
                  listOptions.find((current) => current.id === o.id)
                )
              }
              onClick={() => onClickOption(o)}
              readOnly={true}
              disabled={checkListDisabled}
            />
            {o[optionNameField]}
          </div>
        ))}
      </div>
    </div>
  );
};
