import React, { useState } from "react";

/*
* Only Standard items have inventory.
*/

export const InventoryField = ({
  defaultInventory,
  onChange,
  onBlur,
  className,
  ...rest
}) => {
  const [inventory, setInventory] = useState(defaultInventory);

  const onInventoryChange = (inventory) => {
    const q = inventory.replace(/(?!-)[^0-9.]/g, "");
    setInventoryOnChange(q);
  };

  const onInventoryBlur = () => {
    var intInventory = parseInt(inventory);
    setInventory(intInventory);

    if (onBlur) {
      onBlur(intInventory);
    }
  };

  const setInventoryOnChange = (inventory) => {
    setInventory(inventory);

    if (onChange) {
      onChange(inventory);
    }
  };

  const onKeyDown = (e) => {
    if (e.keyCode === 13) {
      e.preventDefault();
      e.target.blur();
    }
  };

  return (
    <input
      {...rest}
      className={className + " form-control"}
      type="text"
      value={inventory}
      onChange={(e) => onInventoryChange(e.target.value)}
      onBlur={onInventoryBlur}
      onKeyDown={onKeyDown}
    />
  );
};
