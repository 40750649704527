export const FINANCE_APPROVAL = Object.freeze({
    NONE: "NONE",
    APPROVED: "APPROVED",
    NOT_APPROVED: "NOT_APPROVED"
});

export const FINANCE_APPROVAL_OPTIONS = [
    {value: FINANCE_APPROVAL.NONE, text: "---"},
    {value: FINANCE_APPROVAL.APPROVED, text: "Approved"},
    {value: FINANCE_APPROVAL.NOT_APPROVED, text: "Not Approved"}
];

export const FINANCE_APPROVAL_PARAM_VALUE = Object.freeze({
    NONE: null,
    APPROVED: true,
    NOT_APPROVED: false
});
