import React, { useState } from "react";
import { QuantityField } from "../components/QuantityField";
import { isEmpty } from "util/common";
import { RolePermission } from "components/RolePermission";
import { ROLES } from "constants/roles";
import { Thumbnail } from "components/Thumbnail";
import { UnitPriceCostTable } from "components/UnitPriceCostTable";
import { MIN_ORDER_VALUE_DESCRIPTION } from "constants/messages";
import { formatCurrency } from "util/formatNumber";
import { useEffect } from "react";

export const VariableDataProductDetails = ({ product, onQuantityChange, onQuantitiesRefresh, totalQuantity, totalCustomerQuantity }) => {
  const [editQuantity, setEditQuantity] = useState(null);
  const [cartQuantity, setCartQuantity] = useState(0);
  const [numVariableDataCols, setNumVariableDataCols] = useState(Object.keys(product.items[0].variableData).length);
  const [isChangeByUnit, setIsChangeByUnit] = useState(true);
  const [changeAmount, setChangeAmount] = useState(0);

  useEffect(() => {
    const numCols = Object.keys(product.items[0].variableData).length;
    setNumVariableDataCols(numCols);
  }, [product]);

  return (
    <div className="product-item variable-data card shadow">
      <div className="general-details">
        <div className="product-img">
          <Thumbnail
            className="img-fluid"
            imagePath={product.productImagePath}
            imageType={product.productImageType}
          />
        </div>
        <div className="product-info">
          <div className="fixed-info">
            <ul>
              <li>
                <div className="label">PO Number:</div>
                <div className="value">{product.poNumber}</div>
              </li>
              <li>
                <div className="label">Ticket Type:</div>
                <div className="value">{product.productSubcode}</div>
              </li>
              <li>
                <div className="label">Pack Size:</div>
                <div className="value">{product.packSize}</div>
              </li>
              {!isEmpty(product.minValue) && (
                <li>
                  <div
                    className="label"
                    data-toggle="tooltip"
                    data-placement="right"
                    title={MIN_ORDER_VALUE_DESCRIPTION}
                  >
                    Minimum Order Value *
                  </div>
                  <div className="value">
                    {product.minValue.currency +
                      " " +
                      formatCurrency(product.minValue.price)}
                  </div>
                </li>
              )}
            </ul>
            <ul>
              {!isEmpty(product.costs) &&
                (product.costs.length === 1 ? (
                  <li>
                    <div className="label">Unit Cost:</div>
                    <div className="value">
                      <span className="currency">
                        {product.costs[0].currency}{" "}
                      </span>
                      <span className="price">{product.costs[0].price}</span>
                    </div>
                  </li>
                ) : (
                  <UnitPriceCostTable
                    isPrice={false}
                    pricesOrCosts={product.costs}
                    cartQuantity={cartQuantity}
                  />
                ))}
              {!isEmpty(product.prices) &&
                (product.prices.length === 1 ? (
                  <li>
                    <div className="label">Unit Price:</div>
                    {product.prices.map((p, i) => (
                      <div key={"price-" + i} className="value">
                        <span className="currency">{p.currency} </span>
                        <span className="price">{p.price}</span>
                      </div>
                    ))}
                  </li>
                ) : (
                  <UnitPriceCostTable
                    isPrice={true}
                    pricesOrCosts={product.prices}
                    editQuantity={editQuantity}
                    cartQuantity={cartQuantity}
                  />
                ))}
            </ul>
          </div>
          <div className="row product-detail-info">
            <div className="col-6 product-info">
              <div className="label">Description:</div>
              <div className="value">{product.description}</div>
            </div>
            <RolePermission roles={[ROLES.VENDOR]}>
              {/* Adjust Quantity */}
              <div className="col-2">
                <div className="label">Adjust Quantity</div>
                <input
                className="form-control"
                type="number"
                style={{ maxWidth: "150px", display: "inline" }}
                onChange={(e) => setChangeAmount(e.target.value)}
                onBlur={() => setChangeAmount(Math.round(changeAmount))}
                value={changeAmount}
              />
              </div>
              <div className="col-2" style={{ display: "flex" }}>
                <div>
                  <span className="label">By Unit </span>
                  <input
                    type="radio"
                    checked={isChangeByUnit}
                    onChange={() => setIsChangeByUnit(true)}
                    className="radio mt-2"
                  />
                </div>
                <div>
                  <span className="label">By Percent </span>
                  <input
                    type="radio"
                    checked={!isChangeByUnit}
                    onChange={() => setIsChangeByUnit(false)}
                    className="radio mt-2"
                  />
                </div>
              </div>
              <div className="col-2">
                <button
                  style={{ marginTop: "20px", marginLeft: "26px" }}
                  className="btn btn-primary btn-md"
                  type="button"
                  onClick={() => onQuantitiesRefresh(product, isChangeByUnit, changeAmount)}
                >
                  Refresh
                </button>
              </div>
              <div className="row product-detail-info">
                <div className="col-12 product-info">
                  <div className="label">Comment:</div>
                  <div className="value">{product.comment}</div>
                </div>
              </div>
            </RolePermission>
          </div>
          <div className="required-attributes variable-data-table">
            <table className="table table-striped">
              <thead>
                <tr>
                  {Object.keys(product.items[0].variableData).map((key, i) => (
                    <th key={"variable-data-key-" + i} scope="col">
                      {key}
                    </th>
                  ))}
                  <RolePermission roles={[ROLES.VENDOR]}>
                    <th scope="col" width="15%">
                      Quantity
                    </th>
                  </RolePermission>
                </tr>
              </thead>
              <tbody>
                {product.items.map((item, i) => (
                  <tr key={"item-" + i}>
                    {Object.values(item.variableData).map((value, i) => (
                      <td key={"item-value-" + i}>{value}</td>
                    ))}
                    <RolePermission roles={[ROLES.VENDOR]}>
                      <td>
                        <QuantityField
                          defaultQuantity={item.defaultOrderQuantity}
                          minQuantity={product.minOrderQuantity}
                          packSize={product.packSize}
                          onChange={(quantity) => {
                            onQuantityChange(
                              product.productSubcode,
                              item.productItemId,
                              quantity,
                              product.poNumber
                            );
                            setEditQuantity(quantity);
                          }}
                          onFocus={(quantity) => {
                            setEditQuantity(quantity);
                            setCartQuantity(item.cartQuantity);
                          }}
                          onBlur={() => {
                            setEditQuantity(null);
                            setCartQuantity(0);
                          }}
                          required
                        />
                      </td>
                    </RolePermission>
                  </tr>
                ))}
                <tr style={{ backgroundColor: "white" }}>
                  {/* Add Total Row  */}
                  {Object.values(product.items[0].variableData).map((_, i) => (
                    (i === (numVariableDataCols - 2)) ? <td key={"vd-total-" + i} style={{ fontSize: "16px" }}><b>Total</b></td> : (i === (numVariableDataCols - 1) ? <></> : <td key={"vd-total-" + i}></td>)
                  ))}
                  <td style={{ paddingLeft: "18px", fontSize: "16px" }}><b>{totalCustomerQuantity}</b></td>
                  <td style={{ paddingLeft: "25px", fontSize: "16px" }}><b>{totalQuantity}</b></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};
