import React from "react";
import { Redirect } from "react-router";
import { useAuthenticatedUser } from "../hooks/useCurrentUser";
import { PAGE_URL } from "constants/url";
import { USER_LANDING_PAGE_MAP } from "constants/roles";

export const HomeRoute = () => {
  const [, user] = useAuthenticatedUser();
  const url = (user && user.role) ? USER_LANDING_PAGE_MAP[user.role] : null;

  return url ?
    <Redirect to={url} /> : <Redirect to={PAGE_URL.LOGIN} />;
};
