/* eslint-disable no-trailing-spaces */
/* eslint-disable no-unused-vars */

// React
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

// Local
import { api } from "../../services/apiService";
import { PageLayout } from "layouts/PageLayout";
import { useURLNavigate } from "hooks/useURLNavigate";
import { useURLParams } from "hooks/useURLParams";
import { useOverlay } from "hooks/useOverlay";
import { useError } from "hooks/errors";
import { PAGE_URL } from "constants/url";
import { isEmpty, isNullOrUndefined } from "util/common";
import { TopLevelVendorInfo } from "./TopLevelVendorInfo";
import { VendorRelationships } from "./VendorRelationships";
import {
  showSuccessMessage,
  pushSuccessMessage,
  showErrorMessage,
} from "store/messages/messageActions";
import { cloneDeep } from "lodash";
import {getRole} from "../../services/authService";
import { ROLES } from "../../constants/roles";
import { Link } from "react-router-dom";

export const VendorDetailPage = () => {
  // hooks
  const navigate = useURLNavigate();
  const [showError, pushError] = useError();
  const [getParam] = useURLParams();
  const [showOverlay, hideOverlay] = useOverlay();
  const dispatch = useDispatch();
  const message = useSelector((state) => state.message.message);

  // state
  const [editMode, setEditMode] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [customers, setCustomers] = useState();
  const [currencies, setCurrencies] = useState();

  const [vendor, setVendor] = useState();
  const [editVendor, setEditVendor] = useState();

  // url variables
  const vendorId = getParam("id");
  const createMode = getParam("createMode");
  const fromCart = getParam("fromCart");

  useEffect(() => {
    loadData();
  }, []);

  const loadData = async () => {
    // handle url hack
    if (createMode && !isEmpty(vendorId)) {
      pushError("Invalid URL params");
      navigate(PAGE_URL.USERS);
    }

    if (!isEmpty(vendorId)) {
      // load vendor
      try {
        const userResponse = await api.getVendor(vendorId);

        setVendor(cloneDeep(userResponse.data));
        setEditVendor(cloneDeep(userResponse.data));
      } catch (error) {
        pushError(error);
        navigate(PAGE_URL.HOME);
      }
    }

    if (createMode) {
      // setup new vendor
      setEditMode(true);
      setEditVendor({
        customers: [],
        currencyType: { name: "CAD", id: 1 },
        companyName: "",
        shippingAddresses: [],
        preferredShippingMethod: "",
        billingAddress: "",
        billingAccountNumber: "",
        deliveryAccountNumber: "",
        vendorNumber: "",
      });
    }

    if (createMode || !isEmpty(vendorId)) {
      try {
        if (getRole() !== ROLES.VENDOR) {
          const customersResponse = await api.getAllCustomers();
          setCustomers(customersResponse.data.customers);
        }

        const currencyResponse = await api.getCurrencies();
        setCurrencies(currencyResponse.data.refs);
        setLoaded(true);
      } catch (error) {
        pushError(error);
        navigate(PAGE_URL.HOME);
      }
    }
  };

  const toggleEditMode = () => {
    if (createMode) {
      navigate(PAGE_URL.VENDORS);
    } else if (editMode) {
      setEditVendor(cloneDeep(vendor)); // revert changes
      setEditMode(false);
    } else {
      setEditMode(true);
    }
  };

  const onSave = async () => {
    if (isNullOrUndefined(message)) {
      await showOverlay("Saving...");
    }

    if (createMode) {
      try {
        await api.createVendor(editVendor);
        hideOverlay();
        dispatch(pushSuccessMessage("Vendor Successfully Created"));
        navigate(PAGE_URL.VENDORS);
      } catch (error) {
        hideOverlay();
        showError(error);
      }
    } else {
      try {
        const response = await api.updateVendor(editVendor);

        const _editVendor = { ...editVendor };
        _editVendor.shippingAddresses = response.data.addresses;

        setEditVendor(_editVendor);
        setVendor(cloneDeep(_editVendor));
        dispatch(showSuccessMessage("Vendor Successfully Updated"));
      } catch (error) {
        showError(error);
        setEditVendor(vendor);
      } finally {
        setEditMode(false);
        hideOverlay();
      }
    }
  };

  const editVendorField = (field, value, requireNumber = false) => {

    if (requireNumber && value && typeof value === "string") {
      for (const c of value) {
        if (isNaN(Number(c))) {
          return;
        }
      }
    }

    let _editVendor = { ...editVendor };
    _editVendor[field] = value;
    setEditVendor(_editVendor);
  };

  const updateVendorEntity = (field, entity) => {
    let _editVendor = { ...editVendor };

    if (
      _editVendor[field] &&
      _editVendor[field].map((e) => e.id).includes(entity.id)
    ) {
      _editVendor[field] = _editVendor[field].filter((e) => e.id != entity.id);
    } else {
      _editVendor[field].push(entity);
    }

    setEditVendor(_editVendor);
  };

  const selectDeselectAll = (field, entityList) => {
    let _editUser = { ...editVendor };

    if (_editUser[field].length === entityList.length) {
      // remove all
      _editUser[field] = [];
    } else {
      // add all
      _editUser[field] = cloneDeep(entityList);
    }

    setEditVendor(_editUser);
  };

  const addShippingAddress = async (createAddress) => {
    const _editVendor = { ...editVendor };
    const _addresses = _editVendor.shippingAddresses;

    let duplicate = _addresses.find(
      (ship) =>
        ship.optionName === createAddress.optionName &&
        ship.address === createAddress.address
    );

    if (duplicate) {
      dispatch(showErrorMessage("This is a duplicate entry."));
      return;
    }

    // add
    _addresses.push(createAddress);

    // sort
    _addresses.sort((o1, o2) =>
      o1["optionName"].localeCompare(o2["optionName"])
    );

    _editVendor.shippingAddresses = _addresses;
    setEditVendor(_editVendor);
  };

  const updateShippingAddress = async (selectedAddress, editAddress) => {
    const _editVendor = { ...editVendor };
    const _addresses = _editVendor.shippingAddresses;

    let address = _addresses.find(
      (ship) =>
        ship.optionName === selectedAddress.optionName &&
        ship.address === selectedAddress.address
    );

    if (!address) {
      dispatch(showErrorMessage("Couldn't find the address to update."));
      return;
    }

    address.optionName = editAddress.optionName;
    address.address = editAddress.address;

    _editVendor.shippingAddresses = _addresses;
    setEditVendor(_editVendor);
  };

  const removeShippingAddress = async (deleteAddress) => {
    const _editVendor = { ...editVendor };

    const _filtered = _editVendor.shippingAddresses.filter(
      (ship) =>
        ship.optionName != deleteAddress.optionName ||
        ship.address != deleteAddress.address
    );

    _editVendor.shippingAddresses = _filtered;
    setEditVendor(_editVendor);
  };

  return (
    <PageLayout className="vendor-details">
      <section className="site-content bodyWrap" id="content">
        <section className="content-body">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <h4 className="PageTitle">
                  <Link className="page-parent" to={PAGE_URL.VENDORS}>
                    VENDORS
                  </Link>{" "}
                  &ndash; <span className="page-current">VENDOR DETAILS</span>
                </h4>
              </div>
            </div>
            {(fromCart) ?
              <div className="row">
                <div className="col-12">
                  <Link to={PAGE_URL.CART}>
                    <button className="mb-3 btn btn-primary" type="button" >
                      Return to Cart
                    </button>
                  </Link>
                </div>
              </div>
              : <></>}

            {loaded && (
              <div className="row">
                <div className="col-12">
                  <TopLevelVendorInfo
                    editVendor={editVendor}
                    editVendorField={editVendorField}
                    editMode={editMode}
                    toggleEditMode={toggleEditMode}
                    currencies={currencies}
                    onSave={onSave}
                  />
                </div>
              </div>
            )}
            {loaded && (
              <div className="row">
                <div className="col-12">
                  <div className="card shadow" id="top-level-info">
                    <div className="card-body">
                      <VendorRelationships
                        editVendor={editVendor}
                        updateVendorEntity={updateVendorEntity}
                        editMode={editMode}
                        customers={customers}
                        selectDeselectAll={selectDeselectAll}
                        addShippingAddress={addShippingAddress}
                        updateShippingAddress={updateShippingAddress}
                        removeShippingAddress={removeShippingAddress}
                      />
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </section>
      </section>
    </PageLayout>
  );
};
