import React from "react";
import {isNullOrUndefined, pluralize} from "../util/common";

export const Pagination = ({page, lastPage, itemsShowed, totalItems, handlePageChange, listName}) => {
  const previousPage = () => {
    setPage(page-1);
  };
  const nextPage = () => {
    setPage(page+1);
  };
  const setPage = (page) => {
    handlePageChange(Number.parseInt(page));
  };

  const optionsList = () => {
    const content = [];
    for (let p = 0; p <= lastPage; p++) {
      content.push(<option key={p} value={p}>{p+1}</option>);
    }
    return content;
  };

  const showPreviousPageLink = () => {
    return page > 0;
  };

  const showLastPageLink = () => {
    return lastPage > page;
  };

  const showSelectPage = () => {
    return lastPage > 0;
  };

  return (
  <div className="productNumber-pagination">
    {!isNullOrUndefined(totalItems) && <span>Showing {(itemsShowed ? itemsShowed + "/" : "") + totalItems + " " + pluralize(listName, itemsShowed) } </span>}
    <nav aria-label="Page navigation">
    <ul className="pagination pagination-sm m-0">
      {showPreviousPageLink() ? <li className="page-item"><a className="page-link" onClick={previousPage}>Previous</a></li> : ""}

      {showSelectPage() ? <li className="page-item">
        <select className="page-link" required onChange={e => setPage(e.target.value)} value={page}>
        {optionsList()}
        </select></li> : ""}

      {showLastPageLink() ? <li className="page-item"><a className="page-link" onClick={nextPage}>Next</a></li> : ""}
    </ul>
  </nav>
  </div>);
};