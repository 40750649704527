export const BASE_URL = "/api/v1.0";

export const PAGE_URL = Object.freeze({
    HOME: "/",
    LOGIN: "/login",
    RESET_PASSWORD: "/reset-password",
    ORDER: "/order-list",
    VIEW_ORDER: "/order-details",
    PRODUCT: "/product-list",
    PRODUCT_DETALS: "/product-details",
    RESTOCK: "/restock",
    CART: "/cart",
    TERMS_AND_CONDITIONS: "/terms-and-conditions",
    VARIABLE_DATA_UPLOAD: "/variable-data-upload",
    USERS: "/user-list",
    VIEW_USER: "/user-details",
    PRODUCT_CODES: "/product-code-list",
    PRODUCT_CODE_DETAILS: "/product-code-details",
    PRODUCT_SUBCODE: "/product-subcode-list",
    PRODUCT_SUBCODE_DETAILS: "/product-subcode-details",
    CARE_LABEL: "/care-label",
    VENDORS: "/vendor-list",
    VIEW_VENDOR: "/vendor-details",
    CUSTOMER: "/customer-list",
    VIEW_CUSTOMER: "/customer-details",
    FACTORY: "/factory-list",
    FACTORY_DETAILS: "/factory-details",
    WAREHOUSES: "/warehouse-list",
    WAREHOUSE_DETAILS: "/warehouse-details",
    PRODUCTION_REGION : "/production-regions-list",
    PRODUCTION_REGION_DETAILS: "/production-regions-details",
    PRODUCT_ITEM_DETAILS: "/product-item-details",
    ATTRIBUTE_SCHEMA: "/attribute-schemas-list",
    ATTRIBUTE_SCHEMA_DETAILS: "/attribute-schema-details",
});

export const PAGE_TITLE = Object.freeze({
    LOGIN: "Login",
    RESET_PASSWORD: "Reset Password",
    ORDER: "Orders",
    PRODUCT: "Products",
    RESTOCK: "Restock",
    VIEW_ORDER: "View Order",
    PRODUCT_DETALS: "Product Details",
    CART: "Cart",
    TERMS_AND_CONDITIONS: "Terms & Conditions",
    VARIABLE_DATA_UPLOAD: "Variable Data Upload",
    USERS: "Users",
    VIEW_USER: "User Details",
    PRODUCT_CODES: "Product Codes",
    PRODUCT_CODE_DETAILS: "Product Code Details",
    PRODUCT_SUBCODE: "Product Subcodes",
    PRODUCT_SUBCODE_DETAILS: "Product Subcode Details",
    CARE_LABEL: "Care Label",
    VENDORS: "Vendors",
    VIEW_VENDOR: "Vendor Details",
    CUSTOMER: "Customer",
    VIEW_CUSTOMER: "Customer Details",
    FACTORY: "Factories",
    FACTORY_DETAILS: "Factory Details",
    WAREHOUSES: "Warehouses",
    WAREHOUSE_DETAILS: "Warehouse Details",
    PRODUCTION_REGION : "Production Regions",
    PRODUCTION_REGION_DETAILS: "Production Regions Details",
    PRODUCT_ITEM_DETAILS: "Product Item Details",
    ATTRIBUTE_SCHEMA: "Attribute Schemas",
    ATTRIBUTE_SCHEMA_DETAILS: "Attribute Schema Details",
});
