/* eslint-disable no-unused-vars */

// React
import React, { useRef } from "react";

// Local
import { LabelTextField } from "components/LabelTextField";
import { LabelTextArea } from "components/LabelTextArea";


export const TopLevelWarehouseInfo = ({
  warehouse,
  editWarehouse,
  editWarehouseField,
  editMode,
  createMode,
  toggleEditMode,
  onSave,
}) => {
  const saveButtonRef = useRef();

  const onInputKeyDown = (e) => {
    if (e.keyCode === 13) {
      e.preventDefault();
      saveButtonRef.current.click();
    }
  };

  return (
    <form
      onKeyDown={onInputKeyDown}
      onSubmit={(e) => {
        e.preventDefault();
        onSave();
      }}
    >
      <div className="card shadow" id="top-level-info">
        <div className="card-body">
          <div className="row" style={{ justifyContent: "flex-end" }}>
            <div className="col">
              <h2 style={{ marginBottom: "0px", marginLeft: "8px" }}>
                {editWarehouse.warehouseName}
              </h2>
            </div>
            <button
              className="btn btn-primary btn-sm m-1 mr-4"
              type="button"
              onClick={toggleEditMode}
              hidden={editMode}
            >
              Edit
            </button>
            <button
              ref={saveButtonRef}
              className="btn btn-success btn-sm m-1"
              id="green"
              type="submit"
              hidden={!editMode}
            >
              Save
            </button>
            <button
              className="btn btn-primary btn-sm m-1 mr-4"
              type="button"
              onClick={toggleEditMode}
              hidden={!editMode}
            >
              Cancel
            </button>
          </div>
          <div className="row warehouse-info">
            <div className="col-4">
              <div className="long-warehouse-info">
                <ul>
                  <LabelTextField
                    label={"Warehouse Name"}
                    value={editWarehouse.warehouseName}
                    editMode={editMode}
                    onChange={(value) => editWarehouseField("warehouseName", value)}
                    required
                  />
                </ul>
              </div>
            </div>
            <div className="col-4">
              <div className="long-warehouse-info">
                <ul>
                  <LabelTextArea
                    label={"Address"}
                    value={editWarehouse.address}
                    editMode={editMode}
                    onChange={(value) => editWarehouseField("address", value)}
                    required
                  />
                </ul>
              </div>
            </div>
            <div className="col-4">
              <div className="long-warehouse-info">
                <ul>
                  <LabelTextField
                    label={"Preferred Shipping"}
                    value={editWarehouse.preferredShippingMethod}
                    editMode={editMode}
                    onChange={(value) => editWarehouseField("preferredShippingMethod", value)}
                    required
                  />
                </ul>
              </div>
            </div>
          </div>
          <div className="row warehouse-info">
            <div className="col-4">
              <div className="warehouse-info">
                <ul>
                  <LabelTextField
                    label={"Contact Name"}
                    value={editWarehouse.contactName}
                    editMode={editMode}
                    onChange={(value) => editWarehouseField("contactName", value)}
                    required
                  />
                </ul>
              </div>
            </div>
            <div className="col-4">
              <div className="warehouse-info">
                <ul>
                  <LabelTextField
                    label={"Email"}
                    value={editWarehouse.email}
                    editMode={editMode}
                    onChange={(value) => editWarehouseField("email", value)}
                    required
                  />
                </ul>
              </div>
            </div>
            <div className="col-4">
              <div className="warehouse-info">
                <ul>
                  <LabelTextField
                    label={"Telephone"}
                    value={editWarehouse.telephone}
                    editMode={editMode}
                    onChange={(value) => editWarehouseField("telephone", value)}
                    required
                  />
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};
