/* eslint-disable no-multiple-empty-lines */
/* eslint-disable no-trailing-spaces */

// React
import React, {useState} from "react";
import { useSelector } from "react-redux";

// Local
import { UserListSearch } from "./UserListSearch";
import { UserListRow } from "./UserListRow";
import api from "services/apiService";
import { isEmpty, isNullOrUndefined, pluralize } from "util/common";
import { PageLayout } from "layouts/PageLayout";
import { joinParams } from "components/joinParams";
import { useOverlay } from "hooks/useOverlay";
import { useError } from "hooks/errors";
import { useURLNavigate } from "hooks/useURLNavigate";
import { ROLES } from "constants/roles";


export const UserListPage = () => {
  // hooks
  const navigate = useURLNavigate();
  const [showOverlay, hideOverlay] = useOverlay();
  const [showError, ] = useError();
  const message = useSelector(state => state.message.nextMessage);
  
  // state
  const [users, setUsers] = useState([]);
  
  const searchUsers = async (searchValues) => {
    try {
      const params = getParams(searchValues);
      
      if (isNullOrUndefined(message)) {
        await showOverlay("Searching...");
      }

      const response = await api.getUsers(params);
      hideOverlay();
      setUsers(response.data.users);
    } catch (error) {
      hideOverlay();
      showError(error);
    }
  };

  const getParams = (searchValues) => {

    return joinParams({
      role: searchValues.role === ROLES.NONE ? null : searchValues.role,
      email: searchValues.email,
      name: searchValues.name,
      active: isEmpty(searchValues) || isEmpty(searchValues.active) ? true : searchValues.active,
    });
  };

  return (
    <PageLayout className="user-list">
      <section className="site-content bodyWrap" id="content">
        <section className="content-body">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="page-title-row">
                  <h4 className="PageTitle"><span className="page-parent">USERS</span></h4>
                  <button className="btn btn-primary add-entity-btn" onClick={() => navigate("/user-details", "createMode=true")}>Add</button>
                </div>
                <UserListSearch searchUsers={searchUsers} />
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                  <div className="list-group mt-5" id="userList">
                      {users && users.map((user, index) =>
                          <UserListRow key={index} user={user} />
                      )}
                  </div>
              </div>
            </div>
            <div className="userNumber-pagination">
              <span>{"Showing " + users.length + pluralize(" User", users.length)}</span>
            </div>
          </div>
        </section>
      </section>
    </PageLayout>
  );
};
