// React
import React from "react";
import { Link } from "react-router-dom";

// Local
import { isEmpty } from "util/common";
import { LabelValue } from "components/LabelValue";
import { ROLE_OPTIONS } from "constants/roles";
import { getOptionText } from "util/common";
import { LabelDate } from "components/LabelDate";

export const UserListRow = ({ user }) => {

  return (
    <Link className="user-item list-group-item list-group-item-action" to={"/user-details?id=" + user.id}>
      <div className="d-flex w-100">
        <div className="user-details">
          <div className="user-meta">
            <ul>
              <LabelValue label="Name" value={isEmpty(user.contactName) ? "---" : user.contactName} />
              <LabelValue label="Email" value={user.email} listItemStyle={{width: "25rem", overflowWrap: "anywhere"}} />
              <LabelValue label="Role" value={isEmpty(user.role) ? "---" : getOptionText(ROLE_OPTIONS, user.role)} />
              <LabelValue label="Status" value={user.active===true ? "Active" : "Inactive"} labelClassName="short-label" />
              <LabelDate label="Last Login" date={user.lastLogin} optionalDefaultValue="---"/>
            </ul>
          </div>
        </div>
      </div>
    </Link>
  );
};
