export const formatCurrency = (currencyValue) => {
    if (currencyValue === null || currencyValue === undefined || currencyValue === "") {
        return "";
    }

    return currencyValue.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const formatLargeNumber = (currencyValue) => {
    if (currencyValue === null || currencyValue === undefined) {
        return "";
    }

    return currencyValue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};