import React from "react";
import { Link } from "react-router-dom";


export const NavTab = ({ tabName, tabActive, setTabSelected }) => {

    return (
        <li key={tabName} className="nav-item">
            <a className={"nav-link " + (tabActive ? "active" : "")}
                data-toggle="tab"
                onClick={setTabSelected}
                role="tab"
                aria-controls=""
                aria-selected={(tabActive ? "true" : "false")}>
                {tabName}
            </a>
        </li>
    );
};
