// React
import React from "react";

// Local
import { useTierCalculator } from "hooks/useTierCalculator";
import { isEmpty, pluralize, stringToInt } from "util/common";

export const UnitPriceCostTable = ({
  isPrice,
  pricesOrCosts,
  editQuantity,
  cartQuantity,
}) => {
  const getPriceCalculator = useTierCalculator();

  const isExplanationRequired = () =>
    !isEmpty(cartQuantity) &&
    cartQuantity != 0 &&
    getTierWithCartQuantity() != getTierWithoutCartQuantity();

  const getCalculationExplanation = () => {
    return (
      "This " +
      (isPrice ? "Price" : "Cost") +
      " tier calculation includes " +
      cartQuantity +
      " of the same product " +
      pluralize("item", parseInt(cartQuantity)) +
      " in your order."
    );
  };

  const getTierWithCartQuantity = () =>
    getTierForQuantity(editQuantity, cartQuantity);
  const getTierWithoutCartQuantity = () => getTierForQuantity(editQuantity);

  const getTierForQuantity = (editQuantity, cartQuantity) => {
    const price = getPriceCalculator(pricesOrCosts);

    if (isEmpty(editQuantity) || editQuantity == 0) {
      return "";
    }

    const _editQuantity = parseInt(editQuantity);
    const _cartQuantity = stringToInt(cartQuantity);

    return price(_editQuantity + _cartQuantity);
  };

  return (
    <table
      className="table table-striped"
      style={{ width: "18rem" }}
    >
      <thead>
        <tr>
          <th style={{ paddingTop: "0" }} scope="col">
            {"Quantity"}
          </th>
          <th style={{ paddingTop: "0" }} scope="col">
            {"Unit " + (isPrice ? "Price" : "Cost")}
          </th>
        </tr>
      </thead>
      <tbody>
        {pricesOrCosts.map((poc, i) => (
          <tr
            key={i}
            style={{
              backgroundColor:
                getTierWithCartQuantity() == poc.price ? "#c5e8f7" : "",
            }}
            title={
              getTierWithCartQuantity() == poc.price && isExplanationRequired() ? getCalculationExplanation() : ""
            }
          >
            <td>
              &#8805;{" " + poc.quantity}
              <b>{getTierWithCartQuantity() == poc.price && isExplanationRequired() ? "*" : ""}</b>
            </td>
            <td>{poc.currency + " " + poc.price}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};
