import { PAGE_URL } from "./url";

export const ROLES = Object.freeze({
  NONE: "NONE",
  ACCOUNT: "ACCOUNT",
  ADMIN: "ADMIN",
  CSR: "CSR",
  FACTORY_ACCOUNT: "FACTORY_ACCOUNT",
  FINANCE: "FINANCE",
  VENDOR: "VENDOR",
  CUSTOMER: "CUSTOMER"
});

export const ROLE_OPTIONS = [
  { value: ROLES.NONE, text: "---" },
  { value: ROLES.ADMIN, text: "Admin" },
  { value: ROLES.CSR, text: "CSR" },
  { value: ROLES.VENDOR, text: "Vendor" },
  { value: ROLES.CUSTOMER, text: "Customer" },
  { value: ROLES.ACCOUNT, text: "Account" },
  { value: ROLES.FINANCE, text: "Finance" },
  { value: ROLES.FACTORY_ACCOUNT, text: "Regional Supervisor" }
];

export const VIEW_ORDER_LOGS = ["ADMIN", "ACCOUNT", "FACTORY_ACCOUNT", "FINANCE"];
export const PRICE_ACCESSIBLE_USERS = ["ADMIN", "FINANCE", "FACTORY_ACCOUNT", "VENDOR", "CUSTOMER"];
export const COST_ACCESSIBLE_USERS = ["ADMIN", "ACCOUNT", "FACTORY_ACCOUNT", "FINANCE", "CSR"];
export const CONFIGURATION_ACCESS_USERS = [ROLES.ADMIN, ROLES.ACCOUNT];

export const USER_LANDING_PAGE_MAP = {
  [ROLES.ADMIN]: PAGE_URL.ORDER,
  [ROLES.FINANCE]: PAGE_URL.ORDER,
  [ROLES.CSR]: PAGE_URL.ORDER,
  [ROLES.VENDOR]: PAGE_URL.PRODUCT,
  [ROLES.CUSTOMER]: PAGE_URL.ORDER,
  [ROLES.ACCOUNT]: PAGE_URL.ORDER,
  [ROLES.FACTORY_ACCOUNT]: PAGE_URL.ORDER
};

export const ROLE_OPERATIONS = {
  CAN_UPLOAD_SHIPPING_DOCS: [ROLES.ADMIN, ROLES.ACCOUNT, ROLES.FACTORY_ACCOUNT, ROLES.CSR],
  APPROVE_FINANCE: [ROLES.ADMIN, ROLES.FINANCE, ROLES.FACTORY_ACCOUNT],
  UPDATE_INVENTORY: [ROLES.ADMIN, ROLES.ACCOUNT, ROLES.FACTORY_ACCOUNT, ROLES.CSR],
  DOWNLOAD_VARIABLE_DATA_CSV: [ROLES.ADMIN, ROLES.ACCOUNT, ROLES.FACTORY_ACCOUNT, ROLES.CSR],
  DOWNLOAD_INVENTORY_CSV: [ROLES.ACCOUNT, ROLES.ADMIN, ROLES.FINANCE, ROLES.CUSTOMER, ROLES.FACTORY_ACCOUNT],
  INPUT_INVOICE_NUMBER: [ROLES.ADMIN, ROLES.FACTORY_ACCOUNT, ROLES.FINANCE],
  RESTOCK: [ROLES.ADMIN, ROLES.ACCOUNT],
  ALWAYS_COMPLETE_PRODUCTION: [ROLES.ACCOUNT, ROLES.FACTORY_ACCOUNT, ROLES.ADMIN],
  SHIP_AND_COMPLETE:  [ROLES.ADMIN, ROLES.ACCOUNT, ROLES.FACTORY_ACCOUNT, ROLES.CSR],
  MANAGE_ESTIMATED_COMPLETION_DATE: [ROLES.ACCOUNT, ROLES.FACTORY_ACCOUNT, ROLES.CSR, ROLES.ADMIN],
  REVIEW_ARTWORK: [ROLES.ACCOUNT, ROLES.FACTORY_ACCOUNT, ROLES.CSR, ROLES.ADMIN, ROLES.VENDOR],
  UPLOAD_ARTWORK: [ROLES.ACCOUNT, ROLES.FACTORY_ACCOUNT, ROLES.CSR, ROLES.ADMIN],
};

export const PAGE_ACCESSIBLE_USERS = {
  [PAGE_URL.HOME]: [ROLES.ADMIN, ROLES.VENDOR, ROLES.CUSTOMER, ROLES.ACCOUNT, ROLES.FACTORY_ACCOUNT, ROLES.CSR, ROLES.FINANCE],
  [PAGE_URL.PRODUCT]: [ROLES.ADMIN, ROLES.VENDOR, ROLES.CUSTOMER, ROLES.ACCOUNT, ROLES.FACTORY_ACCOUNT, ROLES.CSR],
  [PAGE_URL.RESTOCK]: [ROLES.ADMIN, ROLES.ACCOUNT],
  [PAGE_URL.VIEW_ORDER]: [ROLES.ADMIN, ROLES.VENDOR, ROLES.CUSTOMER, ROLES.ACCOUNT, ROLES.FACTORY_ACCOUNT, ROLES.CSR, ROLES.FINANCE],
  [PAGE_URL.ORDER]: [ROLES.ADMIN, ROLES.VENDOR, ROLES.CUSTOMER, ROLES.ACCOUNT, ROLES.FACTORY_ACCOUNT, ROLES.CSR, ROLES.FINANCE],
  [PAGE_URL.PRODUCT_DETALS]: [ROLES.ADMIN, ROLES.VENDOR, ROLES.CUSTOMER, ROLES.ACCOUNT, ROLES.FACTORY_ACCOUNT, ROLES.CSR],
  [PAGE_URL.CART]: [ROLES.VENDOR, ROLES.ACCOUNT, ROLES.ADMIN],
  [PAGE_URL.VARIABLE_DATA_UPLOAD]: [ROLES.ACCOUNT, ROLES.FACTORY_ACCOUNT, ROLES.ADMIN],
  [PAGE_URL.USERS]: [ROLES.ADMIN],
  [PAGE_URL.VIEW_USER]: [ROLES.ADMIN],
  [PAGE_URL.PRODUCT_CODES]: [ROLES.ADMIN],
  [PAGE_URL.PRODUCT_CODE_DETAILS]: [ROLES.ADMIN],
  [PAGE_URL.PRODUCT_SUBCODE]: [ROLES.ADMIN],
  [PAGE_URL.PRODUCT_SUBCODE_DETAILS]: [ROLES.ADMIN],
  [PAGE_URL.VENDORS]: [ROLES.ADMIN],
  [PAGE_URL.VIEW_VENDOR]: [ROLES.ADMIN, ROLES.VENDOR],
  [PAGE_URL.CUSTOMER]: [ROLES.ADMIN],
  [PAGE_URL.VIEW_CUSTOMER] : [ROLES.ADMIN],
  [PAGE_URL.FACTORY]: [ROLES.ADMIN],
  [PAGE_URL.FACTORY_DETAILS]: [ROLES.ADMIN],
  [PAGE_URL.WAREHOUSES]: [ROLES.ADMIN],
  [PAGE_URL.WAREHOUSE_DETAILS] : [ROLES.ADMIN],
  [PAGE_URL.PRODUCTION_REGION] : [ROLES.ADMIN],
  [PAGE_URL.PRODUCTION_REGION_DETAILS] : [ROLES.ADMIN],
  [PAGE_URL.PRODUCT_ITEM_DETAILS] : [ROLES.ADMIN, ROLES.CSR],
  [PAGE_URL.CARE_LABEL]: [ROLES.ADMIN],
  [PAGE_URL.ATTRIBUTE_SCHEMA]: [ROLES.ADMIN],
  [PAGE_URL.ATTRIBUTE_SCHEMA_DETAILS] : [ROLES.ADMIN],
};

export const userCanPerformOperation = (user, operationAllowedRoles) => {
  return !!user && !!user.role && !!operationAllowedRoles && operationAllowedRoles.includes(user.role);
};
