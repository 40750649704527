export const CARE_INSTRUCTION_TYPE = Object.freeze({
  WASH: "WASH",
  BLEACH: "BLEACH",
  DRY: "DRY",
  IRON: "IRON",
  DRYCLEAN: "DRYCLEAN"
});

export const INSTRUCTION_TYPE_GROUP = Object.freeze({
  [CARE_INSTRUCTION_TYPE.WASH]: "washOptions",
  [CARE_INSTRUCTION_TYPE.BLEACH]: "bleachOptions",
  [CARE_INSTRUCTION_TYPE.DRY]: "dryOptions",
  [CARE_INSTRUCTION_TYPE.IRON]: "ironOptions",
  [CARE_INSTRUCTION_TYPE.DRYCLEAN]: "dryCleanOptions"
});

export const INSTRUCTION_OPTIONS = [
  { value: CARE_INSTRUCTION_TYPE.WASH, text: "Wash" },
  { value: CARE_INSTRUCTION_TYPE.BLEACH, text: "Bleach" },
  { value: CARE_INSTRUCTION_TYPE.DRY, text: "Dry" },
  { value: CARE_INSTRUCTION_TYPE.IRON, text: "Iron" },
  { value: CARE_INSTRUCTION_TYPE.DRYCLEAN, text: "Dry Clean" },
];