import React from "react";

export const FibreContents = ({ fibreContents }) => {

    return (
        <div id="fibreContents">
            <div className="form-row">
                <div className="col-4 order-label-sub-heading">
                    <label>Material</label>
                </div>
                <div className="col-4 order-label-sub-heading">
                    <label>%</label>
                </div>
            </div>
            <ul>
                {fibreContents && fibreContents.map((f, index) =>
                    <li className="material" key={index}>
                        <div className="form-row">
                            <div className="form-group col-4">
                                {f.material}
                            </div>
                            <div className="form-group col-4">
                                {f.percentage}
                            </div>
                            <div className="col-4"></div>
                        </div>
                    </li>
                )}
            </ul>
        </div>);
}