import React, { useEffect } from "react";
import { PAGE_TITLE } from "constants/url";
import { CURRENT_YEAR } from "constants/date";

export const TermsAndConditions = () => {

    useEffect(() => {
        document.title = PAGE_TITLE.TERMS_AND_CONDITIONS + " - The Unique Group";
    }, [])

    return (
        <div id="page" className="terms-and-conditions bodyWrapTerms">


        <div className="legal-text">
            <h4 className="PageTitle">Terms and Conditions</h4>

            <p className="updated-date">
                Last updated on January 9th, 2020
            </p>

            <p>
            The Unique Group (“the company”) provides this Website- Product and Order Management System- (the site and any software provided by the Company for use with the site, the "Site") to you subject to these terms of use (these "Terms"). In these terms we describe users ("Users") of the Site as registered users.
            </p>

            <p>
            Please read these Terms carefully before using the Site. By accessing, using or browsing the Site you agree to be legally bound by these Terms and all terms, policies and guidelines incorporated by reference in these Terms. If you do not agree with these Terms in their entirety, you may not use the Site and may choose to exit the Website.
            </p>

            <p>
            The Company reserves the right to change or modify any of the terms and conditions contained in these Terms, or any policy or guideline of the Site, at any time and in its sole discretion. If we do so, we will notify you at the email address that we have safety saved when we did your registration. If you do not agree with the changes, you can contact our Customer Service department in order to explain your concerns.
            </p>

            <p>
            The services we provide through the Site are for your own use only. You may not resell, lease or provide them in any other way to anyone else.
            </p>


            <p>
            When we registered you for an account on the Site, we provided accurate, current and complete information ("Registration Data”); and we sent you your user and password, you agreed to (a) maintain the security of your password; (b) maintain and promptly update the Registration Data, and any other information we provided to the Site, and to keep it accurate, current and complete; and (c) accept all risks of unauthorized access to the Registration Data and any other information you provide to the Site. You are responsible for all activity on your Site account.
            If you want to change any Registration Data, please contact our Customer Service department.
            </p>

            <p>
            At present we do not charge any fees or other charges for the use of the Site. In these Terms the content on the Site, including all information, artwork, data, logos, marks, designs, graphics, pictures, sound files, other files, and their selection and arrangement, is called "Content". Content provided by Users is called "User Content". User Content is that User's property. The Company’s only right to that User Content is the limited licenses to it granted in these Terms.
            </p>

            <p>
            Other than the User Content, the Site, all Content and all software available on the Site or used to create and operate the Site is the property of the Company or its licensors, and is protected by Canadian and international copyright laws, and all rights to the Site, such Content and such software are expressly reserved. All trademarks, registered trademarks, product names and company names or logos mentioned in the Site are the property of their respective owners. Reference to any products, services, processes or other information (by trade name, trademark, manufacturer, supplier or otherwise) does not constitute or imply endorsement, sponsorship or recommendation thereof by the Company.
            </p>

            <p>
            Your User Content is your responsibility. We have no responsibility or liability for it, including any loss or damage it may cause to other people. Although we have no obligation to do so, we have the absolute discretion to remove, screen or edit without notice any User Content posted or stored on the Site, and we may do this at any time and for any reason.
            </p>

            <p>
            The Company grants you a limited, revocable, non-exclusive, non-sub-licensable license to access the Site and to view, copy and print the portions of the Content available to you on the Site. Except as expressly permitted above, any use of any portion of the Content without the prior written permission of its owner is strictly prohibited and will terminate the license granted in this Agreement and your account with us.
            </p>

            <p>
            You represent and warrant that your use of the Site and the Content will be consistent with this license and will not infringe or violate the rights of any other party or breach any contract or legal duty to any other parties, or violate any applicable law.
            </p>

            <p>
            The Site may contain links to third-party Web sites ("Third-Party Sites") and third-party content ("Third-Party Content") as a service to those interested in this information. You use links to Third-Party Sites, and any Third-Party Content or service provided there at your own risk. The Company does not monitor or have any control over, and makes no claim or representation regarding, Third-Party Content or Third-Party Sites.
            </p>

            <p>
            While the Company attempts to make your access to and use of the Site safe, the Company does not represent or warrant that the Site or any Content are free of viruses or other harmful components.
            </p>

            <p>
            You shall defend, indemnify and hold harmless Company and the other Released Parties from any loss, damages, liabilities, costs, expenses, claims and proceedings arising out of your use of the Site and from the use of the Site by any person to whom you give access to your account, including any claims made by any person that any of your User Content infringes the rights, including the intellectual property rights, of any third party.
            </p>

            <p>
            The Site is controlled by the Company and operated by it from its office in Ontario, Canada. You and the Company both benefit from establishing a predictable legal environment in regard to the Site. Therefore, you and the Company explicitly agree that all disputes, claims or other matters arising from or relating to your use of the Site will be governed by the laws of the Province of Ontario and the federal laws of Canada applicable therein. The United Nations Convention on Contracts for the International Sale of Goods does not apply to these Terms.
            </p>

            <p>
            If any provision of these Terms shall be deemed unlawful, void or for any reason unenforceable, then that provision shall be deemed severable from these Terms and shall not affect the validity and enforceability of any remaining provisions. Company may assign any or all of its rights hereunder to any party without your consent. You are not permitted to assign any of your rights or obligations hereunder without the prior written consent of the Company, and any such attempted assignment will be void and unenforceable. These Terms constitute the entire agreement between you and the Company regarding your use of the Site, and supersede all prior or contemporaneous communications whether electronic, oral or written between you and the Company regarding your use of the Site.
            </p>

        </div>

        <footer className="site-footer mt-5">
        <div className="site-info container text-right">
          <div className="row">
            <div className="col">
              <p>&copy;{CURRENT_YEAR}&nbsp;
                <a href="https://theuniquegroup.com/" target="_blank">The Unique Group</a> {"\u00a0\u00a0"}
              </p>
            </div>
          </div>
        </div>
      </footer>
        </div>
    );
}