import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useURLParams } from "hooks/useURLParams";
import api from "services/apiService";
import { PAGE_URL } from "constants/url";
import { useCurrentUser } from "hooks/useCurrentUser";
import { useTierCalculator } from "hooks/useTierCalculator";
import { ROLES } from "constants/roles";
import { formatCurrency } from "util/formatNumber";
import { sum, isEmpty, stringToInt } from "util/common";
import { VariableDataUtils } from "util/variableData";
import { useURLNavigate } from "../../../hooks/useURLNavigate";
import { CARE_LABEL_TYPE } from "constants/careLabelType";
import {
  showErrorMessage,
  clearMessage,
  pushSuccessMessage
} from "store/messages/messageActions";
import { useRef } from "react";
import { useError } from "hooks/errors";
import { Link } from "react-router-dom";
import { DynamicProductDetailsVendor } from "./DynamicProductDetailsVendor";
import { PRODUCT_TYPE } from "constants/productType";
import { joinParams } from "components/joinParams";
import { useOverlay } from "../../../hooks/useOverlay";
import { ProductUtils } from "util/product";
import { INVALID_PERCENTAGES_ERROR } from "constants/messages";

export const ProductDynamicDetailsPage = ({ product, editMode, orderItemId }) => {

  const user = useCurrentUser();
  const navigate = useURLNavigate();
  const dispatch = useDispatch();
  const [showError] = useError();
  const [showOverlay, hideOverlay] = useOverlay();
  const [getParam] = useURLParams();
  const getPriceCalculator = useTierCalculator();
  const addToOrderRef = useRef(null);

  const [productConfig, setProductConfig] = useState();
  const [total, setTotal] = useState(0);
  const [customerQuantity, setCustomerQuantity] = useState(0);
  const [addToOrderEnabled, setAddToOrderEnabled] = useState(true);
  const [showValidation, setShowValidation] = useState(false);
  const [showNoResults, setShowNoResults] = useState(false);
  const [showDynamicData, setShowDynamicData] = useState(false);
  const [numDataCols, setNumDataCols] = useState(0);
  const [itemVariants, setItemVariants] = useState([]);
  const [currentStyleNumber, setCurrentStyleNumber] = useState("");
  const [currentPoNumber, setCurrentPoNumber] = useState("");

  useEffect(() => {
    onProductResponse(product);
  }, []);

  const onProductResponse = (product) => {
    console.log("product", product);
    const config = createProductConfig(product);
    setProductConfig(config);
    updateTotalForConfig(config);

    if (editMode) {
      let _variants = product.variants;
      setShowDynamicData(true);
      setNumDataCols(Object.keys(_variants[0].dynamicData).length);
      setCurrentPoNumber(product.poNumber);
      setCurrentStyleNumber(product.styleNumber);
    }
  };

  const createProductConfig = (product) => {
    let config = {
      price: getPriceCalculator((user.role === ROLES.VENDOR || user.role === ROLES.CUSTOMER) ? product.prices : product.costs),
      attributes: {},
      quantities: {},
      totalQuantity: 0,
    };

    if (!isEmpty(product.attributes)) {
      product.attributes.forEach(
        (a) => (config.attributes[a.name] = a.value ?? "")
      );
    }

    if (editMode) {
      config.careLabel = product.careLabel;
      updateItemVariants(product.variants, config);
    } else if (product.careLabel) {
      config.careLabel = {
        countryOfOrigin: null,
        specialInstructions: product.careLabel.specialInstructions,
        cautionMaintenance: null,
        garmentComponents: [],
      };

      if (product.careLabel.type === CARE_LABEL_TYPE.CARELABEL_BY_CODE) {
        config.careLabel.instructionsCode = null;
      } else {
        config.careLabel.wash = null;
        config.careLabel.bleach = null;
        config.careLabel.dry = null;
        config.careLabel.iron = null;
        config.careLabel.dryClean = null;
      }
    }

    return config;
  };

  const onAttributeChange = (name, value) => {
    let config = { ...productConfig };
    config.attributes[name] = value;
    setProductConfig(config);
  };

  const clearQuantities = () => {
    let config = { ...productConfig };

    Object.keys(config.quantities).forEach(key => config.quantities[key] = 0);
    config.totalQuantity = 0;

    setProductConfig(config);
    updateTotalForConfig(config);
  };

  const onQuantityChange = (variantId, quantity, adjustForPackSize = false, config = null) => {
    let _config = config ?? { ...productConfig };

    _config.quantities[variantId] = adjustForPackSize ? ProductUtils.getQuantityForPackSize(quantity, product.packSize) : stringToInt(quantity);

    // calculate new quantity total
    const _totalQuantity = sum(Object.values(_config.quantities));
    _config.totalQuantity = _totalQuantity;

    setProductConfig(_config);
    updateTotalForConfig(_config);
  };

  const updateTotalForConfig = (config) => {
    let totalQuantity = 0;
    let totalPrice = 0;

    var quantities = Object.values(config.quantities);
    totalQuantity += sum(quantities);

    quantities.forEach(q => {
      totalPrice += q * config.price(q);
    });

    setAddToOrderEnabled(totalQuantity > 0);
    setTotal(totalPrice);
  };

  const onCareLabelChange = (careLabelConfig) => {
    const config = { ...productConfig };
    config.careLabel = careLabelConfig;
    setProductConfig(config);
  };

  const onAddToOrder = async (e) => {
    e.preventDefault();

    if (product.careLabel) {
      const careLabel = productConfig.careLabel;

      if (hasInvalidPercentages(careLabel)) {
        return false;
      }
    }

    try {
      const data = {
        productType: PRODUCT_TYPE.DYNAMIC,
        quantities: {},
        attributes: {},
        dynamicVariants: {
          styleNumber: currentStyleNumber,
          poNumber: currentPoNumber,
          variants: []
        }
      };

      data.quantities[product.productItemId] = productConfig.totalQuantity;
      data.careLabel = productConfig.careLabel;

      // attributes
      Object.keys(productConfig.attributes).forEach((key) => {
        data.attributes[key] = productConfig.attributes[key];
      });

      // variants
      for (const v of itemVariants) {
        let _variant = {
          id: v.id,
          orderQuantity: productConfig.quantities[v.id],
          dynamicData: v.dynamicData
        };

        data.dynamicVariants.variants.push(_variant);
      }

      if (editMode) {
        data.orderItemId = orderItemId;
        await api.updateOrder(data);
        dispatch(pushSuccessMessage("Product successfully updated"));
      } else {
        console.log(data);
        await api.addToOrder(data);
        dispatch(pushSuccessMessage("Product added to your cart"));
      }

      navigate(PAGE_URL.CART);
    } catch (error) {
      showError(error);
    }

    return false;
  };

  const hasInvalidPercentages = (careLabel) => {
    if (!careLabel.garmentComponentsInvalidPercentages) {
      return false;
    }

    setShowValidation(true);
    dispatch(showErrorMessage(INVALID_PERCENTAGES_ERROR, false));

    return true;
  };

  const updateItemVariants = (variants, config = null) => {
    let _customerQuantity = 0;
    for (const v of variants) {
      onQuantityChange(v.id, editMode ? v.cartQuantity : Number(v.dynamicData["Quantity"]), true, config);
      _customerQuantity += Number(v.dynamicData["Quantity"]);
    }

    setCustomerQuantity(_customerQuantity);
    setItemVariants(VariableDataUtils.getSortedDynamicDataVariants(variants));
  };

  const cancel = () => {
    navigate(PAGE_URL.CART);
  };

  const loadDynamicVariableData = async (styleNumber, poNumber) => {
    const productItemId = getParam("productItemId");
    const params = joinParams({
      styleNumber,
      poNumber
    });

    setShowNoResults(false);
    if (!styleNumber || !poNumber) {
      dispatch(showErrorMessage("Style Number AND PO Number are required."));
    } else {
      await showOverlay("Searching...");
      try {
        const response = await api.getVariableDataByStyleAndPoNumber(productItemId, params);
        const variants = response.data.variants;

        if (variants?.length) {
          updateItemVariants(variants);
          setShowDynamicData(true);
          setNumDataCols(Object.keys(variants[0].dynamicData).length);
          setCurrentStyleNumber(styleNumber);
          setCurrentPoNumber(poNumber);
        } else {
          setShowNoResults(true);
        }

        dispatch(clearMessage());
        hideOverlay();
      } catch (error) {
        hideOverlay();
        showError(error);
      }
    }
  };

  return (
    <div className="container">
      <form onSubmit={onAddToOrder}>
        <div className="row">
          <div className="col-12">
            <h4 className="PageTitle">
              <Link className="page-parent" to={PAGE_URL.PRODUCT}>
                PRODUCTS
              </Link>{" "}
              &ndash;{" "}
              <span className="page-current">PRODUCT DETAILS</span>
            </h4>
            {!isEmpty(product) &&
              <DynamicProductDetailsVendor
                product={product ?? {}}
                itemVariants={itemVariants}
                onAttributeChange={onAttributeChange}
                onQuantityChange={onQuantityChange}
                clearQuantities={clearQuantities}
                onCareLabelChange={onCareLabelChange}
                editMode={editMode}
                showValidation={showValidation}
                setShowValidation={setShowValidation}
                productConfig={productConfig}
                loadDynamicVariableData={loadDynamicVariableData}
                showNoResults={showNoResults}
                showDynamicData={showDynamicData}
                numDataCols={numDataCols}
                customerQuantity={customerQuantity}
              />
            }
            {!isEmpty(product) && user.role === ROLES.VENDOR && (
              <div className="d-flex justify-content-end align-items-center mt-5">
                <h5 className="total pr-5">
                  <span>Total:</span>
                  <span className="currency ml-2 mr-1">
                      {user.role === ROLES.VENDOR
                        ? product.prices[0].currency
                        : product.costs[0].currency}
                    </span>
                  <span className="value">{formatCurrency(total)}</span>
                </h5>
                {editMode && (
                  <button
                    className="btn btn-primary shadow cancel-button"
                    type="button"
                    onClick={(e) => cancel()}
                  >
                    Cancel
                  </button>
                )}
                <button
                  ref={addToOrderRef}
                  disabled={!addToOrderEnabled}
                  className="btn btn-primary shadow"
                  type="submit"
                >
                  {editMode ? "Save Changes" : "Add to Order"}
                </button>
              </div>
            )}
          </div>
        </div>
      </form>
    </div>
  );
};
