import React, {useEffect, useRef, useState} from "react";
import {useDispatch} from "react-redux";
import api from "services/apiService";
import {VariableDataProductDetails} from "./VariableDataProductDetails";
import {PAGE_URL} from "constants/url";
import {useCurrentUser} from "hooks/useCurrentUser";
import {useTierCalculator} from "hooks/useTierCalculator";
import {ROLES} from "constants/roles";
import {formatCurrency} from "util/formatNumber";
import {isEmpty, stringToInt, sum} from "util/common";
import {VariableDataUtils} from "util/variableData";
import {useURLNavigate} from "../../../hooks/useURLNavigate";
import {pushSuccessMessage,} from "store/messages/messageActions";
import {useError} from "hooks/errors";
import {Link} from "react-router-dom";
import { PRODUCT_TYPE } from "constants/productType";

export const ProductVariableDataDetailsPage = ({products, editMode, orderItemId}) => {

  const user = useCurrentUser();
  const navigate = useURLNavigate();
  const dispatch = useDispatch();
  const [showError] = useError();
  const getPriceCalculator = useTierCalculator();

  const [productConfigs, setProductConfigs] = useState({});
  const [total, setTotal] = useState(0);
  const [addToOrderEnabled, setAddToOrderEnabled] = useState(true);

  const addToOrderRef = useRef(null);

  useEffect(() => {
    onProductsResponse(products);
  }, []);

  const onProductsResponse = (products) => {

    if (
      (user.role === ROLES.VENDOR || user.role === ROLES.CUSTOMER) ||
      (products[0].hasInventory &&
        (user.role === ROLES.ACCOUNT ||
          user.role === ROLES.FACTORY_ACCOUNT ||
          user.role === ROLES.ADMIN))
    ) {
      let configs = {};
      products.forEach(product => {
        let key = product.productSubcode + product.poNumber;
        configs[key] = createProductConfig(product);
      });
      setProductConfigs(configs);
      updateTotal(configs);
    }

    VariableDataUtils.customSortVariableDataItems(products);
  };

  const createProductConfig = (product) => {
    let config = {
      price: getPriceCalculator((user.role === ROLES.VENDOR || user.role === ROLES.CUSTOMER) ? product.prices : product.costs),
      quantities: {},
      totalCustomerQuantity: 0,
    };

    product.items.forEach(item => {
      config.quantities[item.productItemId] = item.defaultOrderQuantity;
      config.totalCustomerQuantity += item.defaultCustomerQuantity ? item.defaultCustomerQuantity : 0;
    });
    config.totalQuantity = sum(Object.values(config.quantities));

    return config;
  };

  const onQuantitiesRefresh = (product, isChangeByUnit, changeAmount) => {
    const key = product.productSubcode + product.poNumber;
    const _product = product;
    const _configs = { ...productConfigs };
    const _items = _product.items;

    for (const item of _items) {
      let currentQuantity = _configs[key].quantities[item.productItemId];
      let newQuantity;

      if (isChangeByUnit) {
        // By Unit
        newQuantity = currentQuantity + Math.round(changeAmount);
      } else {
        // By Percentage
        newQuantity = currentQuantity + (currentQuantity * (Math.round(changeAmount) / 100));
      }

      newQuantity = Math.round(newQuantity);

      if (newQuantity < _product.minOrderQuantity) {
        newQuantity = _product.minOrderQuantity;
      } else if (newQuantity % _product.packSize !== 0) {
        newQuantity = Math.ceil(newQuantity / _product.packSize) * _product.packSize;
      }

      _configs[key].quantities[item.productItemId] = newQuantity;
      item.defaultOrderQuantity = newQuantity;
    }

    // update products with the updated quantities
    let i = 0;
    while (i < products.length) {
      let p = products[i];
      if ((p.poNumber + p.productSubcode + p.styleNumber) === (_product.poNumber + _product.productSubcode + _product.styleNumber)) {
        break;
      }
      i++;
    }
    _product.items = _items;
    let _products = [...products];
    _products.splice(i, 1, _product);

    // calculate new quantity total
    const _totalQuantity = sum(Object.values(_configs[key].quantities));
    _configs[key].totalQuantity = _totalQuantity;

    setProductConfigs(_configs);
    updateTotal(_configs);
  };

  const onQuantityChange = (productSubcode, productItemId, quantity, poNumber) => {
    let product = products.find(
      (product) => product.productSubcode == productSubcode && product.poNumber == poNumber
    );
    let item = product?.items?.find(
      (item) => item.productItemId == productItemId
    );
    if (item) {
      item.quantity = quantity;
    }

    let configs = { ...productConfigs };
    let key = productSubcode + poNumber;
    configs[key].quantities[productItemId] = stringToInt(quantity);

    // calculate new quantity total
    const _totalQuantity = sum(Object.values(configs[key].quantities));
    configs[key].totalQuantity = _totalQuantity;

    setProductConfigs(configs);
    updateTotal(configs);
  };

  const updateTotal = (configs) => {
    let totalQuantity = 0;
    let totalPrice = 0;

    Object.values(configs).forEach((config) => {
      var quantities = Object.values(config.quantities);
      totalQuantity += sum(quantities);

      quantities.forEach(q => {
        totalPrice += q * config.price(q);
      });
    });

    setAddToOrderEnabled(totalQuantity > 0);
    setTotal(totalPrice);
  };

  const onAddToOrder = async (e) => {
    e.preventDefault();

    try {
      const data = {
        productType: PRODUCT_TYPE.VARIABLE_DATA,
        quantities: {}
      };

      Object.values(productConfigs).forEach((config) => {
        Object.keys(config.quantities).forEach((key) => {
          data.quantities[key] = config.quantities[key];
        });
      });

      if (editMode) {
        data.orderItemId = orderItemId;
        await api.updateOrder(data);
        dispatch(pushSuccessMessage("Product successfully updated"));
      } else {
        await api.addToOrder(data);
        dispatch(pushSuccessMessage("Product added to your cart"));
      }

      navigate(PAGE_URL.CART);
    } catch (error) {
      showError(error);
    }

    return false;
  };

  const cancel = () => {
    navigate(PAGE_URL.CART);
  };

  return (
    <div className="container">
      <form onSubmit={onAddToOrder}>
        <div className="row">
          <div className="col-12">
            <h4 className="PageTitle">
              <Link className="page-parent" to={PAGE_URL.PRODUCT}>
                PRODUCTS
              </Link>{" "}
              &ndash;{" "}
              <span className="page-current">PRODUCT DETAILS</span>
            </h4>
            {products.map((product, index) => (
              <VariableDataProductDetails
                key={index}
                product={product}
                onQuantityChange={onQuantityChange}
                onQuantitiesRefresh={onQuantitiesRefresh}
                totalQuantity={productConfigs[product.productSubcode + product.poNumber]?.totalQuantity}
                totalCustomerQuantity={productConfigs[product.productSubcode + product.poNumber]?.totalCustomerQuantity}
              />
            ))}
            {!isEmpty(products) &&
            (user.role === ROLES.VENDOR &&
              <div className="d-flex justify-content-end align-items-center mt-5">
                <h5 className="total pr-5">
                  <span>Total:</span>
                  <span className="currency ml-2 mr-1">
                      {user.role === ROLES.VENDOR
                        ? products[0].prices[0].currency
                        : products[0].costs[0].currency}
                    </span>
                  <span className="value">{formatCurrency(total)}</span>
                </h5>
                {editMode && (
                  <button
                    className="btn btn-primary shadow cancel-button"
                    type="button"
                    onClick={(e) => cancel()}
                  >
                    Cancel
                  </button>
                )}
                <button
                  ref={addToOrderRef}
                  disabled={!addToOrderEnabled}
                  className="btn btn-primary shadow"
                  type="submit"
                >
                  {editMode ? "Save Changes" : "Add to Order"}
                </button>
              </div>
            )}
          </div>
        </div>
      </form>
    </div>
  );
};
