import React from "react";
import { useState } from "react";
import { stringToInt } from "util/common";
import { useEffect } from "react";

export const PercentageField = ({ initialValue, onPercentageChange, className, ...rest }) => {
    const [percentage, setPercentage] = useState("");

    useEffect(() => {
        if (initialValue) {
            setPercentage(initialValue);
        }
    }, [initialValue]);

    const onChange = (value) => {
        value = value.replace(/\D/, "");
        setPercentage(value);
    };

    const onBlur = () => {
        let p = stringToInt(percentage);

        if (p > 100) {
            p = 100;
            setPercentage(p);
        } else if (p === 0) {
            p = "";
            setPercentage(p);
        }

        onPercentageChange(p);
    };

    const onKeyDown = (e) => {
        if (e.keyCode === 13) {
            e.preventDefault();
            e.target.blur();
        }
    };

    return (
        <input
            {...rest}
            className={className + " fibre-content-percentage form-control form-control"}
            type="text"
            value={percentage}
            onChange={e => onChange(e.target.value)}
            onBlur={onBlur}
            onKeyDown={onKeyDown}
        />
    );
};
