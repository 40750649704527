/* eslint-disable no-multiple-empty-lines */
/* eslint-disable no-trailing-spaces */

// React
import React, { useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { isEmpty, isNullOrUndefined } from "util/common";
import { setAttributeSchemaSearchValues } from "store/attribute-schema/attributeSchemaActions";

export const AttributeSchemaListSearch = ({ 
  searchAttributeSchemas,
  customers,
  productGroup}) => {
  const dispatch = useDispatch();
  const searchValues = useSelector(state => state.attributeSchema.attributeSchemaSearchValues);
  const searchButtonRef = useRef(null);
  const filterRef = useRef(null);

  useEffect(() => {
    filterRef.current.click();
    searchButtonRef.current.click();
  }, []);

  const updateSearchValue = (name, value) => {
    const _searchValues = { ...searchValues };
    _searchValues[name] = value;

    dispatch(setAttributeSchemaSearchValues(_searchValues));
  };

  const onInputKeyDown = (e) => {
    if (e.keyCode === 13) {
      e.preventDefault();
      searchButtonRef.current.click();
    }
  };

  return (
    <>
      <form onKeyDown={onInputKeyDown}>
        <a className="toggleBTN collapsed"
          data-toggle="collapse"
          href="#searchForm"
          role="button"
          aria-expanded="false"
          aria-controls="searchForm"
          ref={filterRef}><span id="ToggleIcon"></span>Filter</a>
        <div className="collapse rounded" id="searchForm">
          <div className="searchForm">
            <div className="form-row">
              <div className="form-group col-3 col-left-none">
                <label>Name</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter Name"
                  value={isNullOrUndefined(searchValues) ? "" : searchValues.name}
                  onChange={e => updateSearchValue("name", e.target.value)}
                />
              </div>
              <div className="form-group col-3 col-left-none">
                <label>Global</label>
                <select
                  className="form-control"
                  onChange={e => updateSearchValue("global", e.target.value === "NONE" ? null : e.target.value)}
                  value={isNullOrUndefined(searchValues) ? false : searchValues.global}>
                  <option value={"NONE"}>--</option>
                  <option value={true}>True</option>
                  <option value={false}>False</option>
                </select>
              </div>
              <div className="form-group col-3 col-left-none">
                <label>Customer</label>
                <select
                  className="form-control"
                  onChange={(e) =>
                    updateSearchValue("customer", e.target.value)
                  }
                  value={
                    isNullOrUndefined(searchValues) ? "" : searchValues.customer
                  }
                >
                  {customers.map((c, index) => (
                    <option key={index} value={c.id}>
                      {c.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="form-group col-3 col-left-none">
                <label>Product Group</label>
                <select
                  className="form-control"
                  onChange={(e) =>
                    updateSearchValue("productGroup", e.target.value)
                  }
                  value={
                    isNullOrUndefined(searchValues) ? "" : searchValues.productGroup
                  }
                >
                  {productGroup.map((p, index) => (
                    <option key={index} value={p.id}>
                      {p.name}
                    </option>
                  ))}
                </select>
              </div>
              <div id="searchButtonCol" className="col-3 col-left-none offset-9">
                <button
                  ref={searchButtonRef}
                  className="searchBTN btn btn-block btn-outline-primary"
                  type="button"
                  onClick={() => searchAttributeSchemas(isEmpty(searchValues) ? {} : searchValues)}>
                  Search
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  );
};
