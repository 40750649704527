import React from "react";
import PropTypes from "prop-types";
import { Route } from "react-router";

export const PublicRoute = ({ children, ...rest }) => {
  return (
    <Route {...rest}>{children}</Route>
  );
}

PublicRoute.propTypes = {
  children: PropTypes.node
};
