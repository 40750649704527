// React
import React, { useEffect, useState } from "react";

// Local
import { isEmpty, isNotEmpty, stringToInt, stringToFloat } from "util/common";
import { TierTable } from "./TierTable";
import { LabelSelectField } from "components/LabelSelectField";
import { getNameAndIdFromList, optionFromNameAndId } from "constants/options";
import { useDispatch } from "react-redux";
import { showErrorMessage } from "store/messages/messageActions";
import { LabelNumberField } from "components/LabelNumberField";
import { formatCurrency } from "util/formatNumber";

export const PriceAndCostTiers = ({
  editMode,
  createMode,
  priceTiers,
  costTiers,
  minValues,
  currencies,
  costCurrency,
  addSubcodeTier,
  updateSubcodeTier,
  removeSubCodeTier,
  updateMinValue
}) => {
  // hooks
  const dispatch = useDispatch();

  // price state
  const [createPrice, setCreatePrice] = useState(createMode ? { quantity: 1 } : "");
  const [selectedPrice, setSelectedPrice] = useState("");
  const [editPrice, setEditPrice] = useState("");
  const [priceCurrency, setPriceCurrency] = useState("");
  // cost state
  const [createCost, setCreateCost] = useState(createMode ? { quantity: 1 } : "");
  const [selectedCost, setSelectedCost] = useState("");
  const [editCost, setEditCost] = useState("");
  const [previousCostCurrency, setPreviousCostCurrency] = useState(
    costCurrency
  );
  // min order value state
  const [selectedMinValue, setSelectedMinValue] = useState("");
  const [editMinValue, setEditMinValue] = useState("");

  // constants
  const EXISTING_TIER_MESSAGE =
    "A tier for this quantity already exists. To alter this tier please select it, then edit it's values.";
  const MISSING_REQUIRED_FIELDS = "Missing requred tier fields.";
  const ZERO_QUANTITY_ERROR = "A tier cannot have quantity 0.";
  const MISSING_CURRENCY_ERROR = "No currency is selected.";

  useEffect(() => {
    // default currency
    const USD = currencies.find(c => c.name === "USD");
    if (USD) {
      setPriceCurrency(USD);
    } else {
      setPriceCurrency(currencies[0]);
    }
  }, []);

  useEffect(() => {
    if (!editMode) {
      setEditPrice("");
      setEditCost("");
      setSelectedPrice("");
      setSelectedCost("");
      setCreatePrice("");
      setCreateCost("");
      setSelectedMinValue("");
    } else if (isEmpty(priceTiersForVendorCurrency())) {
      setCreatePrice({ quantity: 1 });
    } else if (isEmpty(costTiersForFactoryCurrency())) {
      setCreateCost({ quantity: 1 });
    }
  }, [editMode]);

  useEffect(() => {
    // deselect edit option when factory currency changes
    if (costCurrency.name !== previousCostCurrency.name) {
      if (isEmpty(costTiersForFactoryCurrency())) {
        setCreateCost({ quantity: 1 });
      }
      setEditCost("");
      setSelectedCost("");
      setPreviousCostCurrency(costCurrency);
    }
  }, [costCurrency]);

  useEffect(() => {
    if (isEmpty(priceTiersForVendorCurrency())) {
      setCreatePrice({ quantity: 1 });
    }
    setSelectedPrice("");
    setEditPrice("");

  }, [priceCurrency]);

  const priceTiersForVendorCurrency = () => {
    return priceTiers.filter(
      (tier) => tier.currency === priceCurrency.name
    );
  };

  const minValueForVendorCurrency = () => {
    const minValue = minValues.find(min => min.currency === priceCurrency.name);

    return isEmpty(minValue) ? "" : minValue.price;
  };

  const costTiersForFactoryCurrency = () => {
    return costTiers.filter(
      (tier) => tier.currency === costCurrency.name
    );
  };

  const updateTierEntityField = (entity, setEntity, field, value) => {
    const _editEntity = { ...entity };
    _editEntity[field] = value;
    setEntity(_editEntity);
  };

  const getFieldIfPresent = (entity, field) => {
    return isEmpty(entity) || isEmpty(entity[field]) ? "" : entity[field];
  };

  const areSameTier = (tier1, tier2) => {
    return (
      tier1 &&
      tier2 &&
      tier1?.id == tier2?.id &&
      tier1?.currency === tier2?.currency &&
      tier1.price === tier2.price &&
      tier1.quantity === tier2.quantity
    );
  };

  const onClickPriceTier = (tier) => {
    if (areSameTier(selectedPrice, tier)) {
      setSelectedPrice("");
      setEditPrice("");
    } else {
      setSelectedPrice(tier);
      setEditPrice(tier);
    }
  };

  const onClickCostTier = (tier) => {
    if (areSameTier(selectedCost, tier)) {
      setSelectedCost("");
      setEditCost("");
    } else {
      setSelectedCost(tier);
      setEditCost(tier);
    }
  };

  const onQuantityBlur = (
    editEntity,
    setEditEntity,
    createEntity,
    setCreateEntity
  ) => {
    if (editEntity) {
      // changing existing price tier
      if (!editEntity.quantity || editEntity.quantity <= 0) {
        updateTierEntityField(editEntity, setEditEntity, "quantity", 1);
      } else if (editEntity.quantity % 1 != 0) {
        updateTierEntityField(
          editEntity,
          setEditEntity,
          "quantity",
          Math.round(editEntity.quantity)
        );
      }
    } else {
      // creating new price tier
      if (!isEmpty(createEntity) && !isEmpty(createEntity.quantity)) {
        if (createEntity.quantity <= 0) {
          updateTierEntityField(createEntity, setCreateEntity, "quantity", 1);
        } else if (createEntity.quantity % 1 != 0) {
          updateTierEntityField(
            createEntity,
            setCreateEntity,
            "quantity",
            Math.round(createEntity.quantity)
          );
        }
      }
    }
  };

  const onPriceBlur = (
    editEntity,
    setEditEntity,
    createEntity,
    setCreateEntity
  ) => {
    if (editEntity) {
      if (!editEntity.price || editEntity.price < 0) {
        updateTierEntityField(editEntity, setEditEntity, "price", 0);
      }
    } else {
      if (!isEmpty(createEntity) && createEntity.price) {
        if (createEntity.price < 0) {
          updateTierEntityField(createEntity, setCreateEntity, "price", 0);
        }
      }
    }
  };

  const onMinValueBlur = (minPrice) => {
    if (minPrice < 0) {
      updateMinValue(priceCurrency.name, 0);
    }

  };

  const sameQuantityTierExists = (tiers, tier) => {
    return isNotEmpty(
      tiers.find(
        (t) => t.currency === tier.currency && t.quantity === tier.quantity
      )
    );
  };

  // add
  const onAddPriceTier = () =>
    addTier(priceTiers, createPrice, setCreatePrice, priceCurrency, "priceTiers");
  const onAddCostTier = () =>
    addTier(costTiers, createCost, setCreateCost, costCurrency, "costTiers");

  const addTier = (existingTiers, newTier, setNewTier, currency, field) => {

    // validation
    const valid = isAddEditDataValid(newTier, currency);
    if (!valid) {
      return;
    }

    let _newTier = { ...newTier };
    _newTier.currency = currency.name;

    // if tier for same quantity and currency exists
    if (sameQuantityTierExists(existingTiers, _newTier)) {
      dispatch(showErrorMessage(EXISTING_TIER_MESSAGE));
      return;
    }

    addSubcodeTier(field, _newTier);
    setNewTier("");
  };

  // update
  const onUpdatePriceTier = () =>
    updateTier(
      editPrice,
      selectedPrice,
      setSelectedPrice,
      priceCurrency,
      priceTiers,
      "priceTiers"
    );
  const onUpdateCostTier = () =>
    updateTier(
      editCost,
      selectedCost,
      setSelectedCost,
      costCurrency,
      costTiers,
      "costTiers"
    );

  const updateTier = (
    editTier,
    selectedTier,
    setSelectedTier,
    currency,
    existingTiers,
    field
  ) => {
    // validation
    const valid = isAddEditDataValid(editTier, currency);
    if (!valid) {
      return;
    }

    let _newTier = { ...editTier };
    _newTier.currency = currency.name;

    // if tier for same quantity and currency exists
    if (_newTier.quantity !== selectedTier.quantity && sameQuantityTierExists(existingTiers, _newTier)) {
      dispatch(showErrorMessage(EXISTING_TIER_MESSAGE));
      return;
    }

    // if changing quantity from 1 to anything
    if (selectedTier.quantity === 1 && _newTier.quantity !== 1) {
      dispatch(showErrorMessage("This tiers quantity is not editable."));
      return;
    }

    const success = updateSubcodeTier(field, _newTier, selectedTier);
    if (success) {
      setSelectedTier(_newTier);
    }
  };

  const isAddEditDataValid = (tier, currency) => {
    if (isEmpty(tier) || isEmpty(tier.quantity) || isEmpty(tier.price)) {
      dispatch(showErrorMessage(MISSING_REQUIRED_FIELDS));
      return false;
    }

    if (isEmpty(currency) || isEmpty(currency.name)) {
      dispatch(showErrorMessage(MISSING_CURRENCY_ERROR));
      return false;
    }

    if (tier.quantity === 0) {
      dispatch(showErrorMessage(ZERO_QUANTITY_ERROR));
      return false;
    }

    return true;
  };

  // remove
  const onRemovePriceTier = () =>
    removeTier(selectedPrice, setSelectedPrice, setEditPrice, "priceTiers");
  const onRemoveCostTier = () =>
    removeTier(selectedCost, setSelectedCost, setEditCost, "costTiers");

  const removeTier = (selectedTier, setSelectedTier, setEditTier, field) => {
    if (isEmpty(selectedTier)) {
      dispatch(showErrorMessage("Missing selection to remove."));
      return;
    }

    if (selectedTier.quantity === 1) {
      dispatch(showErrorMessage("A tier with a quantity of 1 is required."));
      return;
    }

    removeSubCodeTier(field, selectedTier);
    setSelectedTier("");
    setEditTier("");
  };

  return (
    <div className="card shadow">
      <div className="card-body">
        <div className="row price-cost-tiers">
          <div className="col">
            <label>Price Tiers</label>
            <div className="row currency-select">
              <ul>
                <LabelSelectField
                  label={"Vendor Currency"}
                  value={
                    editMode
                      ? isEmpty(priceCurrency)
                        ? 1
                        : priceCurrency.id
                      : isEmpty(priceCurrency)
                      ? ""
                      : priceCurrency.name
                  }
                  options={currencies.map((c) => optionFromNameAndId(c))}
                  editMode={editMode}
                  onChange={(id) => setPriceCurrency(getNameAndIdFromList(currencies, id))}
                />
                <LabelNumberField
                    label={"Min. Order Value"}
                    value={editMode ? minValueForVendorCurrency() : isEmpty(minValueForVendorCurrency()) ? "" : formatCurrency(parseInt(minValueForVendorCurrency()))}
                    editMode={editMode}
                    onChange={(minPrice) => updateMinValue(priceCurrency.name, minPrice)}
                    onBlur={onMinValueBlur}
                    disabled={!editMode}
                    step=".01"
                />
              </ul>
            </div>
            <div className="row field-row" style={{ paddingLeft: "6px" }}>
              <input
                className="form-control quantity-field"
                type="number"
                placeholder="Enter a Quantity"
                value={
                  editPrice
                    ? editPrice.quantity
                    : getFieldIfPresent(createPrice, "quantity")
                }
                onChange={(e) => {
                  updateTierEntityField(
                    isEmpty(editPrice) ? createPrice : editPrice,
                    isEmpty(editPrice) ? setCreatePrice : setEditPrice,
                    "quantity",
                    stringToInt(e.target.value)
                  );
                }}
                onBlur={() =>
                  onQuantityBlur(
                    editPrice,
                    setEditPrice,
                    createPrice,
                    setCreatePrice
                  )
                }
                disabled={!editMode || isEmpty(priceTiersForVendorCurrency()) || selectedPrice?.quantity === 1}
              ></input>
              <input
                className="form-control price-field"
                type="number"
                step=".01"
                placeholder="Enter a Unit Price"
                value={
                  editPrice
                    ? editPrice.price
                    : getFieldIfPresent(createPrice, "price")
                }
                onChange={(e) => {
                  updateTierEntityField(
                    isEmpty(editPrice) ? createPrice : editPrice,
                    isEmpty(editPrice) ? setCreatePrice : setEditPrice,
                    "price",
                    stringToFloat(e.target.value)
                  );
                }}
                onBlur={() =>
                  onPriceBlur(
                    editPrice,
                    setEditPrice,
                    createPrice,
                    setCreatePrice
                  )
                }
                disabled={!editMode}
              ></input>
              <button
                className={"btn btn-primary add-tier-btn"}
                onClick={editPrice ? onUpdatePriceTier : onAddPriceTier}
                disabled={!editMode}
              >
                {editPrice ? "Update" : "Add"}
              </button>
              <button
                className={"btn btn-danger remove-tier-btn"}
                onClick={onRemovePriceTier}
                disabled={!editMode || !selectedPrice || selectedPrice?.quantity === 1}
              >
                Remove
              </button>
            </div>
            {priceTiers && (
              <TierTable
                tiers={priceTiersForVendorCurrency()}
                selectedTier={selectedPrice}
                editMode={editMode}
                onClick={onClickPriceTier}
              />
            )}
          </div>
          <div className="col">
            <label>Cost Tiers</label>
            <div
              className="row"
              style={editMode ? { margin: "1rem 0rem" } : { margin: "8px 0" }}
            >
              <label style={{ marginRight: "8px" }}>
                {"Factory Currency:"}
              </label>
              <div className="value">{costCurrency.name}</div>
            </div>
            <div className="row field-row" style={{ paddingLeft: "6px" }}>
              <input
                className="form-control quantity-field"
                type="number"
                placeholder="Enter a Quantity"
                value={
                  editCost
                    ? editCost.quantity
                    : getFieldIfPresent(createCost, "quantity")
                }
                onChange={(e) => {
                  updateTierEntityField(
                    isEmpty(editCost) ? createCost : editCost,
                    isEmpty(editCost) ? setCreateCost : setEditCost,
                    "quantity",
                    stringToInt(e.target.value)
                  );
                }}
                onBlur={() =>
                  onQuantityBlur(
                    editCost,
                    setEditCost,
                    createCost,
                    setCreateCost
                  )
                }
                disabled={!editMode || isEmpty(costTiersForFactoryCurrency()) || selectedCost?.quantity === 1}
              ></input>
              <input
                className="form-control price-field"
                type="number"
                step=".01"
                placeholder="Enter a Unit Cost"
                value={
                  editCost
                    ? editCost.price
                    : getFieldIfPresent(createCost, "price")
                }
                onChange={(e) => {
                  updateTierEntityField(
                    isEmpty(editCost) ? createCost : editCost,
                    isEmpty(editCost) ? setCreateCost : setEditCost,
                    "price",
                    stringToFloat(e.target.value)
                  );
                }}
                onBlur={() =>
                  onPriceBlur(editCost, setEditCost, createCost, setCreateCost)
                }
                disabled={!editMode}
              ></input>
              <button
                className={"btn btn-primary add-tier-btn"}
                onClick={editCost ? onUpdateCostTier : onAddCostTier}
                disabled={!editMode}
              >
                {editCost ? "Update" : "Add"}
              </button>
              <button
                className={"btn btn-danger remove-tier-btn"}
                onClick={onRemoveCostTier}
                disabled={!editMode || !selectedCost || selectedCost?.quantity === 1}
              >
                Remove
              </button>
            </div>
            {costTiers && (
              <TierTable
                tiers={costTiersForFactoryCurrency()}
                selectedTier={selectedCost}
                editMode={editMode}
                name={"Unit Cost"}
                onClick={onClickCostTier}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
