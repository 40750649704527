import React from "react";
import { Dialog, Button, DialogTitle, DialogContent, DialogActions, DialogContentText } from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import { hideAlert } from "store/alerts/alertActions";

export const AlertWindow = () => {
    const alert = useSelector(state => state.alerts.alert);
    const dispatch = useDispatch();

    const cancel = () => {
        if (alert.cancelAction) {
            alert.cancelAction();
        }

        dispatch(hideAlert());
    };

    const confirm = () => {
        if (alert.confirmAction) {
            alert.confirmAction();
        }

        dispatch(hideAlert());
    };

    return (alert ?
        <div>
            <Dialog
                open={true}
                className="alert-window"
            >
                <DialogTitle className="alert-window-title">{alert.title}</DialogTitle>
                <DialogContent>
                    <DialogContentText className="alert-window-content">{alert.description}</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button className="alert-window-primary-btn" onClick={cancel}>{alert.cancelMessage}</Button>
                    <Button className="alert-window-secondary-btn" onClick={confirm}>{alert.confirmMessage}</Button>
                </DialogActions>
            </Dialog>
        </div>
        :
        <></>
    );
};
