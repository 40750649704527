import React from "react";
import { TextInput } from "components/TextInput";
import { TextArea } from "components/TextArea";

export const CartBillingForm = ({ cartConfig, onChange, onBlur }) => {
    return (
        <div className="row">
            <div className="col-12">
                <div className="card" id="payment">
                    <div className="card-body">
                        <h2>Payment</h2>
                        <div className="row">
                            {/* Billing Account */}
                            <div className="col-4">
                                <div className="form-group mt-4">
                                    <label>Billing Account</label>
                                    <TextInput
                                        className="form-control"
                                        value={cartConfig.billingAccount}
                                        onChange={value => onChange("billingAccount", value)}
                                        onBlur={onBlur}
                                    />
                                </div>
                            </div>

                            {/* Bill To */}
                            <div className="col-4">
                                <h3>Bill To:</h3>
                                <div className="form-group">
                                    <label>Address *</label>
                                    <TextArea
                                        className="form-control address-field"
                                        value={cartConfig.billingAddress}
                                        allowEnter={true}
                                        onChange={value => onChange("billingAddress", value)}
                                        onBlur={onBlur}
                                        required
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};