/* eslint-disable no-trailing-spaces */
/* eslint-disable no-unused-vars */
/* eslint-disable no-multiple-empty-lines */

// React
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

// Local
import { api } from "../../services/apiService";
import { PageLayout } from "layouts/PageLayout";
import { useURLNavigate } from "hooks/useURLNavigate";
import { useURLParams } from "hooks/useURLParams";
import { useOverlay } from "hooks/useOverlay";
import { useError } from "hooks/errors";
import { PAGE_URL } from "constants/url";
import { isEmpty, isNullOrUndefined} from "util/common";
import { TopLevelProductionRegionInfo } from "./TopLevelProductionRegionInfo";
import { showSuccessMessage, pushSuccessMessage } from "store/messages/messageActions";
import { cloneDeep } from "lodash";

export const ProductionRegionDetailPage = () => {
  // hooks
  const navigate = useURLNavigate();
  const [showError, pushError] = useError();
  const [getParam] = useURLParams();
  const [showOverlay, hideOverlay] = useOverlay();
  const dispatch = useDispatch();
  const message = useSelector(state => state.message.message);
  
  // state
  const [editMode, setEditMode] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [productionRegion, setProductionRegion] = useState();
  const [editProductionRegion, setEditProductionRegion] = useState();

  // url variables
  const productionRegionId = getParam("id");
  const createMode = getParam("createMode");
  
  useEffect(() => {
    loadData();
  }, []);

  const loadData = async () => {

    // handle url hack
    if (createMode && !isEmpty(productionRegionId)) {
      pushError("Invalid URL params");
      navigate(PAGE_URL.PRODUCTION_REGION);
    }

    if (!isEmpty(productionRegionId)) {
      // load productionRegion
      try {
        const productionRegionResponse = await api.getProductionRegion(productionRegionId);
        setProductionRegion(productionRegionResponse.data);
        setEditProductionRegion(productionRegionResponse.data);
      } catch (error) {
        pushError(error);
        navigate(PAGE_URL.HOME);
      }
    }
    if (createMode) {
      // setup new productionRegion
      setEditMode(true);
      setEditProductionRegion({
        productionRegionName: "",
      });
    }
    setLoaded(true);

  };
  
  const toggleEditMode = () => {
    if (createMode) {
      navigate(PAGE_URL.PRODUCTION_REGION);
    } else if (editMode) {
      setEditProductionRegion(cloneDeep(productionRegion)); // revert changes
      setEditMode(false);
    } else {
      setEditMode(true);
    }
  };

  const onSave = async () => {

    if (isNullOrUndefined(message)) {
      await showOverlay("Saving...");
    }

    if (createMode) {
      try {
        await api.createProductionRegion(editProductionRegion);
        dispatch(pushSuccessMessage("Production Region Successfully Created"));
        navigate(PAGE_URL.PRODUCTION_REGION);
      } catch (error) {
        showError(error);
      }

    } else {
      try {
        await api.updateProductionRegion(productionRegion.id, editProductionRegion);
        setProductionRegion(cloneDeep(editProductionRegion));
        dispatch(showSuccessMessage("Production Region Successfully Updated"));
      } catch (error) {
        showError(error);
        setEditProductionRegion(productionRegion);
      } finally {
        setEditMode(false);
      }
    }

    hideOverlay();
  };

  const editProductionRegionField = (field, value) => {
    let _editProductionRegion = { ...editProductionRegion };
    _editProductionRegion[field] = value;
    setEditProductionRegion(_editProductionRegion);
  };

  return (
    <PageLayout className="production-region-details">
      <section className="site-content bodyWrap" id="content">
        <section className="content-body">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <h4 className="PageTitle">
                  <Link className="page-parent" to={PAGE_URL.PRODUCTION_REGION}>
                    PRODUCTION REGIONS
                  </Link>{" "}
                  &ndash; <span className="page-current">PRODUCTION REGION DETAILS</span>
                </h4>
              </div>
            </div>
            {loaded && (
              <div className="row">
                <div className="col-12">
                  <TopLevelProductionRegionInfo
                    productionRegion={productionRegion}
                    editProductionRegion={editProductionRegion}
                    editProductionRegionField={editProductionRegionField}
                    editMode={editMode}
                    createMode={createMode}
                    toggleEditMode={toggleEditMode}
                    onSave={onSave}
                  />
                </div>
              </div>
            )}
          </div>
        </section>
      </section>
    </PageLayout>
  );
};
