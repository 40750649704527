import { getContentDispositionFileName } from "util/common";

export const downloadFile = (response) => {
    const fileName = getContentDispositionFileName(response.headers["content-disposition"]);
    const blob = new Blob([response.data], {type: response.headers["content-type"]});
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);    
};
