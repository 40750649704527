/* eslint-disable no-multiple-empty-lines */
/* eslint-disable no-trailing-spaces */

// React
import React, { useRef, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

// Local
import api from "services/apiService";
import { isEmpty, isNullOrUndefined } from "util/common";
import { useError } from "hooks/errors";
import { setVendorSearchValues } from "store/vendor/venorActions";

export const VendorListSearch = ({ searchVendors }) => {
  // hooks
  const dispatch = useDispatch();
  const [showError] = useError();
  const searchValues = useSelector((state) => state.vendor.searchValues);
  const searchButtonRef = useRef(null);
  const filterRef = useRef(null);

  // state
  const [customers, setCustomers] = useState([]);

  useEffect(() => {
    loadData();
  }, []);

  useEffect(() => {
    if (!isEmpty(customers)) {
      filterRef.current.click();
      searchButtonRef.current.click();
    }
  }, [customers]);

  const loadData = async () => {
    try {
      const response = await api.getAllCustomers();
      let _customers = response.data.customers;
      let none = { id: "", name: "---" };
      // add to beginning of array
      _customers.unshift(none);

      setCustomers(_customers);
    } catch (error) {
      showError(error);
    }
  };

  const updateSearchValue = (name, value) => {
    const _searchValues = { ...searchValues };
    _searchValues[name] = value;
    dispatch(setVendorSearchValues(_searchValues));
  };

  const onInputKeyDown = (e) => {
    if (e.keyCode === 13) {
      e.preventDefault();
      searchButtonRef.current.click();
    }
  };

  return (
    <form onKeyDown={onInputKeyDown}>
      <a
        className="toggleBTN collapsed"
        data-toggle="collapse"
        href="#searchForm"
        role="button"
        aria-expanded="false"
        aria-controls="searchForm"
        ref={filterRef}
      >
        <span id="ToggleIcon"></span>Filter
      </a>
      <div className="collapse rounded" id="searchForm">
        <div className="searchForm">
          <div className="form-row">
            <div className="form-group col-3 col-left-none">
              <label>Company Name</label>
              <input
                type="text"
                className="form-control"
                placeholder="Enter Company Name"
                value={
                  isNullOrUndefined(searchValues)
                    ? ""
                    : searchValues.companyName
                }
                onChange={(e) =>
                  updateSearchValue("companyName", e.target.value)
                }
              />
            </div>
            <div className="form-group col-3">
              <label>Address</label>
              <input
                type="text"
                className="form-control"
                placeholder="Enter Shipping or Billing Address"
                value={
                  isNullOrUndefined(searchValues) ? "" : searchValues.address
                }
                onChange={(e) => updateSearchValue("address", e.target.value)}
              />
            </div>
            <div className="form-group col-3">
              <label>Customer</label>
              <select
                className="form-control"
                onChange={(e) =>
                  updateSearchValue("customerId", e.target.value)
                }
                value={
                  isNullOrUndefined(searchValues) ? "" : searchValues.customerId
                }
              >
                {customers.map((c, index) => (
                  <option key={index} value={c.id}>
                    {c.name}
                  </option>
                ))}
              </select>
            </div>
            <div id="searchButtonCol" className="col-3 mt-4">
              <button
                ref={searchButtonRef}
                className="searchBTN btn btn-block btn-outline-primary"
                type="button"
                onClick={() =>
                  searchVendors(isEmpty(searchValues) ? {} : searchValues)
                }
              >
                Search
              </button>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};
