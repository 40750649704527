import moment from "moment";

export const startOfDay = (date) => {
    return date ? moment(date).startOf('day').toDate() : null;
};

export const endOfDay = (date) => {
    return date ? moment(date).endOf('day').toDate() : null;
};

export const formatDateForUI = (date) => {
    return date ? moment(date).format("DD-MMM-YY") : null;
};

export const formatDateForVariableData = (date) => {
    return date ? moment(date).format("MM/DD/YYYY hh:mm A") : null;
};

export const formatDateWithTimeForUI = (date) => {
    return date ? moment(date).format("DD-MMM-YY HH:mm:ss") : null;
};

export const formatDateForAPI = (date) => {
    return date ? moment(date).format("YYYY-MM-DD HH:mm:ss") : null;
};
