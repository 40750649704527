// React
import React from "react";
import { useURLNavigate } from "hooks/useURLNavigate";
import { joinParams } from "components/joinParams";
import { PAGE_URL } from "constants/url";



export const ProductItemListRow = ({ productItem, subcode }) => {
  const navigate = useURLNavigate();

  const onClick = () => {
    const params = joinParams({
      id: productItem.id,
      subcode: subcode
    });
    console.log("URL", PAGE_URL.PRODUCT_ITEM_DETAILS, params);
    navigate(PAGE_URL.PRODUCT_ITEM_DETAILS, params);
  };

  return (
    <tr className={productItem.productType === "STANDARD" ? "pf-row" : ""} onClick={productItem.productType === "STANDARD" ? onClick : null}>
      <td className="data">{productItem.productGroup}</td>
      <td className="data">{productItem.productSubcode}</td>
      <td className="data">{productItem.productSize}</td>
      <td className="data">{productItem.productType}</td>
      <td className="data">{productItem.productSubgroup}</td>
    </tr>
  );
};
