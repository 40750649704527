import React from "react";
import { OrderStatusCircle } from "./OrderStatusCircle";
import { ORDER_STATUS_ENUM } from "constants/orderStatus";

export const OrderStatusCircles = ({orderStatus, financeApproval, hasArtwork}) => {
    const orderStep = ORDER_STATUS_ENUM[orderStatus];

    return (
        <div className="status-icons">
            <OrderStatusCircle name="placed" tooltip="Placed" step={1} orderStep={orderStep} />
            {hasArtwork && <OrderStatusCircle name="art-prepared" tooltip="Art Prepared" step={2} orderStep={orderStep} />}
            {hasArtwork && <OrderStatusCircle name="art-approved" tooltip="Art Approved" step={3} orderStep={orderStep} />}
            <OrderStatusCircle name="production" tooltip="Production" step={4} orderStep={orderStep} />
            <OrderStatusCircle name="finance" tooltip="Finance" active={financeApproval} />
            <OrderStatusCircle name="shipped" tooltip="Shipped" step={6} orderStep={orderStep} />
        </div>
    );
};
