import * as types from "./authActionTypes";

export const loginUser = () => {
  return {
    type: types.LOGIN_USER,
  };
};

export const loginSuccess = (data) => {
  return {
    type: types.LOGIN_USER_SUCCESS,
    data,
  };
};

export const loginError = () => {
  return {
    type: types.LOGIN_USER_ERROR,
  };
};

export const isAuthenticated = () => {
  return {
    type: types.IS_AUTHENTICATED
  };
};

export const notAuthenticated = () => {
  return {
    type: types.NOT_AUTHENTICATED
  };
};

export const logoutUser = () => {
  return {
    type: types.LOGOUT_USER
  };
};

export const logoutSuccess = (data) => {
  return {
    type: types.LOGOUT_USER_SUCCESS,
    data,
  };
};

export const logoutError = (error) => {
  return {
    type: types.LOGOUT_USER_ERROR,
    error
  };
};

export const loginRedirectPathAction = (data) => {
  return {
    type: types.LOGIN_REDIRECT_PATH,
    data
  };
};
