import React from "react";
import api from "services/apiService";
import { useError } from "hooks/errors";
import { useOverlay } from "hooks/useOverlay";
import { downloadFile } from "util/download";

export const OrderDetailShippingDoc = ({ orderId, shippingDoc }) => {
    const [showError, ] = useError();
    const [showOverlay, hideOverlay] = useOverlay();

    const downloadDocument = async () => {
        try {
            showOverlay("Downloading...");
            const response = await api.downloadShippingDocument(orderId, shippingDoc.documentId)
            downloadFile(response);
            hideOverlay();
        } catch (error) {
            hideOverlay();
            showError(error);
        }
    };

    return (
            <ul className="readonly flex-list">
                <li className="flex-list-item">
                    <div className="value">
                        <button onClick={() => downloadDocument(shippingDoc)} className="file-btn-link">{shippingDoc.name}</button>
                    </div>
                </li>
            </ul>
    );
};
