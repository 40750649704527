import { LabelValue } from "components/LabelValue";
import React from "react";
import { formatCurrency } from "util/formatNumber";

export const OrderCharge = ({ charge }) => {
  return (
    <li>
      <div className="order-charge card shadow">
        <ul>
          <LabelValue label="Charge Type" value={charge.chargeName} />
          <LabelValue label="Product Code" value={charge.subcode} />
          <LabelValue label="Description" value={charge.subCodeDescription} />
          <LabelValue
            label="Charge Price"
            value={
              " " + charge.charge.currency + " " + formatCurrency(charge.charge.price)
            }
          />
        </ul>
      </div>
    </li>
  );
};
