import * as types from "./cartActionTypes";

const initialState = {
    cartStatus: false
};

export const cartReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.LOAD_CART_STATUS_SUCCESS: {
          const data = action.data;
          return { ...state, cartStatus: data.status};
        }
        default:
            return state;
    }
};
