import * as types from "./authActionTypes";
import { setAccessToken, setRole, clearTokens, getUserInfoFromStorage } from "../../services/authService";

const initialState = {
  isAuthenticated: false,
  user: {},
  loginRedirectPath: null
};

export const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.LOGIN_USER_SUCCESS: {
      const data = action.data;
      setAccessToken(data.access_token);
      setRole(data.role);
      const userInfo = getUserInfoFromStorage();
      return { ...state, isAuthenticated: true, user: userInfo };
    }
    case types.LOGOUT_USER: {
      clearTokens();
      return initialState;
    }
    case types.IS_AUTHENTICATED: {
      const userInfo = getUserInfoFromStorage();
      return { ...state, isAuthenticated: true, user: userInfo };
    }
    case types.NOT_AUTHENTICATED: {
      return initialState;
    }
    case types.LOGIN_REDIRECT_PATH: {
      return {...state, loginRedirectPath: action.data.path};
    }
    default:
      return state;
  }
};