// React
import React, { useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

// Local
import { setProductCodeSearchValues } from "store/product-codes/productCodeActions";
import { isEmpty, isNullOrUndefined } from "util/common";

export const ProductCodeSearch = ({ onSearch }) => {
  const dispatch = useDispatch();
  const searchValues = useSelector((state) => state.productCodes.searchValues);
  const searchButtonRef = useRef(null);
  const filterRef = useRef(null);

  useEffect(() => {
    filterRef.current.click();
    searchButtonRef.current.click();
  }, []);

  const updateSearchValue = (name, value) => {
    const _searchValues = { ...searchValues };
    _searchValues[name] = value;

    dispatch(setProductCodeSearchValues(_searchValues));
  };

  const onInputKeyDown = (e) => {
    if (e.keyCode === 13) {
      e.preventDefault();
      searchButtonRef.current.click();
    }
  };

  return (
    <form onKeyDown={onInputKeyDown}>
      <a
        className="toggleBTN collapsed"
        data-toggle="collapse"
        href="#searchForm"
        role="button"
        aria-expanded="false"
        aria-controls="searchForm"
        ref={filterRef}
      >
        <span id="ToggleIcon"></span>Filter
      </a>
      <div className="collapse rounded" id="searchForm">
        <div className="searchForm">
          <div className="form-row">
            <div className="form-group col-5 col-left-none">
              <label>Product Code</label>
              <input
                type="text"
                className="form-control"
                placeholder="Enter Product Code"
                value={isNullOrUndefined(searchValues) ? "" : searchValues.productCode}
                onChange={(e) => updateSearchValue("productCode", e.target.value)}
              />
            </div>
            <div className="form-group col-3 col-left-none" id="searchButtonCol">
              <button
                ref={searchButtonRef}
                className="searchBTN btn btn-block btn-outline-primary"
                type="button"
                onClick={() =>
                  onSearch(isEmpty(searchValues) ? {} : searchValues)
                }
              >
                Search
              </button>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};
