import { isEmpty } from "util/common";
import { formatDateForAPI } from "./formatDate";

export const joinParams = (params) => {
  let paramsList = [];

  Object.keys(params).forEach((key) => {
    let value = params[key];

    if (!isEmpty(value)) {
      if (value instanceof Date) {
        paramsList.push(key + "=" + formatDateForAPI(value));
      } else if (Array.isArray(value)) {
        paramsList.push(key + "=" + value.map(v => encodeURIComponent(v)).join(","));
      } else {
        paramsList.push(key + "=" + encodeURIComponent(value));
      }
    }
  });

  return paramsList.join("&");
};
