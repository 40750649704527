import React, { useState } from "react";
import { useSelector } from "react-redux";
import { PageLayout } from "../../layouts/PageLayout";
import api from "services/apiService";
import { ProductionRegionListSearch } from "./ProductionRegionListSearch";
import { ProductionRegionListRow } from "./ProductionRegionListRow";
import { joinParams } from "components/joinParams";
import { pluralize, isNullOrUndefined, isEmpty } from "util/common";
import { useOverlay } from "hooks/useOverlay";
import { useError } from "hooks/errors";
import { useURLNavigate } from "hooks/useURLNavigate";
import { PAGE_URL } from "constants/url";

export const ProductionRegionListPage = () => {
  const [showOverlay, hideOverlay] = useOverlay();
  const message = useSelector(state => state.message.message);
  const [productionRegions, setProductionRegions] = useState([]);
  const [showError, ] = useError();
  const navigate = useURLNavigate();

  const searchProductionRegions = async (searchValues) => {
    try {
      const params = getParams(searchValues);
      if (isNullOrUndefined(message)) {
        await showOverlay("Searching...");
      }
      const response = await api.getProductionRegionsFilter(params);
      hideOverlay();
      setProductionRegions(response.data.productionRegions);
    } catch (error) {
      hideOverlay();
      showError(error);
    }
  };

  const getParams = (searchValues) => {
    return joinParams({
      productionRegionName: searchValues.productionRegionName,
    });
  };

  return (
    <PageLayout className="production-region-list">
      <section className="site-content bodyWrap" id="content">
        <section className="content-body">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="page-title-row">
                  <h4 className="PageTitle">
                    <span className="page-parent">PRODUCTION REGIONS</span>
                  </h4>
                  <button
                    className="btn btn-primary add-entity-btn"
                    onClick={() =>
                      navigate(PAGE_URL.PRODUCTION_REGION_DETAILS, "createMode=true")
                    }
                  >
                    Add 
                  </button> 
                </div>
                <ProductionRegionListSearch searchProductionRegions={searchProductionRegions} />
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <section className="pt-5">
                  <table className="table table-striped" id="ProductionRegionList">
                    <thead>
                      <tr>
                        <th scope="col" width="60%">Production Region Name</th>
                      </tr>
                    </thead>
                    <tbody>
                      {productionRegions &&
                        productionRegions.map((productionRegion, index) => (
                          <ProductionRegionListRow key={index} productionRegion={productionRegion} />
                        ))}
                    </tbody>
                  </table>
                </section>
              </div>
            </div>
            <div className="productionRegion-pagination">
              <span>
                {"Showing " +
                  productionRegions.length +
                  pluralize(" Production Region", productionRegions.length)}
              </span>
            </div>
          </div>
        </section>
      </section>
    </PageLayout>
  );
};
