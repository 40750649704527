// React
import React from "react";
import { Link } from "react-router-dom";

// Local
import { isEmpty } from "util/common";
import { LabelValue } from "components/LabelValue";
import { PAGE_URL } from "constants/url";

export const CustomerListRow = ({ customer }) => {

  return (
    <Link className="customer-item list-group-item list-group-item-action" to={PAGE_URL.VIEW_CUSTOMER + "?id=" + customer.id}>
      <div className="d-flex w-100">
        <div className="customer-details">
          <div className="customer-meta">
            <ul>
              <LabelValue label="Name" value={isEmpty(customer.companyName) ? "---" : customer.companyName} />
              <LabelValue label="Warehouse" value={isEmpty(customer.warehouseName) ? "---" : customer.warehouseName} listItemStyle={{width: "25rem", overflowWrap: "anywhere"}} />
            </ul>
          </div>
        </div>
      </div>
    </Link>
  );
};
