/* eslint-disable no-unused-vars */

// React
import React, { useRef } from "react";

// Local
import { LabelTextField } from "components/LabelTextField";
import { LabelSelectField } from "components/LabelSelectField";
import { isEmpty } from "util/common";
import { EMAIL_ENABLED, USER_ACTIVE } from "constants/options";
import { ROLES, ROLE_OPTIONS } from "constants/roles";
import { getOptionText } from "util/common";
import { LabelDate } from "components/LabelDate";
import { ACTIVE_USER_FLAG_EFFECT_DESCRIPTION } from "constants/messages";

export const TopLevelUserInfo = ({
  editUser,
  editUserField,
  editMode,
  createMode,
  toggleEditMode,
  onSave,
  onReset,
}) => {
  const saveButtonRef = useRef();

  const onInputKeyDown = (e) => {
    if (e.keyCode === 13) {
      e.preventDefault();
      saveButtonRef.current.click();
    }
  };

  return (
    <form
      onKeyDown={onInputKeyDown}
      onSubmit={(e) => {
        e.preventDefault();
        onSave();
      }}
    >
      <div className="card shadow" id="top-level-info">
        <div className="card-body">
          <div className="row" style={{ justifyContent: "flex-end" }}>
            <div className="col">
              <h2 style={{ marginBottom: "0px", marginLeft: "8px" }}>
                {editUser.contactName}
              </h2>
            </div>
            <button
              className="btn btn-primary btn-sm m-1"
              type="button"
              onClick={toggleEditMode}
              hidden={editMode}
            >
              Edit
            </button>
            <button
              ref={saveButtonRef}
              className="btn btn-success btn-sm m-1"
              id="green"
              type="submit"
              hidden={!editMode}
            >
              Save
            </button>
            <button
              className="btn btn-primary btn-sm m-1"
              type="button"
              onClick={toggleEditMode}
              hidden={!editMode}
            >
              Cancel
            </button>
            <button
              hidden={createMode}
              className="btn btn-danger btn-sm m-1 mr-4"
              type="button"
              onClick={onReset}
            >
              Reset Password
            </button>
          </div>
          <div className="row user-info">
            <div className="col-4">
              <ul>
                <LabelTextField
                  label={"Name"}
                  value={editUser.contactName}
                  editMode={editMode}
                  onChange={(value) => editUserField("contactName", value)}
                  required
                />
                <LabelTextField
                  label={"Email"}
                  value={editUser.email}
                  editMode={editMode}
                  onChange={(value) => editUserField("email", value)}
                  required
                />
              </ul>
            </div>
            <div className="col-3">
              <ul>
                <LabelSelectField
                  label={"Role"}
                  value={
                    isEmpty(editUser.role)
                      ? "---"
                      : editMode
                      ? editUser.role
                      : getOptionText(ROLE_OPTIONS, editUser.role)
                  }
                  options={ROLE_OPTIONS.filter(option => option.value !== ROLES.NONE)}
                  editMode={editMode}
                  onChange={(value) => editUserField("role", value)}
                />
                <LabelTextField
                  label={"Phone"}
                  value={editUser.telephone}
                  editMode={editMode}
                  onChange={(value) => editUserField("telephone", value)}
                />
              </ul>
            </div>
            <div className="col-3">
              <ul>
                <LabelSelectField
                  label={"Email Enabled"}
                  value={
                    editMode
                      ? editUser.emailEnabled
                      : getOptionText(EMAIL_ENABLED, editUser.emailEnabled)
                  }
                  options={EMAIL_ENABLED}
                  editMode={editMode}
                  onChange={(value) => editUserField("emailEnabled", value)}
                  booleanValue={true}
                />
                <div
                  className="label"
                  data-toggle="tooltip"
                  data-placement="top"
                  title={ACTIVE_USER_FLAG_EFFECT_DESCRIPTION}
                >
                <LabelSelectField
                  label={"*Status"}
                  value={
                    editMode
                      ? editUser.active
                      : getOptionText(USER_ACTIVE, editUser.active)
                  }
                  options={USER_ACTIVE}
                  editMode={editMode}
                  onChange={(value) => editUserField("active", value)}
                  booleanValue={true}
                />
                </div>
              </ul>
            </div>
            <div>
              <ul>
                <LabelDate label="Last Login" date={editUser.lastLogin} optionalDefaultValue="---"/>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};
