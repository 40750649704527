import React, { useState, useEffect } from "react";
import api from "services/apiService";
import { showSuccessMessage } from "store/messages/messageActions";
import { useDispatch } from "react-redux";
import { isEmpty } from "util/common";
import { useError } from "hooks/errors";
import { RelatedDoc } from "./RelatedDoc";
import { ORDER_STATUS_API } from "constants/orderStatus";

export const RelatedDocuments = ({ orderDetails }) => {

    const dispatch = useDispatch();
    const [relatedDocs, setRelatedDocs] = useState(null);
    const [file, setFile] = useState(null);
    const [showError, ] = useError();

    useEffect(() => {
        if (!isEmpty(file)){            
            uploadDocument();
        }

    }, [file]);

    useEffect(() => {
        setRelatedDocs(orderDetails.relatedDocs);
    },[]);

    const uploadDocument = async () => {
        const formData = new FormData();
        formData.append("relatedDocument", file);

        try {
            let _relatedDocs = [...relatedDocs];
            const response = await api.uploadRelatedDocument(orderDetails.orderId, formData);
            dispatch(showSuccessMessage("Document has been uploaded successfully."))
            setRelatedDocs([..._relatedDocs, response.data]);
        } catch (error) {
            showError(error);
        }
    };

    return (
        <div className="row">
            <div className="col-12">
                <div className={orderDetails.orderStatus === ORDER_STATUS_API.PENDING ? "card" : "card shadow"} id="related">
                    <div className="card-body">
                    <h2>Related Documents</h2>

                    <div className="row">
                        <div className="col-6">
                            <ul className="readonly">
                                <li>
                                    <div className="value">
                                        {orderDetails.orderStatus === ORDER_STATUS_API.PENDING &&
                                            <label className="docs-upload file-upload-btn">
                                                Choose File
                                                <input type="file" onChange={(e) => setFile(e.target.files[0])} name="file" className="btn-block" />
                                            </label>
                                        }

                                        {relatedDocs && relatedDocs.map((doc, index) => 
                                            <RelatedDoc relatedDoc={doc} orderId={orderDetails.orderId} key={index}/>)
                                        }
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
