import React, { useState } from "react";
import { QuantityField } from "../components/QuantityField";
import { InventoryField } from "./InventoryField";
import { useCurrentUser } from "hooks/useCurrentUser";
import { ROLES, ROLE_OPERATIONS, userCanPerformOperation } from "constants/roles";
import api from "services/apiService";
import { useDispatch } from "react-redux";
import { showSuccessMessage } from "store/messages/messageActions";
import { useEffect } from "react";
import { useError } from "hooks/errors";
import { Thumbnail } from "components/Thumbnail";
import { isEmpty } from "util/common";
import { UnitPriceCostTable } from "components/UnitPriceCostTable";
import { MIN_ORDER_VALUE_DESCRIPTION } from "constants/messages";
import { formatCurrency } from "util/formatNumber";

export const StandardProductDetailsInventory = ({ product, onQuantityChange }) => {
    const UPDATE_INVENTORY_SUCCESS = "Inventory successfully updated.";

    const user = useCurrentUser();
    const dispatch = useDispatch();
    const [inventories, setInventories] = useState(null);
    const [showError, ] = useError();
    const [editQuantity, setEditQuantity] = useState(null);
    const [cartQuantity, setCartQuantity] = useState(0);

    useEffect(() => {
        let inventories = {};

        if (product.hasSizes) {
            product.items.forEach(item => {
                inventories[item.productItemId] = item.inventoryQuantity;
            });
        } else {
            inventories[product.productItemId] = product.inventoryQuantity;
        }

        setInventories(inventories);
    }, [product]);

    const setInventory = (productItemId, inventory) => {
        let _inventories = {...inventories};
        _inventories[productItemId] = inventory;
        setInventories(_inventories);
    };

    const updateInventory = async () => {
        const data = {
            inventories: inventories
        };

        try {
            await api.updateInventory(data);
            dispatch(showSuccessMessage(UPDATE_INVENTORY_SUCCESS));
        } catch (error) {
            showError(error);
        }
    };

    return (
        <>
        {inventories &&
        <div className="product-item standard card shadow">
            <div className="general-details">
                <div className="product-img">
                    <Thumbnail className="img-fluid" imagePath={product.productImagePath} imageType={product.productImageType} />
                </div>
                <div className="product-info">
                    <div className="fixed-info">
                        <ul>
                            <li>
                                <div className="label">Product Code:</div>
                                <div className="value">{product.productSubcode}</div>
                            </li>
                            <li className="description">
                                <div className="label">Description:</div>
                                <div className="value">{product.description}</div>
                            </li>
                            <li>
                                <div className="label">Pack Size:</div>
                                <div className="value">{product.packSize}</div>
                            </li>
                            {!isEmpty(product.minValue) && (
                            <li>
                              <div
                                className="label"
                                data-toggle="tooltip"
                                data-placement="right"
                                title={MIN_ORDER_VALUE_DESCRIPTION}
                              >
                                Minimum Order Value *
                              </div>
                              <div className="value">
                                {product.minValue.currency +
                                  " " +
                                  formatCurrency(product.minValue.price)}
                              </div>
                            </li>
                          )}
                        </ul>
                        <ul>
                          {!isEmpty(product.costs) && ( product.costs.length === 1 ?
                          <li>
                            <div className="label">Unit Cost:</div>
                            <div className="value">
                              <span className="currency">{product.costs[0].currency} </span><span className="price">{product.costs[0].price}</span>
                            </div>
                          </li>
                          :
                          <UnitPriceCostTable isPrice={false} pricesOrCosts={product.costs} editQuantity={editQuantity} cartQuantity={cartQuantity} />
                          )}
                          {!isEmpty(product.prices) && ( product.prices.length === 1 ?
                          <li>
                            <div className="label">Unit Price:</div>
                            <div className="value">
                              <span className="currency">{product.prices[0].currency} </span><span className="price">{product.prices[0].price}</span>
                            </div>
                          </li>
                          :
                          <UnitPriceCostTable isPrice={true} pricesOrCosts={product.prices} editQuantity={editQuantity} cartQuantity={cartQuantity} />
                          )}
                        </ul>
                    </div>
                    <div className="product-magic-attributes">
                        {product.hasSizes &&
                            <div className="row product-magic-attributes">
                                <div className="col-2 label">Size</div>
                                <div className="col-2 label center-column">Inventory</div>
                                {userCanPerformOperation(user, ROLE_OPERATIONS.RESTOCK) && (
                                <>
                                  <div className="col-1"></div>
                                  <div className="col-2 label last-magic-attribute">Quantity</div>
                                </>
                                )}
                            </div>
                        }
                        {product.hasSizes && product.items.map((item, i) =>
                            <div key={i} className="row product-magic-attribute">
                                <div className="col-2"><div>{item.productSize}</div></div>
                                {userCanPerformOperation(user, ROLE_OPERATIONS.UPDATE_INVENTORY) ?
                                  (<InventoryField
                                    className="col-2"
                                    defaultInventory={item.inventoryQuantity}
                                    onBlur={quantity => setInventory(item.productItemId, quantity)}
                                    required
                                />) : (<div className="value">{item.inventoryQuantity}</div>)
                                }
                                {userCanPerformOperation(user, ROLE_OPERATIONS.RESTOCK) &&
                                  <>
                                    <div className="col-1"></div>
                                    <QuantityField
                                      className="col-2 last-magic-attribute"
                                      defaultQuantity={item.defaultOrderQuantity}
                                      minQuantity={product.minOrderQuantity}
                                      maxQuantity={Math.max(item.inventoryMax - inventories[item.productItemId], 0)}
                                      packSize={product.packSize}
                                      onChange={quantity => {
                                        onQuantityChange(product.productSubcode, item.productItemId, quantity);
                                        setEditQuantity(quantity);
                                      }}
                                      onFocus={quantity => {
                                        setEditQuantity(quantity);
                                        setCartQuantity(item.cartQuantity);
                                      }}
                                      onBlur={() => {
                                        setEditQuantity(null);
                                        setCartQuantity(0);
                                      }}
                                      required
                                    />
                                  </>
                                }
                            </div>
                        )}
                        {product.hasSizes && userCanPerformOperation(user, ROLE_OPERATIONS.UPDATE_INVENTORY) &&
                            <div className="product-quantity-row row">
                                <div className="col-2"></div>
                                <div className="col-3n">
                                    <button className="btn btn-primary shadow" type="button" onClick={e => updateInventory()}>Update Inventory</button>
                                </div>
                            </div>
                        }
                    </div>
                    {!product.hasSizes &&
                        <div className="product-quantity-row">
                            <div className="row">
                                <div className="col-2 product-quantity-row">
                                    <div className="label product-quantity">Inventory</div>
                                    {userCanPerformOperation(user, ROLE_OPERATIONS.UPDATE_INVENTORY) ? (
                                    <InventoryField
                                        defaultInventory={product.inventoryQuantity}
                                        onBlur={quantity => setInventory(product.productItemId, quantity)}
                                        required
                                    />) : (
                                      <div className="value">{product.inventoryQuantity}</div>
                                    )}
                                </div>
                                {userCanPerformOperation(user, ROLE_OPERATIONS.UPDATE_INVENTORY) &&
                                <div className="col-3">
                                    <button className="btn btn-primary shadow" type="button" onClick={e => updateInventory()}>Update Inventory</button>
                                </div>}
                                {(userCanPerformOperation(user, ROLE_OPERATIONS.RESTOCK)) &&
                                    <>
                                    <div className="col-5"></div>
                                    <div className="col-2 product-quantity-row">
                                        <div className="label product-quantity">Quantity</div>
                                        <QuantityField
                                            defaultQuantity={Math.min(product.inventoryMax - product.inventoryQuantity, product.defaultOrderQuantity)}
                                            minQuantity={product.minOrderQuantity}
                                            maxQuantity={Math.max(product.inventoryMax - inventories[product.productItemId], 0)}
                                            packSize={product.packSize}
                                            onChange={quantity => {
                                              onQuantityChange(product.productSubcode, product.productItemId, quantity);
                                              setEditQuantity(quantity);
                                            }}
                                            onFocus={quantity => {
                                              setEditQuantity(quantity);
                                              setCartQuantity(product.cartQuantity);
                                            }}
                                            onBlur={() => {
                                              setEditQuantity(null);
                                              setCartQuantity(0);
                                            }}
                                            required
                                        />
                                    </div>
                                    </>
                                }
                            </div>
                        </div>
                    }
                </div>
            </div>
        </div>
        }
        </>
    );
};
