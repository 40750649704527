import React from "react";
import { CARE_LABEL_TYPE } from "constants/careLabelType";
import { FibreContents } from "./FibreContents";
import { GarmentComponents } from "./GarmentComponents";
import { getFieldValue } from "util/common";

export const CareLabelDetails = ({ careLabel }) => {
    return (
        <div className="product-item care-label card shadow">
            <div className="country-of-origin">
                <h2>Country of Origin</h2>
                <div className="row">
                    <div className="col-4">
                        {careLabel.countryOfOrigin}
                    </div>
                </div>
            </div>
            <div className="care-instructions">
                <h2>Care Instructions</h2>
                {careLabel.type === CARE_LABEL_TYPE.CARELABEL_STANDARD &&
                    <div className="row">
                        <div className="col-4">
                            <label className="order-label-heading">Wash</label>
                            <div className="row">
                                <span className={careLabel.washIcon + " order-care-icon"}></span>{careLabel.wash}
                            </div>
                        </div>
                        <div className="col-4">
                            <label className="order-label-heading">Bleach</label>
                            <div className="row">
                                <span className={careLabel.bleachIcon + " order-care-icon"}></span> {careLabel.bleach}
                            </div>
                        </div>
                        <div className="col-4">
                            <label className="order-label-heading">Dry</label>
                            <div className="row">
                                <span className={careLabel.dryIcon + " order-care-icon"}></span> {careLabel.dry}
                            </div>
                        </div>
                        <div className="col-4">
                            <label className="order-label-heading">Iron</label>
                            <div className="row">
                                <span className={careLabel.ironIcon + " order-care-icon"}></span> {careLabel.iron}
                            </div>
                        </div>
                        <div className="col-4">
                            <label className="order-label-heading">Dry Clean</label>
                            <div className="row">
                                <span className={careLabel.dryCleanIcon + " order-care-icon"}></span> {careLabel.dryClean}
                            </div>
                        </div>
                    </div>
                }
                {careLabel.type === CARE_LABEL_TYPE.CARELABEL_BY_CODE &&
                    <div className="row">
                        <div className="col-4">
                            {careLabel.instructionCode.codeName}
                        </div>
                        <div className="col-1"></div>
                        <div className="form-group col-6 care-label-instruction-code-text">
                            <div>{careLabel.instructionCode.codeText}</div>
                        </div>
                    </div>
                }
            </div>
            {/* Fabric Composition is displayed if present and is configured on the Customer level. */}
            {(careLabel.fibreContents || careLabel.garmentComponents) && <div className="fabric-composition">
                <h2>Fabric Composition</h2>
                {careLabel.fibreContents &&
                    <>
                        <div className="order-label-heading">Fibre Contents</div>
                        <FibreContents fibreContents={careLabel.fibreContents} />
                    </>
                }
                {careLabel.garmentComponents &&
                    <>
                        <div className="order-label-heading">Garment Components</div>
                        <GarmentComponents garmentComponents={careLabel.garmentComponents} />
                    </>
                }
            </div>}
            <div className="special-instructions">
                <h2>Special Instructions</h2>
                {careLabel.specialInstructions &&
                    <ul>
                        {careLabel.specialInstructions && careLabel.specialInstructions.map((g, index) =>
                            <li key={index}>
                                <div className="form-row">
                                    <div className="form-group col-12">
                                        {g}
                                    </div>
                                </div>
                            </li>
                        )}
                    </ul>
                }
            </div>
            <div className="fabric-composition">
                <div className="row special-instruction-read-only">
                    <div className="col-12">
                        <div className="row">
                            {careLabel.copyright &&
                            <div className="col-4">
                                <span className="order-label-heading">Copyright</span>
                            </div>
                            }
                            <div className="col-4">
                                <span className="order-label-heading">Caution Maintenance</span>
                            </div>
                        </div>
                        <div className="row">
                            {careLabel.copyright &&
                            <div className="col-4">
                                <label>{getFieldValue(careLabel.copyright)}</label>
                            </div>
                            }
                            <div className="col-4">
                                <label>{getFieldValue(careLabel.cautionMaintenance)}</label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>)
};
