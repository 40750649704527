// React
import React, { useRef } from "react";

// Local
import { Thumbnail } from "components/Thumbnail";

export const EditThumbnailWrapper = ({
  className,
  btnClassName,
  editMode,
  imagePath,
  imageType,
  onChange,
  forceReload,
  image
}) => {
  const uploadRef = useRef();

  return (
    <>
      {imagePath && <Thumbnail
        className={className}
        imagePath={imagePath}
        imageType={imageType}
        forceReload={forceReload}
      />}
      <input
        ref={uploadRef}
        id="img-upload"
        type="file"
        onChange={(e) => onChange(e.target.files[0])}
        name="file"
        className="btn-block"
        hidden={true}
      />
      <button
        className={"btn btn-primary" + " " + btnClassName}
        style={{ paddingRight: "1rem", paddingLeft: "1rem" }}
        disabled={!editMode}
        onClick={(e) => {
          e.preventDefault();
          uploadRef.current.click();
        }}
      >
          Upload New Image
      </button>
      <div className="file-selected">{image && image.name ? "Selected: " + image.name : ""}</div>
    </>
  );
};
