/* eslint-disable no-trailing-spaces */
/* eslint-disable no-unused-vars */
/* eslint-disable no-multiple-empty-lines */

// React
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

// Local
import { api } from "../../services/apiService";
import { PageLayout } from "layouts/PageLayout";
import { useURLNavigate } from "hooks/useURLNavigate";
import { useURLParams } from "hooks/useURLParams";
import { useOverlay } from "hooks/useOverlay";
import { useError } from "hooks/errors";
import { PAGE_URL } from "constants/url";
import { isEmpty, isNullOrUndefined, addNoneOption } from "util/common";
import { AttributeSchemaRelationships } from "./components/AttributeSchemaRelationships";
import {
  showSuccessMessage,
  pushSuccessMessage,
  showErrorMessage,
} from "store/messages/messageActions";
import { cloneDeep } from "lodash";
import { AttributeFields } from "./components/AttributeFields";

export const AttributeSchemaDetailPage = () => {
  // hooks
  const navigate = useURLNavigate();
  const [showError, pushError] = useError();
  const [getParam] = useURLParams();
  const [showOverlay, hideOverlay] = useOverlay();
  const dispatch = useDispatch();
  const message = useSelector((state) => state.message.message);

  // state
  const [editMode, setEditMode] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [attributeSchema, setAttributeSchema] = useState();
  const [editAttributeSchema, setEditAttributeSchema] = useState();
  const [productGroup, setProductGroup] = useState();
  const [customer, setCustomer] = useState();
  // url variables
  const attributeSchemaId = getParam("id");
  const createMode = getParam("createMode");

  useEffect(() => {
    loadData();
  }, []);

  const loadData = async () => {
    // handle url hack
    if (createMode && !isEmpty(attributeSchemaId)) {
      pushError("Invalid URL params");
      navigate(PAGE_URL.ATTRIBUTE_SCHEMA);
    }

    if (createMode) {
      setEditMode(true);
      setEditAttributeSchema({
        name: "",
        global: false,
        customer: null,
        productGroup: null,
        attributeFields: []
      });
    }

    if (!isEmpty(attributeSchemaId)) {
      // load attributeSchema
      try {
        const attributeSchemaResponse = await api.getAttributeSchemaDetail(
          attributeSchemaId
        );
        setAttributeSchema(cloneDeep(attributeSchemaResponse.data));
        setEditAttributeSchema(cloneDeep(attributeSchemaResponse.data));
      } catch (error) {
        pushError(error);
        navigate(PAGE_URL.HOME);
      }
    }

    if (createMode || !isEmpty(attributeSchemaId)) {
      try {
        const productGroupResponse = await api.getAllProductGroup();
        const customerResponse = await api.getAllCustomers();
        setProductGroup(addNoneOption(productGroupResponse.data.listContent));
        setCustomer(addNoneOption(customerResponse.data.customers));
        setLoaded(true);
      } catch (error) {
        pushError(error);
        navigate(PAGE_URL.HOME);
      }
    }
  };

  const toggleEditMode = () => {
    if (createMode) {
      navigate(PAGE_URL.ATTRIBUTE_SCHEMA);
    } else if (editMode) {
      setEditAttributeSchema(cloneDeep(attributeSchema)); // revert changes
      setEditMode(false);
    } else {
      setEditMode(true);
    }
  };

  const onSave = async () => {
    if (isNullOrUndefined(message)) {
      await showOverlay("Saving...");
    }
    if (createMode) {
      try {
        await api.createAttributeSchema(editAttributeSchema);

        dispatch(pushSuccessMessage("Attribute Schema Successfully Created"));
        navigate(PAGE_URL.ATTRIBUTE_SCHEMA);
      } catch (error) {
        showError(error);
      }
    } else {
      try {
        const response = await api.updateAttributeSchema(
          attributeSchema.id,
          editAttributeSchema
        );

        const _editAttributeSchema = { ...editAttributeSchema };
        _editAttributeSchema.attributeFields = response.data.attributeFields;

        setEditAttributeSchema(_editAttributeSchema);
        setAttributeSchema(cloneDeep(_editAttributeSchema));
        dispatch(showSuccessMessage("Attribute Schema Successfully Updated"));
      } catch (error) {
        showError(error);
        setEditAttributeSchema(attributeSchema);
      } finally {
        setEditMode(false);
      }
    }

    hideOverlay();
  };

  const editAttributeSchemaField = (field, value) => {
    let _editAttributeSchema = { ...editAttributeSchema };
    _editAttributeSchema[field] = value;
    setEditAttributeSchema(_editAttributeSchema);
  };

  // add field
  const addAttributeField = (createField) => {
    const _editAttributeSchema = { ...editAttributeSchema };
    const _fields = _editAttributeSchema.attributeFields;

    let duplicate = _fields.find(
      (field) =>
        field.name === createField.name &&
        field.type === createField.type
    );

    if (duplicate) {
      dispatch(showErrorMessage("This is a duplicate field."));
      return;
    }

    // add
    _fields.push(createField);

    // sort
    _fields.sort((o1, o2) =>
      o1["name"].localeCompare(o2["name"])
    );

    _editAttributeSchema.attributeFields = _fields;
    setEditAttributeSchema(_editAttributeSchema);
  };

  // update field
  const updateAttributeField = (selectedField, editField) => {
    const _editAttributeSchema = { ...editAttributeSchema };
    const _fields = _editAttributeSchema.attributeFields;

    let field = _fields.find(
      (f) =>
        f.name === selectedField.name &&
        f.type === selectedField.type
    );

    if (!field) {
      dispatch(showErrorMessage("Couldn't find the field to update."));
      return;
    }

    field.name = editField.name;
    field.type = editField.type;

    _editAttributeSchema.attributeFields = _fields;
    setEditAttributeSchema(_editAttributeSchema);
  };

  // remove field
  const removeAttributeField = (deleteField) => {
    const _editAttributeSchema = { ...editAttributeSchema };

    const _filtered = _editAttributeSchema.attributeFields.filter(
      (field) =>
        field.name != deleteField.name ||
        field.type != deleteField.type
    );

    _editAttributeSchema.attributeFields = _filtered;
    setEditAttributeSchema(_editAttributeSchema);
  };

  // add field option
  const addOption = (editField, createOption, setEditField) => {
    const _editAttributeSchema = { ...editAttributeSchema };
    const _fields = _editAttributeSchema.attributeFields;

    let _field = _fields.find(
      (f) =>
        f.name === editField.name &&
        f.type === editField.type
    );

    if (!_field) {
      dispatch(showErrorMessage("Couldn't find the field to update."));
      return;
    }


    const _options = isEmpty(_field.options) ? [] : _field.options;

    const duplicate = _options.find(o => o === createOption.name);

    if (duplicate) {
      dispatch(showErrorMessage("This is a duplicate option."));
      return;
    }

    // add
    _options.push(createOption.name);

    // sort
    _options.sort((s1, s2) => s1.localeCompare(s2));
    
    if (isEmpty(_field.options)) { // if empty set list, otherwise is mutated
      _field.options = _options;
    }

    setEditField(_field);
    _editAttributeSchema.attributeFields = _fields;
    setEditAttributeSchema(_editAttributeSchema);
  };

  // update field option
  const updateOption = (editField, editOption, selectedOption, setEditField) => {

    const _editAttributeSchema = { ...editAttributeSchema };
    const _fields = _editAttributeSchema.attributeFields;

    let _field = _fields.find(
      (f) =>
        f.name === editField.name &&
        f.type === editField.type
    );

    if (!_field) {
      dispatch(showErrorMessage("Couldn't find the field to update."));
      return;
    }

    if (isEmpty(_field.options)) {
      dispatch(showErrorMessage("Couldn't option specified."));
      return;
    }
    
    const _options = _field.options.filter(o => o !== selectedOption.name);
    _options.push(editOption.name);
    _options.sort((s1, s2) => s1.localeCompare(s2));
    
    const duplicates = _options.filter(o => o === editOption.name);

    if (duplicates.length > 1) {
      dispatch(showErrorMessage("This is a duplicate option."));
      return;
    }

    _field.options = _options;
    setEditField(_field);

    _editAttributeSchema.attributeFields = _fields;
    setEditAttributeSchema(_editAttributeSchema);
  };

  // remove field option
  const removeOption = (editField, deleteOption, setEditField) => {

    const _editAttributeSchema = { ...editAttributeSchema };
    const _fields = _editAttributeSchema.attributeFields;

    let _field = _fields.find(
      (f) =>
        f.name === editField.name &&
        f.type === editField.type
    );

    if (!_field) {
      dispatch(showErrorMessage("Couldn't find the field to update."));
      return;
    }

    if (isEmpty(_field.options)) {
      dispatch(showErrorMessage("No options on selected field."));
      return;
    }

    _field.options = _field.options.filter(o => o !== deleteOption.name);
    setEditField(_field);
    
    _editAttributeSchema.attributeFields = _fields;
    setEditAttributeSchema(_editAttributeSchema);
  };

  return (
    <PageLayout className="attributeSchema-details">
      <section className="site-content bodyWrap" id="content">
        <section className="content-body">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <h4 className="PageTitle">
                  <Link className="page-parent" to={PAGE_URL.ATTRIBUTE_SCHEMA}>
                    ATTRIBUTE SCHEMAS
                  </Link>{" "}
                  &ndash;{" "}
                  <span className="page-current">ATTRIBUTE SCHEMA DETAILS</span>
                </h4>
              </div>
            </div>
            {loaded && (
              <div className="row">
                <div className="col-12">
                  <div className="card shadow">
                    <div className="card-body">
                      <AttributeSchemaRelationships
                        editAttributeSchema={editAttributeSchema}
                        editAttributeSchemaField={editAttributeSchemaField}
                        editMode={editMode}
                        createMode={createMode}
                        productGroup={productGroup}
                        customer={customer}
                        toggleEditMode={toggleEditMode}
                        onSave={onSave}
                      />
                    </div>
                  </div>
                </div>
              </div>
            )}
            {loaded && (
              <div className="row">
                <div className="col-12">
                  <AttributeFields
                    editAttributeSchema={editAttributeSchema}
                    editMode={editMode}
                    addField={addAttributeField}
                    updateField={updateAttributeField}
                    removeField={removeAttributeField}
                    addFieldOption={addOption}
                    updateFieldOption={updateOption}
                    removeFieldOption={removeOption}
                  />
                </div>
              </div>
            )}
          </div>
        </section>
      </section>
    </PageLayout>
  );
};
