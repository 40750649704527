import * as types from "./productionRegionActionTypes";

const initialState = {
  productionRegionSearchValues: {
    productionRegionName: "",
  }
};

export const productionRegionReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_PRODUCTION_REGION_SEARCH_VALUES: {
      const data = action.data;
      return { ...state, productionRegionSearchValues: data };
    }
    default:
      return state;
  }
};
