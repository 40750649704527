import { useHistory } from "react-router-dom";

export const useURLNavigate = () => {
    let history = useHistory();

    const navigate = (path, params) => {
        history.push(path + (params ? "?" + params : ""));
    };

    return navigate;
};
