/* eslint-disable indent */
import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import DatePickerWithIcon from "components/DatePickerWithIcon";
import { ORDER_STATUS_OPTIONS } from "constants/orderStatus";
import api from "services/apiService";
import { FINANCE_APPROVAL, FINANCE_APPROVAL_OPTIONS } from "constants/financeApproval";
import { hasLength, isEmpty } from "util/common";
import { useCurrentUser } from "hooks/useCurrentUser";
import { ROLES } from "constants/roles";
import { useError } from "hooks/errors";
import { setOrderSearchValues } from "store/orders/orderActions";

export const OrderListSearch = ({ searchOrders }) => {
    const dispatch = useDispatch();
    const user = useCurrentUser();
    const [showError, ] = useError();

    const searchValues = useSelector(state => state.orders.orderSearchValues);
    const initialValuesUpdated = useSelector(state => state.orders.initialOrderSearchValuesUpdated);
    const [customers, setCustomers] = useState(null);
    const [productionRegions, setProductionRegions] = useState(null);
    const [companies, setCompanies] = useState(null);
    const [allCompanies, setAllCompanies] = useState(null);
    const [customerCompanies, setCustomerCompanies] = useState(null);
    const [filtersLoaded, setFiltersLoaded] = useState(false);

    const searchButtonRef = useRef(null);
    const focusButtonRef = useRef(null);
    const startDateRef = useRef(null);
    const endDateRef = useRef(null);
    const filterRef = useRef(null);

    const loadCustomers = async () => {
        try {
            const response = await api.getCustomers();
            setCustomers(response.data.customers);
        } catch (error) {
            showError(error);
        }
    };

    const loadProductionRegions = async() => {
        try {
            const response = await api.getProductionRegions();
            setProductionRegions(response.data.productionRegions);
        } catch (error) {
            showError(error);
        }
    };

    const loadCompanies = async() => {
        try {
            const response = await api.getCompanies();
            setAllCompanies(response.data.vendors);
            setCustomerCompanies(response.data.customerVendors);
            setCompanies(response.data.vendors);
        } catch (error) {
            showError(error);
        }
    };

    useEffect(() => {
        if (filtersLoaded) {
            if (!initialValuesUpdated) {
                const _searchValues = {...searchValues};
                _searchValues.customer = hasLength(customers, 1) ? customers[0] : "";
                _searchValues.productionRegion = hasLength(productionRegions, 1) ? productionRegions[0] : "";
                _searchValues.company = hasLength(companies, 1) ? companies[0] : "";
                _searchValues.financeApproval = (user.role === ROLES.FINANCE) ? FINANCE_APPROVAL.NOT_APPROVED : FINANCE_APPROVAL.NONE;

                dispatch(setOrderSearchValues(_searchValues));
                searchOrders(_searchValues);
            } else {
                searchOrders(searchValues);
            }

            filterRef.current.click();
        }
    }, [filtersLoaded]);

    useEffect(() => {
        if (!filtersLoaded && customers && productionRegions && (user.role === ROLES.CSR || (companies && allCompanies && customerCompanies))) {
            setFiltersLoaded(true);
        }
    }, [customers, productionRegions, companies, allCompanies, customerCompanies]);

    const updateSearchValue = (name, value) => {
        const _searchValues = {...searchValues};
        _searchValues[name] = value;

        if (name === "customer") {
            let companies;

            if (isEmpty(value)) {
                companies = allCompanies;
            } else {
                companies = customerCompanies[value];
            }

            setCompanies(companies);
            _searchValues.company = hasLength(companies, 1) ? companies[0] : "";
        }

        dispatch(setOrderSearchValues(_searchValues));
    };

    const onStartDateChange = (date) => {
        if (searchValues.endDate && date > searchValues.endDate) {
            dispatch(setOrderSearchValues({
                ...searchValues,
                startDate: date,
                endDate: null,
                startHighlightDates: []
            }));
        } else {
            dispatch(setOrderSearchValues({
                ...searchValues,
                startDate: date
            }));
        }

        if (date === null) {
            startDateRef.current.setOpen(false);
        }

        focusButtonRef.current.focus();
    };

    const onEndDateChange = (date) => {
        if (date === null) {
            dispatch(setOrderSearchValues({
                ...searchValues,
                endDate: date,
                startHighlightDates: []
            }));
        } else {
            dispatch(setOrderSearchValues({
                ...searchValues,
                endDate: date,
                startHighlightDates: [{"datepicker-highlight-end-date": [date]}]
            }));
        }

        if (date === null) {
            endDateRef.current.setOpen(false);
        }

        focusButtonRef.current.focus();
    };

    useEffect(() => {
        loadCustomers();
        loadProductionRegions();

        loadCompanies();
    }, []);

    const onInputKeyDown = (e) => {
        if (e.keyCode === 13) {
            e.preventDefault();
            searchButtonRef.current.click();
        }
    };

    return (
        <form onKeyDown={onInputKeyDown}>
            <a className="toggleBTN collapsed" data-toggle="collapse" href="#searchForm" role="button" aria-expanded="false" aria-controls="searchForm" ref={filterRef}><span id="ToggleIcon"></span>Filter</a>
            <div className="collapse rounded" id="searchForm">
                <div className="searchForm">
                    <div className="form-row">
                        <div className="form-group col-3 col-left-none">
                            <label>Order No.</label>
                            <input type="text" className="form-control" placeholder="Enter No." value={searchValues.orderNumber} onChange={e => updateSearchValue("orderNumber", e.target.value)} />
                        </div>
                        <div className="form-group col-3">
                            <label>Start Date</label>
                            <DatePickerWithIcon
                                ref={startDateRef}
                                selected={searchValues.startDate}
                                dateFormat="dd-MMM-yyyy"
                                className="form-control"
                                placeholderText="Enter Start Date"
                                onChange={date => onStartDateChange(date)}
                                highlightDates={searchValues.startHighlightDates}
                            />
                        </div>
                        <div className="form-group col-3">
                            <label>End Date</label>
                            <DatePickerWithIcon
                                ref={endDateRef}
                                selected={searchValues.endDate}
                                dateFormat="dd-MMM-yyyy"
                                className="form-control"
                                placeholderText="Enter End Date"
                                onChange={date => onEndDateChange(date)}
                                minDate={searchValues.startDate ? searchValues.startDate : null}
                            />
                        </div>
                        <div className="form-group col-3">
                            <label>Order Status</label>
                            <select className="form-control" onChange={e => updateSearchValue("orderStatus", e.target.value)} value={searchValues.orderStatus}>
                                {ORDER_STATUS_OPTIONS.map((option, index) =>
                                    <option key={index} value={option.value}>{option.text}</option>
                                )}
                            </select>
                        </div>
                    </div>
                    <div className="form-row">
                        <div className="form-group col-3 col-left-none">
                            <label>Customer</label>
                            <select className="form-control" onChange={e => updateSearchValue("customer", e.target.value)} value={searchValues.customer}>
                                {!customers || (customers.length !== 1) &&
                                    <option value="">---</option>
                                }
                                {customers && customers.map((c, index) =>
                                    <option key={index} value={c}>{c}</option>
                                )}
                            </select>
                        </div>
                        <div className="form-group col-3">
                            <label>Production Region</label>
                            <select className="form-control" onChange={e => updateSearchValue("productionRegion", e.target.value)} value={searchValues.productionRegion}>
                                {!productionRegions || (productionRegions.length !== 1) &&
                                    <option value="">---</option>
                                }
                                {productionRegions && productionRegions.map((p, index) =>
                                    <option key={index} value={p}>{p}</option>
                                )}
                            </select>
                        </div>
                        <div className="form-group col-3">
                            <label>Company</label>
                            <select className="form-control" disabled={user.role === ROLES.CSR} onChange={e => updateSearchValue("company", e.target.value)} value={searchValues.company}>
                                {!companies || (companies.length !== 1) &&
                                    <option value="">---</option>
                                }
                                {companies && companies.map((c, index) =>
                                    <option key={index} value={c}>{c}</option>
                                )}
                            </select>
                        </div>
                        <div className="form-group col-3">
                            <label>Finance Approval</label>
                            <select className="form-control" onChange={e => updateSearchValue("financeApproval", e.target.value)} value={searchValues.financeApproval}>
                                {FINANCE_APPROVAL_OPTIONS.map((option, index) =>
                                    <option key={index} value={option.value}>{option.text}</option>
                                )}
                            </select>
                        </div>
                    </div>
                    <div className="form-row">
                        <div id="searchButtonCol" className="col-3 col-left-none offset-9">
                            <button ref={searchButtonRef} className="searchBTN btn btn-block btn-outline-primary" type="button" onClick={() => searchOrders(searchValues)}>Search</button>
                        </div>
                    </div>
                    <button ref={focusButtonRef} className="hidden-focus-button" type="button" />
                </div>
            </div>
        </form>
    );
};
