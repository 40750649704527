import { isEmpty } from "util/common";

export const FULFILLMENT_PROCESS = Object.freeze({
  NONE: "NONE",
  PICK_AND_PACK: "PICK_AND_PACK",
  MAKE_AND_SHIP: "MAKE_AND_SHIP",
  BUILD: "BUILD",
});

export const FULFILLMENT_PROCESS_OPTIONS = [
  { value: FULFILLMENT_PROCESS.NONE, text: "---" },
  { value: FULFILLMENT_PROCESS.PICK_AND_PACK, text: "Pick & Pack" },
  { value: FULFILLMENT_PROCESS.MAKE_AND_SHIP, text: "Make & Ship" },
  { value: FULFILLMENT_PROCESS.BUILD, text: "Build" },
];

export const FULFILLMENT_PROCESS_DISPLAY = Object.freeze({
  NONE: "---",
  PICK_AND_PACK: "Pick & Pack",
  MAKE_AND_SHIP: "Make & Ship",
  BUILD: "Build",
});

export const getDisplayForProcessValue = (value) => {
  let text = FULFILLMENT_PROCESS_DISPLAY[value];

  return isEmpty(text) ? "not found" : text;
};