import React, { useRef } from "react";
import {
  optionFromNameAndId,
  getNameAndIdFromList,
} from "constants/options";
import { getOptionText } from "util/common";
import { LabelTextField } from "components/LabelTextField";
import { LabelSelectField } from "components/LabelSelectField";
import { isEmpty } from "util/common";
import { IS_GLOBAL_OPTIONS } from "constants/globalStatus";

export const AttributeSchemaRelationships = ({
  editAttributeSchema,
  editAttributeSchemaField,
  editMode,
  productGroup,
  customer,
  toggleEditMode,
  onSave,
}) => {
  const saveButtonRef = useRef();

  const onInputKeyDown = (e) => {
    if (e.keyCode === 13) {
      e.preventDefault();
      saveButtonRef.current.click();
    }
  };

  return (
    <div className="attributeSchemaEntity">
      <form
        onKeyDown={onInputKeyDown}
        onSubmit={(e) => {
          e.preventDefault();
          onSave();
        }}
      >
        <div className="row" style={{ justifyContent: "flex-end" }}>
          <div className="col">
            <h2 style={{ marginBottom: "0px", marginLeft: "8px" }}>
              {editAttributeSchema.name}
            </h2>
          </div>
          <button
            className="btn btn-primary btn-sm m-1 mr-4"
            type="button"
            onClick={toggleEditMode}
            hidden={editMode}
          >
            Edit
          </button>
          <button
            ref={saveButtonRef}
            className="btn btn-success btn-sm m-1"
            id="green"
            type="submit"
            hidden={!editMode}
          >
            Save
          </button>
          <button
            className="btn btn-primary btn-sm m-1 mr-4"
            type="button"
            onClick={toggleEditMode}
            hidden={!editMode}
          >
            Cancel
          </button>
        </div>
      </form>
      <div className="row entity-list">
        <div className="col">
          <div className="attributeSchema-info">
            <ul>
              <LabelTextField
                label={"Name"}
                value={editAttributeSchema.name}
                editMode={editMode}
                onChange={(value) => editAttributeSchemaField("name", value)}
                required
              />
            </ul>
          </div>
        </div>
        <div className="col">
          <div className="attributeSchema-info">
            <ul>
              <LabelSelectField
                label={"Global"}
                value={
                  isEmpty(editAttributeSchema.global)
                    ? "None"
                    : editMode
                    ? editAttributeSchema.global
                    : getOptionText(
                        IS_GLOBAL_OPTIONS,
                        editAttributeSchema.global
                      )
                }
                options={IS_GLOBAL_OPTIONS}
                editMode={editMode}
                onChange={(value) =>
                  editAttributeSchemaField(
                    "global",
                    value === "None" ? null : value
                  )
                }
                booleanValue={true}
              />
            </ul>
          </div>
        </div>
      </div>
      <div className="row entity-list">
        <div className="col">
          <div className="attributeSchema-info">
            <ul>
              <LabelSelectField
                label={"Product Group"}
                value={
                  editMode
                    ? isEmpty(editAttributeSchema.productGroup)
                      ? "None"
                      : editAttributeSchema.productGroup.id
                    : isEmpty(editAttributeSchema.productGroup)
                    ? "None"
                    : editAttributeSchema.productGroup.name
                }
                options={productGroup.map((c) => optionFromNameAndId(c))}
                editMode={editMode}
                onChange={(id) =>
                  editAttributeSchemaField(
                    "productGroup",
                    id === "NONE"
                      ? null
                      : getNameAndIdFromList(productGroup, id)
                  )
                }
              />
            </ul>
          </div>
        </div>
        <div className="col">
          <div className="attributeSchema-info">
            <ul>
              <LabelSelectField
                label={"Customer"}
                value={
                  editMode
                    ? isEmpty(editAttributeSchema.customer)
                      ? "None"
                      : editAttributeSchema.customer.id
                    : isEmpty(editAttributeSchema.customer)
                    ? "None"
                    : editAttributeSchema.customer.name
                }
                options={customer.map((c) => optionFromNameAndId(c))}
                editMode={editMode}
                onChange={(id) =>
                  editAttributeSchemaField(
                    "customer",
                    id === "NONE" ? null : getNameAndIdFromList(customer, id)
                  )
                }
              />
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};
