/* eslint-disable no-trailing-spaces */
/* eslint-disable no-unused-vars */
/* eslint-disable no-multiple-empty-lines */

// React
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

// Local
import { api } from "../../services/apiService";
import { PageLayout } from "layouts/PageLayout";
import { useURLNavigate } from "hooks/useURLNavigate";
import { useURLParams } from "hooks/useURLParams";
import { useOverlay } from "hooks/useOverlay";
import { useError } from "hooks/errors";
import { PAGE_URL } from "constants/url";
import { isEmpty, isNullOrUndefined } from "util/common";
import { TopLevelUserInfo } from "./components/TopLevelUserInfo";
import { UserRelationships } from "./components/UserRelationships";
import { showSuccessMessage, pushSuccessMessage } from "store/messages/messageActions";
import { cloneDeep } from "lodash";
import { ROLES } from "constants/roles";

export const UserDetailPage = () => {
  // hooks
  const navigate = useURLNavigate();
  const [showError, pushError] = useError();
  const [getParam] = useURLParams();
  const [showOverlay, hideOverlay] = useOverlay();
  const dispatch = useDispatch();
  const message = useSelector(state => state.message.message);
  
  // state
  const [editMode, setEditMode] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [user, setUser] = useState();
  const [editUser, setEditUser] = useState();
  const [vendors, setVendors] = useState();
  const [customers, setCustomers] = useState();
  const [factories, setFactories] = useState();
  const [regions, setRegions] = useState();
  
  // url variables
  const userId = getParam("id");
  const createMode = getParam("createMode");
  
  useEffect(() => {
    loadData();
  }, []);

  const loadData = async () => {

    // handle url hack
    if (createMode && !isEmpty(userId)) {
      pushError("Invalid URL params");
      navigate(PAGE_URL.USERS);
    }

    if (!isEmpty(userId)) {
      // load user
      try {
        const userResponse = await api.getUser(userId);

        setUser(cloneDeep(userResponse.data));
        setEditUser(cloneDeep(userResponse.data));
      } catch (error) {
        pushError(error);
        navigate(PAGE_URL.HOME);
      }
    }

    if (createMode) {
      // setup new user
      setEditMode(true);
      setEditUser({
        email: "",
        contactName: "",
        telephone: "",
        role: ROLES.VENDOR,
        active: true,
        emailEnabled: true,
        customers: [],
        vendors: [],
        factories: [],
        regions: [],
      });
    }

    if (createMode || !isEmpty(userId)) {
      // load entities
      try {
        const vendorsResponse = await api.getAllVendors();
        const customersResponse = await api.getAllCustomers();
        const factoriesResponse = await api.getAllFactories();
        const regionsResponse = await api.getAllRegions();
  
        setVendors(vendorsResponse.data.vendors);
        setCustomers(customersResponse.data.customers);
        setFactories(factoriesResponse.data.factories);
        setRegions(regionsResponse.data.regions);
        setLoaded(true);
      } catch (error) {
        pushError(error);
        navigate(PAGE_URL.HOME);
      }
    }

  };
  
  const toggleEditMode = () => {
    if (createMode) {
      navigate(PAGE_URL.USERS);
    } else if (editMode) {
      setEditUser(cloneDeep(user)); // revert changes
      setEditMode(false);
    } else {
      setEditMode(true);
    }
  };

  const onSave = async () => {

    if (isNullOrUndefined(message)) {
      await showOverlay("Saving...");
    }

    if (createMode) {
      try {
        await api.createUser(editUser);
        dispatch(pushSuccessMessage("User Successfully Created"));
        navigate(PAGE_URL.USERS);
      } catch (error) {
        showError(error);
      }

    } else {
      try {
        const response = await api.updateUser(user.id, editUser);

        const _editUser = editUser;
        _editUser.lastLogin = response.data.lastLogin;

        setUser(cloneDeep(_editUser));
        dispatch(showSuccessMessage("User Successfully Updated"));
      } catch (error) {
        showError(error);
        setEditUser(user);
      } finally {
        setEditMode(false);
      }
    }

    hideOverlay();
  };

  const onReset = async () => {
    try {
      if (isNullOrUndefined(message)) {
        await showOverlay("Requesting Reset...");
      }
      await api.requestPasswordReset(userId);
      hideOverlay();
      dispatch(showSuccessMessage("Password Reset Sent"));
    } catch (error) {
      hideOverlay();
      showError(error);
    }
  };

  const editUserField = (field, value) => {
    let _editUser = { ...editUser };
    _editUser[field] = value;
    setEditUser(_editUser);
  };

  const updateUserEntity = (field, entity) => {
    let _editUser = { ...editUser };

    if (_editUser[field] && _editUser[field].map(e => e.id).includes(entity.id)) {
      _editUser[field] = _editUser[field].filter(e => e.id != entity.id);
    } else {
      _editUser[field].push(entity);
    }

    setEditUser(_editUser);
  };

  const selectDeselectAll = (field, entityList) => {
    let _editUser = { ...editUser };

    if (_editUser[field].length === entityList.length) {
      // remove all
      _editUser[field] = [];
    } else {
      // add all 
      _editUser[field] = cloneDeep(entityList);
    }

    setEditUser(_editUser);
  };

  return (
    <PageLayout className="user-details">
      <section className="site-content bodyWrap" id="content">
        <section className="content-body">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <h4 className="PageTitle">
                  <Link className="page-parent" to={PAGE_URL.USERS}>
                    USERS
                  </Link>{" "}
                  &ndash; <span className="page-current">USER DETAILS</span>
                </h4>
              </div>
            </div>
            {loaded && (
              <div className="row">
                <div className="col-12">
                  <TopLevelUserInfo
                    editUser={editUser}
                    editUserField={editUserField}
                    editMode={editMode}
                    createMode={createMode}
                    toggleEditMode={toggleEditMode}
                    onSave={onSave}
                    onReset={onReset}
                  />
                </div>
              </div>
            )}
            {loaded && (
              <div className="row">
                <div className="col-12">
                  <div className="card shadow" id="top-level-info">
                    <div className="card-body">
                      <UserRelationships 
                        editUser={editUser}
                        updateUserEntity={updateUserEntity}
                        editMode={editMode}
                        createMode={createMode}
                        vendors={vendors}
                        customers={customers}
                        factories={factories}
                        regions={regions}
                        selectDeselectAll={selectDeselectAll}
                    />
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </section>
      </section>
    </PageLayout>
  );
};
