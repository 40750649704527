import { formatDateForVariableData } from "components/formatDate";
import React from "react";
import { ProductCheckbox } from "./ProductCheckbox";

export const VariableDataProductRow = ({ group, onCheckboxChange, checkAll, showOrdered }) => {
  const removeDuplicatesTitles = (value) => {
    return Array.from(new Set(value.split(","))).toString();
  };
  return (
    <tr>
      <th scope="row">
          <ProductCheckbox productIds={group.productItemIds} onChange={onCheckboxChange} checkAll={checkAll} />
      </th>
      <td>{group.poNumber}</td>
      <td>{group.styleNumber}</td>
      <td>{removeDuplicatesTitles(group.ticketTypes)}</td>
      <td>{removeDuplicatesTitles(group.descriptions)}</td>
      <td>{group.creationDate ? formatDateForVariableData(group.creationDate) : "---"}</td>
      {showOrdered && <td>{group.ordered === true ? "Yes" : "---"}</td>}
    </tr>
  );
};
