import * as types from "./messageActionTypes";
import { isTrue, isFalse } from "util/common";
import { MESSAGE_TYPE } from "constants/messageType";

export const showSuccessMessage = (message, retain) => {
    return {
        type: types.SHOW_MESSAGE,
        message: message,
        messageType: MESSAGE_TYPE.SUCCESS,
        retain: isTrue(retain) ? true : false
    };
};

export const showErrorMessage = (message, retain) => {
    return {
        type: types.SHOW_MESSAGE,
        message: message,
        messageType: MESSAGE_TYPE.ERROR,
        retain: isFalse(retain) ? false : true
    };
};

export const clearMessage = () => {
    return {
        type: types.CLEAR_MESSAGE
    };
};

export const pushSuccessMessage = (message, retain) => {
    return {
        type: types.PUSH_MESSAGE,
        message: message,
        messageType: MESSAGE_TYPE.SUCCESS,
        retain: isTrue(retain) ? true : false
    };
};

export const pushErrorMessage = (message, retain) => {
    return {
        type: types.PUSH_MESSAGE,
        message: message,
        messageType: MESSAGE_TYPE.ERROR,
        retain: isFalse(retain) ? false : true
    };
};

export const popMessage = () => {
    return {
        type: types.POP_MESSAGE
    };
};

export const showOverlayMessage = (message) => {
    return {
        type: types.SHOW_OVERLAY_MESSAGE,
        message: message
    };
};

export const hideOverlayMessage = () => {
    return {
        type: types.HIDE_OVERLAY_MESSAGE
    };
};
