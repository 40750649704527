import React from "react";
import { OrderDetailsStatusStep } from "./OrderDetailsStatusStep";
import { ORDER_STATUS_ENUM } from "constants/orderStatus";

export const OrderDetailsStatus = ({ orderStatus, hasArtwork, financeApproved }) => {
    const orderStep = ORDER_STATUS_ENUM[orderStatus];

    return (
        <div className="row">
            <div className="col-12">
                <div id="orderStatus">
                    <OrderDetailsStatusStep step={1} currentStep={orderStep} name="placed"  displayName="Placed"/>
                    {hasArtwork && <OrderDetailsStatusStep step={2} currentStep={orderStep} name="art-prepared" displayName="Art Prepared" />}
                    {hasArtwork && <OrderDetailsStatusStep step={3} currentStep={orderStep} name="art-approved" displayName="Art Approved" />}
                    <OrderDetailsStatusStep step={4} currentStep={orderStep} name="production" displayName="Production" />
                    <OrderDetailsStatusStep name="finance" displayName="Finance" active={financeApproved}/>
                    <OrderDetailsStatusStep step={6} currentStep={orderStep} name="shipped" displayName="Shipped" />
                </div>
            </div>
        </div>
    );
};
