/* eslint-disable no-unused-vars */

// React
import React, { useRef } from "react";

// Local
import { LabelTextField } from "components/LabelTextField";
import { LabelSelectField } from "components/LabelSelectField";
import { isEmpty } from "util/common";
import { optionFromNameAndId, getNameAndIdFromList } from "constants/options";

export const TopLevelFactoryInfo = ({
  editFactory,
  editFactoryField,
  editMode,
  toggleEditMode,
  regions,
  currencies,
  onSave,
}) => {
  const saveButtonRef = useRef();

  const onInputKeyDown = (e) => {
    if (e.keyCode === 13) {
      e.preventDefault();
      saveButtonRef.current.click();
    }
  };

  return (
    <form
      onKeyDown={onInputKeyDown}
      onSubmit={(e) => {
        e.preventDefault();
        onSave();
      }}
    >
      <div className="card shadow" id="top-level-info">
        <div className="card-body">
          <div className="row" style={{ justifyContent: "flex-end" }}>
            <div className="col">
              <h2 style={{ marginBottom: "0px", marginLeft: "8px" }}>
                {editFactory.name}
              </h2>
            </div>
            <button
              className="btn btn-primary btn-sm m-1 mr-4"
              type="button"
              onClick={toggleEditMode}
              hidden={editMode}
            >
              Edit
            </button>
            <button
              ref={saveButtonRef}
              className="btn btn-success btn-sm m-1"
              id="green"
              type="submit"
              hidden={!editMode}
            >
              Save
            </button>
            <button
              className="btn btn-primary btn-sm m-1 mr-4"
              type="button"
              onClick={toggleEditMode}
              hidden={!editMode}
            >
              Cancel
            </button>
          </div>
          <div className="row factory-info">
            <div className="col-4">
              <ul>
                <LabelTextField
                  label={"Factory Name"}
                  value={editFactory.name}
                  editMode={editMode}
                  onChange={(value) => editFactoryField("name", value)}
                  required
                />
                <LabelTextField
                  label={"Factory Short Code"}
                  value={editFactory.shortCode}
                  editMode={editMode}
                  onChange={(value) => editFactoryField("shortCode", value)}
                  required
                />
              </ul>
            </div>
            <div className="col-5">
              <ul>
                <LabelTextField
                  label={"Address"}
                  value={editFactory.address}
                  editMode={editMode}
                  onChange={(value) => editFactoryField("address", value)}
                />
                <LabelTextField
                  label={"Telephone"}
                  value={editFactory.telephone}
                  editMode={editMode}
                  onChange={(value) => editFactoryField("telephone", value)}
                />
              </ul>
            </div>
            <div className="col-3">
              <div className="factory-info">
                <ul>
                  <LabelSelectField
                    label={"Currency Type"}
                    value={
                      editMode
                        ? isEmpty(editFactory.currencyType)
                          ? 1
                          : editFactory.currencyType.id
                        : isEmpty(editFactory.currencyType)
                        ? ""
                        : editFactory.currencyType.name
                    }
                    options={currencies.map((c) => optionFromNameAndId(c))}
                    editMode={editMode}
                    onChange={(id) =>
                      editFactoryField(
                        "currencyType",
                        getNameAndIdFromList(currencies, id)
                      )
                    }
                  />
                  <LabelSelectField
                    label={"Production Region"}
                    value={
                      editMode
                        ? editFactory.productionRegion.id
                        : editFactory.productionRegion.name
                    }
                    options={regions.map((r) => optionFromNameAndId(r))}
                    editMode={editMode}
                    onChange={(id) =>
                      editFactoryField(
                        "productionRegion",
                        getNameAndIdFromList(regions, id)
                      )
                    }
                  />
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};
