import React from "react";
import { LabelValue } from "components/LabelValue";
import { isEmpty } from "util/common";


export const OrderDetailBilling = ({ orderDetails }) => {

    return (
        <div className="row">
            <div className="col-12">
                <div className="card shadow" id="payment">
                    <div className="card-body">
                        <h2>Payment</h2>
                        <div className="row">
                            <div className="col-4">
                                <ul className="readonly">
                                    <LabelValue label="Billing Account" 
                                                value={isEmpty(orderDetails.billingAccount) ? '---' : orderDetails.billingAccount} />
                                </ul>
                            </div>
                            <div className="col-4">
                                <ul className="readonly">
                                    <LabelValue label="Bill To" value={orderDetails.billingAddress} />
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );

};