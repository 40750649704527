import React, { useState } from "react";
import { LabelDate } from "components/LabelDate";
import { LabelValue } from "components/LabelValue";
import { LabelButton } from "components/LabelButton";
import { ROLES, userCanPerformOperation, ROLE_OPERATIONS } from "constants/roles";
import { LabelCurrency } from "components/LabelCurrency";
import { ORDER_STATUS, ORDER_STATUS_ENUM, ORDER_STATUS_API } from "constants/orderStatus";
import { useCurrentUser } from "hooks/useCurrentUser";
import DatePickerWithIcon from "components/DatePickerWithIcon";
import { useRef } from "react";
import api from "services/apiService";
import { formatDateForAPI } from "components/formatDate";
import { isNullOrUndefined } from "util/common";
import { useError } from "hooks/errors";
import { downloadFile } from "util/download";
import { useOverlay } from "hooks/useOverlay";
import {getRole} from "../../../services/authService";

export const OrderDetailHeader = ({ details, orderId }) => {
    const user = useCurrentUser();
    const estimatedCompletionDateRef = useRef(null);
    const [estimatedCompletionDate, setEstimatedCompletionDate] = useState(Date.parse(details.estimatedCompletionDate));
    const [invoiceNumber, setInvoiceNumber] = useState(details.invoiceNumber);
    const [showError, ] = useError();
    const [showOverlay, hideOverlay] = useOverlay();


    const onEstimatedCompletionDateChange = (date) => {
        setEstimatedCompletionDate(date);

        if (date === null) {
            estimatedCompletionDateRef.current.setOpen(false);
        }

        updateEstimatedCompletionDate(date);
    };

    const updateEstimatedCompletionDate = async (date) => {
        const data = {
            orderId: orderId,
            estimatedCompletionDate: formatDateForAPI(date)
        };

        try {
            await api.udpateEstimatedCompletionDate(data);
        } catch (error) {
            showError(error);
        }
    };

    const onKeyDown = (e) => {
        if (e.keyCode === 13) {
            e.preventDefault();
            e.target.blur();
        }
    };

    const updateInvoiceNumber = async () => {
        const data = {
            orderId: orderId,
            invoiceNumber: invoiceNumber
        };
        
        try {
            await api.updateInvoiceNumber(data);
        } catch (error) {
            showError(error);
        }
    };

    const downloadVendorPo = async () => {
        try {
            showOverlay("Downloading...");
            const response = await api.downloadVendorPo(orderId);
            downloadFile(response);
            hideOverlay();
        } catch (error) {
            hideOverlay();
            showError(error);
        }
    };

    const downloadProductionPo = async (poId) => {
        try {
            showOverlay("Downloading...");
            const response = await api.downloadProductionPo(orderId, poId);
            downloadFile(response);
            hideOverlay();
        } catch (error) {
            hideOverlay();
            showError(error);
        }
    };

    const downloadVendorPackingSlip = async () => {
        try {
            showOverlay("Downloading...");
            const response = await api.downloadVendorPackingSlip(orderId);
            downloadFile(response);
            hideOverlay();
        } catch (error) {
            hideOverlay();
            showError(error);
        }
    };

    const downloadProductionPackingSlip = async (packingSlipId) => {
        try {
            showOverlay("Downloading...");
            const response = await api.downloadProductionPackingSlip(orderId, packingSlipId);
            downloadFile(response);
            hideOverlay();
        } catch (error) {
            hideOverlay();
            showError(error);
        }
    };
    
    return (
        <div className="row">
            <div className="col-12">
                <div className="list-group" id="orderList">
                    <div className="order-item list-group-item list-group-item-action">
                        <div className="d-flex w-100">
                            <div className="order-details">
                                <h5 className="company">{details.company}</h5>
                                <div className="order-meta">
                                    <ul>
                                        <LabelDate label="Order Date" date={details.orderDate} labelClassName={"order-detail-header"} labelClassName={"order-detail-header"} />

                                        <LabelValue label="Customer" value={details.customer} labelClassName={"order-detail-header"} />

                                        <LabelValue label="Production Region" value={details.productionRegion} labelClassName={"order-detail-header"} />

                                        <LabelButton label="Vendor PO" value={details.vendorPO} onClickHandler={() => downloadVendorPo()} labelClassName={"order-detail-header"}/>
                                        
                                        {details.productionsPurchaseOrders && details.productionsPurchaseOrders.map((po, index) =>
                                            <LabelButton key={index} label="Production PO" value={po.productionPO} onClickHandler={() => downloadProductionPo(po.purchaseOrderId)} labelClassName={"order-detail-header"} />
                                        )}

                                        {details.vendorPO && details.vendorPackingSlip ? <LabelButton label="Vendor Packing Slip" value={"PS-" + details.vendorPO} onClickHandler={() => downloadVendorPackingSlip()} labelClassName={"order-detail-header"}/> : <></>}

                                        {details.productionPackingSlips && details.productionPackingSlips.map((po, index) =>
                                            <LabelButton key={index} label="Production Packing Slip" value={po.productionPackingSlipNumber} onClickHandler={() => downloadProductionPackingSlip(po.packingSlipId)} labelClassName={"order-detail-header"} />
                                        )}

                                        <LabelDate label="Date Requested" date={details.dateRequested} labelClassName={"order-detail-header"} />

                                        {userCanPerformOperation(user, ROLE_OPERATIONS.MANAGE_ESTIMATED_COMPLETION_DATE) && ORDER_STATUS_ENUM[details.orderStatus] < ORDER_STATUS_ENUM[ORDER_STATUS_API.COMPLETE_AND_SHIPPED] && !details.cancelled &&
                                            <li>
                                                <div className="label order-detail-header">
                                                    <label>Estimated Completion Date:</label>
                                                </div>
                                                <div className="input-group"></div>
                                                <DatePickerWithIcon
                                                        ref={estimatedCompletionDateRef}
                                                        selected={estimatedCompletionDate}
                                                        dateFormat="dd-MMM-yyyy"
                                                        className="form-control"
                                                        placeholderText="Enter Est. Completion Date"
                                                        onChange={onEstimatedCompletionDateChange}
                                                        minDate={new Date()}
                                                />
                                            </li>
                                        }
                                        
                                        {(
                                            (!userCanPerformOperation(user, ROLE_OPERATIONS.MANAGE_ESTIMATED_COMPLETION_DATE)) || 
                                            (userCanPerformOperation(user, ROLE_OPERATIONS.MANAGE_ESTIMATED_COMPLETION_DATE) && ((ORDER_STATUS_ENUM[details.orderStatus] > ORDER_STATUS_ENUM[ORDER_STATUS_API.READY_TO_SHIP]) || details.cancelled))
                                        ) &&
                                            <LabelDate label="Estimated Completion Date" date={details.estimatedCompletionDate} labelClassName={"order-detail-header"} />
                                        }

                                        <LabelDate label="Date Shipped" date={details.shipAndCompleteDate} labelClassName={"order-detail-header"} />
                                        
                                        {(!isNullOrUndefined(details.invoiceNumber) && userCanPerformOperation(user, ROLE_OPERATIONS.INPUT_INVOICE_NUMBER) && details.orderStatus !== ORDER_STATUS.SHIPPED) &&
                                            <li>
                                                <div className="label order-detail-header">Invoice Number:</div>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="Enter Invoice Number"
                                                    value={invoiceNumber}
                                                    onKeyDown={onKeyDown}
                                                    onChange={e => setInvoiceNumber(e.target.value)}
                                                    onBlur={updateInvoiceNumber}
                                                />
                                            </li>
                                        }
                                        {(!isNullOrUndefined(details.invoiceNumber) && (user.role === ROLES.ACCOUNT || user.role === ROLES.FACTORY_ACCOUNT || user.role === ROLES.CUSTOMER || details.orderStatus === ORDER_STATUS.SHIPPED)) &&
                                            <LabelValue label="Invoice Number" value={details.invoiceNumber} labelClassName={"order-detail-header"} />
                                        }
                                    </ul>
                                </div>
                            </div>
                            <div className="order-price">
                                {details.totalPrice &&
                                    <div>
                                        {details.totalCost &&
                                            <span className="descriptor">Price:</span>
                                        }
                                        <LabelCurrency currency={details.totalPrice.currency} value={details.totalPrice.price} />
                                    </div>
                                }
                                {details.totalCost &&
                                    <div>
                                        {details.totalPrice &&
                                            <span className="descriptor">Cost:</span>
                                        }
                                        <LabelCurrency currency={details.totalCost.currency} value={details.totalCost.price} />                                        
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
