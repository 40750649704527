/* eslint-disable no-multiple-empty-lines */
/* eslint-disable no-trailing-spaces */
/* eslint-disable no-unused-vars */
import React from "react";

export const UserRelationships = ({
  editUser,
  updateUserEntity,
  editMode,
  vendors,
  customers,
  factories,
  regions,
  selectDeselectAll,
}) => {
  const getSelectLabel = (items, itemsSelected) =>
    items.length == itemsSelected.length ? "Deselect All" : "Select All";

  return (
    <div className="row entity-list">
      <div className="col">
        <label>Vendors</label>
        <button
          className="btn btn-secondary select-all-btn"
          disabled={!editMode}
          onClick={() => selectDeselectAll("vendors", vendors)}
        >
          {getSelectLabel(vendors, editUser.vendors)}
        </button>
        <div className="scroller">
          {editUser &&
            editUser.vendors &&
            vendors &&
            vendors.map((v, index) => (
              <div className="item" key={index}>
                <input
                  key={index}
                  disabled={!editMode}
                  type="checkbox"
                  checked={editUser.vendors.map((v) => v.id).includes(v.id)}
                  onClick={() => updateUserEntity("vendors", v)}
                  className="checkbox"
                />
                {v.name}
              </div>
            ))}
        </div>
      </div>
      <div className="col">
        <label>Customers</label>
        <button
          className="btn btn-secondary select-all-btn"
          disabled={!editMode}
          onClick={() => selectDeselectAll("customers", customers)}
        >
          {getSelectLabel(customers, editUser.customers)}
        </button>
        <div className="scroller">
          {editUser &&
            editUser.customers &&
            customers &&
            customers.map((c, index) => (
              <div className="item" key={index}>
                <input
                  key={index}
                  disabled={!editMode}
                  type="checkbox"
                  checked={editUser.customers.map((c) => c.id).includes(c.id)}
                  onClick={() => updateUserEntity("customers", c)}
                  className="checkbox"
                />
                {c.name}
              </div>
            ))}
        </div>
      </div>
      <div className="col">
        <label>Factories</label>
        <button
          className="btn btn-secondary select-all-btn"
          disabled={!editMode}
          onClick={() => selectDeselectAll("factories", factories)}
        >
          {getSelectLabel(factories, editUser.factories)}
        </button>
        <div className="scroller">
          {editUser &&
            editUser.factories &&
            factories &&
            factories.map((f, index) => (
              <div className="item" key={index}>
                <input
                  key={index}
                  disabled={!editMode}
                  type="checkbox"
                  checked={editUser.factories.map((f) => f.id).includes(f.id)}
                  onClick={() => updateUserEntity("factories", f)}
                  className="checkbox"
                />
                {f.name}
              </div>
            ))}
        </div>
      </div>
      <div className="col">
        <label>Regions</label>
        <button
          className="btn btn-secondary select-all-btn"
          disabled={!editMode}
          onClick={() => selectDeselectAll("regions", regions)}
        >
          {getSelectLabel(regions, editUser.regions)}
        </button>
        <div className="scroller">
          {editUser &&
            editUser.regions &&
            regions &&
            regions.map((r, index) => (
              <div className="item" key={index}>
                <input
                  key={index}
                  disabled={!editMode}
                  type="checkbox"
                  checked={editUser.regions.map((r) => r.id).includes(r.id)}
                  onClick={() => updateUserEntity("regions", r)}
                  className="checkbox"
                />
                {r.name}
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};
