/* eslint-disable no-multiple-empty-lines */
/* eslint-disable no-trailing-spaces */

// React
import React, { useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { isEmpty, isNullOrUndefined } from "util/common";
import { setCustomerSearchValues } from "store/customer/customerActions";

export const CustomerListSearch = ({ searchCustomers }) => {
  const dispatch = useDispatch();
  const searchValues = useSelector(state => state.customer.customerSearchValues);
  const searchButtonRef = useRef(null);
  const filterRef = useRef(null);

  useEffect(() => {
    filterRef.current.click();
    searchButtonRef.current.click();
  }, []);

  const updateSearchValue = (name, value) => {
    const _searchValues = { ...searchValues };
    _searchValues[name] = value;

    dispatch(setCustomerSearchValues(_searchValues));
  };

  const onInputKeyDown = (e) => {
    if (e.keyCode === 13) {
      e.preventDefault();
      searchButtonRef.current.click();
    }
  };

  return (
    <>
      <form onKeyDown={onInputKeyDown}>
        <a className="toggleBTN collapsed" 
          data-toggle="collapse" 
          href="#searchForm" 
          role="button" 
          aria-expanded="false" 
          aria-controls="searchForm" 
          ref={filterRef}><span id="ToggleIcon"></span>Filter</a>
        <div className="collapse rounded" id="searchForm">
          <div className="searchForm">
            <div className="form-row">
              <div className="form-group col-3 col-left-none">
                <label>Name</label>
                <input 
                  type="text" 
                  className="form-control" 
                  placeholder="Enter Name" 
                  value={isNullOrUndefined(searchValues) ? "" : searchValues.name}
                  onChange={e => updateSearchValue("name", e.target.value)} 
                />
              </div>
              <div id="searchButtonCol" className="col-3 col-left-none">
                <button 
                  ref={searchButtonRef} 
                  className="searchBTN btn btn-block btn-outline-primary" 
                  type="button" 
                  onClick={() => searchCustomers(isEmpty(searchValues) ? {} : searchValues)}>
                  Search
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  );
};
