import React from "react";
import { PercentageField } from "./PercentageField";
import { Link } from "react-router-dom";

export const GarmentComponent = ({ component, isOnlyComponent, getAvailableComponentTypes, onPercentageChange, onMaterialChange, removeFabricComposition, addFabricComposition, onComponentTypeChange, getAvailableMaterials }) => {

    const onKeyDown = (e) => {
        if (e.keyCode === 13) {
            e.preventDefault();
            e.target.blur();
        }
    };

    return (
        <li className="garment" key={component.id}>
            <div className="form-row">
                <div className="form-group col-4">
                    <select className="garment-component form-control" value={component.componentType} onChange={e => onComponentTypeChange(component.id, e.target.value)} onKeyDown={onKeyDown} required={true}>
                        <option value="" hidden>---</option>
                        {getAvailableComponentTypes(component.componentType).map((c, index) =>
                            <option key={index} value={c.value}>{c.displayName}</option>
                        )}
                    </select>
                </div>
                <div className="col-8">
                    <ul>
                        {component.fabricCompositions.map(f =>
                            <li className="material" key={f.id}>
                                <div className="form-row">
                                    <div className="form-group col-2">
                                        <PercentageField className={!component.validationCleared ? "invalid-red" : ""} initialValue={f.percentage} onPercentageChange={p => onPercentageChange(component.id, f.id, p)} required={true} />
                                    </div>
                                    <div className="form-group col-5">
                                        <select className="garment-component-material form-control" value={f.material} onChange={e => onMaterialChange(component.id, f.id, e.target.value)} onKeyDown={onKeyDown} required={true}>
                                            <option value="" hidden>---</option>
                                            {getAvailableMaterials(component.id, f.material).map((m, index) =>
                                                <option key={index} value={m.value}>{m.displayName}</option>
                                            )}
                                        </select>
                                    </div>
                                    <div className={"col-5 material-actions" + (isOnlyComponent && component.fabricCompositions.length === 1 ? " isHidden" : "" )}>
                                        <Link to="#" className="remove" data-toggle="tab" onClick={() => removeFabricComposition(component.id, f.id)}><i className="fal fa-times-square fa-2x"></i></Link>
                                    </div>
                                </div>
                            </li>
                        )}
                        <li className="material-actions">
                            <div className="col-7"></div>
                            <div className="col-3">
                                <Link to="#" className="add add-for-garment" data-toggle="tab" onClick={() => addFabricComposition(component.id)}><i className="fal fa-plus-square fa-2x"></i>Add Material</Link>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </li>
    );
};
