import React from "react";
import { PageLayout } from "layouts/PageLayout";
import { useState } from "react";
import api from "services/apiService";
import { useDispatch } from "react-redux";
import { showSuccessMessage } from "store/messages/messageActions";
import { useEffect } from "react";
import { useRef } from "react";
import { useOverlay } from "hooks/useOverlay";
import { useError } from "hooks/errors";

export const VariableDataUploadPage = () => {
    const dispatch = useDispatch();
    const [showOverlay, hideOverlay] = useOverlay();
    const fileRef = useRef(null);
    const [file, setFile] = useState(null);
    const [customers, setCustomers] = useState(null);
    const [customer, setCustomer] = useState(null);
    const [showError, ] = useError();

    useEffect(() => {
        getVariableDataCustomers();
    }, []);

    useEffect(() => {
        if (customers && customers.length === 1) {
            setCustomer(customers[0].customerId);
        }
    }, [customers]);

    const getVariableDataCustomers = async () => {
        try {
            const response = await api.getVariableDataCustomers();
            setCustomers(response.data.customers);
        } catch (error) {
            showError(error);
        }
    };

    const onKeyDown = (e) => {
        if (e.keyCode === 13) {
            e.preventDefault();
            e.target.blur();
        }
    };

    const upload = async () => {
        const data = new FormData();
        data.append("file", file);
        data.append("customerId", customer);

        try {
            await showOverlay("Uploading Variable Data File...");
            await api.uploadVariableData(data);
            hideOverlay();
            dispatch(showSuccessMessage("Variable data file successfully uploaded. You will receive an email once it has finished processing. This may take several minutes.", true));
        } catch (error) {
            hideOverlay();
            showError(error);
        }

        setFile(null);
        fileRef.current.value = "";
    };

    return (
        <PageLayout className="variable-data-upload">
            <section className="site-content bodyWrap" id="content">
                <section className="content-body">
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <h4 className="PageTitle"><span className="page-parent">VARIABLE DATA</span></h4>
                            </div>
                        </div>
                        {customers &&
                            <div className="row">
                                <div className="col-12">
                                    <div className="card card-body form-group">
                                        <h2>Variable Data Upload</h2>
                                        <div className="row first">
                                            <div className="col-4">
                                                <label><strong>Step 1:&nbsp;&nbsp;</strong>Select Customer</label>
                                                <select className="form-control" required onChange={e => setCustomer(e.target.value)} onKeyDown={onKeyDown} value={customer}>
                                                    {(customers && customers.length !== 1) &&
                                                        <option value="" hidden>---</option>
                                                    }
                                                    {customers && customers.map(customer =>
                                                        <option value={customer.customerId}>{customer.customerName}</option>
                                                    )}
                                                </select>
                                            </div>
                                        </div>
                                        <hr />
                                        <div className="row">
                                            <div className="col-6">
                                                <label><strong>Step 2:&nbsp;&nbsp;</strong>Select variable data file</label>
                                                <input ref={fileRef} type="file" onChange={e => setFile(e.target.files[0])} name="file" className="btn-block" />
                                            </div>
                                        </div>
                                        <hr />
                                        <div className="row">
                                            <div className="col-3">
                                                <label><strong>Step 3:</strong></label>
                                                <div>
                                                    <input className="btn btn-primary" type="button" value="Upload" onClick={() => upload()} disabled={customer === null || file === null} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                </section>
            </section>
        </PageLayout>
    );
};
