// React
import React, { useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

// Local
import { api } from "../../services/apiService";
import { PageLayout } from "layouts/PageLayout";
import { PAGE_URL } from "constants/url";
import { useURLNavigate } from "hooks/useURLNavigate";
import { useURLParams } from "hooks/useURLParams";
import { useError } from "hooks/errors";
import { useOverlay } from "hooks/useOverlay";
import { isEmpty, isNullOrUndefined } from "util/common";
import { LabelTextField } from "components/LabelTextField";
import {
  showSuccessMessage,
  pushSuccessMessage,
} from "store/messages/messageActions";

export const ProductCodeDetailPage = () => {
  // hooks
  const navigate = useURLNavigate();
  const [showError, pushError] = useError();
  const [getParam] = useURLParams();
  const [showOverlay, hideOverlay] = useOverlay();
  const dispatch = useDispatch();
  const saveButtonRef = useRef();
  const message = useSelector((state) => state.message.message);

  // state
  const [editMode, setEditMode] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [productCode, setProductCode] = useState();
  const [editProductCode, setEditProductCode] = useState();

  // url variables
  const productCodeId = getParam("id");
  const createMode = getParam("createMode");

  useEffect(() => {
    loadData();
  }, []);

  const loadData = async () => {
    // url hack
    if (createMode && !isEmpty(productCodeId)) {
      pushError("Invalid URL params");
      navigate(PAGE_URL.PRODUCT_CODES);
    }

    if (!isEmpty(productCodeId)) {
      // load product code
      try {
        const productCodeResponse = await api.getProductCode(productCodeId);
        setProductCode(productCodeResponse.data);
        setEditProductCode(productCodeResponse.data);
      } catch (error) {
        pushError(error);
        navigate(PAGE_URL.HOME);
      }
    } else if (createMode) {
      setEditMode(true);
      setEditProductCode({
        productCode: "",
      });
    }

    setLoaded(true);
  };

  const toggleEditMode = () => {
    if (createMode) {
      navigate(PAGE_URL.PRODUCT_CODES);
    } else if (editMode) {
      setEditProductCode(productCode); // revert changes
      setEditMode(false);
    } else {
      setEditMode(true);
    }
  };

  const onSave = async () => {
    if (isNullOrUndefined(message)) {
      await showOverlay("Saving...");
    }

    if (createMode) {
      try {
        await api.addProductCode(editProductCode);
        dispatch(pushSuccessMessage("Product Code Successfully Created"));
        navigate(PAGE_URL.PRODUCT_CODES);
      } catch (error) {
        showError(error);
      }
    } else {
      try {
        await api.updateProductCode(editProductCode);
        setProductCode(editProductCode);
        dispatch(showSuccessMessage("Product Code Successfully Updated"));
      } catch (error) {
        showError(error);
        setEditProductCode(productCode);
      } finally {
        setEditMode(false);
      }
    }

    hideOverlay();
  };

  const editProductCodeField = (field, value) => {
    let _editProductCode = { ...editProductCode };
    _editProductCode[field] = value;
    setEditProductCode(_editProductCode);
  };

  const onInputKeyDown = (e) => {
    if (e.keyCode === 13) {
      e.preventDefault();
      saveButtonRef.current.click();
    }
  };

  return (
    <PageLayout className="product-code-details">
      <section className="site-content bodyWrap" id="content">
        <section className="content-body">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <h4 className="PageTitle">
                  <Link className="page-parent" to={PAGE_URL.PRODUCT_CODES}>
                    PRODUCT CODES
                  </Link>{" "}
                  &ndash;{" "}
                  <span className="page-current"> PRODUCT CODE DETAILS</span>
                </h4>
              </div>
            </div>
            {loaded && (
              <div className="row">
                <div className="col-12">
                  <div className="card shadow" id="top-level-info">
                    <div className="card-body">
                      <form
                        onKeyDown={onInputKeyDown}
                        onSubmit={(e) => {
                          e.preventDefault();
                          onSave();
                        }}
                      >
                        <div
                          className="row"
                          style={{ justifyContent: "flex-end" }}
                        >
                          <div className="col">
                            <h2
                              style={{ marginBottom: "0px", marginLeft: "8px" }}
                            >
                              {editProductCode.productCode}
                            </h2>
                          </div>
                          <button
                            ref={saveButtonRef}
                            className="btn btn-success btn-sm m-1"
                            id="green"
                            type="submit"
                            hidden={!editMode}
                          >
                            Save
                          </button>
                          <button
                            className="btn btn-primary btn-sm m-1 mr-4"
                            type="button"
                            onClick={toggleEditMode}
                            hidden={editMode}
                          >
                            Edit
                          </button>
                          <button
                            className="btn btn-primary btn-sm m-1 mr-4"
                            type="button"
                            onClick={toggleEditMode}
                            hidden={!editMode}
                          >
                            Cancel
                          </button>
                        </div>
                        <div className="row code-info">
                          <div className="col-4">
                            <ul>
                              <LabelTextField
                                label={"Product Code"}
                                value={editProductCode.productCode}
                                editMode={editMode}
                                onChange={(value) =>
                                  editProductCodeField("productCode", value)
                                }
                                required
                              />
                            </ul>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </section>
      </section>
    </PageLayout>
  );
};
