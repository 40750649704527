export const isEmpty = (value) => {
    return value === null || value === undefined || value.length === 0;
};

export const isNotEmpty = (value) => {
  return !isEmpty(value);
};

export const isNullOrUndefined = (value) => {
    return value === null || value === undefined;
};

export const isTrue = (value) => {
    return value === true;
};

export const isFalse = (value) => {
    return value === false;
};

export const isNullOrTrue = (value) => {
    return value === null || value === true;
};

export const isNullOrFalse = (value) => {
    return value === null || value === false;
};

export const pluralize = (word, number) => {
    return word + (number === 1 ? "" : "s");
};

export const pluralies = (word, number) => {
    return word + (number === 1 ? "y" : "ies");
};

export const hasLength = (list, length) => {
    return list && length && list.length === length;
};

export const isNumber = (value) => {
    return typeof value === "number";
};

export const isString = (value) => {
    return typeof value === "string";
};

export const sum = (values) => {
    let s = 0;

    if (values) {
        values.forEach(value => s += value);
    }

    return s;
};

export const filter = (list, predicate) => {
    let results = [];

    if (list && Array.isArray(list)) {
        list.forEach(item => {
            if (predicate(item)) {
                results.push(item);
            }
        });
    }

    return results;
};

export const stringToFloat = (s) => {
  return isEmpty(s) ? 0 : parseFloat(s);
};

export const stringToInt = (s) => {
    return isEmpty(s) ? 0 : parseInt(s);
};

export const emptyStringIfNull = (s) => {
    return s === null || s === undefined ? "" : s;
};

export const getFieldValue = (value) => {
    return isEmpty(value) ? "---" : value;
};

export const getContentDispositionFileName = (contentDisposition) => {
    const index = contentDisposition.indexOf("filename=");
    return contentDisposition.substr(index + 9);
};

export const getOptionText = (options, value) => {
  const option = options.filter(option => option.value == value);
  return option.length !== 1 ? "N/A" : option[0].text;
};

export const addNoneOption = (listContent) => {
    let _listContent = listContent;
    _listContent.unshift({ id: "NONE", name: "None" });
    return _listContent;
};

