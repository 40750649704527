/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
/* eslint-disable no-trailing-spaces */
// React
import React from "react";

// Local
import { LabelValue } from "components/LabelValue";

export const LabelTextField = ({
  label,
  value,
  editMode,
  onChange,
  onBlur,
  className,
  ...rest
}) => {
  
  return (
    <LabelValue
      showLabelAlways={true}
      listItemStyle={{
        overflowWrap: "anywhere",
      }}
      label={label}
      value={
        editMode ? (
          <input
            className={className + " form-control"}
            type="text"
            value={value}
            onChange={(e) => onChange(e.target.value)}
            {...rest}
            ></input>
        ) : (
          value
        )
      }
    />
  );
};
