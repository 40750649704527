import decode from "jwt-decode";

export const setAccessToken = (accessToken) => {
  sessionStorage.setItem("access_token", accessToken);
};

export const getAccessTokenFromStorage = () => {
  return sessionStorage.getItem("access_token");
};

export const setRole = (role) => {
  sessionStorage.setItem("role", role);
};

export const getRole = () => {
  return sessionStorage.getItem("role");
};

export const clearTokens = () => {
  sessionStorage.clear();
};

export const isAccessTokenExpired = () => {
  const accessToken = getAccessTokenFromStorage();
  if (accessToken) {
    const token = decode(accessToken);
    const now = Date.now().valueOf() / 1000;
    return now > token.exp;
  }
  return true;
};

export const getUserInfoFromStorage = () => {
  const accessToken = getAccessTokenFromStorage();
  if (accessToken) {
    const token = decode(accessToken);
    return {
      "role": token.role
    };
  } else {
    return undefined;
  }
};
