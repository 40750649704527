// React
import React, { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";

// Local
import { MessagePanel } from "layouts/components/MessagePanel";
import {
  clearMessage,
  showErrorMessage,
  pushSuccessMessage,
} from "store/messages/messageActions";
import { useError } from "hooks/errors";
import { useOverlay } from "hooks/useOverlay";
import { PAGE_URL } from "constants/url";
import { CURRENT_YEAR } from "constants/date";
import { hasWhiteSpace, isEmpty, isNullOrUndefined } from "util/common";
import { useURLParams } from "hooks/useURLParams";
import { useURLNavigate } from "hooks/useURLNavigate";
import api from "services/apiService";
import { joinParams } from "components/joinParams";

export const ResetPasswordPage = () => {
  // hooks
  const dispatch = useDispatch();
  const [getParam] = useURLParams();
  const navigate = useURLNavigate();
  const [showError] = useError();
  const [showOverlay, hideOverlay] = useOverlay();

  // state
  const [password, setPassword] = useState();
  const [confirmPassword, setConfirmPassword] = useState();
  const resetButtonRef = useRef();
  const message = useSelector((state) => state.message);

  // url token
  const token = getParam("token");

  useEffect(() => {
    if (isEmpty(token)) {
      navigate(PAGE_URL.HOME);
    }
  }, [token, navigate]);

  const resetPassword = async () => {
    // validate
    if (isEmpty(password) || isEmpty(confirmPassword)) {
      dispatch(showErrorMessage("Please enter and confirm your password"));
    } else if (password !== confirmPassword) {
      dispatch(showErrorMessage("Passwords must match"));
    } else if (password.length < 14) {
      dispatch(showErrorMessage("Password must be at least 14 characters long"));
    } else {
      // reset password
      try {
        const params = getParams();

        if (isNullOrUndefined(message)) {
          await showOverlay("Updating Password...");
        }

        await api.updatePassword(params, { password });
        hideOverlay();
        dispatch(pushSuccessMessage("Password Successfully Updated"));
        navigate(PAGE_URL.HOME);
      } catch (error) {
        hideOverlay();
        showError(error);
      }
    }
  };

  const getParams = () => {
    return joinParams({
      token,
    });
  };

  const onInputKeyDown = (e) => {
    if (e.keyCode === 13) {
      e.preventDefault();
      resetButtonRef.current.click();
    }
  };

  const onInputChange = () => {
    dispatch(clearMessage());
  };

  return (
    <div className="bodyWrapResetPassword">
      <div className="p-0 reset" id="page">
        <a className="skip-link sr-only" href="#content"></a>
        <header className="site-header"></header>
        <MessagePanel />
        <section className="site-content" id="content">
          <section className="content-body">
            <div className="container">
              <div className="row">
                <div className="col">
                  <div className="resetWrap shadow">
                    <div className="reset-form">
                      <form
                        onKeyDown={onInputKeyDown}
                        onSubmit={(e) => {
                          e.preventDefault();
                          resetPassword();
                        }}
                      >
                        <div className="form">
                          <h4
                            style={{ marginBottom: "2em", textAlign: "center" }}
                          >
                            Reset Password
                          </h4>
                          <div className="form-group">
                            <label>Password:</label>
                            <input
                              className="form-control"
                              type="password"
                              onChange={(e) => {
                                setPassword(e.target.value);
                                onInputChange();
                              }}
                              required
                            />
                            <div className="invalid-feedback">
                              Please enter your username
                            </div>
                          </div>
                          <div className="form-group">
                            <label>Confirm Password:</label>
                            <input
                              className="form-control"
                              type="password"
                              onChange={(e) => {
                                setConfirmPassword(e.target.value);
                                onInputChange();
                              }}
                              required
                            />
                            <div className="invalid-feedback">
                              Please enter your password
                            </div>
                          </div>
                          <button
                            ref={resetButtonRef}
                            className="btn shadow btn-primary btn-block mt-4"
                            type="submit"
                          >
                            Reset Password
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </section>
        <footer className="site-footer">
          <div className="site-info container text-center">
            <div className="row">
              <div className="col">
                <p>
                  &copy; {CURRENT_YEAR}{" "}
                  <a href="https://theuniquegroup.com/" target="_blank">
                    The Unique Group
                  </a>{" "}
                  {"\u00a0\u00a0"} | {"\u00a0\u00a0"}
                  <Link
                    to={PAGE_URL.TERMS_AND_CONDITIONS}
                    target="_self"
                    title=""
                  >
                    Terms &amp; Conditions{" "}
                  </Link>
                </p>
              </div>
            </div>
          </div>
        </footer>
      </div>
    </div>
  );
};
