import { useMemo, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  isAccessTokenExpired,
  getAccessTokenFromStorage,
  getUserInfoFromStorage,
} from "../services/authService";
import {
  logoutUser,
  logoutSuccess,
  logoutError,
  isAuthenticated,
  notAuthenticated,
} from "../store/auth/authActions";
import { api } from "../services/apiService";

export const useCurrentUser = () => {
  const user = useSelector(state => state.auth.user);
  return user;
};

export const useAuthenticatedUser = () => {
  const dispatch = useDispatch();
  const authState = useSelector(state => state.auth);
  const user = useSelector(state => state.auth.user);
  const [authenticated, authenticatedUser] = useMemo (() => {
    if (!authState.isAuthenticated) {
      if (getAccessTokenFromStorage()) {
        if (!isAccessTokenExpired()) {
          const userInfo = getUserInfoFromStorage();
          dispatch(isAuthenticated());
          return [true, userInfo];
        } else {
          alert("Authentication expired. Please log in.");
          dispatch(logoutUser());
        }
      } else {
        dispatch(notAuthenticated());
      }
      return [false, null];
    } else {
      return [true, user];
    }
  }, [authState.isAuthenticated, dispatch, user]);
  return [authenticated, authenticatedUser];
};

var listenerRef = null;

export const unregisterSignedInUser = () => {
  if (typeof (Storage) !== "undefined") {
    if (localStorage !== "") {
      localStorage["username"] = "";
    }
    if (listenerRef)
      window.removeEventListener("storage", listenerRef);
  }
};

export const useLogout = () => {
  const dispatch = useDispatch();

  const logout = useCallback(async () => {
    try {
      if (!isAccessTokenExpired()) {
        await api.logout();
        dispatch(logoutUser());
      }
      dispatch(logoutSuccess());
    } catch (error) {
      dispatch(logoutError("Error with logging out"));
    }
  }, [dispatch]);

  const storageHandler = useCallback((e) => {
    if (e.key === "username") {
      if (e.oldValue !== e.newValue) {
        if (e.newValue !== "" && e.oldValue !== "") {
          alert("Another user signed in from another tab, you will be logged out automatically.");
        }
        window.removeEventListener("storage", storageHandler);
        logout();
      }
    }
  }, [logout]);

  const registerSignedInUser = useCallback((username) => {
    if (typeof (Storage) !== "undefined") {
      window.addEventListener("storage", storageHandler);
      listenerRef = storageHandler;
      localStorage["username"] = username;
    }
  }, [storageHandler]);

  return [logout, registerSignedInUser, unregisterSignedInUser];
};