/* eslint-disable no-trailing-spaces */
/* eslint-disable no-unused-vars */
/* eslint-disable no-multiple-empty-lines */

// React
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

// Local
import { api } from "../../services/apiService";
import { PageLayout } from "layouts/PageLayout";
import { useURLNavigate } from "hooks/useURLNavigate";
import { useURLParams } from "hooks/useURLParams";
import { useOverlay } from "hooks/useOverlay";
import { useError } from "hooks/errors";
import { PAGE_URL } from "constants/url";
import { isEmpty, isNullOrUndefined, addNoneOption } from "util/common";
import { CustomerRelationships } from "./components/CustomerRelationships";
import { TopLevelCustomerInfo } from "./components/TopLevelCustomerInfo";
import {
  showSuccessMessage,
  pushSuccessMessage,
} from "store/messages/messageActions";
import { cloneDeep } from "lodash";
import { PARSER_TYPE } from "constants/parserType";

export const CustomerDetailPage = () => {
  // hooks
  const navigate = useURLNavigate();
  const [showError, pushError] = useError();
  const [getParam] = useURLParams();
  const [showOverlay, hideOverlay] = useOverlay();
  const dispatch = useDispatch();
  const message = useSelector((state) => state.message.message);

  // state
  const [editMode, setEditMode] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [customer, setCustomer] = useState();
  const [editCustomer, setEditCustomer] = useState();
  const [warehouse, setWarehouse] = useState();
  const [cautionMaintenanceList, setCautionMaintenanceList] = useState();
  const [fabricCompositionList, setFabricCompositionList] = useState();
  const [careInstructionList, setCareInstructionList] = useState();
  const [careCodeList, setCareCodeList] = useState();
  const [countryOfOriginList, setCountryOfOriginList] = useState();
  const [specialInstructionsList, setSpecialInstructionsList] = useState();

  // url variables
  const customerId = getParam("id");
  const createMode = getParam("createMode");

  useEffect(() => {
    loadData();
  }, []);

  const loadData = async () => {
    // handle url hack
    if (createMode && !isEmpty(customerId)) {
      pushError("Invalid URL params");
      navigate(PAGE_URL.CUSTOMER);
    }

    if (!isEmpty(customerId)) {
      // load customer
      try {
        const customerResponse = await api.getCustomer(customerId);
        setCustomer(cloneDeep(customerResponse.data));
        setEditCustomer(cloneDeep(customerResponse.data));
      } catch (error) {
        pushError(error);
        navigate(PAGE_URL.HOME);
      }
    }

    if (createMode) {
      // setup new customer
      setEditMode(true);
      setEditCustomer({
        companyName: "",
        parserType: PARSER_TYPE.NONE,
        warehouse: null,
        cautionMaintenanceList: null,
        fabricCompositionList: null,
        careInstructionList: null,
        careCodeList: null,
        countryOfOriginList: null,
        specialInstructionsList: null,
        specialInstructionsLimit: 0,
        copyrightShow: false,
        fabricCompositionShow: true,
      });
    }

    if (createMode || !isEmpty(customerId)) {
      try {
        const warehouseResponse = await api.getAllWarehouses();
        const cautionMaintResponse = await api.getAllCautionMaintenanceLists();
        const fabricCompositionResponse = await api.getAllFabricCompositionLists();
        const careInstructionResponse = await api.getAllCareInstructionLists();
        const careCodeResponse = await api.getAllCareCodeLists();
        const countryOfOriginResponse = await api.getAllCountryOfOriginLists();
        const specialInstructionsResponse = await api.getAllSpecialInstructionLists();

        // Overwrite the customer with the default value to be first item in the list
        if (createMode) {
          setEditCustomer({
            companyName: "",
            parserType: PARSER_TYPE.NONE,
            warehouse: warehouseResponse.data.listContent[0],
            cautionMaintenanceList: cautionMaintResponse.data.listContent[0],
            fabricCompositionList:
              fabricCompositionResponse.data.listContent[0],
            careInstructionList: careInstructionResponse.data.listContent[0],
            careCodeList: careCodeResponse.data.listContent[0],
            countryOfOriginList: null,
            specialInstructionsList: null,
            specialInstructionsLimit: 0,
            copyrightShow: false,
            fabricCompositionShow: true,
          });
        }

        setWarehouse(warehouseResponse.data.listContent);
        setCautionMaintenanceList(cautionMaintResponse.data.listContent);
        setFabricCompositionList(fabricCompositionResponse.data.listContent);
        setCareInstructionList(careInstructionResponse.data.listContent);
        setCareCodeList(careCodeResponse.data.listContent);
        setCountryOfOriginList(
          addNoneOption(countryOfOriginResponse.data.listContent)
        );
        setSpecialInstructionsList(
          addNoneOption(specialInstructionsResponse.data.listContent)
        );
        setLoaded(true);
      } catch (error) {
        pushError(error);
        navigate(PAGE_URL.HOME);
      }
    }
  };

  const toggleEditMode = () => {
    if (createMode) {
      navigate(PAGE_URL.CUSTOMER);
    } else if (editMode) {
      setEditCustomer(cloneDeep(customer)); // revert changes
      setEditMode(false);
    } else {
      setEditMode(true);
    }
  };

  const onSave = async () => {
    if (isNullOrUndefined(message)) {
      await showOverlay("Saving...");
    }

    if (createMode) {
      try {
        await api.createCustomer(editCustomer);
        dispatch(pushSuccessMessage("Customer Successfully Created"));
        navigate(PAGE_URL.CUSTOMER);
      } catch (error) {
        showError(error);
      }
    } else {
      try {
        await api.updateCustomer(customer.id, editCustomer);
        setCustomer(cloneDeep(editCustomer));
        dispatch(showSuccessMessage("Customer Successfully Updated"));
      } catch (error) {
        showError(error);
        setEditCustomer(customer);
      } finally {
        setEditMode(false);
      }
    }

    hideOverlay();
  };

  const editCustomerField = (field, value) => {
    let _editCustomer = { ...editCustomer };
    _editCustomer[field] = value;
    setEditCustomer(_editCustomer);
  };

  const updateCustomerEntity = (field, entity) => {
    let _editCustomer = { ...editCustomer };
    _editCustomer[field] = entity;
    setEditCustomer(_editCustomer);
  };

  return (
    <PageLayout className="customer-details">
      <section className="site-content bodyWrap" id="content">
        <section className="content-body">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <h4 className="PageTitle">
                  <Link className="page-parent" to={PAGE_URL.CUSTOMER}>
                    CUSTOMER
                  </Link>{" "}
                  &ndash; <span className="page-current">CUSTOMER DETAILS</span>
                </h4>
              </div>
            </div>
            {loaded && (
              <div className="row">
                <div className="col-12">
                  <TopLevelCustomerInfo
                    warehouse={warehouse}
                    editCustomer={editCustomer}
                    editCustomerField={editCustomerField}
                    editMode={editMode}
                    createMode={createMode}
                    toggleEditMode={toggleEditMode}
                    onSave={onSave}
                  />
                </div>
              </div>
            )}
            {loaded && (
              <div className="row">
                <div className="col-12">
                  <div className="card shadow" id="top-level-info">
                    <div className="card-body">
                      <CustomerRelationships
                        editCustomer={editCustomer}
                        updateCustomerEntity={updateCustomerEntity}
                        editCustomerField={editCustomerField}
                        editMode={editMode}
                        createMode={createMode}
                        cautionMaintenanceList={cautionMaintenanceList}
                        fabricCompositionList={fabricCompositionList}
                        careInstructionList={careInstructionList}
                        careCodeList={careCodeList}
                        countryOfOriginList={countryOfOriginList}
                        specialInstructionsList={specialInstructionsList}
                      />
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </section>
      </section>
    </PageLayout>
  );
};
