import React, {useEffect, useState} from "react";
import { QuantityField } from "../components/QuantityField";
import { AttributeField } from "../components/AttributeField";
import { CareLabelDetails } from "../components/CareLabelDetails";
import { Thumbnail } from "components/Thumbnail";
import { UnitPriceCostTable } from "components/UnitPriceCostTable";
import { isEmpty } from "util/common";
import { MIN_ORDER_VALUE_DESCRIPTION } from "constants/messages";
import { formatCurrency } from "util/formatNumber";

export const StandardProductDetailsVendor = ({
  product,
  onQuantityChange,
  onAttributeChange,
  onCareLabelChange,
  editMode,
  showValidation,
  setShowValidation,
  onAttributeItemChange
}) => {
  const [editQuantity, setEditQuantity] = useState(null);
  const [cartQuantity, setCartQuantity] = useState(0);
  const [attributes, setAttributes] = useState([]);
  const [items, setItems] = useState([]);

  useEffect(() => {
    if(product) {
      setAttributes(product.attributes);
      setItems(product.items);
    }
  }, [product]);

  return (
    <>
      <div className="product-item standard card shadow">
        <div className="general-details">
          <div className="product-img">
            <Thumbnail
              className="img-fluid"
              imagePath={product.productImagePath}
              imageType={product.productImageType}
            />
          </div>
          <div className="product-info">
            <div className="fixed-info">
              <ul>
                <li>
                  <div className="label">Product Code:</div>
                  <div className="value">{product.productSubcode}</div>
                </li>
                <li className="description">
                  <div className="label">Description:</div>
                  <div className="value">{product.description}</div>
                </li>
                <li>
                  <div className="label">Pack Size:</div>
                  <div className="value">{product.packSize}</div>
                </li>
                {!isEmpty(product.minValue) && (
                  <li>
                    <div
                      className="label"
                      data-toggle="tooltip"
                      data-placement="right"
                      title={MIN_ORDER_VALUE_DESCRIPTION}
                    >
                      Minimum Order Value *
                    </div>
                    <div className="value">
                      {product.minValue.currency +
                        " " +
                        formatCurrency(product.minValue.price)}
                    </div>
                  </li>
                )}

              </ul>
              {product.prices.length === 1 ? (
                <ul>
                  <li>
                    <div className="label">Unit Price:</div>
                    <div className="value">
                      <span className="currency">
                        {product.prices[0].currency}{" "}
                      </span>
                      <span className="price">{product.prices[0].price}</span>
                    </div>
                  </li>
                </ul>
              ) : (
                <UnitPriceCostTable
                  isPrice={true}
                  pricesOrCosts={product.prices}
                  editQuantity={editQuantity}
                  cartQuantity={cartQuantity}
                />
              )}
            </div>
            <div className="row product-attributes">
              {attributes &&
                attributes.map((a, i) => (
                  <AttributeField
                    key={i}
                    name={a.name}
                    type={a.type}
                    options={a.options}
                    initialValue={a && a.value ? a.value : ""}
                    onAttributeChange={(value) =>
                      onAttributeChange(product.productSubcode, a.name, value)
                    }
                  />
                ))}
            </div>
            <div className="product-magic-attributes">
              {product.hasSizes && (
                <div className="row product-magic-attributes">
                  <div className="col-2 label">Size</div>
                  <div className="col-2 label">Quantity</div>
                  {items && items[0] &&
                    items[0].attributes &&
                    items[0].attributes.map((a, i) => (
                      <div key={i} className="col-3 label product-attribute-group">
                        {a.name}
                      </div>
                    ))}
                </div>
              )}
              {product.hasSizes && items &&
                items.map((item, i) => (
                  <div key={i} className="row product-magic-attribute">
                    <div className="col-2">
                      <div>{item.productSize}</div>
                    </div>
                    <QuantityField
                      className="col-2"
                      defaultQuantity={item.defaultOrderQuantity}
                      minQuantity={product.minOrderQuantity}
                      packSize={product.packSize}
                      onChange={(quantity) => {
                        onQuantityChange(
                          product.productSubcode,
                          item.productItemId,
                          quantity
                        );
                        setEditQuantity(quantity);
                      }}
                      onFocus={(quantity) => {
                        setEditQuantity(quantity);
                        setCartQuantity(item.cartQuantity);
                      }}
                      onBlur={() => {
                        setEditQuantity(null);
                        setCartQuantity(0);
                      }}
                      required
                    />
                    {item.attributes &&
                      item.attributes.map((a, i) => (
                        <AttributeField
                          key={i}
                          name={a.name}
                          type={a.type}
                          options={a.options}
                          initialValue={a && a.value ? a.value : ""}
                          showLabel={false}
                          required={item.quantity > 0}
                          onAttributeChange={(value) =>
                            onAttributeItemChange(
                              product.productSubcode,
                              item.productItemId,
                              a.name,
                              value
                            )
                          }
                        />
                      ))}
                  </div>
                ))}
            </div>
            {!product.hasSizes && (
              <div className="product-quantity-row">
                <div className="row">
                  <div className="col-10"></div>
                  <div className="col-2 product-quantity-row">
                    <div className="label product-quantity">Quantity</div>
                    <QuantityField
                      defaultQuantity={product.defaultOrderQuantity}
                      minQuantity={product.minOrderQuantity}
                      packSize={product.packSize}
                      onChange={(quantity) => {
                        onQuantityChange(
                          product.productSubcode,
                          product.productItemId,
                          quantity
                        );
                        setEditQuantity(quantity);
                      }}
                      onFocus={(quantity) => {
                        setEditQuantity(quantity);
                        setCartQuantity(product.cartQuantity);
                      }}
                      onBlur={() => {
                        setEditQuantity(null);
                        setCartQuantity(0);
                      }}
                      required
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      {product.careLabel && (
        <CareLabelDetails
          careLabel={product.careLabel}
          onCareLabelChange={onCareLabelChange}
          editMode={editMode}
          showValidation={showValidation}
          setShowValidation={setShowValidation}
        />
      )}
    </>
  );
};
