import React from "react";

export const FactoryRelationships = ({
  editFactory,
  updateFactoryEntity,
  editMode,
  customers,
  selectDeselectAll,
}) => {
  const getSelectLabel = (items, itemsSelected) =>
    items.length == itemsSelected.length ? "Deselect All" : "Select All";

  return (
    <div className="row entity-list" style={{ justifyContent: "center" }}>
      <div className="col-6">
        <label>Customers</label>
        <button
          className="btn btn-secondary select-all-btn"
          disabled={!editMode}
          onClick={() => selectDeselectAll("customers", customers)}
        >
          {getSelectLabel(customers, editFactory.customers)}
        </button>
        <div className="scroller">
          {editFactory &&
            editFactory.customers &&
            customers &&
            customers.map((c, index) => (
              <div className="item" key={index}>
                <input
                  key={index}
                  disabled={!editMode}
                  type="checkbox"
                  checked={editFactory.customers.map((c) => c.id).includes(c.id)}
                  onClick={() => updateFactoryEntity("customers", c)}
                  className="checkbox"
                />
                {c.name}
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};
