import axios from "axios";
import uuid from "uuid/v4";
import {
  BASE_URL
} from "../constants/url";

import {
  getAccessTokenFromStorage
} from "./authService";

export const auth = axios.create({
  baseURL: BASE_URL
});

auth.interceptors.request.use(async (config) => {
  config.headers["X-Request-Id"] = uuid();
  return config;
});

export const main = axios.create({
  baseURL: BASE_URL
});

main.interceptors.request.use(async (config) => {
  config.headers["X-Request-Id"] = uuid();
  config.headers["Authorization"] = "Bearer " + getAccessTokenFromStorage();
  return config;
});

const careLabelApis = {
  // lists
  getFabricCompositionListItems: (id) => main.get("/refs/fabric-composition-list/" + id),
  getCareInstructionListItems: (id) => main.get("/refs/care-instruction-list/" + id),
  getCareCodeListItems: (id) => main.get("/refs/care-code-list/" + id),
  getCautionMaintenanceListItems: (id) => main.get("/refs/caution-maint-list/" + id),
  getCountryOfOriginListItems: (id) => main.get("/refs/country-of-origin-list/" + id),
  getSpecialInstructionListItems: (id) => main.get("/refs/special-instruction-list/" + id),
  addRemoveComponentFromFabricList: (listId, data) => main.put("refs/fabric-composition-list/component/" + listId, data),
  addRemoveMaterialFromFabricList: (listId, data) => main.put("refs/fabric-composition-list/material/" + listId, data),
  addRemoveItemFromCareInstructionList: (listId, data) => main.put("refs/care-instruction-list/" + listId, data),
  addRemoveItemFromCareCodeList: (listId, data) => main.put("refs/care-code-list/" + listId, data),
  addRemoveItemFromCautionList: (listId, data) => main.put("refs/caution-maint-list/" + listId, data),
  addRemoveItemFromCountryList: (listId, data) => main.put("refs/country-of-origin-list/" + listId, data),
  addRemoveItemFromSpecialList: (listId, data) => main.put("refs/special-instruction-list/" + listId, data),
  addCautionMaintenanceList: (data) => main.post("refs/caution-maint-list", data),
  addCountryOfOriginList: (data) => main.post("refs/country-of-origin-list", data),
  addSpecialInstructionList: (data) => main.post("refs/special-instruction-list", data),
  addFabricCompositionList: (data) => main.post("refs/fabric-composition-list", data),
  addCareInstructionList: (data) => main.post("refs/care-instruction-list", data),
  addCareCodeList: (data) => main.post("refs/care-code-list", data),
  updateCautionMaintenanceList: (data) => main.put("refs/caution-maint-list", data),
  updateCountryOfOriginList: (data) => main.put("refs/country-of-origin-list", data),
  updateSpecialInstructionList: (data) => main.put("refs/special-instruction-list", data),
  updateFabricCompositionList: (data) => main.put("refs/fabric-composition-list", data),
  updateCareInstructionList: (data) => main.put("refs/care-instruction-list", data),
  updateCareCodeList: (data) => main.put("refs/care-code-list", data),
  removeCautionMaintenanceList: (data) => main.delete("refs/caution-maint-list", { data }),
  removeCountryOfOriginList: (data) => main.delete("refs/country-of-origin-list", { data }),
  removeSpecialInstructionList: (data) => main.delete("refs/special-instruction-list", { data }),
  removeFabricCompositionList: (data) => main.delete("refs/fabric-composition-list", { data }),
  removeCareInstructionList: (data) => main.delete("refs/care-instruction-list", { data }),
  removeCareCodeList: (data) => main.delete("refs/care-code-list", { data }),
  // options
  getCountryOfOriginOptions: () => main.get("/refs/all-country-of-origin-options"),
  getComponentTypes: () => main.get("/refs/all-component-type-options"),
  getMaterials: () => main.get("/refs/all-material-options"),
  getSpecialInstructions: () => main.get("/refs/all-special-instruction-options"),
  getCautionMaintenanceOptions: () => main.get("/refs/all-caution-maintenance-options"),
  getCareCodes: () => main.get("/refs/all-care-code-options"),
  getCareInstructions: () => main.get("/refs/all-care-instruction-options"),
  addCountryOfOriginOption: (data) => main.post("/refs/country-of-origin-option", data),
  addComponentTypeOption: (data) => main.post("/refs/component-type-option", data),
  addMaterialOption: (data) => main.post("/refs/material-option", data),
  addSpecialInstructionOption: (data) => main.post("/refs/special-instruction-option", data),
  addCautionMaintenanceOption: (data) => main.post("/refs/caution-maintenance-option", data),
  addCareCodeOption: (data) => main.post("/refs/care-code-option", data),
  addCareInstructionOption: (data) => main.post("/refs/care-instruction-option", data),
  updateCountryOfOriginOption: (data) => main.put("/refs/country-of-origin-option", data),
  updateComponentTypeOption: (data) => main.put("/refs/component-type-option", data),
  updateMaterialOption: (data) => main.put("/refs/material-option", data),
  updateSpecialInstructionOption: (data) => main.put("/refs/special-instruction-option", data),
  updateCautionMaintenanceOption: (data) => main.put("/refs/caution-maintenance-option", data),
  updateCareCode: (data) => main.put("/refs/care-code-option", data),
  updateCareInstructionOption: (data) => main.put("/refs/care-instruction-option", data),
};

export const api = {
  getFromUrl: (url) => main.get(url, { baseURL: "" }),
  login: (data) => auth.post("/auth/login", data),
  logout: (data) => main.get("/auth/logout", data),
  requestPasswordReset: (id) => main.post("/auth/users/" + id + "/reset-password"),
  updatePassword: (params, data) => auth.post("/auth/update-password?" + params, data),
  getAttributeSchemas: () => main.get("/attributes/all-schemas"),
  getCurrencies: () => main.get("/refs/currency-types"),
  getProductGroups: (data) => main.get("/refs/product-group", data),
  getProductList: (params) => main.get("/products?" + params),
  getProductVariableDataList: (params) => main.get("/products/variable-data?" + params),
  getProductCode: (id) => main.get("products/product-code/" + id),
  getProductCodeList: (params) => main.get("products/product-codes?" + params),
  updateProductCode: (data) => main.put("/products/product-code/", data),
  addProductCode: (data) => main.post("/products/product-code/", data),
  getProductSubcodeList: (params) => main.get("products/product-subcodes?" + params),
  getProductSubcode: (id) => main.get("products/product-subcodes/" + id),
  updateProductSubcode: (data) => main.put("products/product-subcodes", data),
  createProductSubcode: (data) => main.post("products/product-subcodes", data),
  uploadProductImage: (id, data) => main.post("products/product-subcodes/" + id, data),
  ...careLabelApis,
  getOrders: (params) => main.get("/orders?" + params),
  getUsers: (params) => main.get("/users?" + params),
  getFilteredCustomers: (params) => main.get("/customers-filtered?" + params),
  getUser: (id) => main.get("/users/" + id),
  getCustomer: (id) => main.get("/customers/" + id),
  hasRootsCustomer: () => main.get("/has-roots-customer"),
  createCustomer: (data) => main.post("/customers/", data),
  updateCustomer: (id, data) => main.put("/customers/" + id, data),
  updateUser: (id, data) => main.put("/users/" + id, data),
  createUser: (data) => main.post("/users/", data),
  getShippingAddresses: () => main.get("/entity/shipping-addresses"),
  getVendors: (params) => main.get("/entity/vendors?" + params),
  getVendor: (id) => main.get("/entity/vendors/" + id),
  createVendor: (data) => main.post("/entity/vendors", data),
  updateVendor: (data) => main.put("/entity/vendors", data),
  getWarehouses: (params) => main.get("/entity/warehouses?" + params),
  getWarehouse: (wid) => main.get("entity/warehouses/" + wid),
  createWarehouse: (data) => main.post("/entity/warehouses", data),
  updateWarehouse: (id, data) => main.put("/entity/warehouses/" + id, data),
  getProductionRegionsFilter: (params) => main.get("/entity/production-regions-list?" + params),
  getProductionRegion: (id) => main.get("entity/production-regions-list/" + id),
  createProductionRegion: (data) => main.post("/entity/production-regions-list", data),
  updateProductionRegion: (id, data) => main.put("/entity/production-regions-list/" + id, data),
  getProductItems: (params) => main.get("/products/product-items?" + params),
  convertProductType: (params) => main.put("/products/convert-product-type?" + params),
  getProductItem: (id) => main.get("/products/product-items/" + id),
  createProductItem: (data) => main.post("/products/product-items", data),
  updateProductItem: (id, data) => main.put("/products/product-items", data),
  getFilteredAttributeSchemas: (params) => main.get("/attributes/all-filtered-schemas?" + params),
  getAttributeSchemaDetail: (id) => main.get("/attributes/schemas/" + id),
  createAttributeSchema: (data) => main.post("/attributes/schemas/", data),
  updateAttributeSchema: (id, data) => main.put("/attributes/schemas/", data),
  getProductSubcodeNameId: (id) => main.get("/products/get-product-subcode-name-id/" + id),
  getAllVendors: (data) => main.get("/entity/all-vendors", data),
  getAllCustomers: (data) => main.get("/entity/all-customers", data),
  getAllFactories: (data) => main.get("/entity/all-factories", data),
  getAllRegions: (data) => main.get("/entity/all-regions", data),
  getAllWarehouses: (data) => main.get("/entity/all-warehouses", data),
  getAllProductGroup:(data) => main.get("products/all-product-group", data),
  getAllProductSubgroup:(data) => main.get("products/all-product-subgroup", data),
  getAllAttributeSchema: (data) => main.get("products/all-attribute-schema", data),
  getAllCautionMaintenanceLists: (data) => main.get("/refs/all-caution-maint-list", data),
  getAllFabricCompositionLists: (data) => main.get("/refs/all-fabric-composition-list", data),
  getAllCareCodeLists: (data) => main.get("/refs/all-care-code-list", data),
  getAllCareInstructionLists: (data) => main.get("/refs/all-care-instruction-list", data),
  getAllCountryOfOriginLists: (data) => main.get("/refs/all-country-of-origin-list", data),
  getAllSpecialInstructionLists: (data) => main.get("/refs/all-special-instruction-list", data),
  getFactories: (params) => main.get("/entity/production-factory?" + params),
  getFactory: (id) => main.get("/entity/production-factory/" + id),
  createFactory: (data) => main.post("/entity/production-factory/", data),
  updateFactory: (data) => main.put("/entity/production-factory/", data),
  getCustomers: (data) => main.get("/customers", data),
  getRestockingCustomers: (data) => main.get("/restocking-customers", data),
  getOrder: (id, data) => main.get("/orders/" + id, data),
  getCart: (data) => main.get("/orders/cart", data),
  updateCartOrder: (data) => main.put("/orders/cart", data),
  removeFromCartOrder: (id, data) => main.delete("/orders/cart/" + id, data),
  getCartStatus: (data) => main.get("/orders/cart-status", data),
  getProductionRegions: (data) => main.get("/entity/production-regions", data),
  getProductSearchProductionRegions: (data) => main.get("/entity/product-search-production-regions", data),
  getCompanies: (data) => main.get("/vendors", data),
  userHasCompany: (data) => main.get("/has-vendor", data),
  getProductDetails: (ids, data) => main.get("/products/details?ids=" + ids, data),
  getVariableDataByStyleAndPoNumber: (productItemId, params) => main.get("/products/variable-data/dynamic/" + productItemId + "?" + params),
  getEditProductDetails: (orderItemId, data) => main.get("/products/edit-details/" + orderItemId, data),
  addToOrder: (data) => main.post("/orders", data),
  updateOrder: (data) => main.put("/orders", data),
  placeOrder: (data) => main.put("/orders/place", data),
  udpateEstimatedCompletionDate: (data) => main.post("/orders/completion-date", data),
  updateInventory: (data) => main.put("/products/inventory", data),
  uploadArtwork: (orderId, orderItemId, artwork) => main.post("/orders/" + orderId + "/artwork/" + orderItemId, artwork),
  getArtwork: (orderId, orderItemId) => main.get("/orders/" + orderId + "/artwork/" + orderItemId, {responseType: "arraybuffer" }),
  markArtworkPrepared: (orderId) => main.put("/orders/" + orderId + "/artwork/prepare"),
  reviewArtwork: (orderId, orderItemId, data) => main.put("/orders/" + orderId + "/artwork/" + orderItemId, data),
  submitArtworkReviews: (orderId) => main.put("/orders/" + orderId + "/artwork/review"),
  updateInvoiceNumber: (data) => main.put("/orders/invoice-number", data),
  approveFinance: (orderId) => main.post("/orders/" + orderId + "/approve-finance"),
  uploadVariableData: (data) => main.post("/configuration/upload-variable-data", data),
  getVariableDataCustomers: () => main.get("/configuration/variable-data-customers"),
  completeProduction: (orderId) => main.post("/orders/" + orderId + "/complete-production"),
  shipAndComplete: (orderId) => main.post("/orders/" + orderId + "/ship-and-complete"),
  uploadRelatedDocument: (orderId, relatedDocument) => main.post("/orders/" + orderId + "/related-document", relatedDocument),
  downloadRelatedDocument: (orderId, documentId) => main.get("/orders/" + orderId + "/related-document/" + documentId, {responseType: "arraybuffer" }),
  uploadShippingDocument: (orderId, shippingDocument) => main.post("/orders/" + orderId + "/shipping-document", shippingDocument),
  downloadShippingDocument: (orderId, documentId) => main.get("/orders/" + orderId + "/shipping-document/" + documentId, {responseType: "arraybuffer" }),
  downloadVendorPo: (orderId) => main.get("/orders/" + orderId + "/vendor-po/", {responseType: "arraybuffer" }),
  downloadProductionPo: (orderId, poId) => main.get("/orders/" + orderId + "/production-po/" + poId, {responseType: "arraybuffer" }),
  downloadVendorPackingSlip: (orderId) => main.get("/orders/" + orderId + "/vendor-packing-slip/", {responseType: "arraybuffer" }),
  downloadProductionPackingSlip: (orderId, packingSlipId) => main.get("/orders/" + orderId + "/production-packing-slip/" + packingSlipId, {responseType: "arraybuffer" }),
  downloadOrderData: (orderId) => main.get("/orders/" + orderId + "/download-data"),
  downloadOrderCsv: (orderId) => main.get("/orders/" + orderId + "/download-csv"),
  downloadOrdersCsv: (params) => main.get("/orders/download-all-csv?" + params),
  downloadOrdersFullCsv: (params) => main.get("/orders/download-all-full-csv?" + params),
  downloadInventoryCsv: (params) => main.get("/orders/download-inventory-csv?" + params),
  downloadRootsInventoryCsv: (params) => main.get("/orders/download-roots-inventory-csv?" + params),
  downloadRootsFullInventoryCsv: (params) => main.get("/orders/download-roots-full-inventory-csv?" + params),
  restock: (customerId) => main.post("/orders/restock/" + customerId),
  getProductImage: (imagePath) => main.get("/common/product-image/" + imagePath, {responseType: "arraybuffer" }),
  getMaxArtworkUploadSize: () => main.get("/orders/max-artwork-upload-size"),
  getCustomerDefaultEqualTo: (params) => main.get("/customer-default-is-equal-to?" + params),
  cancelOrder: (orderId) => main.put("/orders/cancel-order/" + orderId)
};

export default api;
