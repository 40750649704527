import React, { useState } from "react";
import { useSelector } from "react-redux";
import { PageLayout } from "../../layouts/PageLayout";
import api from "services/apiService";
import { WarehouseListSearch } from "./WarehouseListSearch";
import { WarehouseListRow } from "./WarehouseListRow";
import { joinParams } from "components/joinParams";
import { pluralize, isNullOrUndefined, isEmpty } from "util/common";
import { useOverlay } from "hooks/useOverlay";
import { useError } from "hooks/errors";
import { useURLNavigate } from "hooks/useURLNavigate";

export const WarehouseListPage = () => {
  const [showOverlay, hideOverlay] = useOverlay();
  const message = useSelector(state => state.message.message);
  const [warehouses, setWarehouses] = useState([]);
  const [showError, ] = useError();
  const navigate = useURLNavigate();

  const searchWarehouses = async (searchValues) => {
    try {
      const params = getParams(searchValues);

      if (isNullOrUndefined(message)) {
        await showOverlay("Searching...");
      }

      const response = await api.getWarehouses(params);
      hideOverlay();
      setWarehouses(response.data.warehouses);
    } catch (error) {
      hideOverlay();
      showError(error);
    }
  };

  const getParams = (searchValues) => {
    return joinParams({
      warehouseName: searchValues.warehouseName,
      address: searchValues.address,
      preferredShippingMethod: searchValues.preferredShippingMethod
    });
  };

  return (
    <PageLayout className="warehouse-list">
      <section className="site-content bodyWrap" id="content">
        <section className="content-body">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="page-title-row">
                  <h4 className="PageTitle">
                    <span className="page-parent">WAREHOUSES</span>
                    
                  </h4>
                  <button
                    className="btn btn-primary add-entity-btn"
                    onClick={() =>
                      navigate("/warehouse-details", "createMode=true")
                    }
                  >
                    Add 
                  </button> 
                </div>
                <WarehouseListSearch searchWarehouses={searchWarehouses} />
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <section className="pt-5">
                  <table className="table table-striped" id="WarehouseList">
                    <thead>
                      <tr>
                        <th scope="col" width="20%">Warehouse Name</th>
                        <th scope="col" width="60%">Address</th>
                        <th scope="col" width="20%">Preferred Shipping Method</th>
                      </tr>
                    </thead>
                    <tbody>
                      {warehouses &&
                        warehouses.map((warehouse, index) => (
                          <WarehouseListRow key={index} warehouse={warehouse} />
                        ))}
                    </tbody>
                  </table>
                </section>
              </div>
            </div>
            <div className="warehouseNumber-pagination">
              <span>
                {"Showing " +
                  warehouses.length +
                  pluralize(" Warehouse", warehouses.length)}
              </span>
            </div>
          </div>
        </section>
      </section>
    </PageLayout>
  );
};
