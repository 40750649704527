/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */

// React
import React, { useRef } from "react";

// Local
import { LabelTextField } from "components/LabelTextField";
import { LabelSelectField } from "components/LabelSelectField";
import { isEmpty } from "util/common";
import { optionFromNameAndId, getNameAndIdFromList } from "constants/options";
import {getRole} from "../../services/authService";
import {ROLES} from "../../constants/roles";
import { VENDOR_NUMBER_DESCRIPTION } from "constants/messages";

export const TopLevelVendorInfo = ({
  editVendor,
  editVendorField,
  editMode,
  toggleEditMode,
  onSave,
  currencies,
}) => {
  const saveButtonRef = useRef();

  const onInputKeyDown = (e) => {
    if (e.keyCode === 13) {
      e.preventDefault();
      saveButtonRef.current.click();
    }
  };

  return (
    <form
      onKeyDown={onInputKeyDown}
      onSubmit={(e) => {
        e.preventDefault();
        onSave();
      }}
    >
      <div className="card shadow" id="top-level-info">
        <div className="card-body">
          <div className="row" style={{ justifyContent: "flex-end" }}>
            <div className="col">
              <h2 style={{ marginBottom: "0px", marginLeft: "8px" }}>
                {editVendor.companyName}
              </h2>
            </div>
            <button
              className="btn btn-primary btn-sm m-1 mr-4"
              type="button"
              onClick={toggleEditMode}
              hidden={editMode}
            >
              Edit
            </button>
            <button
              ref={saveButtonRef}
              className="btn btn-success btn-sm m-1"
              id="green"
              type="submit"
              hidden={!editMode}
            >
              Save
            </button>
            <button
              className="btn btn-primary btn-sm m-1 mr-4"
              type="button"
              onClick={toggleEditMode}
              hidden={!editMode}
            >
              Cancel
            </button>
          </div>
          {getRole() !== ROLES.VENDOR ? <div className="row vendor-info">
            <div className="col-4">
              <ul>
                <LabelTextField
                  label={"Company Name"}
                  value={editVendor.companyName}
                  editMode={editMode}
                  onChange={(value) => editVendorField("companyName", value)}
                  required
                />
                <LabelSelectField
                  label={"Currency Type"}
                  value={
                    editMode
                      ? isEmpty(editVendor.currencyType)
                        ? 1
                        : editVendor.currencyType.id
                      : isEmpty(editVendor.currencyType)
                      ? ""
                      : editVendor.currencyType.name
                  }
                  options={currencies.map((c) => optionFromNameAndId(c))}
                  editMode={editMode}
                  onChange={(id) =>
                    editVendorField(
                      "currencyType",
                      getNameAndIdFromList(currencies, id)
                    )
                  }
                />
                <div
                  className="label"
                  data-toggle="tooltip"
                  data-placement="right"
                  title={VENDOR_NUMBER_DESCRIPTION}
                >
                  <LabelTextField
                    label={"Vendor Number *"}
                    value={editVendor.vendorNumber}
                    editMode={editMode}
                    onChange={(value) => editVendorField("vendorNumber", value, true)}
                    maxLength={10}
                  />
                </div>
              </ul>
            </div>
            <div className="col-4">
              <ul>
                <LabelTextField
                  label={"Billing Address"}
                  value={editVendor.billingAddress}
                  editMode={editMode}
                  onChange={(value) => editVendorField("billingAddress", value)}
                />
                <LabelTextField
                  label={"Billing Account Number"}
                  value={editVendor.billingAccountNumber}
                  editMode={editMode}
                  onChange={(value) =>
                    editVendorField("billingAccountNumber", value)
                  }
                />
              </ul>
            </div>
            <div className="col-4">
              <div className="vendor-info">
                <ul>
                  <LabelTextField
                    label={"Preferred Shipping Method"}
                    value={editVendor.preferredShippingMethod}
                    editMode={editMode}
                    onChange={(value) =>
                      editVendorField("preferredShippingMethod", value)
                    }
                  />
                  <LabelTextField
                    label={"Delivery Account Number"}
                    value={editVendor.deliveryAccountNumber}
                    editMode={editMode}
                    onChange={(value) =>
                      editVendorField("deliveryAccountNumber", value)
                    }
                  />
                </ul>
              </div>
            </div>
          </div> : <></>}
        </div>
      </div>
    </form>
  );
};
