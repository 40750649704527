import React, { useState } from "react";
import { useSelector } from "react-redux";
import { PageLayout } from "../../layouts/PageLayout";
import api from "services/apiService";
import { OrderListRow } from "./OrderListRow";
import { joinParams } from "components/joinParams";
import { FINANCE_APPROVAL_PARAM_VALUE } from "constants/financeApproval";
import { ORDER_STATUS_PARAM_VALUE, ORDER_STATUS } from "constants/orderStatus";
import { OrderListSearch } from "./OrderListSearch";
import { Pagination } from "components/Pagination";
import { startOfDay, endOfDay } from "components/formatDate";
import { isNullOrUndefined, isEmpty } from "util/common";
import { useOverlay } from "hooks/useOverlay";
import { useError } from "hooks/errors";
import { downloadFile } from "util/download";
import { useCurrentUser } from "hooks/useCurrentUser";
import { ROLE_OPERATIONS, userCanPerformOperation } from "constants/roles";

export const OrderListPage = () => {
  const user = useCurrentUser();
  const [showOverlay, hideOverlay] = useOverlay();
  const message = useSelector(state => state.message.message);
  const searchValues = useSelector(state => state.orders.orderSearchValues);

  const [orders, setOrders] = useState([]);
  const [pagination, setPagination] = useState({});
  const [showError, ] = useError();

  const [rootCustomer, setRootCustomer] = useState(false);

  const searchOrders = async (searchValues) => {
    try {
      searchValues.page = 0;
      const params = getParams(searchValues);

      if (isNullOrUndefined(message)) {
        await showOverlay("Searching...");
      }

      const response = await api.getOrders(params);
      hideOverlay();
      setOrders(response.data.orders);
      isRootsCustomer();
      setPagination({
        currentPage: response.data.currentPage,
        pageSize: response.data.pageSize,
        lastPage: response.data.lastPage,
        totalItems: response.data.totalItems
      });
    } catch (error) {
      hideOverlay();
      showError(error);
    }
  };

  const isRootsCustomer = async () => {
    try {
      const response = await api.hasRootsCustomer();
      setRootCustomer(response.data);
    } catch (error) {
      showError(error);
    }
  };

  const getParams = (searchValues) => {
    let cancelled = null;

    if (searchValues.orderStatus !== ORDER_STATUS.NONE) {
      cancelled = (searchValues.orderStatus === ORDER_STATUS.CANCELLED);
    }

    return joinParams({
      orderNumber: searchValues.orderNumber,
      financeApproval: FINANCE_APPROVAL_PARAM_VALUE[searchValues.financeApproval],
      orderStatus: (searchValues.orderStatus === ORDER_STATUS.CANCELLED) ? null : ORDER_STATUS_PARAM_VALUE[searchValues.orderStatus],
      cancelled,
      customer: searchValues.customer,
      company: searchValues.company,
      productionRegion: searchValues.productionRegion,
      startDate: startOfDay(searchValues.startDate),
      endDate: endOfDay(searchValues.endDate),
      page: searchValues.page,
      size: searchValues.size
    });
  };

  const handlePageChange = async (page) => {
    try {
      searchValues.page = page;
      const params = getParams(searchValues);

      if (isNullOrUndefined(message)) {
        await showOverlay("Searching...");
      }

      const response = await api.getOrders(params);
      hideOverlay();
      setOrders(response.data.orders);
      setPagination({...pagination, currentPage: page });
    } catch (error) {
      hideOverlay();
      showError(error);
    }
  };

  const downloadInventoryCsv = async () => {
    try {
      showOverlay("Downloading...");
      const params = getParams(searchValues);
      const response = await api.downloadInventoryCsv(params);
      downloadFile(response);
      hideOverlay();
    } catch (error) {
      hideOverlay();
      showError(error);
    }
  };

  const downloadRootsInventoryCsv = async () => {
    try {
      showOverlay("Downloading...");
      const params = getParams(searchValues);
      const response = await api.downloadRootsInventoryCsv(params);
      downloadFile(response);
      hideOverlay();
    } catch (error) {
      hideOverlay();
      showError(error);
    }
  };

  const downloadRootsFullInventoryCsv = async () => {
    try {
      showOverlay("Downloading...");
      const params = getParams(searchValues);
      const response = await api.downloadRootsFullInventoryCsv(params);
      downloadFile(response);
      hideOverlay();
    } catch (error) {
      hideOverlay();
      showError(error);
    }
  };

  const downloadCsv = async () => {
    try {
      showOverlay("Downloading...");
      const params = getParams(searchValues);
      const response = await api.downloadOrdersCsv(params);
      downloadFile(response);
      hideOverlay();
    } catch (error) {
      hideOverlay();
      showError(error);
    }
  };

  const downloadFullCsv = async () => {
    try {
      showOverlay("Downloading...");
      const params = getParams(searchValues);
      const response = await api.downloadOrdersFullCsv(params);
      downloadFile(response);
      hideOverlay();
    } catch (error) {
      hideOverlay();
      showError(error);
    }
  };

  return (
        <PageLayout className="order-list">
            <section className="site-content bodyWrap" id="content">
                <section className="content-body">
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <h4 className="PageTitle"><span className="page-parent">ORDERS</span></h4>
                                <OrderListSearch searchOrders={searchOrders} />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <div className="list-group mt-5" id="orderList">
                                    {orders && orders.map((order, index) =>
                                        <OrderListRow key={index} order={order} />
                                    )}
                                </div>
                            </div>
                        </div>
                        <Pagination
                              page={pagination.currentPage}
                              lastPage={pagination.lastPage}
                              itemsShowed={orders.length}
                              totalItems={pagination.totalItems}
                              handlePageChange={handlePageChange}
                              listName="Order" />
                        <div className="d-flex">
                            <div className="d-space"></div>
                            {userCanPerformOperation(user, ROLE_OPERATIONS.DOWNLOAD_INVENTORY_CSV) &&
                                <div className="d-flex">
                                    <div>
                                        <button className="btn btn-primary shadow" type="button" onClick={() => downloadInventoryCsv()}>Inventory Report</button>
                                    </div>
                                  {rootCustomer &&
                                  <>
                                    <div className="download-csv">
                                      <button className="btn btn-primary shadow" type="button"
                                              onClick={() => downloadRootsInventoryCsv()}>Roots Inventory Report
                                      </button>
                                    </div>
                                    <div className="download-csv">
                                      <button className="btn btn-primary shadow" type="button"
                                              onClick={() => downloadRootsFullInventoryCsv()}>Roots Full Inventory Report
                                      </button>
                                    </div>
                                  </>
                                  }
                                </div>
                            }
                            {!isEmpty(orders) &&
                                <div className="d-flex">
                                    <div className="download-csv">
                                        <button className="btn btn-primary shadow" type="button" onClick={() => downloadCsv()}>Order Report</button>
                                    </div>
                                    <div className="download-csv">
                                        <button className="btn btn-primary shadow" type="button" onClick={() => downloadFullCsv()}>Full Order Report</button>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </section>
            </section>
        </PageLayout>
  );
};
