// React
import { useError } from "hooks/errors";
import React, { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

// Local
import { api } from "services/apiService";
import { isEmpty, isNullOrUndefined } from "util/common";
import { SelectorCheckList } from "components/SelectorCheckList";
import {
  CARE_INSTRUCTION_TYPE,
  INSTRUCTION_OPTIONS,
  INSTRUCTION_TYPE_GROUP,
} from "constants/careInstructionType";
import { EditableListPanel } from "../shared/EditableListPanel";
import { useOverlay } from "hooks/useOverlay";
import {
  showErrorMessage,
  showSuccessMessage,
} from "store/messages/messageActions";

export const CareLabelLists = ({
  countryOfOriginLists,
  cautionMaintenanceLists,
  specialInstructionLists,
  fabricCompositionLists,
  careCodeLists,
  careInstructionLists,
  setCountryOfOriginLists,
  setCautionMaintenanceLists,
  setSpecialInstructionLists,
  setFabricCompositionLists,
  setCareCodeLists,
  setCareInstructionLists,
  countryOfOriginOptions,
  cautionMaintenanceOptions,
  specialInstructionOptions,
  componentTypeOptions,
  materialOptions,
  careCodeOptions,
  careInstructionOptions,
}) => {
  // hooks
  const [showError] = useError();
  const [showOverlay, hideOverlay] = useOverlay();
  const dispatch = useDispatch();
  const message = useSelector((state) => state.message.message);

  // state
  const [selectedCountryList, setSelectedCountryList] = useState("");
  const [selectedCautionList, setSelectedCautionList] = useState("");
  const [selectedSpecialList, setSelectedSpecialList] = useState("");
  const [selectedFabricList, setSelectedFabricList] = useState("");
  const [selectedCodeList, setSelectedCodeList] = useState("");
  const [selectedInstructionList, setSelectedInstructionList] = useState("");

  const [editCountryList, setEditCountryList] = useState("");
  const [editFabricList, setEditFabricList] = useState("");
  const [editSpecialList, setEditSpecialList] = useState("");
  const [editCautionList, setEditCautionList] = useState("");
  const [editCodeList, setEditCodeList] = useState("");
  const [editInstructionList, setEditInstructionList] = useState("");

  const [createCountryList, setCreateCountryList] = useState("");
  const [createFabricList, setCreateFabricList] = useState("");
  const [createSpecialList, setCreateSpecialList] = useState("");
  const [createCautionList, setCreateCautionList] = useState("");
  const [createCodeList, setCreateCodeList] = useState("");
  const [createInstructionList, setCreateInstructionList] = useState("");

  const [itemsInCountryList, setItemsInCountryList] = useState([]);
  const [itemsInCautionList, setItemsInCautionList] = useState([]);
  const [itemsInSpecialList, setItemsInSpecialList] = useState([]);
  const [itemsInComponentList, setItemsInComponentList] = useState([]);
  const [itemsInMaterialList, setItemsInMaterialList] = useState([]);
  const [itemsInCodeList, setItemsInCodeList] = useState([]);
  const [
    instructionListItemsGrouped,
    setInstructionListItemsGrouped,
  ] = useState([]);

  const [selectedInstructionType, setSelectedInstructionType] = useState(
    CARE_INSTRUCTION_TYPE.WASH
  );

  // methods

  // country list change
  useEffect(() => {
    if (isEmpty(selectedCountryList)) {
      setItemsInCountryList([]);
    } else {
      onListChange(
        selectedCountryList.id,
        api.getCountryOfOriginListItems,
        setItemsInCountryList
      );
    }
  }, [selectedCountryList]);

  // caution list change
  useEffect(() => {
    if (isEmpty(selectedCautionList)) {
      setItemsInCautionList([]);
    } else {
      onListChange(
        selectedCautionList.id,
        api.getCautionMaintenanceListItems,
        setItemsInCautionList
      );
    }
  }, [selectedCautionList]);

  // special list change
  useEffect(() => {
    if (isEmpty(selectedSpecialList)) {
      setItemsInSpecialList([]);
    } else {
      onListChange(
        selectedSpecialList.id,
        api.getSpecialInstructionListItems,
        setItemsInSpecialList
      );
    }
  }, [selectedSpecialList]);

  // fabric list change
  useEffect(() => {
    if (isEmpty(selectedFabricList)) {
      setItemsInComponentList([]);
      setItemsInMaterialList([]);
    } else {
      updateItemsInFabricList(selectedFabricList.id);
    }
  }, [selectedFabricList]);

  // care code list change
  useEffect(() => {
    if (isEmpty(selectedCodeList)) {
      setItemsInCodeList([]);
    } else {
      onListChange(
        selectedCodeList.id,
        api.getCareCodeListItems,
        setItemsInCodeList,
        true
      );
    }
  }, [selectedCodeList]);

  // care instruction list change
  useEffect(() => {
    if (isEmpty(selectedInstructionList)) {
      setInstructionListItemsGrouped([]);
    } else {
      updateGroupedInstructionItems(selectedInstructionList.id);
    }
  }, [selectedInstructionList]);

  // change list
  const updateItemsInFabricList = async (listId) => {
    try {
      const itemsResponse = await api.getFabricCompositionListItems(listId);
      setItemsInComponentList(itemsResponse.data.componentTypes);
      setItemsInMaterialList(itemsResponse.data.materials);
    } catch (error) {
      showError(error);
    }
  };
  const updateGroupedInstructionItems = async (listId) => {
    try {
      const careInstructionItemsResponse = await api.getCareInstructionListItems(
        listId
      );
      setInstructionListItemsGrouped(careInstructionItemsResponse.data);
    } catch (error) {
      showError(error);
    }
  };

  // check / uncheck
  const onClickCountry = (country) =>
    onClickItemCheck(
      country,
      itemsInCountryList,
      setItemsInCountryList,
      selectedCountryList,
      api.addRemoveItemFromCountryList
    );
  const onClickCaution = (caution) =>
    onClickItemCheck(
      caution,
      itemsInCautionList,
      setItemsInCautionList,
      selectedCautionList,
      api.addRemoveItemFromCautionList
    );
  const onClickSpecial = (special) =>
    onClickItemCheck(
      special,
      itemsInSpecialList,
      setItemsInSpecialList,
      selectedSpecialList,
      api.addRemoveItemFromSpecialList
    );
  const onClickComponent = (component) =>
    onClickItemCheck(
      component,
      itemsInComponentList,
      setItemsInComponentList,
      selectedFabricList,
      api.addRemoveComponentFromFabricList
    );
  const onClickMaterial = (material) =>
    onClickItemCheck(
      material,
      itemsInMaterialList,
      setItemsInMaterialList,
      selectedFabricList,
      api.addRemoveMaterialFromFabricList
    );
  const onClickCode = (code) =>
    onClickItemCheck(
      code,
      itemsInCodeList,
      setItemsInCodeList,
      selectedCodeList,
      api.addRemoveItemFromCareCodeList
    );
  const onClickInstruction = (instruction) => {
    const setList = (newList) => {
      const _instructionListItemsGrouped = { ...instructionListItemsGrouped };
      _instructionListItemsGrouped[
        INSTRUCTION_TYPE_GROUP[selectedInstructionType]
      ] = newList;
      setInstructionListItemsGrouped(_instructionListItemsGrouped);
    };

    onClickItemCheck(
      instruction,
      instructionListItemsGrouped[
        INSTRUCTION_TYPE_GROUP[selectedInstructionType]
      ],
      setList,
      selectedInstructionList,
      api.addRemoveItemFromCareInstructionList
    );
  };

  // generic
  const onListChange = async (listId, apiCall, setListItems, isCodes = false) => {
    try {
      const itemsResponse = await apiCall(listId);
      setListItems(isCodes ? itemsResponse.data.codes : itemsResponse.data.refs);
    } catch (error) {
      showError(error);
    }
  };

  const onClickItemCheck = async (
    item,
    listItems,
    setListItems,
    selectedList,
    apiCall
  ) => {
    const itemInList = listItems.map((i) => i.id).includes(item.id);
    const data = { id: item.id };

    try {
      await apiCall(selectedList.id, data);

      if (itemInList) {
        removeItem(item, listItems, setListItems);
      } else {
        addItem(item, listItems, setListItems);
      }
    } catch (error) {
      showError(error);
    }
  };

  const removeItem = (item, list, setList) => {
    let _list = [...list];

    _list = _list.filter((c) => c.id != item.id);
    setList(_list);
  };

  const addItem = (item, list, setList) => {
    let _list = [...list];

    _list.push(item);
    setList(_list);
  };

  const addOrUpdateList = (
    editList,
    createList,
    lists,
    setLists,
    addApiCall,
    updateApiCall
  ) => {
    if (isEmpty(editList)) {
      addList(createList, lists, setLists, addApiCall);
    } else {
      updateList(editList, lists, setLists, updateApiCall);
    }
  };

  const addList = async (createList, lists, setLists, apiCall) => {
    if (isNullOrUndefined(message)) {
      await showOverlay("Adding...");
    }

    try {
      const response = await apiCall(createList);

      const _list = { ...createList };
      _list.id = response.data;

      const _lists = [...lists];
      _lists.push(_list);
      _lists.sort((l1, l2) => l1.name.localeCompare(l2.name));

      setLists(_lists);
      dispatch(showSuccessMessage("List Successfully Added"));
    } catch (error) {
      showError(error);
    } finally {
      hideOverlay();
    }
  };

  const updateList = async (editList, lists, setLists, apiCall) => {
    if (isNullOrUndefined(message)) {
      await showOverlay("Updating...");
    }

    try {
      await apiCall(editList);

      const _lists = [...lists];
      const _list = _lists.find(l => l.id === editList.id);
      if (isEmpty(_list)) {
        dispatch(showErrorMessage("Could not find list specified"));
      } else {
        _list.name = editList.name;
        _lists.sort((l1, l2) => l1.name.localeCompare(l2.name));

        setLists(_lists);
        dispatch(showSuccessMessage("List Successfully Updated"));
      }
    } catch (error) {
      showError(error);
    } finally {
      hideOverlay();
    }
  };

  const removeList = async (selectedList, setSelectedList, setEditList, lists, setLists, apiCall) => {
    if (isNullOrUndefined(message)) {
      await showOverlay("Updating...");
    }

    try {
      const data = { id: selectedList.id };
      await apiCall(data);

      const _lists = lists.filter(l => l.id !== selectedList.id);

      setLists(_lists);
      setSelectedList("");
      setEditList("");
      dispatch(showSuccessMessage("List Successfully Removed"));
    } catch (error) {
      showError(error);
    } finally {
      hideOverlay();
    }
  };

  return (
    <div className="entity-list">
      <div className="row">
        <div className="col-4">
          <EditableListPanel
            title={"Country Of Origin Lists"}
            placeholder={"Enter a List Name"}
            options={countryOfOriginLists}
            selectedOption={selectedCountryList}
            editOption={editCountryList}
            createOption={createCountryList}
            setOptions={setCountryOfOriginLists}
            setSelectedOption={setSelectedCountryList}
            setEditOption={setEditCountryList}
            setCreateOption={setCreateCountryList}
            apiAddCall={api.addCountryOfOriginList}
            apiUpdateCall={api.updateCountryOfOriginList}
            apiRemoveCall={api.removeCountryOfOriginList}
            addOrUpdateOption={addOrUpdateList}
            removeOption={removeList}
          />
          <SelectorCheckList
            title={"Options"}
            options={countryOfOriginOptions}
            listOptions={itemsInCountryList}
            onClickOption={onClickCountry}
            checkListDisabled={isEmpty(selectedCountryList)}
          />
        </div>
        <div className="col-4">
          <EditableListPanel
            title={"Caution Maintenance Lists"}
            placeholder={"Enter a List Name"}
            options={cautionMaintenanceLists}
            selectedOption={selectedCautionList}
            editOption={editCautionList}
            createOption={createCautionList}
            setOptions={setCautionMaintenanceLists}
            setSelectedOption={setSelectedCautionList}
            setEditOption={setEditCautionList}
            setCreateOption={setCreateCautionList}
            apiAddCall={api.addCautionMaintenanceList}
            apiUpdateCall={api.updateCautionMaintenanceList}
            apiRemoveCall={api.removeCautionMaintenanceList}
            addOrUpdateOption={addOrUpdateList}
            removeOption={removeList}
          />
          <SelectorCheckList
            title={"Options"}
            options={cautionMaintenanceOptions}
            listOptions={itemsInCautionList}
            onClickOption={onClickCaution}
            checkListDisabled={isEmpty(selectedCautionList)}
          />
        </div>
        <div className="col-4">
          <EditableListPanel
            title={"Special Instruction Lists"}
            placeholder={"Enter a List Name"}
            options={specialInstructionLists}
            selectedOption={selectedSpecialList}
            editOption={editSpecialList}
            createOption={createSpecialList}
            setOptions={setSpecialInstructionLists}
            setSelectedOption={setSelectedSpecialList}
            setEditOption={setEditSpecialList}
            setCreateOption={setCreateSpecialList}
            apiAddCall={api.addSpecialInstructionList}
            apiUpdateCall={api.updateSpecialInstructionList}
            apiRemoveCall={api.removeSpecialInstructionList}
            addOrUpdateOption={addOrUpdateList}
            removeOption={removeList}
          />
          <SelectorCheckList
            title={"Options"}
            options={specialInstructionOptions}
            listOptions={itemsInSpecialList}
            onClickOption={onClickSpecial}
            checkListDisabled={isEmpty(selectedSpecialList)}
          />
        </div>
      </div>
      <div className="row mb-0" id="fabric-composition-lists-row">
        <div className="col-12">
          <EditableListPanel
            title={"Fabric Composition Lists"}
            placeholder={"Enter a List Name"}
            options={fabricCompositionLists}
            selectedOption={selectedFabricList}
            editOption={editFabricList}
            createOption={createFabricList}
            setOptions={setFabricCompositionLists}
            setSelectedOption={setSelectedFabricList}
            setEditOption={setEditFabricList}
            setCreateOption={setCreateFabricList}
            apiAddCall={api.addFabricCompositionList}
            apiUpdateCall={api.updateFabricCompositionList}
            apiRemoveCall={api.removeFabricCompositionList}
            addOrUpdateOption={addOrUpdateList}
            removeOption={removeList}
          />
        </div>
      </div>
      <div className="row mt-0">
        <div className="col-6" style={{ marginTop: "24px" }}>
          <SelectorCheckList
            style={{ marginTop: "0px" }}
            title={"Component Options"}
            options={componentTypeOptions}
            listOptions={itemsInComponentList}
            onClickOption={onClickComponent}
            checkListDisabled={isEmpty(selectedFabricList)}
          />
        </div>
        <div className="col-6" style={{ marginTop: "24px" }}>
          <SelectorCheckList
            title={"Material Options"}
            options={materialOptions}
            listOptions={itemsInMaterialList}
            onClickOption={onClickMaterial}
            checkListDisabled={isEmpty(selectedFabricList)}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-6">
          <EditableListPanel
            title={"Care Code Lists"}
            placeholder={"Enter a List Name"}
            options={careCodeLists}
            selectedOption={selectedCodeList}
            editOption={editCodeList}
            createOption={createCodeList}
            setOptions={setCareCodeLists}
            setSelectedOption={setSelectedCodeList}
            setEditOption={setEditCodeList}
            setCreateOption={setCreateCodeList}
            apiAddCall={api.addCareCodeList}
            apiUpdateCall={api.updateCareCodeList}
            apiRemoveCall={api.removeCareCodeList}
            addOrUpdateOption={addOrUpdateList}
            removeOption={removeList}
          />
          <SelectorCheckList
            title={"Options"}
            options={careCodeOptions.map(o => {
              if (isEmpty(o["concatenated_name"])) {
                o["concatenated_name"] = `${o.code} - ${o.text}`;
              }
              return o;
            })}
            optionNameField={"concatenated_name"}
            listOptions={itemsInCodeList}
            onClickOption={onClickCode}
            checkListDisabled={isEmpty(selectedCodeList)}
          />
        </div>
        <div className="col-6">
          <EditableListPanel
            title={"Care Instruction Lists"}
            placeholder={"Enter a List Name"}
            options={careInstructionLists}
            selectedOption={selectedInstructionList}
            editOption={editInstructionList}
            createOption={createInstructionList}
            setOptions={setCareInstructionLists}
            setSelectedOption={setSelectedInstructionList}
            setEditOption={setEditInstructionList}
            setCreateOption={setCreateInstructionList}
            apiAddCall={api.addCareInstructionList}
            apiUpdateCall={api.updateCareInstructionList}
            apiRemoveCall={api.removeCareInstructionList}
            addOrUpdateOption={addOrUpdateList}
            removeOption={removeList}
          />
          <SelectorCheckList
            secondaryLists={INSTRUCTION_OPTIONS}
            secondaryOnChangeList={setSelectedInstructionType}
            secondarySelectedList={selectedInstructionType}
            secondaryValueField={"value"}
            secondaryTextField={"text"}
            options={
              careInstructionOptions[
                INSTRUCTION_TYPE_GROUP[selectedInstructionType]
              ]
            }
            listOptions={
              isEmpty(instructionListItemsGrouped)
                ? []
                : instructionListItemsGrouped[
                    INSTRUCTION_TYPE_GROUP[selectedInstructionType]
                  ]
            }
            onClickOption={onClickInstruction}
            optionNameField={"displayName"}
            checkListDisabled={isEmpty(selectedInstructionList)}
          />
        </div>
      </div>
    </div>
  );
};
