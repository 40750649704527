import { useLocation } from "react-router-dom";

export const useURLParams = () => {
    const params = new URLSearchParams(useLocation().search);

    const getParam = (param) => {
        return params.get(param);
    };

    const getParamList = (param) => {
        return params.get(param).split(",");
    };

    return [getParam, getParamList];
};
