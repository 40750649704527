/* eslint-disable no-multiple-empty-lines */
/* eslint-disable no-trailing-spaces */
/* eslint-disable no-unused-vars */

// React
import React, { useEffect, useState } from "react";

// Local
import { isEmpty } from "util/common";

export const SubcodeRelationships = ({
  editSubcode,
  editSubcodeField,
  editMode,
  customers,
  factories,
  productCodes,
  schemas,
}) => {
  const [searchValue, setSearchValue] = useState("");
  const [filteredProductCodes, setFilteredProductCodes] = useState(
    productCodes
  );

  useEffect(() => {
    const _filtered = productCodes.filter((code) =>
      code.productCode.toLowerCase().includes(searchValue.toLowerCase())
    );
    setFilteredProductCodes(_filtered);
  }, [searchValue]);

  return (
    <div className="card shadow">
      <div className="card-body">
        <div className="row entity-list">
          <div className="col">
            <label>Customer</label>
            <div className="scroller" style={{ height: "200px" }}>
              {editSubcode &&
                customers &&
                customers.map((c, index) => (
                  <div className="item" key={index}>
                    <input
                      key={index}
                      disabled={!editMode}
                      type="radio"
                      checked={
                        editSubcode.customer && editSubcode.customer.id === c.id
                      }
                      onClick={() => editSubcodeField("customer", c)}
                      className="radio"
                    />
                    {c.name}
                  </div>
                ))}
            </div>
            <label style={{ marginTop: "20px" }}>Required Attributes</label>
            <button
              className="btn btn-secondary select-all-btn"
              disabled={!editMode || isEmpty(editSubcode.schema)}
              onClick={() => editSubcodeField("schema", null)}
            >
              Remove Selection
            </button>
            <div className="scroller" style={{ height: "200px" }}>
              {editSubcode &&
                editSubcode.customer &&
                schemas &&
                schemas
                  .filter(
                    (s) =>
                      s.customerId == -1 ||
                      s.customerId == editSubcode.customer.id
                  )
                  .map((s, index) => (
                    <div className="item" key={index}>
                      <input
                        key={index}
                        disabled={!editMode}
                        type="radio"
                        checked={
                          !!(
                            editSubcode.schema && editSubcode.schema.id == s.id
                          )
                        }
                        onChange={() => editSubcodeField("schema", s)}
                        className="radio"
                      />
                      {s.name}
                    </div>
                  ))}
            </div>
          </div>
          <div className="col">
            <label>Factory</label>
            <div className="scroller" style={{ height: "495px" }}>
              {editSubcode &&
                editSubcode.factory &&
                factories &&
                factories.map((f, index) => (
                  <div className="item" key={index}>
                    <input
                      key={index}
                      disabled={!editMode}
                      type="radio"
                      checked={
                        editSubcode.factory && editSubcode.factory.id === f.id
                      }
                      onClick={() => editSubcodeField("factory", f)}
                      className="radio"
                    />
                    {f.name}
                  </div>
                ))}
            </div>
          </div>
          <div className="col">
            <label>Product Code</label>
            <button
              className="btn btn-secondary select-all-btn"
              disabled={!editMode || isEmpty(editSubcode.productCode)}
              onClick={() => editSubcodeField("productCode", null)}
            >
              Remove Selection
            </button>
            <input
              style={{ marginBottom: "8px" }}
              className="form-control"
              type="text"
              placeholder="Search for a Product Code"
              value={searchValue}
              onChange={(e) => setSearchValue(e.target.value)}
            ></input>
            <div className="scroller" style={{ height: "405px" }}>
              {editSubcode &&
                filteredProductCodes &&
                filteredProductCodes.map((pc, index) => (
                  <div className="item" key={index}>
                    <input
                      key={index}
                      disabled={!editMode}
                      type="radio"
                      checked={
                        !!(
                          editSubcode.productCode &&
                          editSubcode.productCode.id === pc.id
                        )
                      }
                      value={pc.id}
                      onChange={() => editSubcodeField("productCode", pc)}
                      className="radio"
                    />
                    {pc.productCode}
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
