/* eslint-disable indent */
import React, {useEffect} from "react";
import { useState } from "react";
import SearchIcon from "@material-ui/icons/Search";
import {IconButton} from "@material-ui/core";

export const AttributeField = ({ name, type, options, initialValue, showSearchButton, onSearch, onAttributeChange, showLabel = true, required = true}) => {
    const [value, setValue] = useState(initialValue);

    const onChange = (value) => {
        setValue(value);
        onAttributeChange(value);
    };

    const onKeyDown = (e) => {
        if (e.keyCode === 13) {
            e.preventDefault();
            e.target.blur();
        }
    };

    const getSearchButton = () => {
        if (showSearchButton) {
            return <IconButton style={{height: "4rem", width: "4rem", marginTop: "12px" }} color="primary" component="label"
                               onClick={() => onSearch(value)}>
                <SearchIcon/>
            </IconButton>;
        }
    };

    useEffect( () => {
        setValue(initialValue);
        onAttributeChange(initialValue);
    }, [initialValue]);

    const getField = () => {
        if (type === "DROPDOWN") {
            return (
                <select className="form-control" required onChange={e => onChange(e.target.value)} value={value}>
                    <option value="" hidden></option>
                    {options.map((option, idx) =>
                        <option key={idx} value={option}>{option}</option>
                    )}
                </select>
            );
        } else {
            return <input
                className="form-control"
                type="text"
                placeholder={"Enter " + name}
                onChange={e => onChange(e.target.value)}
                onKeyDown={onKeyDown}
                value={value}
                required={required}
            />;
        }
    };

    return (
        <>
        <div className="col-3 product-attribute-group">
            {showLabel && <div className="label product-attribute">{name} *</div>}
            {getField()}
        </div>
        {getSearchButton()}
        </>
    );
};
