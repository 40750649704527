// tooltips

export const MIN_ORDER_VALUE_DESCRIPTION = `The minimum order value shown is applied across 
all items in your order with the same product code. If the subtotal for the product 
items in the order with this code do not meet the Minimum Order Value, then you will
be charged the difference.`;
export const VENDOR_NUMBER_DESCRIPTION = "The Vendor Number determines a user's access to variable and dynamic data products.";

export const ACTIVE_USER_FLAG_EFFECT_DESCRIPTION = `When a user is switched to the Active state,
the user's Last Login date will also be reset.\nOnce the user logs in, it will be repopulated.`;

// errors

export const UNEXPECTED_ERROR_MESSAGE = "An unexpected error has occured.";
export const SERVER_CONNECTION_ERROR_MESSAGE = "ERROR: Unable to reach network server.";
export const INVALID_EMAIL_MESSAGE = "* Please include a single valid email address";
export const INVALID_PERCENTAGES_ERROR = "All material percentages must add up to 100%. Please correct the fields below.";