import * as types from "./userActionTypes";

export const userHasAVendor = (data) => {
  return {
    type: types.SET_USER_HAS_A_VENDOR,
    data,
  };
};

export const setUserSearchValues = (data) => {
  return {
    type: types.SET_USER_SEARCH_VALUES,
    data,
  };
};
