import React from "react";
import { useState, useEffect } from "react";
import { PageLayout } from "../../layouts/PageLayout";
import { api } from "../../services/apiService";
import { ProductSearchForm } from "./components/ProductSearchForm";
import { joinParams } from "components/joinParams";
import { VariableDataProductRow } from "./components/VariableDataProductRow";
import { StandardProductRow } from "./components/StandardProductRow";
import { isEmpty, pluralize } from "util/common";
import { useURLNavigate } from "hooks/useURLNavigate";
import { PAGE_URL } from "constants/url";
import { useOverlay } from "hooks/useOverlay";
import { useError } from "hooks/errors";
import moment from "moment";
import {Pagination} from "../../components/Pagination";

export const ProductListPage = () => {
  const navigate = useURLNavigate();
  const [showOverlay, hideOverlay] = useOverlay();
  const [showError, ] = useError();

  const [variableDataView, setVariableDataView] = useState(null);
  const [variableDataViewNext, setVariableDataViewNext] = useState(null);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [checkAll, setCheckAll] = useState(false);
  const [enableOrderSelected, setEnableOrderSelected] = useState(false);
  const [productsOrGroups, setProductsOrGroups] = useState([]);
  const [showingRecentProducts, setShowingRecentProducts] = useState(false);
  const [pagination, setPagination] = useState({
    currentPage: 0,
    pageSize: 0,
    lastPage: 0,
    totalItems: 0
  });
  const [filters, setFilters] = useState({});

  const ORDERED_TOOLTIP = "Indicates if an item in this PO group has already been ordered.";

  useEffect(() => {
    setVariableDataView(variableDataViewNext);
    productsOrGroups.map(product => product.selected = false);
  }, [productsOrGroups, variableDataViewNext]);

  const onProductSearch = async (values) => {
    values.size = 50;
    values.page = 0;
    setFilters(values);
    const params = getParams(values);
    const isVariableDataSearch = values.productGroup === "VARIABLE_DATA";
    let _productsOrGroups;
    try {
      await showOverlay("Searching...");
      if (isVariableDataSearch) {
        // Group the variable data products by PO number
        const response = await api.getProductVariableDataList(params);
        _productsOrGroups = groupVariableDataItemsByPO(response.data.products);
        setShowingRecentProducts(!values.poNumber && !values.styleNumber);
        const pageableOrderItemsInfo = response.data.pageableOrderItemsInfo;
        setPagination({
          currentPage: pageableOrderItemsInfo.currentPage,
          pageSize: pageableOrderItemsInfo.pageSize,
          lastPage: pageableOrderItemsInfo.lastPage,
          totalItems: pageableOrderItemsInfo.totalItems
        });

      } else {
        const response = await api.getProductList(params);
        _productsOrGroups = response.data.products;
      }
      hideOverlay();
      setVariableDataViewNext(isVariableDataSearch);
      setProductsOrGroups(_productsOrGroups);
    } catch (error) {
      hideOverlay();
      showError(error);
    }
  };

  const handleVariableDataPageChange = async (page) => {
    let values = {...filters, page: page};
    setFilters(values);
    const params = getParams(values);
    const isVariableDataSearch = filters.productGroup === "VARIABLE_DATA";
    try {
      await showOverlay("Searching...");
      const response = await api.getProductVariableDataList(params);
      const pageableOrderItemsInfo = response.data.pageableOrderItemsInfo;

      // Group the variable data products by PO number
       let _productsOrGroups = groupVariableDataItemsByPO(response.data.products);
       setShowingRecentProducts(!filters.poNumber && !filters.styleNumber);

      hideOverlay();
      setVariableDataViewNext(isVariableDataSearch);
      setProductsOrGroups(_productsOrGroups);
      setPagination({
        currentPage: pageableOrderItemsInfo.currentPage,
        pageSize: pageableOrderItemsInfo.pageSize,
        lastPage: pageableOrderItemsInfo.lastPage,
        totalItems: pageableOrderItemsInfo.totalItems
      });
    } catch (error) {
      hideOverlay();
      showError(error);
    }
  };

  const getParams = (values) => {
    return joinParams(values);
  };

  const groupVariableDataItemsByPO = (variableDataProducts) => {
    // Group products by PO number
    const groups = new Map();
    for (const p of variableDataProducts) {
      let key = p.poNumber + "-" + (p.creationDate ? p.creationDate : "n/a");
      let _variableDataGroup = groups.get(key);

      if (_variableDataGroup) {
        _variableDataGroup.ticketTypes = p.productSubcodes;
        _variableDataGroup.descriptions = p.descriptions;
        _variableDataGroup.productItemIds = p.productItemIds;

        // update date with most recent creation date
        let productDate = moment(p.creationDate);
        let groupDate = moment(_variableDataGroup.creationDate);

        if (productDate.isAfter(groupDate)) {
          _variableDataGroup.creationDate = p.creationDate;
        }

        if (!_variableDataGroup.ordered && p.ordered) {
          _variableDataGroup.ordered = true;
        }

      } else {
        _variableDataGroup = {
          poNumber: p.poNumber,
          styleNumber: p.styleNumber,
          creationDate: p.creationDate,
          ordered: p.ordered,
          ticketTypes: p.productSubcodes,
          descriptions: p.descriptions,
          productItemIds: p.productItemIds,
        };
      }

      groups.set(key, _variableDataGroup);
    }

    return [...groups.values()];
  };

  const onProductGroupCheckboxChange = (productIds, checked) => {

    let _products = [...selectedProducts];
    if (checked) {
      // add all ids in group to selectedProducts
      for (const id of productIds.split(",")) {
        addIfNotPresent(_products, id);
      }

      setSelectedProducts(_products);

      if (!enableOrderSelected) {
        setEnableOrderSelected(true);
      }

    } else {
      // remove all ids in group from selectedProducts
      for (const id of productIds) {
        const index = _products.indexOf(id);

        if (index >= 0) {
          _products.splice(index, 1);
        }
      }

      setSelectedProducts(_products);

      if (_products.length === 0) {
        setEnableOrderSelected(false);
      }
    }
  };

  const onCheckAllChange = () => {
    if (checkAll) {
      setSelectedProducts([]);
    } else {
      let _selectedProductIds = [];
      for (const group of productsOrGroups) {
        group.productItemIds.split(",").map(id => addIfNotPresent(_selectedProductIds, id));
      }

      setSelectedProducts(_selectedProductIds);
    }

    setCheckAll(!checkAll);
    setEnableOrderSelected(!checkAll && !isEmpty(productsOrGroups));
  };

  const onOrderSelected = () => {
    const params = joinParams({
      productItemId: selectedProducts
    });
    navigate(PAGE_URL.PRODUCT_DETALS, params);
  };

  const addIfNotPresent = (list, item) => {
    if (!list.includes(item)) {
      list.push(item);
    }
  };

  return (
    <PageLayout className="product-list">
        <section className="site-content bodyWrap" id="content">
            <section className="content-body">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <h4 className="PageTitle">
                              <span className="page-parent">PRODUCTS</span>
                            </h4>
                            <ProductSearchForm onProductSearch={onProductSearch} />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <section className="pt-5">
                                {variableDataView !== null && (
                                <table className="table table-striped">
                                    <thead>
                                        <tr>
                                            {variableDataView && (
                                              <>
                                                <th scope="col" width="5%">
                                                    <input className="custom-control-input" type="checkbox" onChange={onCheckAllChange}></input>
                                                </th>
                                                <th scope="col" width="10%">PO Number</th>
                                                <th scope="col" width="10%">Style No.</th>
                                              </>
                                            )}
                                            <th scope="col" width={variableDataView ? "15%" : ""}>{variableDataView ? "Ticket Type" : "Product Code"}</th>
                                            <th scope="col">Description</th>
                                            {variableDataView &&
                                            <>
                                              <th scope="col" width="15%">Create Date</th>
                                              {showingRecentProducts &&
                                                <th scope="col" width="5%" data-toggle="tooltip" data-placement="bottom" title={ORDERED_TOOLTIP}>Ordered</th>
                                              }
                                            </>
                                            }
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {productsOrGroups && productsOrGroups.map((productOrGroup, index) =>
                                            variableDataView ? <VariableDataProductRow key={index} group={productOrGroup} onCheckboxChange={onProductGroupCheckboxChange} checkAll={checkAll} showOrdered={showingRecentProducts} /> : <StandardProductRow key={index} product={productOrGroup} />
                                        )}
                                    </tbody>
                                </table>
                                )}
                                {variableDataView && (
                                  <>
                                    <Pagination
                                      page={pagination.currentPage}
                                      lastPage={pagination.lastPage}
                                      itemsShowed={productsOrGroups.length}
                                      totalItems={pagination.totalItems}
                                      handlePageChange={handleVariableDataPageChange}
                                      listName="Product" />
                                    <div className="d-flex justify-content-end mt-3">
                                        <button className="btn btn-primary shadow" type="submit" disabled={!enableOrderSelected} onClick={onOrderSelected}>Order Selected</button>
                                    </div>
                                  </>
                                )}
                            </section>
                        </div>
                    </div>
                </div>
            </section>
        </section>
    </PageLayout>
  );
};
