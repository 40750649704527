import * as types from "./alertActionTypes";

const initialState = {
    alert: null
};

export const alertReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.SHOW_ALERT: {
            const alert = {
                title: action.title,
                description: action.description,
                cancelMessage: action.cancelMessage,
                confirmMessage: action.confirmMessage,
                cancelAction: action.cancelAction,
                confirmAction: action.confirmAction
            };
            return { ...state, alert: alert};
        }
        case types.HIDE_ALERT: {
            return { ...state, alert: null};
        }
        default:
            return state;
    }
}
