import React from "react";
import { formatDateWithTimeForUI } from "components/formatDate";
import { isEmpty } from "util/common";

export const OrderLogs = ({ logs }) => {
    return (
        <div className="row order-logs">
            <div className="col-12">
                <div className="card shadow">
                    <div className="card-body">
                        <h2>Logs</h2>
                        <div className="row log-header">
                            <div className="col-2 label">
                                <div>Timestamp</div>
                            </div>
                            <div className="col-4 label">
                                <div>User</div>
                            </div>
                            <div className="col-6 label">
                                <div>Description</div>
                            </div>
                        </div>
                        {logs.map((orderLog, index) => 
                            <div key={index} className="row log-entry">
                                <div className="col-2">
                                    <div>{formatDateWithTimeForUI(orderLog.timestamp)}</div>
                                </div>
                                <div className="col-4">
                                    <div>{orderLog.user}</div>
                                </div>
                                <div className="col-6">
                                    <div>{orderLog.description}</div>
                                </div>
                            </div>
                        )}
                        {isEmpty(logs) &&
                            <div className="row log-entry">
                                <div className="col-2">
                                    <div>---</div>
                                </div>
                                <div className="col-4">
                                    <div>---</div>
                                </div>
                                <div className="col-6">
                                    <div>---</div>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    );
};
