import { stringToInt } from "./common";

export class ProductUtils {

  static getQuantityForPackSize(quantity, packSize) {
    const intQuantity = stringToInt(quantity);

    const _packSize = packSize ? parseInt(packSize) : 1;
    return Math.ceil(intQuantity / _packSize) * packSize;
  }

}