import React from "react";

export const TextInput = ({ onChange, ...rest }) => {

    const onKeyDown = (e) => {
        if (e.keyCode === 13) {
            e.preventDefault();
            e.target.blur();
        }
    };

    return <input
        {...rest}
        type="text"
        onChange={e => onChange(e.target.value)}
        onKeyDown={onKeyDown}
    />
};
