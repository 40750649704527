import React from "react";
import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { PageLayout } from "../../layouts/PageLayout";
import { api } from "../../services/apiService";
import { CartHeader } from "./components/CartHeader";
import { OrderDetailItem } from "modules/order-details/OrderDetailItem";
import { CartShippingForm } from "./components/CartShippingForm";
import { CartBillingForm } from "./components/CartBillingForm";
import { PAGE_URL } from "constants/url";
import { useURLNavigate } from "hooks/useURLNavigate";
import {
  showSuccessMessage,
  pushSuccessMessage,
  showErrorMessage,
} from "store/messages/messageActions";
import { formatDateForAPI } from "components/formatDate";
import { isTrue, emptyStringIfNull } from "util/common";
import { useOverlay } from "hooks/useOverlay";
import { useError } from "hooks/errors";
import { RelatedDocuments } from "./components/RelatedDocuments";
import { useCurrentUser } from "hooks/useCurrentUser";
import { ROLES } from "constants/roles";
import { OrderCharge } from "modules/order-details/OrderCharge";
import { VariableDataUtils } from "util/variableData";

export const CartPage = () => {
  const dispatch = useDispatch();
  const navigate = useURLNavigate();
  const [showError, pushError] = useError();
  const user = useCurrentUser();

  const [loaded, setLoaded] = useState(false);
  const [cartOrderDetails, setCartOrderDetails] = useState(null);
  const [cartConfig, setCartConfig] = useState(null);
  const [shippingAddresses, setShippingAddresses] = useState([]);
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [showOverlay, hideOverlay] = useOverlay();
  const [validEmail, setValidEmail] = useState(null);


  const loadCartOrder = async () => {

    await showOverlay("Loading...");

    try {
      const cartResponse = await api.getCart();
      const addressResponse = await api.getShippingAddresses();

      const cartOrderDetails = cartResponse.data;
      const variableDataItems = cartOrderDetails.orderItems.filter(item => item.variableDataProduct === true);
      VariableDataUtils.customSortVariableDataItems(variableDataItems);

      console.log(cartOrderDetails);
      const cartConfig = {
        vendorPO: emptyStringIfNull(cartOrderDetails.vendorPO),
        vendorId: emptyStringIfNull(cartOrderDetails.vendorId),
        contact: emptyStringIfNull(cartOrderDetails.contact),
        email: emptyStringIfNull(cartOrderDetails.email),
        phone: emptyStringIfNull(cartOrderDetails.phone),
        dateRequested: Date.parse(cartOrderDetails.dateRequested),
        shippingAddress: emptyStringIfNull(cartOrderDetails.shippingAddress),
        deliveryMethod: emptyStringIfNull(cartOrderDetails.deliveryMethod),
        deliveryAccount: emptyStringIfNull(cartOrderDetails.deliveryAccount),
        billingAccount: emptyStringIfNull(cartOrderDetails.billingAccount),
        billingAddress: emptyStringIfNull(cartOrderDetails.billingAddress),
        comments: emptyStringIfNull(cartOrderDetails.comments),
      };

      const _addresses = addressResponse.data.addresses;
      let none = { optionName: "---", address: "" };
      _addresses.unshift(none);

      setCartConfig(cartConfig);
      setCartOrderDetails(cartOrderDetails);
      setShippingAddresses(_addresses);
      setLoaded(true);
    } catch (error) {
      pushError(error);
      navigate(PAGE_URL.HOME);
    } finally {
      hideOverlay();
    }
  };

  useEffect(() => {
    loadCartOrder();
  }, []);

  useEffect(() => {
    window.setCustomInvalidHandlers();
  }, [cartOrderDetails]);

  const onChange = (field, value, blur) => {
    let _cartConfig = { ...cartConfig };
    _cartConfig[field] = value;
    setCartConfig(_cartConfig);

    if (isTrue(blur)) {
      updateCart(_cartConfig);
    }
  };

  const onBlur = () => {
    updateCart(cartConfig);
  };

  const updateCart = async (cartConfig) => {
    try {
      const data = { ...cartConfig };
      data.dateRequested = formatDateForAPI(cartConfig.dateRequested);
      await api.updateCartOrder(data);
    } catch (error) {
      showError(error);
    }
  };

  const removeOrderItem = async (orderItemId) => {
    await showOverlay("Removing...");
    try {
      const isLastItem = cartOrderDetails.orderItems.length === 1;

      await api.removeFromCartOrder(orderItemId);

      if (isLastItem) {
        dispatch(pushSuccessMessage("Item successfuly removed from order"));
        navigate(PAGE_URL.ORDER);
      } else {
        loadCartOrder(); // load updated order prices and total
        dispatch(showSuccessMessage("Item successfuly removed from order"));
      }
    } catch (error) {
      showError(error);
    } finally {
      hideOverlay();
    }
  };

  const placeOrder = async (e) => {
    e.preventDefault();

    try {
      const data = {
        termsAndConditions: termsAccepted,
      };

      await showOverlay("Placing order...");
      const response = await api.placeOrder(data);

      hideOverlay();

      if (response.data.orderId === -1) {
        if (user.role === ROLES.VENDOR) {
          dispatch(showErrorMessage("The prices on the order have changed. Please refresh the order to view the new prices and continue."));
          return;
        } else {
          dispatch(showErrorMessage("The costs on the order have changed. Please refresh the order to view the new costs and continue."));
          return;
        }
      } else if (response.data.orderId === -2) {
        dispatch(showErrorMessage("The charges on the order have changed. Please refresh the order to view the changes."));
        return;
      } else if (response.data.orderId === -3) {
        dispatch(showErrorMessage("Care Label Configurations have changed: Please refresh the page to view the changes before placing the order."));
        return;
      } else if (response.data.orderId === -4) {
        dispatch(showErrorMessage("Care Label Configurations have changed: Please click Edit on the Order Items with Care Labels to Add Fabric Compositions."));
        return;
      }

      dispatch(pushSuccessMessage("Order has been successfully placed"));
      navigate(PAGE_URL.VIEW_ORDER, "orderId=" + response.data.orderId);
    } catch (error) {
      hideOverlay();
      showError(error);
    }
  };

  const isValidEmail = (validation) => {
    setValidEmail(validation);
  };

  return (
    <PageLayout className="cart">
      <section className="site-content bodyWrap" id="content">
        <section className="content-body">
          <div className="container">
            <form onSubmit={placeOrder}>
              <div className="row">
                <div className="col-12">
                  <h4 className="PageTitle">
                    <span className="page-parent">CART</span>
                  </h4>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <Link to={PAGE_URL.PRODUCT}>
                    <button className="mb-3 btn btn-primary" type="button">
                      Return to Shopping
                    </button>
                  </Link>
                </div>
              </div>
              {loaded && (
                <>
                  <CartHeader
                    cartConfig={cartConfig}
                    cartOrderDetails={cartOrderDetails}
                    onChange={onChange}
                    onBlur={onBlur}
                  />

                  {/* Cart Items */}
                  <div className="row">
                    <div className="col-12">
                      <ul className="products-list mt-4">
                        {cartOrderDetails.orderItems.map((item, index) => (
                          <OrderDetailItem
                            orderItem={item}
                            isPendingOrder={true}
                            removeItem={removeOrderItem}
                            key={index}
                          />
                        ))}
                      </ul>
                    </div>
                  </div>

                  {/* Order Charges */}
                  <div className="row">
                    <div className="col">
                      <ul className="charge-list">
                        {cartOrderDetails.orderCharges && cartOrderDetails.orderCharges.map((c, idx) => (
                          <OrderCharge key={idx} charge={c} />
                        ))}
                      </ul>
                    </div>
                  </div>

                  <CartShippingForm
                    cartConfig={cartConfig}
                    shippingAddresses={shippingAddresses}
                    validEmail={validEmail}
                    setValidEmail={isValidEmail}
                    onChange={onChange}
                    onBlur={onBlur}
                  />

                  <CartBillingForm
                    cartConfig={cartConfig}
                    onChange={onChange}
                    onBlur={onBlur}
                  />

                  <RelatedDocuments orderDetails={cartOrderDetails} />

                  <div className="row">
                    <div className="col-12">
                      <div
                        className="d-flex justify-content-between align-items-center"
                        id="placeOrder"
                      >
                        <div className="form-group form-check mb-0">
                          <input
                            className="form-check-input"
                            onClick={() => setTermsAccepted(!termsAccepted)}
                            type="checkbox"
                            required
                          />
                          <label className="form-check-label">
                            I agree to the{" "}
                            <Link
                              to={PAGE_URL.TERMS_AND_CONDITIONS}
                              target="_blank"
                            >
                              Terms &amp; Conditions
                            </Link>
                          </label>
                        </div>
                        <button className="btn btn-primary" type="submit">
                          Place Order
                        </button>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </form>
          </div>
        </section>
      </section>
    </PageLayout>
  );
};
