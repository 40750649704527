/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
/* eslint-disable no-trailing-spaces */
// React
import React from "react";

// Local
import { LabelValue } from "components/LabelValue";

export const LabelSelectField = ({
  label,
  value,
  options,
  editMode,
  onChange,
  onBlur,
  className,
  booleanValue = false, // Important if using boolean options!
  ...rest
}) => {

  // option tag converts num and int to strings
  // this method changes them back if requred.
  const changeHandler = (value) => {
    let _value = value;
    if (booleanValue) {
      _value = _value === "true" || _value === true;
    }

    onChange(_value);
  };

  return (
    <LabelValue
      showLabelAlways={true}
      listItemStyle={{
        overflowWrap: "anywhere",
      }}
      label={label}
      value={
        editMode ? (
          <select
            className={className + " form-control"}
            onChange={(e) => {
              changeHandler(e.target.value);
            }}
            value={value}
            {...rest}
          >
            {options && options.map((option, index) => (
              <option key={index} value={option.value}>
                {option.text}
              </option>
            ))}
          </select>
        ) : (
          value
        )
      }
    />
  );
};
