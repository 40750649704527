/* eslint-disable no-multiple-empty-lines */

// React
import React, { useState } from "react";

// Local
import api from "services/apiService";
import { PageLayout } from "layouts/PageLayout";
import { useURLNavigate } from "hooks/useURLNavigate";
import { pluralize, isNullOrUndefined } from "util/common";
import { ProductSubcodeSearch } from "./ProductSubcodeListSearch";
import { ProductSubcodeRow } from "./ProductSubcodeListRow";
import { joinParams } from "components/joinParams";
import { useOverlay } from "hooks/useOverlay";
import { useError } from "hooks/errors";
import { useSelector } from "react-redux";
import {
  FULFILLMENT_PROCESS,
} from "constants/fulfillmentProcess";

export const ProductSubcodeListPage = () => {
  // hooks
  const [showOverlay, hideOverlay] = useOverlay();
  const [showError] = useError();
  const navigate = useURLNavigate();
  const message = useSelector((state) => state.message.message);

  // state
  const [subcodes, setSubcodes] = useState([]);

  // methods
  const onSearch = async (searchValues) => {
    try {
      const params = getParams(searchValues);

      if (isNullOrUndefined(message)) {
        await showOverlay("Searching...");
      }

      const response = await api.getProductSubcodeList(params);
      hideOverlay();
      setSubcodes(response.data.subcodes);
    } catch (error) {
      hideOverlay();
      showError(error);
    }
  };

  const getParams = (searchValues) => {

    return joinParams({
      productSubcode: searchValues.productSubcode,
      productCode: searchValues.productCode,
      description: searchValues.description,
      sampleReferenceNumber: searchValues.sampleReferenceNumber,
      fulfillmentProcess:
        searchValues.fulfillmentProcess === FULFILLMENT_PROCESS.NONE
          ? ""
          : searchValues.fulfillmentProcess,
      customerId: searchValues.customerId,
      factoryId: searchValues.factoryId,
      sustainable: searchValues.sustainable,
    });
  };

  return (
    <PageLayout className="product-subcode-list">
      <section className="site-content bodyWrap" id="content">
        <section className="content-body">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="page-title-row">
                  <h4 className="PageTitle">
                    <span className="page-parent">PRODUCT SUBCODES</span>
                  </h4>
                  <button
                    className="btn btn-primary add-entity-btn"
                    onClick={() =>
                      navigate("/product-subcode-details", "createMode=true")
                    }
                  >
                    Add
                  </button>
                </div>
                <ProductSubcodeSearch onSearch={onSearch} />
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <section className="pt-5">
                  <table className="table table-striped" id="SubcodeList">
                    <thead>
                      <tr>
                        <th scope="col" width="12%">
                          Subcode
                        </th>
                        <th scope="col" width="12%">
                          Product Code
                        </th>
                        <th scope="col" width="21%">
                          Description
                        </th>
                        <th scope="col" width="10%">
                          Sample #
                        </th>
                        <th scope="col" width="8%">
                          Customer
                        </th>
                        <th scope="col" width="20%">
                          Factory
                        </th>
                        <th scope="col" width="9%">
                          Fulfillment Process
                        </th>
                        <th scope="col" width="8%">
                          Sustainable
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {subcodes &&
                        subcodes.map((subcode, index) => (
                          <ProductSubcodeRow key={index} subcode={subcode} />
                        ))}
                    </tbody>
                  </table>
                </section>
              </div>
            </div>
            <div className="subcodeNumber-pagination">
              <span>
                {"Showing " +
                  subcodes.length +
                  pluralize(" Product Subcode", subcodes.length)}
              </span>
            </div>
          </div>
        </section>
      </section>
    </PageLayout>
  );
};
